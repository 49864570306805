import { SET_PRODUTOS, ALTERAR_PRODUTO } from "./ActionTypes";

export const setProdutos = (produtos) => ({
  type: SET_PRODUTOS,
  produtos,
});

export const alterarProduto = produto => ({
  type: ALTERAR_PRODUTO,
  produto
})
