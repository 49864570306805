import React, { Component } from "react";
import { connect } from "react-redux";
import { Div, I, If, Label } from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";
import ProdutosSugestaoPesquisa from "../produtos/ProdutosSugestaoPesquisa";
import "./pesquisa.css";

const ITEM_LOCAL_STORAGE = "buscas-anteriores-loja-web";
const ID_ITEM_FECHA_MENU = Utils.uuidString();

const styles = {
  container: {
    position: "fixed",
    right: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background: "black",
    opacity: 0.5,
  },
  search: {
    position: "absolute",
    top: 106,
    height: "100%",
    width: "100%",
    zIndex: 1010,
  },
  labelBusca: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
};

class PesquisaProduto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resultadoPesquisa: props.resultadoPesquisa || [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      resultadoPesquisa: props.resultadoPesquisa,
      textoPesquisa: props.textoPesquisa,
      empresa: props.empresa
    });
  }

  getBuscasAnteriores = () => {
    let buscas = JSON.parse(localStorage.getItem(ITEM_LOCAL_STORAGE));

    return Utils.isValueValid(buscas) ? Array.from(buscas).reverse() : [];
  };

  fecharMenuBusca() {
    let div = document.getElementById(this.props.id);
    if (Utils.isValueValid(div)) {
      div.classList.toggle("collapsed");
      document.getElementById(ID_ITEM_FECHA_MENU).click();
    }
  }

  realizarPesquisa(texto, idBtnFecharMenu) {
    Http.get(`/busca/${texto}`)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              resultadoPesquisa: data,
            },
            () => {
              if (data.sucessoPesquisa) {
                this.props.history.push("/resultadopesquisa", {
                  resultadoPesquisa: data,
                  textoPesquisa: texto,
                  menus: this.state.menus,
                  empresa: this.state.empresa
                });
                this.fecharMenuBusca(idBtnFecharMenu);
              }

         //     this.setState({ textoPesquisa: "" });
            }
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  render() {
    const buscasAnteriores = this.getBuscasAnteriores();

    let produtos = this.state.resultadoPesquisa.sucessoPesquisa
      ? []
      : this.state.resultadoPesquisa.listaProdutosSugeridos;

    return (
      <Div
        bg={Color.NODE.LIGHT}
        id={this.props.id}
        className="collapse barra-pesquisa"
        style={styles.search}
      >
        <Div inline="center" padding="3">
          <Div
            className="d-flex flex-column align-items-center align-self-start justify-content-start"
            responsive="4"
            padding="4"
          >
            <Label
              value="BUSCAS ANTERIORES"
              family="SemiBold"
              style={styles.labelBusca}
            />
            <If and value1={Utils.isValueValid(buscasAnteriores)}>
              <Div padding="3">
                {buscasAnteriores.map((busca, index) => {
                  return (
                    <Div
                      inline="start"
                      key={index}
                      style={{ color: "#c5c5c5" }}
                      pointer
                      handlerClick={() => {
                        this.setState({ textoPesquisa: busca }, () =>
                          this.realizarPesquisa(busca, ID_ITEM_FECHA_MENU)
                        );
                      }}
                    >
                      <li>{busca}</li>
                    </Div>
                  );
                })}
              </Div>
              <Div
                inline="start"
                marginTop="2"
                handlerClick={() => {
                  localStorage.removeItem(ITEM_LOCAL_STORAGE);
                  this.props.handlerRemoveBuscas();
                }}
              >
                <I
                  icon={Type.ICON.CLOSEX}
                  colorText={Color.NODE.DANGER}
                  sizeIcon="1"
                  style={{ marginBottom: 10 }}
                  pointer
                />
                <Label
                  value="LIMPAR BUSCAS"
                  family="Light"
                  marginLeft="1"
                  pointer
                />
              </Div>
            </If>
            <If and value1={!Utils.isValueValid(buscasAnteriores)}>
              <Label value="Nenhuma busca anterior encontrada" />
            </If>
          </Div>

          <Div
            className="h-100 border-left align-self-start"
            responsive="8"
            padding="4"
          >
            <Div className="d-flex flex-column justify-content-start">
              <If and value1={Utils.isValueValid(produtos)}>
                <Div inline="start" margin="2">
                  <I
                    icon={Type.ICON.INFO}
                    colorText={Color.NODE.WARNING}
                    sizeIcon="2"
                  />
                  <Label
                    margin="2"
                    value={`Nenhum produto encontrado para o termo: "${this.state.textoPesquisa}"`}
                    style={{ color: Color.ECOMMERCE.MARRON_ESCURO }}
                    family="Light"
                  />
                </Div>
              </If>
              <Label
                value="SUGESTÕES"
                family="SemiBold"
                style={styles.labelBusca}
              />
            </Div>
            <Div padding="3">
              <If and value1={Utils.isValueValid(produtos)}>
                <ProdutosSugestaoPesquisa produtos={produtos} />
              </If>
              <If and value1={!Utils.isValueValid(produtos)}>
                <Label value="Nenhum produto sugerido para a pesquisa!" />
              </If>
            </Div>
          </Div>
        </Div>
        <Div
          toggle="collapse"
          target={`#${this.props.id}`}
          id={ID_ITEM_FECHA_MENU}
        />
        <Div
          style={styles.container}
          handlerClick={() => {
            this.fecharMenuBusca();
            this.props.handlerFecharMenuBusca();
          }}
        />

        <I
          icon={Type.ICON.CLOSEX}
          colorText={Color.NODE.DANGER}
          sizeIcon="2"
          pointer
          handlerClick={() => {
            this.fecharMenuBusca();
            this.props.handlerFecharMenuBusca();
          }}
          style={{ position: "absolute", right: 10, top: 10 }}
        />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
  };
};

export default connect(mapStateToProps)(PesquisaProduto);
