import React from 'react'

const DropDownHeader = (props) => {

    return (
        <a href={props.href} className={`dropdown-header ${props.className}`}>{props.value || props.children}</a>
    )

}

export default DropDownHeader 