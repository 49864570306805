export default class Horario {
  idEmpresa;
 	descricao;
	inicio;
	fim;
 	segunda;
  terca;
	quarta;
	quinta;
	sexta;
	sabado;
	domingo;
  

  constructor(idEmpresa) {
    this.idEmpresa = idEmpresa;
    this.descricao = "";
    this.inicio = "00:00";
    this.fim = "00:30";
    this.segunda = false;
    this.terca = false;
    this.quarta = false;
    this.quinta = false;
    this.sexta = false;
    this.sabado = false;
    this.domingo = false;
  }
}