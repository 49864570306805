import { Http } from '../utilities';

class EmpresaService {
  static async getEmpresaParams() {

    let res = null;
    await Http.get(`/empresa/parametros`).then(
      response => {
        res = response;
      },
    );
    return res;
  }

  static async getSobre() {

    let res = null;
    await Http.get(`/empresa/sobre`).then(
      response => {
        res = response;
      },
    );
    return res;
  }

  static async getPolitica() {

    let res = null;
    await Http.get(`/empresa/politica`).then(
      response => {
        res = response;
      },
    );
    return res;
  }

  static async putEmpresa(obj) {
    let res = null;

    await Http.post('/empresa/sincronizar/parametros', obj).then(response => {
      res = response;
    });

    return res;

  }
}

export default EmpresaService;

