import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./assets/css/fonts/fonts.css"

import "bootstrap/dist/js/bootstrap.min.js";
import Init from "./Init";

import { Provider } from "react-redux";
import { Store } from "./redux/store/Store";
import ThemeContextProvider from "./context/ThemeContext"
import TagManagerContextProvider from "./context/useTagManager"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <ThemeContextProvider>
        <TagManagerContextProvider>
          <Init />
        </TagManagerContextProvider>
      </ThemeContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
