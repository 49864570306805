export default class EndPoints {

  static BASE_URL = 'http:\\www.maiitre.com.br:8586/loja/v1/';

  static CLIENTE_ENDERECO = "cliente/endereco"
  static CLIENTE_ENDERECOS = "cliente/enderecos"
  static ENDERECOS_POR_CLIENTE = "cliente/%s/enderecos"
  static CLIENTE = "cliente"
  static CLIENTE_PEDIDOS = "pedido/resumo/cliente/"
  static PEDIDO = "pedido/%s"
  static PRODUTOS_POR_ANUNCIO = "produto/oferta/%s"
  static BANNERS_POR_DEPARTAMENTO = "/oferta/departamento/%s"
  static PRODUTOS_SIMILARES = "produto/%s/similares"
  static PRODUTOS_DEPARTAMENTO ="produto/departamento/%s/pagina/%p"
  static PRODUTOS_SECAO ="produto/secao/%s/pagina/%p"
  static PRODUTOS_MARCA = "produto/marca/%s/pagina/%p"
  static PRODUTOS_BAIXARAM_PRECO = "produto/baixaram/preco"
  static PRODUTOS_DESTAQUE = "produto/destaque"
  static PRODUTOS_COMBO = "produto/combo"
  static PEDIDO_BIN_CARTAO = "pedido/consultar/bin/%s";
  static PEDIDO_SITUACAO = "pedido/%s/situacao"
  static EMPRESA_POLITICA = "empresa/politica"
  static EMPRESA_SOBRE = "empresa/sobre"
  static MENU = "menu"
  static PRODUTOS_ULTIMOS_VISUALIZADOS = "produto/visualizados/cliente"
  static ANUNCIO_DESTAQUE = "oferta/destaques"
  static USUARIO_RECUPERAR_SENHA = "cliente/resetarsenhaporemail";
  static PRODUTO_BUSCA_FILTRO = "produto/busca/filtro"
  static ANUNCIO_PRODUTOS = "oferta/produtos"
  static CUPOM_DESCONTO_VALIDAR = "cupom/validar"
  static VERIFICAR_EMAIL = "cliente/verificaremail/%s";
  static LISTA_COMPRA_SUGERIDAS = "listacompra/sugeridas"

  static getEndPoint = (url) => {
    return this.BASE_URL.concat(url)
  }


}
