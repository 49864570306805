import React, { Component } from "react";
import Div from "../layout/div/Div";
import { Label } from "../index"

import "./carousel.css";
import CarouselBannerGenerico from "./CarouselBannerGenerico";
import { Color, Mask } from "../../utilities";
import Row from "../layout/Row";

const WIDTH_IMAGE = 540;
const HEIGHT_IMAGE = 491;

class CarouselAnuncioOferta extends Component {

  labelsBanners() {
    return (
      <Div
        style={{
          position: "absolute",
          color: "#FFF",
          width: this.props.widthContainerBanner,
          height: HEIGHT_IMAGE,
          borderRadius: "11px",
          zIndex: 997,
          padding: "30px",
          fontWeight: "bolder",
          
        }}
      >
        <Row>
          <Label style={{ fontSize: "26px" }} value="Brinquedos para pet a partir de:" /> <br />
        </Row>
        
        <Label style={{ fontSize: "26px", color: Color.EMPRESA.PRIMARIA }} value={ "R$ " + Mask.convertNumberBr(115)} />

      </Div>
    )
  }


  render() {
    return (
      <Div style={{ width: this.props.widthContainerBanner, margin: 3 }}>

        <CarouselBannerGenerico
          timeSlide={7000}
          itens={this.props.itens}
          slidesPerPage={1}
          dots
          widthDots={WIDTH_IMAGE}
        />
      </Div>
    );
  }
}

export default CarouselAnuncioOferta;
