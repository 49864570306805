import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Div, I, Label } from "../../components";
import RadioCustom from "../../components/radiocustom/RadioCustom";
import { Color, Mask, Routes, Type, Utils } from "../../utilities";

import { setEnderecoEntrega } from "../../redux/actions";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";

const styles = {
  btnCloseLoginMenu: {
    position: "absolute",
    top: 5,
    right: 5,
    fontSize: 25,
    color: Color.ECOMMERCE.LARANJA,
  },
  labelMinhaConta: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 20,
  },
  itemMenuCliente: {
    color: Color.ECOMMERCE.CINZA,
    fontSize: 15,
  },
  containterLocaisEntrega: {
    height: 200,
    overflowY: "auto",
  },
};

class MenuCliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuario: this.initUsuario(),
      mensagemErro: "",
      recuperarSenha: false,
    };
  }

  initUsuario() {
    // let usuarioSalvo = JSON.parse(
    //   localStorage.getItem(Keys.KEY_CLIENTE_LOJA_WEB)
    //  );

    let usuarioSalvo = UsuarioUtils.getUsuarioLojaWeb();

    if (Utils.isValueValid(usuarioSalvo)) {
      return { ...usuarioSalvo };
    } else {
      return { usuario: "", senha: "" };
    }
  }

  sair() {
    UsuarioUtils.removerUsuarioLojaWeb();
    Utils.refreshPage();
  }

  getEnderecoSelecionado(endereco) {
    let selecionado = false;

    let carrinho = CarrinhoUtils.getCarrinho();

    if (Utils.isValueValid(carrinho)) {
      let enderecoEntrega = carrinho.enderecoEntrega;

      if (Utils.isValueValid(enderecoEntrega)) {
        selecionado =
          enderecoEntrega.idClienteEndereco === endereco.idClienteEndereco;
      } else {
        selecionado = endereco.principal;
      }
    } else {
      selecionado = endereco.principal;
    }

    return selecionado;
  }

  setEnderecoSelecionado(endereco) {
    let carrinho = {
      ...this.props.carrinho,
      enderecoEntrega: endereco,
    };

    this.props.setEnderecoEntrega(carrinho);
  }

  setTipoEntregaRetirada(tipoEntregaRetirada) {
    let carrinho = {
      ...this.props.carrinho,
      tipoEntregaRetirada,
    };

    this.props.setEnderecoEntrega(carrinho);
  }

  renderMeuLocalEntrega() {
    const { usuario } = this.state;

    let enderecos = Array.from(usuario.enderecos);
    let parametros = this.props.parametros || {};

    return (
      <>
        <I
          icon={Type.ICON.CLOSEX}
          pointer
          style={styles.btnCloseLoginMenu}
          handlerClick={() => this.props.handlerCloseMenu()}
        />
        <Div column>
        {
          /**
           *   <Div inline="between" style={{ marginTop: 20 }}>
            <CheckboxToggle
              labelRight="RETIRADA"
              labelLeft="ENTREGA"
              bg="info"
              checked={isCheckedTipoRetirada}
              handlerClick={() =>
                this.setTipoEntregaRetirada(
                  isCheckedTipoRetirada
                    ? EntregaRetiradaUtils.TIPO_ENTREGA
                    : EntregaRetiradaUtils.TIPO_RETIRADA
                )
              }
            />
          </Div>
           */
        }
          <Label
            className="modal-title"
            style={{
              color: Color.ECOMMERCE.LARANJA,
              fontSize: 20,
              marginTop: 10,
            }}
            family="Bold"
            value="Meu local de entrega"
          />
          <Div marginTop="2" style={styles.containterLocaisEntrega}>
            {enderecos.map((endereco, index) => {
              let isEnderecoSelecionado = this.getEnderecoSelecionado(endereco);
              return (
                <Div
                  inline="start"
                  key={index}
                  handlerClick={() => this.setEnderecoSelecionado(endereco)}
                  pointer
                >
                  <RadioCustom
                    checked={isEnderecoSelecionado}
                    //handlerChange={(e) => this.setEnderecoSelecionado(endereco)}
                    left
                    value={
                      <>
                        <Label
                          pointer
                          value={endereco.descricao}
                          style={{
                            lineHeight: "15px",
                            fontSize: 13,
                            width: 200,
                            color: Color.ECOMMERCE.CINZA_ESCURO,
                          }}
                          family="Bold"
                          className="text-ellipsis"
                        />
                        <Label
                          pointer
                          value={`${endereco.logradouro}, ${endereco.bairro}`}
                          style={{
                            lineHeight: "13px",
                            fontSize: 13,
                            width: 200,
                            color: Color.ECOMMERCE.CINZA,
                          }}
                          family="Light"
                          className="text-ellipsis"
                        />
                      </>
                    }
                  />
                </Div>
              );
            })}
          </Div>
        </Div>
        <Label
          value={`Entrega grátis à partir de R$ ${Mask.formatValueBr(parametros.valorMinimoPedidoFreteGratis || 0)}`}
          family="Bold"
          col="12"
          style={{
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 13,
            textAlign: "center",
          }}
        />
        <Label style={{ width: "100%", borderBottom: `1px dotted #c8c8c8` }} />
        <Div
          className="d-flex flex-column justify-content-end align-items-end"
          margin="2"
        >
          <Label
            family="SemiBold"
            style={styles.labelMinhaConta}
            value="Minha conta"
          />
          <Label
            family="SemiBold"
            style={styles.itemMenuCliente}
            value="Dados pessoais"
            pointer
            handlerClick={() => {
              this.props.history.push(Routes.CLIENTE);
              this.props.handlerCloseMenu();
            }}
          />
          <Label
            family="SemiBold"
            style={styles.itemMenuCliente}
            value="Meus pedidos"
            pointer
            handlerClick={() => {
              this.props.history.push(Routes.CLIENTE_PEDIDOS);
              this.props.handlerCloseMenu();
            }}
          />
          <Label
            family="SemiBold"
            style={styles.itemMenuCliente}
            value="Sair"
            pointer
            handlerClick={() => this.sair()}
          />
        </Div>
      </>
    );
  }

  render() {
    return <Div style={{ width: 320 }}>{this.renderMeuLocalEntrega()}</Div>;
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.parametrosPedidoState,
    ...store.carrinhoState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEnderecoEntrega }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuCliente);
