import UsuarioUtils from "../utils/UsuarioUtils";

export default class PedidoLojaWeb {
  idlancamentoPedidoApp;
  itens;
  idCliente;
  valorTotal;
  idFilial;
  idEmpresaHorarioEntrega;
  tipoPagamento;
  tipoEntrega;
  dataEntrega;
  valorTaxaEntrega;
  valorTaxaServico;
  valorDescontoCupom;
  obs;
  responsavelRecebimento;
  incluirCpfCnpjNota;
  levarTrocoPara;
  recebimento;
  enderecoEntrega;

  constructor(itens, valorTotal, enderecoEntrega) {
    //let cliente = JSON.parse(localStorage.getItem(Keys.KEY_USUARIO_LOJA_WEB)) || {};
    let cliente = UsuarioUtils.getUsuarioLojaWeb() || {};
    
    this.itens = itens || [];
    this.recebimento = [];
    this.idCliente = cliente.idCliente;
    this.responsavelRecebimento = cliente.nome;
    this.valorTotal = valorTotal;
    //  this.idlancamentoPedidoApp = Utils.uuidv4();
    this.incluirCpfCnpjNota = false;
    this.recebimento = {};
    this.enderecoEntrega = enderecoEntrega;
  }

}
