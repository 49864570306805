import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from "jodit-react";

const TextAreaCustom = ({ handleChance, value }) => {
  const editor = useRef(null)
  const [content, setContent] = useState(value);

  function customHandleChance(e) {
    setContent(e)
    handleChance(e)
  }

  useEffect(() => {
    if(!!value) {
      setContent(value)
    }
  }, [value])

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    language: "pt_br",
    buttons: "bold,italic,underline,strikethrough,ul,ol,indent,outdent,left,font,fontsize,paragraph,classSpan,brush,cut,copy,selectall,hr,table"
  }

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      
      tabIndex={1} // tabIndex of textarea
      onBlur={newContent => customHandleChance(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={newContent => { }}
    />
  );
}

export default TextAreaCustom;