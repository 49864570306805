import { SET_PARAMETROS } from "../actions/ActionTypes";

const initialState = {
  parametros: {},
};

export const parametrosPedidoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARAMETROS:
      return {
        ...state,
        parametros: action.parametros,
      };
    default:
      return state;
  }
};
