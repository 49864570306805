import React from 'react'
import Image from './Image'
import logo from '../../assets/images/logo1.svg'



const Logo = (props) => {

    return (
        <Image src={logo} alt="Não foi possível carregar a logo"  responsive></Image>
    )

}

export default Logo