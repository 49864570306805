import React, { Component } from "react";
import { Div, I, Label } from "../../../components";

import ItemProdutoAbaixouPreco from "../ItemProdutoAbaixouPreco";
import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";

import "../produtos.css";
import { connect } from "react-redux";
import CarouselProdutoGenericoNovo from "../../../components/carousel/CarouselProdutoGenericoNovo";
import ItemProdutoCombo from "../combo/ItemProdutoCombo";
import { ProdutoUtils } from "../..";

class ProdutosCombo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
    };
  }

  componentDidMount() {
    this.listarProdutos();
  }

  listarProdutos() {
    Http.get(EndPoints.PRODUTOS_COMBO)
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtos: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getProdutosCombo = () => {
    let itens = [];

    Array.from(this.state.produtos).forEach((produto, index) => {
      let prod = { ...produto.combo, combo: true, itensCombo: produto.itens };
      itens.push(
        <ItemProdutoAbaixouPreco
          history={this.props.history}
          key={index}
          image={prod.urlImagemPrincipalThumbnail}
          descricao={prod.descricao}
          precoUnitario={prod.precoUnitario}
          precoVenda={prod.precoVenda}
          precoAtacado={prod.precoAtacado}
          codigoBarras={prod.codigoBarras}
          handlerClick={(updateItem) =>
            this.props.handlerClick(prod, updateItem)
          }
          quantidade={prod.quantidade}
          disponivel={prod.disponivel}
          percentualEconomia={prod.percentualEconomia}
          estoqueDisponivel={prod.estoqueDisponivel}
          quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
          fatorEmGrama={prod.fatorEmGrama}
          possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
          precosConcorrentes={prod.precosConcorrentes || []}
          handlerAddItemCarrinho={(quantidade) => {
            prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
            this.props.handlerAddItemCarrinho(prod);
          }}
          empresa={this.props.empresa}
        />
      );
    });

    return itens;
  };

  getProdutos() {
    return Array.from(this.state.produtos).map((prod, index) => {
      return (
        <ItemProdutoCombo
          history={this.props.history}
          key={index}
          image={prod.urlImagemPrincipalThumbnail}
          descricao={prod.descricao}
          precoUnitario={prod.precoUnitario}
          precoVenda={prod.precoVenda}
          precoAtacado={prod.precoAtacado}
          codigoBarras={prod.codigoBarras}
          handlerClick={
            (updateItem) => {}
            // this.props.handlerClick(prod, updateItem)
          }
          quantidade={prod.quantidade}
          disponivel={prod.disponivel}
          empresa={this.props.empresa}
          percentualEconomia={prod.percentualEconomia}
          estoqueDisponivel={prod.estoqueDisponivel}
          quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
          fatorEmGrama={prod.fatorEmGrama}
          possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
          precosConcorrentes={prod.precosConcorrentes || []}
          handlerAddItemCarrinho={(quantidade) => {
            //prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
            //this.props.handlerAddItemCarrinho(prod);
          }}
        />
      );
    });
  }

  render() {
    let combos = this.getProdutosCombo();
    return (
      Utils.isValueValid(combos) && (
        <Div
          style={{
            width: 1150,
            marginBottom: 30,
            borderRadius: 10,
          }}
        >
          <Div inline="start" col="12">
            <I
              icon={Type.ICON.CUBES}
              style={{
                fontSize: 25,
                color: Color.ECOMMERCE.CINZA_ESCURO,
                marginBottom: 15,
              }}
            />
            <Label
              style={{
                color: Color.ECOMMERCE.CINZA_ESCURO,
                fontSize: "25px",
                marginLeft: 10,
              }}
              family="Bold"
              value="COMBOS"
            />
          </Div>
          <Div inline="start">
            <CarouselProdutoGenericoNovo
              itens={combos}
              slidesPerPage={3}
              arrows
            />
          </Div>
        </Div>
      )
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
    ...store.empresaState
  };
};

export default connect(mapStateToProps)(ProdutosCombo);
