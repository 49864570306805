import React from "react";
import PropTypes from "prop-types";
import {
  className,
  underline,
  colorText,
  float,
  pointer,
  margin,
  padding,
  display,
  offset,
  backGround,
  inline,
  col,
  border,
  family,
} from "../../css";

const getClassDefault = (props) => {
  let classe = "";
  classe = classe.concat(colorText(props));
  classe = classe.concat(underline(props));
  classe = classe.concat(float(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(display(props));
  classe = classe.concat(offset(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(border(props));
  classe = classe.concat(col(props));
  classe = classe.concat(className(props));

  return classe;
};

const Titulo = (props) => {
  return generateTitle(
    props,
    props.type,
    getClassDefault(props),
    props.value,
    props.children,
    props.style
  );
};

const getStyleFontFamily = (props) => {
  let style = {
    ...props.style,
    ...family(props),
  };
  return style;
};

const generateTitle = (props, type, classe, message, children, style) => {
  let title;
  switch (type) {
    case "h2":
      title = (
        <h2
          id={props.id}
          className={classe}
          data-toggle={props.toggle}
          data-dismiss={props.dismiss}
          data-target={props.target}
          style={getStyleFontFamily(props)}
        >
          {message || children}
        </h2>
      );
      break;
    case "h3":
      title = (
        <h3
          id={props.id}
          className={classe}
          data-toggle={props.toggle}
          data-dismiss={props.dismiss}
          data-target={props.target}
          style={getStyleFontFamily(props)}
        >
          {message || children}
        </h3>
      );
      break;
    case "h4":
      title = (
        <h4
          id={props.id}
          className={classe}
          data-toggle={props.toggle}
          data-dismiss={props.dismiss}
          data-target={props.target}
          style={getStyleFontFamily(props)}
        >
          {message || children}
        </h4>
      );
      break;
    case "h5":
      title = (
        <h5
          id={props.id}
          className={classe}
          data-toggle={props.toggle}
          data-dismiss={props.dismiss}
          data-target={props.target}
          style={getStyleFontFamily(props)}
        >
          {message || children}
        </h5>
      );
      break;
    case "h6":
      title = (
        <h6
          id={props.id}
          className={classe}
          data-toggle={props.toggle}
          data-dismiss={props.dismiss}
          data-target={props.target}
          style={getStyleFontFamily(props)}
        >
          {message || children}
        </h6>
      );
      break;
    default:
      title = (
        <h1
          id={props.id}
          className={classe}
          data-toggle={props.toggle}
          data-dismiss={props.dismiss}
          data-target={props.target}
          style={getStyleFontFamily(props)}
        >
          {message || children}
        </h1>
      );
  }
  return title;
};

Titulo.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  value: PropTypes.any,
};

Titulo.defaultProps = {
  type: "h1",
};

export default Titulo;
