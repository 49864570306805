import Type from "./Type";

export default class Pattern {

  static DATE = {
    DD: "DD",
    DD_MM_YYYY: "DD/MM/YYYY",
    DD_MM_YYYY_HH_MM: "DD/MM/YYYY HH:mm",
    DD_MM: "DD/MM",
    YYYY_MM_DD_HH_MM: "YYYY-MM-DD HH:mm",
    YYYY_MM_DD: "YYYY-MM-DD"
  }

  static INPUT = {
    CPF_CNPJ: {
      pattern:
        "([0-9]{2}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[-]?[0-9]{2})",
      message: "CPF com 11 dígitos ou CNPJ com 14 dígitos"
    },
    PHONE: {
      pattern: "\\([0-9]{2}\\) [0-9]{4,5}-[0-9]{4}$",
      message: "Telefone com DDD",
      example: "(99) 9999-9999",
    },
    CEP: {
      pattern: "[0-9]{5}[-]?[0-9]{3}",
      message: "CEP com 8 dígitos",
      example: "74000-000"
    },
    NUMBER: {
      pattern: "^[0-9]{1,6}(\\,\\d{1,2})?$",
      message: "Número sem ponto e vírgula",
      example: "1"
    }
  };

  static getPattern(type) {
    switch (type) {
      case Type.INPUT.PHONE:
        return Pattern.INPUT.PHONE;
      case Type.INPUT.CPF_CNPJ:
        return Pattern.INPUT.CPF_CNPJ;
      case Type.INPUT.CEP:
        return Pattern.INPUT.CEP;
      default:
        return "";
    }
  }
}
