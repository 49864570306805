import React from "react";
import { Component } from "react";
import {
  ButtonIconOutLine,
  Div,
  I,
  If,
  Label,
  MessageSpinner,
  Row,
  TimeLinePedidoLojaWeb,
} from "../../components";
import {
  Color,
  EndPoints,
  Http,
  StatusPedidoLojaWeb,
  Type,
  Utils,
} from "../../utilities";

import UsuarioUtils from "../utils/UsuarioUtils";

import { ReactComponent as IconCalendario } from "../../assets/images/icon-calendario.svg";
import { ReactComponent as IconProduto } from "../../assets/images/icon-produto.svg";
import { ReactComponent as IconLojaHome } from "../../assets/images/icon-loja-home.svg";
import TemplatePedidos from "./TemplatePedidos";

const styles = {
  containerPedidos: {
    // backgroundColor: "#E5E5E5",
    borderRadius: 10,
    padding: 10,
  },
  containterHeaderPedido: {
    backgroundColor: Color.ECOMMERCE.WHITE,
    width: 300,
    height: 350,
    borderRadius: 10,
    margin: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
  },
  labelDescricao: { fontSize: 15, color: "#878787", lineHeight: "12px" },
  labelValor: {
    color: "#C4C4C4",
    fontSize: 15,
    lineHeight: "12px",
  },
  labelValorAlterado: {
    color: "#C4C4C4",
    fontSize: 15,
    lineHeight: "12px",
    textDecoration: "line-through",
  },
  btnDetalhesCompra: {
    backgroundColor: Color.EMPRESA.PRIMARIA,
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    width: "239px",
    borderRadius: 10,
    fontWeight: "bold",
  },
  containterTopBody: {
    borderBottom: "1px dashed #C4C4C4",
    width: "100%",
  },
  topBody: {
    lineHeight: "10px",
  },
  labelPedido: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    textAlign: "left",
  },
  labelPedidoCancelado: {
    color: "red",
    textAlign: "left",
    textDecoration: "line-through",
  },
  labelPagamentoConfirmado: {
    color: "#31CDFF",
    fontSize: 15,
    lineHeight: "18px",
    width: "100%",
  },
  labelPagamentoAguardar: {
    color: Color.EMPRESA.PRIMARIA,
    fontSize: 16,
    lineHeight: "18px",
    width: "100%",
  },
  labelTitleHeader: {
    fontSize: 17,
    color: "#878787",
    lineHeight: "12px",
  },
  labelNaoTemPedidos: {
    fontSize: 30,
    color: Color.EMPRESA.PRIMARIA,
  },
};

class MeusPedidos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pedidos: [],
      pesquisando: false,
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.setState({ pesquisando: true }, () => this.listarPedidos());
  }

  listarPedidos() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();

    if (Utils.isValueValid(cliente)) {
      Http.get(EndPoints.CLIENTE_PEDIDOS.concat(cliente.idCliente))
        .then(({ status, data }) => {
          if (status) {
            this.setState({ pedidos: data, pesquisando: false });
          } else {
            this.setState({ pesquisando: false });
          }
        })
        .catch((erro) => console.log(erro));
    }
  }

  getElemententosTimeLine(pedido) {
    let elementos = [];

    elementos.push({
      elemento: StatusPedidoLojaWeb.EM_ABERTO,
      active: Utils.isValueValid(pedido.dataSituacaoEmAberto),
    });
    elementos.push({
      elemento: StatusPedidoLojaWeb.EM_SEPARACAO,
      active: Utils.isValueValid(pedido.dataSituacaoEmSeparacao),
    });

    if (pedido.tipoEntrega === "ENTREGA") {
      elementos.push({
        elemento: StatusPedidoLojaWeb.SAIU_ENTREGA,
        active: Utils.isValueValid(pedido.dataSituacaoSaiuEntrega),
      });
    } else {
      elementos.push({
        elemento: StatusPedidoLojaWeb.AGUARDANDO_RETIRADA,
        active: Utils.isValueValid(pedido.dataSituacaoAguardandoRetirada),
      });
    }

    elementos.push({
      elemento: StatusPedidoLojaWeb.CONCLUIDO,
      active: Utils.isValueValid(pedido.dataSituacaoConcluido),
    });

    return elementos;
  }

  setStatusPedido(pedido) {
    let status = "ABERTO";
    if (Utils.isValueValid(pedido.dataSituacaoEmAberto))
      status = StatusPedidoLojaWeb.EM_ABERTO;
    if (Utils.isValueValid(pedido.dataSituacaoEmSeparacao))
      status = StatusPedidoLojaWeb.EM_SEPARACAO;
    if (Utils.isValueValid(pedido.dataSituacaoAguardandoRetirada))
      status = StatusPedidoLojaWeb.AGUARDANDO_RETIRADA;
    if (Utils.isValueValid(pedido.dataSituacaoSaiuEntrega))
      status = StatusPedidoLojaWeb.SAIU_ENTREGA;
    if (Utils.isValueValid(pedido.dataSituacaoProblemasEntrega))
      status = StatusPedidoLojaWeb.PROBLEMAS_ENTREGA;
    if (Utils.isValueValid(pedido.dataSituacaoConcluido))
      status = StatusPedidoLojaWeb.CONCLUIDO;
    if (Utils.isValueValid(pedido.dataSituacaoCancelado))
      status = StatusPedidoLojaWeb.CANCELADO;

    return status;
  }

  getStyleLabelPedido(pedido) {
    let style = styles.labelPedido;

    if (pedido.status === StatusPedidoLojaWeb.CANCELADO) {
      style = styles.labelPedidoCancelado;
    }

    return style;
  }

  renderTemplatePedidos() {
    let pedidos = this.state.pedidos;

    return (
      <Div inline="center" className="w-100" padding="2">
        <Div style={styles.containerPedidos} responsive="12">
          {Array.from(pedidos).map((pedido, index) => {
            pedido.status = this.setStatusPedido(pedido);
            pedido.alteradoNaSeparacao =
              Number(pedido.valorTotal).toFixed(2) !==
              Number(pedido.valorTotalSeparacao);

            if (pedido.status === StatusPedidoLojaWeb.CANCELADO) {
              pedido.cancelado = true;
            } else if (
              pedido.status === StatusPedidoLojaWeb.PROBLEMAS_ENTREGA
            ) {
              pedido.problemasEntrega = true;
            }

            if (Utils.isValueValid(pedido.dataSituacaoPagamentoConfirmado)) {
              pedido.pago = true;
            }

            let styleLabelPedido = this.getStyleLabelPedido(pedido);

            return (
              <Div
                inline="start"
                style={{
                  backgroundColor: "#E5E5E5",
                  borderRadius: 10,
                  padding: 10,
                  margin: "20px 0",
                }}
                key={index}
              >
                
                <Div
                  className="d-flex flex-column align-self-start"
                  padding="2"
                  responsive="8"
                >
                  <Div inline="between" style={styles.containterTopBody}>
                    <Div inline="center">
                      {/* <Image src={IconLoja} /> */}
                      <IconLojaHome />
                      <Div
                        className="d-flex flex-column align-items-start"
                        marginLeft="2"
                        marginTop="2"
                        style={styles.topBody}
                      >
                        {Utils.isValueValid(pedido.fantasia) && (
                          <Label
                            value={Utils.capitalize(pedido.fantasia)}
                            family="Bold"
                            style={styles.labelTitleHeader}
                          />
                        )}

                        <Label
                          value={`em ${pedido.descricaoDataPedido}`}
                          family="Light"
                          style={styles.labelTitleHeader}
                        />
                      </Div>
                    </Div>
                    <Div inline="center">
                      {/* <Image src={IconPrevisaoEntrega} /> */}
                      <IconCalendario />
                      <Div
                        className="d-flex flex-column align-items-start"
                        marginLeft="2"
                        marginTop="2"
                        style={styles.topBody}
                      >
                        <Label
                          value="Previsão de entrega"
                          family="Bold"
                          style={styles.labelTitleHeader}
                        />
                        <Label
                          value={`${pedido.descricaoDataEntrega}, ${pedido.descricaoHorario}`}
                          family="Light"
                          style={styles.labelTitleHeader}
                        />
                      </Div>
                    </Div>
                    <Div inline="center">
                      {/* <Image src={IconQuantidadeProduto} /> */}
                      <IconProduto />
                      <Div
                        className="d-flex flex-column align-items-start"
                        marginLeft="2"
                        marginTop="2"
                        style={styles.topBody}
                      >
                        <Label
                          value={`${pedido.quantidadeProdutos} produtos`}
                          family="Bold"
                          style={styles.labelTitleHeader}
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Div marginTop="5">
                    <TimeLinePedidoLojaWeb
                      elements={this.getElemententosTimeLine(pedido)}
                    />
                  </Div>
                </Div>

                <TemplatePedidos
                  pedido={pedido}
                  referencia={pedido.referencia}
                  status={pedido.status}
                  styleLabelPedido={styleLabelPedido}
                />

                <Div inline="start" style={{ width: "100%", zIndex: 9999, marginTop: "-65px" }}>
                  <ButtonIconOutLine
                    className="btn-sm"
                    toggle="collapse"
                    //icon="fa fa-filter"
                    style={styles.btnDetalhesCompra}
                    value="Detalhes do Pedido"
                    handlerClick={() =>
                      this.props.history.push("/cliente/pedidos/detalhe", {
                        pedido,
                      })
                    }
                  />
                </Div>
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }

  renderTemplateNaoTemPedido() {
    return (
      <Div column className="w-100" padding="5">
        <Div
          style={styles.labelNaoTemPedidos}
          responsive="10"
          inline="center"
          margin="5"
        >
          <I icon={Type.ICON.INFO} sizeIcon="2" />
          <Label
            family="SemiBold"
            value="Nenhum pedido encontrado, realize seu primeiro pedido."
            margin="3"
          />
        </Div>
        <Div
          style={{
            color: Color.ECOMMERCE.WHITE,
            backgroundColor: this.state.corPrimaria,
            padding: 15,
            borderRadius: 10,
            fontSize: 25,
          }}
          pointer
          handlerClick={() => this.props.history.replace("/")}
        >
          <I icon={Type.ICON.SALE_CART} sizeIcon="2" pointer />
          <Label
            value="Continuar comprando"
            family="Light"
            margin="3"
            pointer
          />
        </Div>
      </Div>
    );
  }

  render() {
    let pedidos = this.state.pedidos;
    return (
      <Div className="w-100" padding="2" style={{ marginTop: "-60px" }}>
        <Row padding="3" style={{ alignItems: "center" }}>
          <I
            icon={Type.ICON.HOME}
            pointer
            style={{ fontSize: 24, marginRight: 5, color: "#BABABA" }}
          />
          <Label
            value="Inicio"
            style={{ fontSize: 16, color: "#BABABA" }}
            family="Light"
            pointer
            margin="2"
            handlerClick={() =>
              this.props.history.push("/")
            }
          />
          <Label
            value=">"
            style={{ fontSize: 16, color: "rgb(54, 162, 235)" }}
            margin="2"
          />

          <Label
            value="Pedidos"
            family="bold"
            style={{ fontSize: 16, color: "#373646" }}
            margin="2"
          />
        </Row>
        <If and value1={Utils.isValueValid(pedidos)}>
          {this.renderTemplatePedidos()}
        </If>
        <If and value1={this.state.pesquisando}>
          <Div inline="center" className="w-100" marginTop="5">
            <MessageSpinner
              style={{ backgroundColor: Color.EMPRESA.PRIMARIA }}
              colorText={Color.NODE.WHITE}
            >
              <Label
                value="Consultando pedidos, aguarde"
                family="Light"
                style={{ fontSize: 30 }}
                margin="2"
              />
            </MessageSpinner>
          </Div>
        </If>
        <If
          and
          value1={!Utils.isValueValid(pedidos)}
          value2={!this.state.pesquisando}
        >
          {this.renderTemplateNaoTemPedido()}
        </If>
      </Div>
    );
  }
}

export default MeusPedidos;
