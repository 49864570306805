import React, { Component } from "react";
import { Color, Utils } from "../../utilities";
import Div from "../layout/div/Div";

import "./carousel.css";

const styles = {
  labelTopLeft: {
    width: "100%",
  },
  labelTitulo: {
    color: Color.ECOMMERCE.WHITE,
    position: "absolute",
    right: 10,
    top: 10,
    width: 350,
    //textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
  },
  labelTeste: {},
};

class CarouselBannerTopMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      next: false,
      previos: true,
      itens: props.itens || [],
      activeIndex: 0,
    };

    this.visualizarProdutosAnuncio = this.visualizarProdutosAnuncio.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ itens: props.itens });
  }

  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
  }) {
    this.props.history.push("/anuncio/produtos", {
      titulo,
      idAnuncioOferta,
      idDepartamentoSecaoFiltro,
      tipoDepartamentoSecao,
      marcaFiltro,
      possuiProdutos,
    });
  }

  render() {
    const ID_CAROUSEL = Utils.uuidString();

    const { itens } = this.state;

    return (
      <Div>
        <Div className="carousel slide" id={ID_CAROUSEL} dataRide="carousel">
          <Div className="carousel-inner">
            {Array.from(itens).map((item, index) => {
              return (
                <Div
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  key={index}
                  handlerClick={() => this.visualizarProdutosAnuncio(item)}
                >
                  <Div
                    className="carousel-captiond-md-block"
                    style={styles.labelTopLeft}
                  >
                    {item.value}
                  </Div>
                </Div>
              );
            })}
          </Div>
        </Div>
        <ol style={{ listStyleType: "none", marginTop: 10 }}>
          <Div inline="start">
            {Array.from(this.props.itens).map((item, index) => {
              return (
                <li
                  key={index}
                  data-target={`#${ID_CAROUSEL}`}
                  data-slide-to={index}
                  onClick={() => this.setState({ activeIndex: index })}
                  className={`indicator-carousel-banner-top-mobile m-1 ${
                    this.state.activeIndex === index ? "active" : ""
                  }`}
                />
              );
            })}
          </Div>
        </ol>
      </Div>
    );
  }
}

export default CarouselBannerTopMobile;
