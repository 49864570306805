import { SET_MENUS } from "../actions/ActionTypes";

const initialState = {
  menus: [],
};

export const menusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENUS:
      return {
        ...state,
        menus: action.menus,
      };
    default:
      return state;
  }
};
