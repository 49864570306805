import React, { Component } from "react";
import moment from "moment";
import {
  Div,
  I,
  InputSearch,
  Select,
  SelectValue,
  Title,
} from "../../components";
import OrcamentoService from "../../service/OrcamentoService";
import { Color, DateUtils, Type, Utils } from "../../utilities";

const ENTER = 13;

const styles = {
  selectOrcamento: {
    padding: "10px 20px",
    borderBottom: "1px solid #dee2e6",
    border: "1px solid rgb(135, 135, 135)",
    color: "rgb(135, 135, 135)",
    borderRadius: "3px",
    marginBottom: "10px",
  },
  orcamento: {
    padding: "10px 20px",
    borderBottom: "1px solid #dee2e6",
    color: "rgb(135, 135, 135)",
  },
};

class BuscarPedido extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inicio: this.addDiasDataHojeBr(-30),
      fim: this.addDiasDataHojeBr(30),
      buscaCatalogo: this.props.posicao,
      numeroPagina: 1,
      orcamento: [],
      serchText: "",
      diasLancamentoSelec: this.props.diasLancamento,
      diasLancamento: [
        new SelectValue("1", "Hoje", true),
        new SelectValue("2", "Hoje e Ontem", false),
        new SelectValue("3", "Últimos 3 dias", false),
        new SelectValue("7", "Últimos 7 dias", false),
        new SelectValue("15", "Últimos 15 dias", false),
        new SelectValue("30", "Últimos 30 dias", false),
        new SelectValue("60", "Últimos 60 dias", false),
      ],
      depSelecionado: 0,
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.getOrcamentos("", this.props.posicao);
  }

  addDiasDataHojeBr(days) {
    // Date.prototype.addDays = function (days) {
    //   const date = new Date(this.valueOf());
    //   date.setDate(date.getDate() + days);
    //   return date;
    // };

    let date = new Date();
    let newDate = DateUtils.addDays(date, days)
    return moment(newDate).format("YYYY-MM-DD");
  }

  getOrcamentos(
    descricao = "",
    posicao = this.state.buscaCatalogo,
    diasLancamento = this.props.diasLancamento
  ) {
    let obj = {
      descricao,
      posicao,
      diasLancamento,
    };

    OrcamentoService.consultarOrcamentosFiltro(obj).then((data) => {
      if (data) {
        this.setState(
          {
            orcamento: data,
            loading: false,
          },
          this.props.setIdPedido(
            Utils.isArrayNotEmpty(data) ? data[0].idlancamentosOrcamento : 0
          )
        );
      } else {
        this.props.setIdPedido(0);
      }
    });
  }

  render() {
    const { orcamento } = this.state;

    return (
      <Div
        margin="0"
        padding="2"
        borderLeft={Color.NODE.PRIMARY}
        bg={Color.NODE.WHITE}
        shadow
      >
        <Div col="12" inline="between">
          <Div responsive="12">
            <InputSearch
              // handlerChange={(e) =>
              //   this.buscarProduto(e.target.value)
              // }
              handlerBlur={(e) => this.getOrcamentos(e.target.value)}
              handlerIcon={(e) => this.getOrcamentos(e.target.value)}
              handlerKeyUp={(e) => {
                if (e.keyCode === ENTER) {
                  this.buscarProduto(e.target.value);
                }
              }}
              bgIcon={Color.NODE.BEGE}
              colorText={Color.NODE.WHITE}
              label="Pesquisar Pedidos"
            />
          </Div>

          <Div responsive="12">
            <Select
              required
              responsive="6"
              label="Período"
              paddingLeft="0"
              handlerChange={(e) => {
                this.getOrcamentos(
                  "",
                  this.state.buscaCatalogo,
                  e.target.value
                );
                this.props.setAbasStatus(e.target.value);
              }}
              values={this.state.diasLancamento}
              valueSelected={this.props.diasLancamento}
            />
          </Div>

          <Div
            col="12"
            style={{
              maxHeight: "53vh",
              margin: "25px 0px",
              overflow: "scroll",
              paddingTop: "0px !important",
            }}
            //  handlerScroll={(e) => this.handleScroll(e)}
          >
            {orcamento.map((orc, index) => {
              return (
                <Div
                  style={
                    orc.idlancamentosOrcamento === this.props.idPedido
                      ? styles.selectOrcamento
                      : styles.orcamento
                  }
                  pointer
                  handlerClick={() =>
                    this.props.setIdPedido(orc.idlancamentosOrcamento)
                  }
                >
                  <Title
                    pointer
                    style={{
                      fontSize: "15px",
                      marginTop: "10px",
                    }}
                    bold
                    type="h4"
                    className="headerProducts"
                    value={orc.referencia}
                  />
                  <I
                    icon={Type.ICON.ARROW_ANGLE_RIGHT}
                    style={{
                      position: "absolute",
                      right: "30px",
                      marginTop: "-28px",
                      background: Color.CUSTOM_COLORS.PRIMARY,
                      color: "#fff",
                      height: "21px",
                      width: "21px",
                      textAlign: "center",
                      lineHeight: "20px",
                      borderRadius: "12px",
                      paddingLeft: "2px",
                    }}
                  />
                </Div>
              );
            })}
          </Div>
        </Div>
      </Div>
    );
  }
}

export default BuscarPedido;
