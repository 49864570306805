export default class Page {

    number
    indexStart
    indexEnd

    constructor(number, indexStart, indexEnd) {
        this.number = number
        this.indexEnd = indexEnd
        this.indexStart = indexStart
    }

}