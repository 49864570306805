import { SET_PRODUTOS, ALTERAR_PRODUTO } from "../actions/ActionTypes";

const initialState = {
  produtos: [],
};

export const produtosReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUTOS:
      return {
        ...state,
        produtos: action.produtos,
      };
    case ALTERAR_PRODUTO: {
      return {
        ...state,
        produto: action.produto,
      };
    }
    default:
      return state;
  }
};
