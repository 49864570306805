import React, { Component } from "react";
import { Div, ModalNotification } from "../../components";

import ItemProdutoAbaixouPreco from "./ItemProdutoAbaixouPreco";
import { Utils } from "../../utilities";

import "./produtos.css";
import ProdutoDetalhe from "./web/ProdutoDetalhe";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { atualizarCarrinho, setProdutos } from "../../redux/actions";
import ProdutoUtils from "./ProdutoUtils";
import CarouselProdutosGenericoNovo from "../../components/carousel/CarouselProdutoGenericoNovo";
import { toast } from "react-toastify";

class ProdutosSugestaoPesquisa extends Component {
  visualizarProduto = (produto, updateItem) => {
    ModalNotification.render(
      produto.combo ? "Informações do combo" : "Informações do produto",
      <ProdutoDetalhe
        produto={produto}
        listasDeCompraCliente={this.props.listasDeCompra}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          if (Utils.isFunction(updateItem)) {
            updateItem(produto.quantidade);
          }
          toast.info("Produto adicionado no carrinho com sucesso!");
        }}
        history={this.props.history}
        empresa={this.props.empresa}
        handlerAdicionarProdutoLista={() =>
          this.renderAdicionarProdutoLista(produto, updateItem)
        }
      />
    );
  };

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;

    let itens = Array.from(carrinho.itens);

    let produtoSelecionado = itens.filter(
      (item) => item.codigoBarras === produto.codigoBarras
    )[0];

    let produtoDaLista = Array.from(produtos).filter(
      (item) => item.codigoBarras === produto.codigoBarras
    )[0];

    if (Utils.isValueValid(produtoDaLista)) {
      produtoDaLista = produto;
    }

    if (Utils.isValueValid(produtoSelecionado)) {
      if (produtoSelecionado.quantidade > 0) {
        produtoSelecionado = produto;
      } else {
        itens.splice(itens.indexOf(produtoSelecionado), 1);
      }
    } else {
      itens.push(produto);
    }

    carrinho.itens = itens;

    this.props.atualizarCarrinho(carrinho);
    this.props.setProdutos(produtos);

    if (Utils.isFunction(atualizarProdutoDetalhe)) {
      atualizarProdutoDetalhe();
    }
  }

  getProdutos = () => {
    return Array.from(this.props.produtos).map((prod, index) => {
      return (
        <ItemProdutoAbaixouPreco
          history={this.props.history}
          image={prod.urlImagemPrincipalThumbnail}
          descricao={prod.descricao}
          precoUnitario={prod.precoUnitario}
          precoVenda={prod.precoVenda}
          precoAtacado={prod.precoAtacado}
          codigoBarras={prod.codigoBarras}
          quantidade={prod.quantidade}
          precosConcorrentes={prod.precosConcorrentes}
          possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
          fatorEmGrama={prod.fatorEmGrama}
          disponivel={prod.disponivel}
          proibidaVenda={prod.proibidaVenda}
          empresa={this.props.empresa}
          quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
          handlerClick={() => this.visualizarProduto(prod)}
          handlerAddItemCarrinho={(quantidade, callback) => {
            prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
            this.adicionarItemNoCarrinho(prod, callback);
          }}
        />
      );
    });
  };

  render() {
    return (
      <Div>
        <CarouselProdutosGenericoNovo
          itens={this.getProdutos()}
          timeSlide={7000}
          slidesPerPage={3}
          arrows
        />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.empresaState,
    ...store.listasDeCompraState
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProdutosSugestaoPesquisa);
