import React, { Component } from "react";
import { Div, If, Input, Label } from "../../components";
import { Color, EndPoints, Http, Mask, Utils } from "../../utilities";

class RecuperarSenha extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      mensagem: "",
      emailValido: false
    };
  }

  verificarEmailEReenviarSenha() {
    Http.get(EndPoints.VERIFICAR_EMAIL.replace("%s", this.state.email))
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.reenviarSenha();
          } else {
            this.setState({ mensagem: data.mensagem });
          }
        } else {
          this.setState({
            mensagem:
              "Falha ao reenviar a senhar, verifique a conexão com a internet",
          });
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  reenviarSenha() {
    Http.put("/cliente/resetarsenhaporemail", { email: this.state.email })
      .then(({ status, data }) => {
        if (status) {
          this.setState({ mensagem: data.mensagem });
        }
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    let input = document.getElementById("inputRecuperarSenhaLojaWeb");
    if (Utils.isValueValid(input)) {
      input.focus();
    }
  }

  render() {
    return (
      <Div style={{ height: 200 }}>
        <Div column margin="2">
          <Input
            placeholder="E-mail"
            handlerChange={(e) =>
              this.setState({
                email: e.target.value,
                emailValido: Mask.validarEmail(e.target.value)
              })
            }
            value={this.state.email}
            style={{
              width: "100%",

              border: "none",
              fontSize: 25,
              textAlign: "center",
              backgroundColor: "transparent",
              margin: 10,
            }}
            id="inputRecuperarSenhaLojaWeb"
            className="border-bottom"
          />

          <If and value1={Utils.isValueValid(this.state.mensagem)}>
            <Label
              family="SemiBold"
              style={{ color: Color.ECOMMERCE.LARANJA }}
              value={this.state.mensagem}
            />
          </If>

          <Label
            family="SemiBold"
            value="Reenviar a senha para o email"
            style={{
              backgroundColor: this.state.emailValido ? this.props.corPrimaria : "#c4c4c4",
              color: "white",
              fontSize: 25,
              padding: 10,
              borderRadius: 10,
              width: "100%",
              textAlign: "center",
            }}
            handlerClick={(e) => {
              if (Utils.isValueValid(this.state.email)) {
                this.setState({ mensagem: "" }, () =>
                  this.verificarEmailEReenviarSenha()
                );
              } else {
                this.setState({
                  mensagem:
                    "Preencher o campo de email para o reenvio da senha!",
                });
              }
            }}
          />
        </Div>
      </Div>
    );
  }
}

export default RecuperarSenha;
