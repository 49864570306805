import React from 'react'

const DropDownItem = (props) => {

    return (
        <a className={props.className} href={props.target} onClick={props.handlerClick}>{props.value || props.children}</a>
    )

}

export default DropDownItem 