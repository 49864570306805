import React from "react";
import { Component } from "react";

import { CarouselOfertasEspeciais, Div, Label } from "../../components";
import { Color } from "../../utilities";
import BackgroundEspeciais from "../../assets/images/banner/background-especiais.webp";

import "./anuncio.css";

const styles = {
  labelEspeciais: {
    fontSize: 30,
  },
  containerEspeciais: {
    padding: 20,
    marginRight: 20,
  },
  containerOfertasEspeciais: {
    marginRight: "40%",
  },
};

class AnunciosBanner extends Component {

  render() {
    return (
      <Div
        style={{ padding: 20, backgroundImage: `url(${BackgroundEspeciais})` }}
        inline="end"
      >
        <Div
          className={`d-flex flex-column align-items-start justify-content-end`}
          colorText={Color.NODE.WHITE}
          responsive="2"
        >
          <Label
            value="Especiais"
            family="Bold"
            style={styles.labelEspeciais}
            className="text-border-black"
          />
          <Label
            marginTop="3"
            value="Todas promoções vigentes"
            family="Light"
            className="anuncio-banner-label"
          />
          <Label
            className="anuncio-banner-label"
            value="Oferta relâmpago"
            family="Light"
            marginTop="2"
          />
          <Label
            className="anuncio-banner-label"
            value="Lista com descontos"
            family="Light"
            marginTop="2"
          />
          <Label
            value="20%: Campeões de Cashback"
            className="anuncio-banner-label"
            family="Light"
            marginTop="2"
          />
          <Label
            value="Promoção Pequeno Grande Pet"
            family="Light"
            className="anuncio-banner-label"
            marginTop="2"
          />
        </Div>
        <Div responsive="10">
          <CarouselOfertasEspeciais itens={this.props.itens} />
        </Div>
      </Div>
    );
  }
}

export default AnunciosBanner;
