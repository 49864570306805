import React from "react";
import { float, col } from "../css";

const Pagination = props => {
  return (
    <div className={getClassPagination(props)}>
      <nav aria-label="...">
        <ul className="pagination">{props.children}</ul>
      </nav>
    </div>
  );
};

const getClassPagination = props => {
  let classe = "";
  classe = classe.concat(col(props));
  classe = classe.concat(float(props));
  return classe;
};

export default Pagination;
