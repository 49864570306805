import React, { Component } from "react";
import { Http } from "../../../utilities";
import { Notification, SelectValue, Select } from "../..";

class ComboBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  UNSAFE_componentWillMount() {
    if(this.props.url) {
      this.listar();
    } 
  }

  listar() {
    Http.get(this.props.url)
      .then(({status, data}) => {
        if (status) {
          let listData = [];
          if (this.props.labelPrimaryItem) {
            listData.push(new SelectValue("", this.props.labelPrimaryItem));
          }
          Array.from(data).forEach((data) => {
            listData.push(
              new SelectValue(data[this.props.name], this.getLabelSelect(data))
            );
          });
          this.setState({ data: listData });
        }
      })
      .catch((error) => {
        Notification.danger(error.message);
      });
  }

  getLabelSelect(data) {
    return this.props.code
      ? `${data[this.props.code]} - ${data[this.props.text]}`
      : `${data[this.props.text]}`;
  }

  render() {
    return (
      <Select
        {...this.props}
        values={this.props.values || this.state.data}
      ></Select>
    );
  }
}

export default ComboBox;
