import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import "./colorpicker.css";
import {
	Div,
	Label

} from "../../../components";

import { responsive } from "../../css";

class ColorPickerDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			displayColorPicker: props.displayColorPicker | false,
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}


	getClassDefault = props => {
		let classe = "";
		classe = classe.concat(responsive(props));

		return classe;
	};


	handleClick() {
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose() {
		this.setState({ displayColorPicker: false })
	};

	handleChange(color) {
		this.props.onChangeComplete({ target: { value: color.hex, name: this.props.name } });
	};

	validateKeyPress(e) {
		if (e.key === "Enter" || e.key === "ENTER") {
			e.preventDefault();
		}
	};


	render() {

		const styles = reactCSS({
			'default': {
				label: {
					backgroundColor: '#e9ecef',
					borderRadius: '.25rem 0 0 .25rem',
					cursor: 'pointer'

				},
				color: {
					width: '100%',
					height: '100%',
					borderRadius: '5px',
					background: `${this.props.defaultValue}`,
					border: '1px solid #e9ecef',
				},

				swatch: {
					padding: '5px',
					background: '#fff',
					borderRadius: '0 .25rem .25rem 0',
					cursor: 'pointer',
					width: '50%'
				},
				popover: {
					position: 'absolute',
					zIndex: '2',
					top: '-76px',
					left: '0px'
				},
				cover: {
					position: 'fixed',
					top: '0px',
					right: '0px',
					bottom: '0px',
					left: '0px'
				},
			},
		});

		return (
			<Div className="form-group" responsive={this.props.responsive}>
				<Label>{this.props.label}</Label>
				<Div className="d-flex flex-row bd-highlight mb-3">
					<Label className="12 form-control color-picker-label">{this.props.defaultValue}</Label>
					<Div className="form-control" style={styles.swatch} handlerClick={this.handleClick}>
						<Div style={styles.color} />
					</Div>
				</Div>


				{this.state.displayColorPicker ?
					<Div style={styles.popover}>
						<Div style={styles.cover} handlerClick={this.handleClose} />
						<SketchPicker
							disableAlpha
							color={this.props.defaultValue}
							onChangeComplete={this.handleChange}
						/>
					</Div>
					: null}

			</Div>
		)
	}
}

export default ColorPickerDashboard;