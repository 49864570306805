import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import br from "date-fns/locale/pt-BR";

import { Div, I, Label } from "../../components";

class Date extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.date,
      open: false
    };

    this.setStartDate = this.setStartDate.bind(this);
  }

  setStartDate(date) {
    this.setState({
      startDate: date,
      open: !this.state.open
    });
  }

  setOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  getIconGroup() {
    return (
      <div className="flex-fill bd-highlight input-group-prepend">
        <I
          className="input-group-text"
          icon="fa fa-calendar"
          handlerClick={e => this.setOpen()}
        ></I>
      </div>
    );
  }

  render() {
    return (
      <Div className="form-group" col="12">
        <Div col="12">
          {this.props.label && <Label value={this.props.label}></Label>}
        </Div>
        <Div className="d-flex bd-highlight">
          <div className="flex-fill bd-highlight">
            <DatePicker
              calendarClassName="col-12"
              className="form-control col-12"
              selected={this.state.startDate}
              onChange={date => this.setStartDate(date)}
              onInputClick={date => this.setStartDate(date)}
              locale={br}
              open={this.state.open}
            />
          </div>
          {this.props.dategroup && this.getIconGroup()}
        </Div>
      </Div>
    );
  }
}

export default Date;
