import React from 'react';

const NavbarMenu = (props) => {

    return (
        <ul id={props.id} className="menu-content collapse out">              
            {props.value || props.children}
        </ul>
    )

}

export default NavbarMenu