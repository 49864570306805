import React, { useEffect, useState } from "react";
import { Checkbox, Div, Label } from "../../../components";
import { Color, Utils } from "../../../utilities";
import "../produtos.css";

export default function FiltrosMapeados({
  filtros,
  styles,
  aplicarFiltrosNaTela,
  listarProdutos,
  setState,
  limparFiltros,
}) {
  const [filtroSecao, setFiltroSecao] = useState([]);
  const [filtroPreco, setFiltroPreco] = useState([]);
  const [filtroMarca, setFiltroMarca] = useState([]);
  const [secoesSelecionadas, setSecoesSelecionadas] = useState([]);

  useEffect(() => {
    mapearFiltros();
  }, [filtros]);

  useEffect(() => {
    if (Utils.isValueValid(filtros)) {
      mapearFiltrosSecao(secoesSelecionadas, [...filtros]);
    }
  }, [secoesSelecionadas]);

  const removerSecao = (id) => {
    setSecoesSelecionadas((secoesAnteriores) =>
      secoesAnteriores.filter((secaoId) => secaoId !== id)
    );
  };

  const scrollToDiv = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Para rolagem suave
    });
  };

  function mapearFiltros() {
    let filtroSecao = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];
    let filtroPreco = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];
    let filtroMarca = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    if (Utils.isValueValid(filtroPreco)) {
      setFiltroPreco(filtroPreco.itens);
    }
    if (Utils.isValueValid(filtroSecao)) {
      setFiltroSecao(filtroSecao.itens);
    }
    if (Utils.isValueValid(filtroMarca)) {
      setFiltroMarca(filtroMarca.itens);
    }
  }

  function LimparFiltrosMarcas(idFiltro, marcas) {
    let filtroMarca = Array.from(marcas).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    let itensMarca = [];

    if (secoesSelecionadas.length > 0) {
      itensMarca = filtroMarca.itens.filter((item) => {
        if (!item.secoes.some((secao) => idFiltro.includes(secao.idFiltro))) {
          item.active = false;
        }
        return item.secoes.some((secao) => idFiltro.includes(secao.idFiltro));
      });
    } else {
      itensMarca = filtroMarca.itens;
      itensMarca.forEach((item) => {
        item.active = false;
      });
      limparFiltros(filtros);
    }

    let filtroNew = [...filtros];
    filtroNew[3] = {
      ...filtroMarca,
      itens: [...itensMarca],
    };
  }

  function mapearFiltrosSecao(idFiltro, marcas) {
    let filtroMarca = Array.from(marcas).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    let itensMarca = [];

    if (secoesSelecionadas.length > 0) {
      itensMarca = filtroMarca.itens.filter((item) => {
        if (!item.secoes.some((secao) => idFiltro.includes(secao.idFiltro))) {
          item.active = false;
        }
        return item.secoes.some((secao) => idFiltro.includes(secao.idFiltro));
      });
    } else {
      itensMarca = filtroMarca.itens;
      itensMarca.forEach((item) => {
        item.active = false;
      });
      limparFiltros(filtros);
    }
    setFiltroMarca(itensMarca);
  }

  return (
    <>
      <Div key="preco">
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Preços" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroPreco, "descricao").map((item, index) => {
            return (
              <Label
                key={index}
                value={item.descricao}
                padding="2"
                bg={Color.NODE.LIGHT}
                family="Light"
                pointer
                style={
                  item.active ? styles.labelPrecoActive : styles.labelPreco
                }
                handlerClick={() => {
                  item.active = !item.active;
                  setState({ filtros });
                }}
              />
            );
          })}
        </Div>
      </Div>
      <Div key="Secao">
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Categorias" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroSecao, "descricao").map((item, index) => {
            return (
              <Checkbox
                danger
                label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                key={index}
                handlerClick={() => scrollToDiv()}
                handlerChange={({ checked }) => {
                  item.active = checked;

                  if (checked) {
                    setSecoesSelecionadas([
                      ...secoesSelecionadas,
                      item.idFiltro,
                    ]);
                  }

                  if (!checked) {
                    removerSecao(item.idFiltro);
                    let newSelectSecoes = secoesSelecionadas.filter(
                      (secaoId) => secaoId !== item.idFiltro
                    );
                    LimparFiltrosMarcas(newSelectSecoes, [...filtros]);
                  }

                  setState({ filtros, pagina: 1 }, () => {
                    if (
                      item.quantidadeEncontrada === item.quantidadeTotal &&
                      checked
                    ) {
                      aplicarFiltrosNaTela(filtroMarca);
                    } else {
                      listarProdutos();
                    }
                  });
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      </Div>
      <Div key="Marca">
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Marca" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroMarca, "descricao").map((item, index) => {
            return (
              <Checkbox
                danger
                label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                key={index}
                handlerChange={({ checked }) => {
                  item.active = checked;

                  scrollToDiv();

                  if (!checked) {
                    // removerSecao(item.idFiltro);
                    //   LimparFiltrosMarcas([...filtros]);
                    aplicarFiltrosNaTela();
                  }

                  setState({ filtros, pagina: 1 }, () => {
                    if (
                      item.quantidadeEncontrada === item.quantidadeTotal &&
                      checked
                    ) {
                      aplicarFiltrosNaTela();
                    } else {
                      listarProdutos();
                    }
                  });
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      </Div>
    </>
  );
}
