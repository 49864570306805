import React from "react";

const If = (props) => {
  let { value1 = true, value2 = true, value3 = true, value4 = true } = props;

  return (
    <>
      {props.and && value1 && value2 && value3 && value4 && props.children}
      {props.or && (value1 || value2 || value3 || value4) && props.children}
    </>
  );
};

export default If;
