import React from "react";
import { Div, I, If, Image, Label, ModalNotification } from "../../components";
import { Color, Mask, Type, Utils } from "../../utilities";

import IconApple from "../../assets/images/icones/icon-apple.png";
import IconGooglePlay from "../../assets/images/icones/icon-googleplay.png";
import IconWhatsapp from "../../assets/images/icones/icon-whatsapp.png";
import IconFacebook from "../../assets/images/icones/icon-facebook.png";
import IconInstagram from "../../assets/images/icones/icon-instagram.png";

import LogoMobioh from "../../assets/images/logo-mobioh-branco.webp";
import TemplateFormasPagamento from "./TemplateFormasPagamento";

const getParametros = (empresa) => {
  let parametros = {};
  if (Utils.isValueValid(empresa)) {
    parametros = { ...empresa.parametros };
  }

  return parametros;
};

const getFormasPagamento = (empresa) => {
  let formasPagamento = [];
  if (empresa && Utils.isValueValid(empresa.formasPagamentosResumidas)) {
    formasPagamento = [...empresa.formasPagamentosResumidas];
  }

  return formasPagamento;
};

const renderFormasPagamento = (formasPagamento) => {
  ModalNotification.render(
    "Formas de pagamento",
    <TemplateFormasPagamento formasDePagamento={formasPagamento} />
  );
};

const Rodape = (props) => {
  let empresa = props.empresa || {};
  let parametros = getParametros(empresa);
  let formasDePagamento = Utils.quebrarArray(getFormasPagamento(empresa), 4);
  let formasPagamentoCompleto = getFormasPagamento(empresa);
  let filialNumber = Utils.isNotObjectEmpty(empresa)
    ? empresa.filiais[0].telefone
    : null;

  return (
    <footer>
      <Div
        style={{
          backgroundColor: parametros.corRodape,
          padding: 20,
          //  paddingRight: "2%",
          //  paddingTop: "3%",
          //  paddingBottom: "3%",
          //  marginTop: "3%",
          // opacity: 0.9,
        }}
        inline="between"
      >
        <Div
          style={{ color: Color.ECOMMERCE.WHITE }}
          className="d-flex flex-column align-items-start justify-content-start align-self-start"
          responsive="3"
        >
          <Div>
            <Image
              src={parametros.urlLogoEmail}
              width="140px"
              height="104px"
              alt={empresa.razaoSocial}
            />
            <Label
              marginTop="2"
              value={`© 2021 ${Utils.capitalize(empresa.razaoSocial || "")}`}
              style={{ lineHeight: "15px" }}
              family="Light"
            />
            <Label
              value="Todos os direitos reservados"
              style={{ lineHeight: "12px" }}
              family="Light"
            />
            {/* <Div inline="start">
            <Label
              style={{ color: "white" }}
              family="Light"
              value={empresa.razaoSocial}
            />
          </Div> */}
            <Div inline="start">
              <Label value="CNPJ:" style={{ color: "white" }} family="Light" />
              <Label
                style={{ color: "white" }}
                family="Light"
                marginLeft="2"
                value={Mask.getValueMaskCpfCnpj(empresa.cpfCnpj)}
              />
            </Div>
            <Label
              value={`${empresa.enderecoCompleto} - CEP: ${empresa.cep}`}
              family="Light"
            />
          </Div>
          <Div className="w-100 d-flex flex-column align-items-start justify-content-start align-self-start">
            <Label value="Atendimento" family="Bold" style={{ fontSize: 20 }} />
            <Div inline="start">
              <Image
                src={IconWhatsapp}
                width="25px"
                height="25px"
                margin="2"
                alt="whatsapp"
              />
              <Label
                margin="2"
                value={Mask.getValueMaskPhone(parametros.telefoneWhatsapp)}
                family="Light"
              />
            </Div>
            {filialNumber && (
              <Div inline="start">
                <I
                  icon={Type.ICON.PHONE_SQUARE}
                  colorText={"#FFF"}
                  sizeIcon="2"
                  style={{ margin: "0px 7px" }}
                />
                <Label
                  margin="2"
                  value={Mask.getValueMaskPhone(filialNumber)}
                  family="Light"
                />
              </Div>
            )}
            <Label value={parametros.emailAtendimento} family="Light" />
          </Div>
        </Div>
        <If and value1={Utils.isValueValid(formasDePagamento)}>
          <Div
            style={{ color: Color.ECOMMERCE.WHITE }}
            responsive="3"
            className="align-self-start"
          >
            <Div style={{ height: 230 }}>
              <Label
                value="Formas de pagamento"
                style={{ fontSize: 20 }}
                family="Bold"
              />
              {Array.from(formasDePagamento).map((formas, index) => {
                return (
                  <Div key={index} inline="start">
                    {Array.from(formas).map((forma, index) => {
                      return (
                        <Div margin="2" key={index}>
                          <If and value1={Utils.isValueValid(forma.urlFoto)}>
                            <Image
                              src={forma.urlFoto}
                              width={50}
                              height={50}
                              alt={forma.descricao}
                            />
                          </If>
                        </Div>
                      );
                    })}
                  </Div>
                );
              })}
              <Label
                value="Ver detalhes +"
                family="Bold"
                pointer
                style={{ fontSize: 20 }}
                handlerClick={() =>
                  renderFormasPagamento(formasPagamentoCompleto)
                }
              />
            </Div>
            <Div className="d-flex flex-column align-self-start">
              <Label value="Segurança" style={{ fontSize: 20 }} family="Bold" />
              <Div inline="start">
                <Image
                  src={`https://${empresa.hostServidor}/geral/icones/seguranca/iconegooglelojasegura.svg`}
                  width="80px"
                  height="38px"
                  style={{
                    backgroundColor: "white",
                    padding: 5,
                    borderRadius: 5,
                  }}
                  handlerClick={() => {
                    window.open(
                      `https://www.google.com/transparencyreport/safebrowsing/diagnostic/?hl=pt-BR#url=${empresa.hostServidor}`
                    );
                  }}
                  pointer
                  alt="Google loja segura"
                />
                <Image
                  src={`https://${empresa.hostServidor}/geral/icones/seguranca/iconeletsencrypt.png`}
                  width="80px"
                  height="38px"
                  style={{
                    marginLeft: 5,
                    backgroundColor: "white",
                    padding: 5,
                    borderRadius: 5,
                  }}
                  pointer
                  alt="Lets encrypt"
                />
                <Image
                  src={`https://${empresa.hostServidor}/geral/icones/seguranca/iconehttps.webp`}
                  width="80px"
                  height="38px"
                  style={{
                    marginLeft: 5,
                    backgroundColor: "white",
                    padding: 5,
                    borderRadius: 5,
                  }}
                  pointer
                  alt="Https"
                />
              </Div>
            </Div>
          </Div>
        </If>

        <Div responsive="3" className="align-self-start">
          <Div style={{ height: 230 }}>
            <If
              and
              value1={
                Utils.isValueValid(parametros.urlPlayStore) ||
                Utils.isValueValid(parametros.urlAppStore)
              }
            >
              <Label
                value="Baixe nosso app"
                style={{ color: Color.ECOMMERCE.WHITE, fontSize: 20 }}
                family="Bold"
              />
              <Div inline="start">
                <Div
                  style={{
                    backgroundColor: "black",
                    border: "1px solid white",
                    borderRadius: 5,
                    width: 140,
                    height: 45,
                    padding: 5,
                    color: "white",
                  }}
                  inline="center"
                >
                  <Image
                    src={IconApple}
                    width="20px"
                    height="20px"
                    paddingBottom="2"
                  />
                  <Div
                    className="d-flex flex-column align-items-start justify-content-center"
                    marginLeft="3"
                  >
                    <Label
                      value="App Store"
                      family="Bold"
                      style={{ height: 10, fontSize: 12 }}
                    />
                    <Label
                      value="iOS"
                      family="Light"
                      style={{ height: 10, fontSize: 8, marginTop: 1 }}
                    />
                  </Div>
                </Div>
                <Div
                  style={{
                    backgroundColor: "black",
                    border: "1px solid white",
                    borderRadius: 5,
                    width: 140,
                    height: 45,
                    padding: 5,
                    marginLeft: 5,
                    color: "white",
                  }}
                  inline="center"
                >
                  <Image
                    src={IconGooglePlay}
                    width="20px"
                    height="20px"
                    paddingBottom="2"
                  />
                  <Div
                    className="d-flex flex-column align-items-start justify-content-center"
                    marginLeft="3"
                  >
                    <Label
                      value="Google Play"
                      family="Bold"
                      style={{ height: 10, fontSize: 12 }}
                    />
                    <Label
                      value="Android"
                      family="Light"
                      style={{ height: 10, fontSize: 8, marginTop: 1 }}
                    />
                  </Div>
                </Div>
              </Div>
            </If>

            <Div className="align-self-start" marginTop="3">
              <Div>
                <Label
                  value="Siga-nos nas redes sociais"
                  style={{ color: Color.ECOMMERCE.WHITE, fontSize: 20 }}
                  family="Bold"
                />
                <Div inline="start">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={parametros.urlFacebook}
                  >
                    <Image
                      src={IconFacebook}
                      width="45px"
                      height="45px"
                      marginRight="1"
                      alt="Facebook Icone"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={parametros.urlInstagram}
                  >
                    <Image
                      src={IconInstagram}
                      width="45px"
                      height="45px"
                      marginLeft="1"
                      alt="Instagram Icone"
                    />
                  </a>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            className="d-flex flex-column align-self-start"
            style={{ color: "white" }}
          >
            <Label
              value="Desenvolvido por"
              style={{ fontSize: 20 }}
              family="Bold"
            />
            <Image
              src={LogoMobioh}
              alt="Mobioh Sistemas"
              width="70px"
              height="45px"
              marginBottom="1"
            />
          </Div>
        </Div>
        <Div inline="center" marginTop="3">
          <Label
            responsive="11"
            value={parametros.obsRodape}
            style={{ fontSize: 12, color: Color.ECOMMERCE.WHITE }}
            family="Light"
          />
        </Div>
      </Div>
    </footer>
  );
};

export default Rodape;
