import React, { Component } from "react";
import { Div, Button } from "../../components";
import { Color, Utils } from "../../utilities";
import If from "../if/If";
import Label from "../texts/label/Label";
import "./modal.css";

class ModalLoginLoja extends Component {
  DBLOCK = "d-block";
  ID_MODAL = "modal-mobioh";

  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      show: props.show,
    };

    this.closed = props.closed || false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    Utils.pararScroolTelaPrincipal();
    this.setState({ show: props.show });
  }

  componentDidMount() {
    Utils.pararScroolTelaPrincipal();
  }

  componentWillUnmount() {
    Utils.ativarScroolTelaPrincipal();
  }

  getClassModal = () => {
    let classe = "modal ";
    classe = classe.concat(this.state.show ? this.DBLOCK : "d-none");
    return classe;
  };

  onDismiss() {
    document.getElementById(this.ID_MODAL).classList.toggle(this.DBLOCK);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.closed === false;
  }

  componentDidUpdate(props, state) {
    if (!this.closed) {
      this.onDismiss();
    }
  }

  cancel(event) {
    this.onDismiss();
    this.componentWillUnmount();
    if (this.props.handlerCancel) {
      this.props.handlerCancel(event);
    }
  }

  confirm(event) {
    this.onDismiss();
    this.props.handlerConfirm(event);
  }

  render() {
    return (
      <Div className={this.getClassModal()} id={this.ID_MODAL}>
        <Div
          inline="center"
          className="vertical-center table-wrapper-scroll-y my-custom-scrollbar mx-auto"
          responsive={this.props.responsive ? this.props.responsive : "6"}
        >
          <Div className="modal-content" style={{ backgroundColor: "#F2F2F2" }}>
            <If and value1={Utils.isValueValid(this.props.title)}>
              <Div column>
                {this.props.title}
              </Div>
              <Div
                style={{ position: "absolute", top: 10, right: 10 }}
                handlerClick={(e) =>
                  this.setState({ show: false }, () => this.cancel(e))
                }
              >
                <Label value="Fechar" pointer />
                <Button className="close" pointer>
                  <span aria-hidden="true">&times;</span>
                </Button>
              </Div>
            </If>
            <If and value1={!Utils.isValueValid(this.props.title)}>
              <Div inline="end">
                <Button
                  className="close m-2"
                  handlerClick={(e) => this.cancel(e)}
                >
                  <span aria-hidden="true">&times;</span>
                </Button>
              </Div>
            </If>
            <Div className="modal-body">
              {this.props.body || this.props.children}
            </Div>
            <If and value1={!this.props.hide}>
              <Div
                className="modal-footer "
                inline="center"
                style={{ backgroundColor: Color.ECOMMERCE.LARANJA }}
              >
                <Button
                  handlerClick={(e) => this.confirm(e)}
                  value={this.props.labelButton || "ENTRAR"}
                  style={{ color: "white" }}
                />
              </Div>
            </If>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default ModalLoginLoja;
