export default class DateEnum {
  static MES = {
    JANEIRO: {
      name: "JANEIRO",
      value: 0
    },
    FEVEREIRO: {
      name: "FEVEREIRO",
      value: 1
    },
    MARCO: {
      name: "MARCO",
      value: 2
    },
    ABRIL: {
      name: "ABRIL",
      value: 3
    },
    MAIO: {
      name: "MAIO",
      value: 4
    },
    JUNHO: {
      name: "JUNHO",
      value: 5
    },
    JULHO: {
      name: "JULHO",
      value: 6
    },
    AGOSTO: {
      name: "AGOSTO",
      value: 7
    },
    SETEMBRO: {
      name: "SETEMBRO",
      value: 8
    },
    OUTUBRO: {
      name: "OUTUBRO",
      value: 9
    },
    NOVEMBRO: {
      name: "NOVEMBRO",
      value: 10
    },
    DEZEMBRO: {
      name: "DEZEMBRO",
      value: 11
    }
  };


  static DIAS_SEMANA = {
    DOMINGO: {
      label: "DOMINGO",
      value: 0
    },
    SEGUNDA: {
      label: "SEGUNDA",
      value: 1
    },
    TERCA: {
      label: "TERCA",
      value: 2
    },
    QUARTA: {
      label: "QUARTA",
      value: 3
    },
    QUINTA: {
      label: "QUINTA",
      value: 4
    },
    SEXTA: {
      label: "SEXTA",
      value: 5
    },
    SABADO: {
      label: "SABADO",
      value: 6
    }
  }

}
