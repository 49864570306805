import React, { Component } from "react";
import {Div} from "../../components";

class Center extends Component {
  render() {
    return <Div>{this.props.children}</Div>;
  }
}

export default Center;
