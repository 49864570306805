import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Div, Form,  Collapse } from "../../components";
import {  Utils } from "../../utilities";
import ConfigColors from "./ConfigColors";
import InformacoesGerais from "./InformacoesGerais";


class ViewPersonalizacao extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  UNSAFE_componentWillMount() {

  }

  render() {

    let idParentCollapse = Utils.uuidString();
    let idCollapseConfigColors = Utils.uuidString();
    let idCollapseInfoGeral = Utils.uuidString();

    return (
      <Form id="formEdicaoPersonalizacao" name="formEdicaoPersonalizacao" handlerSubmit={this.submit}>

        <Div col="12" className="mt-2" id={idParentCollapse}>

          <Collapse
            idParent={idParentCollapse}
            primary
            bgwhite
            pointer
            id={idCollapseInfoGeral}
            arrow
            title={"Informações Gerais"}
            show
            body={<InformacoesGerais />}
          ></Collapse>

          <Collapse
            idParent={idParentCollapse}
            bege
            bgwhite
            pointer
            id={idCollapseConfigColors}
            arrow
            title={"Configuração de Cores"}
            body={<ConfigColors />}
          ></Collapse>



        </Div>

        <Button
          margin="3"
          value="Salvar"
          btn="primary"
          float="right"
        ></Button>

      </Form>
    )
  }
}

const mapStateToProps = (store) => ({
  idUsuario: "1",
});

export default connect(mapStateToProps)(ViewPersonalizacao);