export default class ClienteLogin {
    nome;
    tipoPessoa;
    cpfCnpj;
    logradouro;
    numero;
    complemento;
    bairro;
    idCidadeIbge;
    cep;
    logradouroEntrega;
    numeroEntrega;
    complementoEntrega;
    bairroEntrega;
    permiteReceberOferta;
    permiteSerContatado;
    idCidadeIbgeEntrega;
    cepEntrega;
    telefone;
    email;
    senha
}