import React from "react";
import BreadCrumbItem from "./BreadCrumbItem";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import './breadcrumb.css'
import { className, active } from "../css";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(active(props));
  classe = classe.concat(className(props));
  return classe;
};

const Breadcrumb = props => {
  return (
    <nav aria-label="breadcrumb" className={getClassDefault(props)}>
      <ol className={`breadcrumb bg-container-mobioh ${props.className}`}>
        {Array.from(props.itens).map((item, key) => {
          return (
            <Link className="breadcrumb-default" to={item.target} key={key}>
              <BreadCrumbItem
                handlerClick={props.handlerClick ? props.handlerClick(item): null}
                target={item.target}
                value={item.value}
                active={item.active}
                separator={key < props.itens.length - 1}
              ></BreadCrumbItem>
            </Link>
          );
        })}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  target: PropTypes.string,
  value: PropTypes.string,
  active: PropTypes.bool
};

export default Breadcrumb;
