import React, { Component } from "react";
import { I } from "..";
import { Type } from "../../utilities";

class IconToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: props.iconDefault || Type.ICON.ARROW_CHEV_DOWN
    };
  }

  render() {
    return (
      <I
        {...this.props}
        icon={this.state.icon}
        handlerClick={e => {
          this.setState({
            icon:
              this.state.icon ===
              (this.props.iconDefault || Type.ICON.ARROW_CHEV_DOWN)
                ? this.props.iconChange || Type.ICON.ARROW_CHEV_UP
                : this.props.iconDefault || Type.ICON.ARROW_CHEV_DOWN
          });
          this.props.handlerClick(e);
        }}
      ></I>
    );
  }
}

export default IconToggle;
