import React from "react";
import { Label, Input, Div } from "../..";

const InputFile = props => {
  return (
    <Div>
      {props.label && <Label value={props.label}></Label>}
      <Input
        id={props.id}
        type="file"
        handlerChange={props.handlerChange}
        formcontrol
        accept={props.accept}
        value={props.value}
        name={props.name}
        className={props.className}
      />
    </Div>
  );
};

export default InputFile;
