import React from "react";
import TableColumnButton from "./TableColumnButton";
import PropTypes from "prop-types";
import Type from "../../../utilities/enuns/Type";
import Color from "../../../utilities/enuns/Color";

const TableColumnEdit = (props) => {
  return (
    <TableColumnButton
      {...props}
      type={props.confirmIcon ? Type.ICON.SUCCESS : Type.ICON.EDIT}
    ></TableColumnButton>
  );
};

TableColumnEdit.propTypes = {
  sizeIcon: PropTypes.string,
  colorText: PropTypes.string,
  handlerClick: PropTypes.func.isRequired,
};

TableColumnEdit.defaultProps = {
  type: Type.ICON.EDIT,
  sizeIcon: "2",
  colorText: Color.NODE.DEFAULT,
};

export default TableColumnEdit;
