import React from "react";
import TableColumnButton from "./TableColumnButton";
import PropTypes from "prop-types";
import Type from "../../../utilities/enuns/Type";
import Color from "../../../utilities/enuns/Color";

const TableColumnButtonDetail = (props) => {
  return (
    <TableColumnButton
      {...props}
    ></TableColumnButton>
  );
};

TableColumnButtonDetail.propTypes = {
  sizeIcon: PropTypes.string,
  colorText: PropTypes.string,
  handlerClick: PropTypes.func.isRequired,
};

TableColumnButtonDetail.defaultProps = {
  type: Type.ICON.SERVER,
  sizeIcon: "2",
  colorText: Color.NODE.DEFAULT,
};

export default TableColumnButtonDetail;
