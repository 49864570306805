import React, { Component } from "react";
import {
  
  Div,
  I,
  If,
  Image,
  Label,
  MessageSpinner,
  ModalNotification,
} from "../../../components";
import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";

import "../produtos.css";
import IconsDepartamento from "../../../utilities/enuns/IconsDepartamento";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { atualizarCarrinho, setProdutos } from "../../../redux/actions";
import ItemProdutoAbaixouPrecoMobile from "../ItemProdutoAbaixouPrecoMobile";
import ProdutoUtils from "../ProdutoUtils";
import BotaoPaginaInicial from "../../utils/BotaoPaginaInicial";
import ProdutoDetalheMobile from "./ProdutoDetalheMobile";
import ContatoWhatsappMobile from "../../ContatoWhatsappMobile";
import UsuarioUtils from "../../utils/UsuarioUtils";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import Zindex from "../../utils/Zindex";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { toast } from "react-toastify";
import AdicionarProdutoListaCompraMobile from "../listascompras/mobile/AdicionarProdutoListaCompraMobile";

const styles = {
  title: {
    color: "#878787",
  },
  labelFiltros: {
    padding: 9,
    border: `3px solid ${Color.ECOMMERCE.LARANJA}`,
    borderRadius: 10,
    color: Color.ECOMMERCE.LARANJA,
    opacity: 0.8,
    lineHeight: "10px",
  },
  labelResultado: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelPrecoActive: {
    border: "2px solid #E94E25",
    width: "100%",
    margin: 3,
  },
  labelPreco: {
    width: "100%",
    margin: 3,
  },
  containerVerMais: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: Color.ECOMMERCE.WHITE,
    padding: 5,
    fontSize: 15,
    width: "100%",
    textAlign: "center",
    zIndex: 1000,
  },
  menuFiltros: {
    position: "fixed",
    zIndex: 1010,
    backgroundColor: "#f2f2f2",
    height: window.innerHeight,
    left: 0,
    right: 0,
    transition: "0.5s",
    overflow: "auto",
    paddingBottom: 200,
  },
};

class ProdutosPorAnuncioOfertaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosFiltrados: [],
      filtros: [],
      pagina: 1,
      verMais: true,
      anuncio: { ...props.location.state },
      pesquisando: false,
      paginarConsulta: false,
      open: false,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
    this.listarProdutosSimilares = this.listarProdutosSimilares.bind(this);
    this.toggleMenuFiltros = this.toggleMenuFiltros.bind(this);
  }

  toggleMenuFiltros = () => {
    this.setState({ open: !this.state.open }, () => {
      Utils.toggleScrollBody(this.state.open ? "hidden" : "auto");
    });
  };

  componentDidMount() {
    this.setState({ pesquisando: true }, () => {
      this.listarProdutos();
      this.consultarParametrosPedido();
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    let state = props.location.state;
    this.setState(
      {
        anuncio: { ...state },
        pesquisando: true,
      },
      () => this.listarProdutos()
    );
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState({ parametros: data });
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  getUrlListaProdutosOfertaAnuncio() {
    let {
      idDepartamentoSecaoFiltro,
      marcaFiltro,
      possuiProdutos,
      tipoDepartamentoSecao,
      idAnuncioOferta,
    } = this.state.anuncio;

    let urlListar = "";

    let isUrlProdutoBarraOferta =
      !Utils.isValueValid(idDepartamentoSecaoFiltro) &&
      !Utils.isValueValid(marcaFiltro) &&
      possuiProdutos;

    let isUrlDepartamento =
      Utils.isValueValid(idDepartamentoSecaoFiltro) &&
      tipoDepartamentoSecao === "DEPARTAMENTO";
    let isUrlSecao =
      Utils.isValueValid(idDepartamentoSecaoFiltro) &&
      tipoDepartamentoSecao === "SECAO";

    let isUrlMarca = Utils.isValueValid(marcaFiltro);

    if (isUrlProdutoBarraOferta) {
      urlListar = EndPoints.PRODUTOS_POR_ANUNCIO.replace("%s", idAnuncioOferta);
    } else if (isUrlMarca) {
      urlListar = EndPoints.PRODUTOS_MARCA.replace("%s", marcaFiltro).replace(
        "%p",
        this.state.pagina
      );
    } else if (isUrlDepartamento) {
      urlListar = EndPoints.PRODUTOS_DEPARTAMENTO.replace(
        "%s",
        idDepartamentoSecaoFiltro
      ).replace("%p", this.state.pagina);
    } else if (isUrlSecao) {
      urlListar = EndPoints.PRODUTOS_SECAO.replace(
        "%s",
        idDepartamentoSecaoFiltro
      ).replace("%p", this.state.pagina);
    } else {
      console.log("Oferta anúncio não se encaixa em nenhum tipo especificado!");
    }

    return {
      url: urlListar,
      paginarConsulta: isUrlDepartamento || isUrlSecao || isUrlMarca,
    };
  }

  getFiltrosMapeados() {
    const { filtros } = this.state;

    let templates = [];

    let filtroPreco = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];

    if (Utils.isValueValid(filtroPreco)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Preços" family="SemiBold" style={styles.title} />
          {Array.from(filtroPreco.itens).map((item, index) => {
            return (
              <Label
                key={index}
                value={item.descricao}
                padding="2"
                bg={Color.NODE.LIGHT}
                family="Light"
                pointer
                style={         
                  item.active ? styles.labelPrecoActive : styles.labelPreco
                }
                handlerClick={() => {
                  item.active = !item.active;
                  this.setState({ filtros });
                  this.aplicarFiltros();
                }}
              />
            );
          })}
        </Div>
      );
    }

    return templates;
  }

  limparFiltros() {
    let filtros = this.state.filtros;
    Array.from(filtros).forEach((filtro) => {
      Array.from(filtro.itens).map((item) => (item.active = false));
    });

    this.setState({ filtros }, () => {
      this.aplicarFiltros();
      this.toggleMenuFiltros();
    });
  }

  aplicarFiltros() {
    let produtos = JSON.parse(JSON.stringify(this.state.produtos));

    let filtroPreco = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];

    let itensPrecoMarcados = Utils.isNotNull(filtroPreco) ?
      Array.from(filtroPreco.itens).filter((item) => item.active) : []

    let filtroDepartamento = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "DEPARTAMENTO"
    )[0];

    let itensDepartamentoMarcados = Utils.isNotNull(filtroDepartamento) ?
      Array.from(filtroDepartamento.itens).filter((item) => item.active) : []

    let filtroSecao = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];

    let itensSecaoMarcados = Utils.isNotNull(filtroSecao) ? Array.from(filtroSecao.itens).filter(
      (item) => item.active
    ) : []

    let filtroMarca = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    let itensMarcaMarcados = Utils.isNotNull(filtroMarca) ? Array.from(filtroMarca.itens).filter(
      (item) => item.active
    ) : []

    let produtosFiltrados = [];

    if (Utils.isValueValid(itensDepartamentoMarcados)) {
      let filtros = itensDepartamentoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.departamento)) {
            produtosFiltrados.push(produto);
          }
        });
      }
    }

    if (Utils.isValueValid(itensSecaoMarcados)) {
      let filtros = itensSecaoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.secao)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (Utils.isValueValid(itensMarcaMarcados)) {
      let filtros = itensMarcaMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.marca)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (!Utils.isValueValid(produtosFiltrados)) {
      produtosFiltrados = produtos;
    }

    if (Utils.isValueValid(itensPrecoMarcados)) {
      let filtros = itensPrecoMarcados.map((item) => item.idFiltro);

      let produtosExcluir = [];

      if (Utils.isValueValid(filtros)) {
        produtosFiltrados.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (!filtros.includes(filtroProduto.preco)) {
            produtosExcluir.push(produto);
          }
        });

        produtosExcluir.forEach((produto) => {
          produtosFiltrados.splice(produtosFiltrados.indexOf(produto), 1);
        });
      }
    }

    this.setState({
      produtosFiltrados,
    });
  }

  getFiltrosPaginados(filtrosNovaPagina) {
    let filtros = Array.from(this.state.filtros);

    if (Utils.isValueValid(filtrosNovaPagina)) {
      Array.from(filtrosNovaPagina).forEach((filtro) => {
        let filtroPorTipo = filtros.filter((f) => f.tipo === filtro.tipo)[0];
        if (Utils.isValueValid(filtroPorTipo)) {
          let itens = Array.from(filtroPorTipo.itens);
          let itensNovaPagina = Array.from(filtro.itens);

          if (Utils.isValueValid(itensNovaPagina)) {
            itensNovaPagina.forEach((item) => {
              let filtroJaAdicionado = itens.filter(
                (i) => i.idFiltro === item.idFiltro
              )[0];
              if (!Utils.isValueValid(filtroJaAdicionado)) {
                itens.push(item);
              } else {
                filtroJaAdicionado.quantidadeEncontrada =
                  Number(filtroJaAdicionado.quantidadeEncontrada) +
                  Number(item.quantidadeEncontrada);
              }
            });
          }
        } else {
          filtros.push(filtro);
        }
      });
    }

    return filtros;
  }

  getParamListarProdutos() {
    const { pagina, anuncio } = this.state;

    let param = {
      numeroPagina: pagina,
      idAnuncioOferta: anuncio.idAnuncioOferta,
    };

    return param;
  }

  listarProdutos(carregarMais = true) {
    let param = this.getParamListarProdutos(carregarMais);

    Http.post(EndPoints.ANUNCIO_PRODUTOS, param)
      .then(({ status, data }) => {
        if (status && Utils.isValueValid(data.listaProdutos)) {
          if (this.state.pagina > 1) {
            this.setState(
              {
                produtos: [...this.state.produtos, ...data.listaProdutos],
                filtros: this.getFiltrosPaginados(data.filtros),
                verMais: true,
                pesquisando: false,
              },
              () => this.aplicarFiltros(param)
            );
          } else {
            this.setState(
              {
                produtos: data.listaProdutos,
                filtros: this.getFiltrosPaginados(data.filtros),
                verMais: true,
                pesquisando: false,
              },
              () => this.aplicarFiltros(param)
            );
          }
        } else {
          if (this.state.pagina === 1) {
            this.setState({
              produtos: [],
              produtosFiltrados: [],
              // filtros: [],
              verMais: false,
              pesquisando: false,
            });
          } else {
            this.setState({ verMais: false, pesquisando: false });
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  getProdutosPorDepartamento = () => {
    let itensParaRenderizar = [];

    let produtosAgrupados = Utils.groupBy(
      this.state.produtosFiltrados,
      "descricaoDepartamento"
    );

    for (const item of produtosAgrupados) {

      let departamento = item[0];
      let produtos = Array.from(item[1]);

      let produtosSeparados = produtos;

      itensParaRenderizar.push({
        tamanho: produtosSeparados.length,
        produtos: produtosSeparados,
        departamento,
      });

      itensParaRenderizar = Utils.sortReverse(itensParaRenderizar, "tamanho");
    }

    return itensParaRenderizar;
  };

  renderAdicionarProdutoLista(produto, updateItem) {
    let listas = this.props.listasDeCompra;
    ModalNotification.renderMessage({
      title: "Listas de compra",
      body: (
        <AdicionarProdutoListaCompraMobile
          listas={listas}
          produto={produto}
          empresa={this.props.empresa}
          handlerCancel={() => this.visualizarProduto(produto, updateItem)}
          handlerNovaListaCadastrada={() =>
            this.visualizarProduto(produto, updateItem)
          }
        />
      ),
    });
  }

  visualizarProduto = (produto, updateItem) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalheMobile
        produto={produto}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          if (Utils.isFunction(updateItem)) {
            updateItem(produto.quantidade);
          }

          toast.info("Produto adicionado no carrinho!", {
            position: "top-right",
          });
        }}
        history={this.props.history}
        empresa={this.props.empresa}
        listasDeCompraCliente={this.props.listasDeCompra}
        handlerAdicionarProdutoLista={() =>
          this.renderAdicionarProdutoLista(produto, updateItem)
        }
      />
    );
  };

  listarProdutosSimilares(produto, updateItem) {
    Http.get(EndPoints.PRODUTOS_SIMILARES.replace("%s", produto.idProdutoWeb))
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtosSimilares: data }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        } else {
          this.setState({ produtosSimilares: [] }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        }
      })
      .catch((error) => console.log(error));
  }

  async adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    console.log("ADICIONANDO CARRINHO");
    let { carrinho, produtos } = this.props;
    if (Utils.isValueValid(this.state.parametros)) {
      CarrinhoUtils.adicionarItemNoCarrinho(
        produto,
        carrinho,
        produtos,
        this.props.atualizarCarrinho,
        this.props.setProdutos,
        atualizarProdutoDetalhe,
        this.state.parametros
      );
    } else {
      await this.consultarParametrosPedido();
      CarrinhoUtils.adicionarItemNoCarrinho(
        produto,
        carrinho,
        produtos,
        this.props.atualizarCarrinho,
        this.props.setProdutos,
        atualizarProdutoDetalhe,
        this.state.parametros
      );
    }
  }

  render() {
    const { anuncio, produtosFiltrados, open } = this.state;
    const ID_FILTROS = Utils.uuidString();

    return (
      <>
        <Div
          style={{
            marginTop: 15,
            width: "100%",
          }}
        >
          <Div
            className="d-flex align-self-start justify-content-start"
            style={{ paddingBottom: 50 }}
          >
            <Div margin="2" style={{ width: "100%" }}>
              <If and value1={!open}>
                <Div inline="between">
                  <BotaoPaginaInicial
                    history={this.props.history}
                    label="Continuar comprando"
                  />
                  <Div
                    inline="center"
                    marginTop="2"
                    handlerClick={() => this.toggleMenuFiltros()}
                    toggle="collapse"
                    target={`#${ID_FILTROS}`}
                  >
                    <If and value1={!this.state.open}>
                      <Label style={styles.labelFiltros}>
                        <I icon={Type.ICON.FILTER} />
                        {"Filtrar"}
                      </Label>
                    </If>
                  </Div>
                </Div>
              </If>

              <If and value1={this.state.open}>
                <Div id={ID_FILTROS} style={styles.menuFiltros}>
                  <Div
                    inline="between"
                    className="w-100 p-1 border-bottom bg-light"
                    style={{ position: "fixed", zIndex: Zindex.MIL_E_DOIS }}
                  >
                    <Div
                      inline="center"
                      pointer
                      handlerClick={() => this.toggleMenuFiltros()}
                      className="bg-light border"
                      rounded
                      padding="1"
                      marginTop="2"
                      toggle="collapse"
                      target={`#${ID_FILTROS}`}
                    >
                      <I
                        icon={Type.ICON.SUCCESS}
                        colorText={Color.NODE.SUCCESS}
                        paddingBottom="2"
                        pointer
                      />
                      <Label
                        value="Aplicar filtros"
                        family="Light"
                        marginLeft="2"
                        pointer
                      />
                    </Div>
                    <Div
                      inline="center"
                      pointer
                      handlerClick={() => this.limparFiltros()}
                      className="bg-light border"
                      rounded
                      padding="1"
                      marginTop="2"
                    >
                      <I
                        icon={Type.ICON.CLOSEX}
                        colorText={Color.NODE.DANGER}
                        paddingBottom="2"
                        pointer
                        handlerClick={() => this.limparFiltros()}
                      />
                      <Label
                        value="Limpar filtros"
                        family="Light"
                        marginLeft="2"
                        pointer
                        handlerClick={() => this.limparFiltros()}
                      />
                    </Div>
                  </Div>
                  <Div style={{ marginTop: 70 }}>
                    {this.getFiltrosMapeados().map((item, index) => (
                      <Div key={index} padding="1">
                        {item}
                      </Div>
                    ))}
                  </Div>
                </Div>
              </If>

              <Div inline="between" marginTop="2">
                <Label
                  value={`Resultados da busca por: "${anuncio.titulo}"`}
                  style={styles.labelResultado}
                  family="SemiBold"
                />
              </Div>
              <If and value1={this.state.pesquisando}>
                <Div inline="center" style={{ marginTop: 30 }}>
                  <MessageSpinner
                    style={{ backgroundColor: Color.EMPRESA.PRIMARIA }}
                    colorText={Color.NODE.WHITE}
                  >
                    <Label
                      value="Consultando produtos"
                      family="Bold"
                      style={{ fontSize: 22 }}
                      margin="2"
                    />
                  </MessageSpinner>
                </Div>
              </If>

              <If
                and
                value1={!Utils.isValueValid(produtosFiltrados)}
                value2={!this.state.pesquisando}
              >
                <Label
                  family="Light"
                  value={`Não foi encontrado produtos para ${anuncio.titulo}`}
                  style={{
                    color: Color.ECOMMERCE.LARANJA_CLARO,
                    fontSize: 25,
                    marginLeft: 5,
                  }}
                />
              </If>
              {Array.from(this.getProdutosPorDepartamento()).map(
                (data, index) => {
                  return (
                    <Div className="d-flex flex-column" key={index}>
                      <Div inline="start">
                        <Image
                          src={IconsDepartamento.getIcon(data.departamento)}
                          style={styles.imageIcon}
                          width="25px"
                        />
                        <Label
                          style={styles.labelDepartamento}
                          value={data.departamento}
                          margin="2"
                        />
                      </Div>
                      <Label className="border-label-departamento" />
                      <Label className="border-bottom border-label-departamento-bottom" />
                      <Div inline="between">
                        {Array.from(data.produtos).map((produtos, subindex) => {
                          let produtosParaRenderizar =
                            Array.from(produtos).length > 1
                              ? produtos
                              : [produtos];

                          return produtosParaRenderizar.map((prod) => {
                            return (
                              <Div className="align-self-start">
                                <ItemProdutoAbaixouPrecoMobile
                                  key={subindex}
                                  history={this.props.history}
                                  image={prod.urlImagemPrincipalThumbnail}
                                  descricao={prod.descricao}
                                  precoUnitario={prod.precoUnitario}
                                  precoVenda={prod.precoVenda}
                                  precoAtacado={prod.precoAtacado}
                                  codigoBarras={prod.codigoBarras}
                                  precosConcorrentes={prod.precosConcorrentes}
                                  disponivel={prod.disponivel}
                                  quantidade={prod.quantidade}
                                  empresa={this.props.empresa}
                                  handlerClick={() =>
                                    this.listarProdutosSimilares(prod)
                                  }
                                  handlerAddItemCarrinho={(
                                    quantidade,
                                    callback
                                  ) => {
                                    prod = ProdutoUtils.calcularValorProduto(
                                      prod,
                                      quantidade
                                    );
                                    this.adicionarItemNoCarrinho(
                                      prod,
                                      callback
                                    );
                                  }}
                                />
                              </Div>
                            );
                          });
                        })}
                      </Div>
                    </Div>
                  );
                }
              )}
            </Div>
          </Div>

          {/**
            *  <If and value1={this.state.verMais} value2={!this.state.pesquisando}>
            <Div
              style={styles.containerVerMais}
              pointer
              handlerClick={() =>
                this.setState({ pagina: this.state.pagina + 1 }, () =>
                  this.listarProdutos()
                )
              }
              inline="center"
            >
              <Image src={ArrowDownVerMais} width="15px" />
              <Label
                value="Carregar mais itens..."
                margin="2"
                family="Bold"
                pointer
              />
            </Div>
          </If>
            */}
        </Div>
        <ContatoWhatsappMobile empresa={this.props.empresa} />
        <BottomScrollListener
          onBottom={() => {
            if (this.state.verMais) {
              this.setState({ pagina: this.state.pagina + 1 }, () =>
                this.listarProdutos(true)
              );
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.empresaState,
    ...store.listasDeCompraState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProdutosPorAnuncioOfertaMobile);
