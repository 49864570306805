import React from "react";
import TableColumnButton from "./TableColumnButton";
import Type from "../../../utilities/enuns/Type";
import Color from "../../../utilities/enuns/Color";
import PropTypes from "prop-types";

const TableColumnDelete = props => {
  return <TableColumnButton {...props}></TableColumnButton>;
};

TableColumnDelete.propTypes = {
  sizeIcon: PropTypes.string,
  colorText: PropTypes.string,
  handlerClick: PropTypes.func.isRequired
};

TableColumnDelete.defaultProps = {
  type: Type.ICON.DELETE,
  sizeIcon: "2",
  colorText: Color.NODE.DANGER
};

export default TableColumnDelete;
