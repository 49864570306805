import { Utils } from "../../utilities";

export default class EmpresaUtils {
  static getParametros(empresa) {
    return Utils.isValueValid(empresa) ? empresa.parametros : {};
  }

  static getParametro(empresa, param) {
    let parametros = EmpresaUtils.getParametros(empresa);
    return Utils.isValueValid(parametros) ? parametros[param] : null;
  }

  static getCorPrimaria(empresa) {
    return EmpresaUtils.getParametro(empresa, "corPrimaria");
  }

  static getCorSecundaria(empresa) {
    return EmpresaUtils.getParametro(empresa, "corSecundaria");
  }

  static getCorTerciaria(empresa) {
    return EmpresaUtils.getParametro(empresa, "corTerciaria");
  }

  static getCorRodape(empresa) {
    return EmpresaUtils.getParametro(empresa, "corRodape");
  }

  static getLogoEmpresa(empresa) {
    return EmpresaUtils.getParametro(empresa, "urlFoto");
  }

  static getPaginaPrincipal(empresa) {
    return EmpresaUtils.getParametro(empresa, "paginaPrincipal");
  }

  static getChavePix(empresa) {
    return EmpresaUtils.getParametro(empresa, "chavePix");
  }

}
