import React, { Component } from "react";
import { Div, I, If, Label } from "../../components";

import { Color, Type, Utils } from "../../utilities";
import "./deptolojainstantanea.css";
import { EmpresaUtils } from "..";
import { connect } from "react-redux";

const styles = {
  imageIcon: {
    color: Color.ECOMMERCE.LARANJA,
  },
  labelDepartamentoActive: {
    color: Color.ECOMMERCE.LARANJA,
    textTransform: "uppercase",
    fontSize: 20,
  },
  labelDepartamentoAZ: {
    color: Color.ECOMMERCE.WHITE,
    fontSize: 30,
  },
  labelCategoriasDdpto: {
    color: Color.ECOMMERCE.WHITE,
    fontSize: 30,
  },
  labelCategoriaDepartamento: {
    color: Color.ECOMMERCE.LARANJA,
    textTransform: "uppercase",
    fontSize: 20,
  },
};

class TemplateTodosDepartamentos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departamentoSelecionado: {},
      idTop: Utils.uuidString(),
    };
  }

  getClassNameContainer = (open) => {
    let classe = "slider-departamentos ";
    classe = classe.concat(open ? null : "close-departamentos");

    return classe;
  };

  getClassificacoes(tipo) {
    let menus = Array.from(this.props.menus).filter((c) => c.tipo === tipo);
    if (Utils.isValueValid(menus)) {
      menus = Utils.sort(menus, "descricao");
    }

    return menus;
  }

  getSecoes(departamento) {
    return Array.from(this.props.menus).filter(
      (c) =>
        Number(c.idDepartamentoWeb) ===
          Number(departamento.idDepartamentoSecao) && c.tipo === "SECAO"
    );
  }

  componentWillReceiveProps(props) {
    let div = document.getElementById(this.state.idTop);
    if (Utils.isValueValid(div)) {
      div.scrollTop = 0;
    }
  }

  render() {
    let departamentos = this.getClassificacoes("DEPARTAMENTO");
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    let background = EmpresaUtils.getParametro(
      this.props.empresa,
      "urlBackground"
    );
    return (
      <Div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundColor: "#020000",
          color: corPrimaria
        }}
        rounded
        id={this.state.idTop}
        className={this.getClassNameContainer(this.props.open)}
      >
        <I
          icon={Type.ICON.CLOSEX}
          sizeIcon="2"
          pointer
          handlerClick={() => this.props.handlerClose()}
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            color: corPrimaria,
          }}
        />
        <Div
          inline="start"
          responsive="10"
          className="mx-auto"
          style={{ paddingTop: 50, paddingBottom: 200 }}
        >
          <Label
            col="12"
            value="Departamentos A-Z"
            family="Bold"
            style={styles.labelDepartamentoAZ}
          />
          {Array.from(departamentos).map((depto, index) => {
            return (
              <Div
                key={index}
                className="d-flex flex-column align-self-start"
                col="3"
              >
                <Div
                  inline="start"
                  marginTop="2"
                  pointer
                  handlerClick={() =>
                    this.setState({ departamentoSelecionado: depto })
                  }
                >
                  {/**
   *                   <Image
                    src={IconsDepartamento.getIconLaranja(
                      String(depto.descricao).toUpperCase()
                    )}
                    // style={{backgroundColor: Color.ECOMMERCE.LARANJA, borderRadius: 10}}
                    width="40px"
                    margin="2"
                  />
   */}
                  <Label
                    value={depto.descricao}
                    style={{
                      ...styles.labelCategoriaDepartamento,
                      color: corPrimaria,
                    }}
                    family="Bold"
                    marginTop="3"
                    pointer
                    handlerClick={() => {
                      this.props.history.push(
                        `/produto/departamento/${depto.descricao}`,
                        {
                          departamento: depto,
                          isSecao: false,
                          empresa: this.props.empresa,
                        }
                      );
                      this.props.handlerClose();
                    }}
                  />
                </Div>
                <If and value1={Utils.isValueValid(depto.submenus)}>
                  <Div className="d-flex flex-column justify-content-start align-items-start">
                    {Array.from(depto.submenus).map((subMenu, index) => {
                      return (
                        <Label
                          value={subMenu.descricao}
                          key={index}
                          pointer
                          handlerClick={() => {
                            this.props.history.push(
                              `/produto/departamento/${subMenu.descricao}`,
                              {
                                departamento: subMenu,
                                isSecao: true,
                                empresa: this.props.empresa,
                              }
                            );
                            this.props.handlerClose();
                          }}
                        />
                      );
                    })}
                  </Div>
                </If>
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.empresaState,
  };
};

export default connect(mapStateToProps)(TemplateTodosDepartamentos);
