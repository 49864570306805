import React from "react";
import { Mask, Type } from "../../../utilities";
import InputDefault from "./InputDefault";
import Pattern from "../../../utilities/enuns/Pattern";

const InputCep = props => {
  return (
    <InputDefault
      {...props}
      formcontrol
      pattern={Pattern.INPUT.CEP.pattern}
      title={Pattern.INPUT.CEP.message}
      placeholder={props.placeholder || Pattern.INPUT.CEP.example}
      type={Type.INPUT.TEXT}
      handlerChange={e => {
        Mask.maskCep(e);
        return props.handlerChange(e);
      }}
    />
  );
};

export default InputCep;
