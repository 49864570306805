import React from 'react'

const DropDownMenu = (props) => {

    return (
        <div className={props.className + " dropdown-menu dropdown-menu-right"} style={props.style}>
            {props.children}
        </div>
    )

}

export default DropDownMenu 