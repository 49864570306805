import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Div, If, Image, Label, ModalNotification } from "../../../components";
import {
  atualizarCarrinho,
  setProdutos,
  setEmpresa,
  setParametrosPedido,
} from "../../../redux/actions";
import { Color, EndPoints, Http, Utils } from "../../../utilities";
import ProdutoDetalhe from "../../produtos/web/ProdutoDetalhe";

import UsuarioUtils from "../../utils/UsuarioUtils";
import CarouselBannerGenerico from "../../../components/carousel/CarouselBannerGenerico";
import ContatoWhatsapp from "../../ContatoWhatsapp";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import { EmpresaUtils, ProdutosDestaque } from "../..";

const initContatoWhatsapp = {
  telefone: "",
  mensagem: "",
};

class PrincipalColorauWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: [],
      produtos: [],
      menus: [],
      produtosVisualizados: [],
      produtosSimilares: [],
      carrinho: props.carrinho,
      empresa: {},
      zIndexHeader: 1001,
      contatoWhatsapp: initContatoWhatsapp,
      caracteristicaSelecionada: "CIMENTO QUEIMADO",
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
    this.visualizarProduto = this.visualizarProduto.bind(this);
    this.listarProdutosSimilares = this.listarProdutosSimilares.bind(this);
    this.visualizarProdutosAnuncio = this.visualizarProdutosAnuncio.bind(this);
  }

  setFavIcon = (empresa) => {
    let iconFiveIcon = document.querySelector("link[rel~='icon']");
    let urlFavIcon = EmpresaUtils.getParametro(empresa, "urlFavIcon");
    if (!iconFiveIcon) {
      iconFiveIcon = document.createElement("link");
      iconFiveIcon.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(iconFiveIcon);
    }
    iconFiveIcon.href = urlFavIcon;
  };

  setTitlePage = () => {
    document.title = "Tintas Colorau";
  };

  listarMenus() {
    Http.get(EndPoints.MENU)
      .then(({ status, data }) => {
        if (status) {
          this.setState({ menus: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  componentDidMount() {
    this.consultarParametrosPedido();
    this.consultarEmpresa();
    this.listarMenus();
    this.consultarOfertas();
    this.setTitlePage();
    // this.consultarProdutos();
  }

  consultarEmpresa() {
    Http.get("empresa")
      .then(({ status, data }) => {
        if (status) {
          this.setState({ empresa: data }, () => {
            this.props.setEmpresa(data);
            if (Utils.isValueValid(data)) {
              this.setFavIcon(data);
            }
          });
        }
      })
      .catch((erro) => console.log(erro));
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.props.setParametrosPedido(data);
            CarrinhoUtils.calcularValorTaxaEntrega(
              this.props.parametros,
              this.props.carrinho,
              this.props.atualizarCarrinho
            );
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  consultarOfertas() {
    Http.get("oferta")
      .then(({ status, data }) => {
        if (status) {
          this.setState({ anuncios: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  consultarProdutos() {
    Http.get("produto")
      .then(({ status, data }) => {
        if (status) {
          this.props.setProdutos(data);
        }
      })
      .catch((erro) => console.log(erro));
  }

  listarProdutosSimilares(produto, updateItem) {
    Http.get(EndPoints.PRODUTOS_SIMILARES.replace("%s", produto.idProdutoWeb))
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtosSimilares: data }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        } else {
          this.setState({ produtosSimilares: [] }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        }
      })
      .catch((error) => console.log(error));
  }

  visualizarProduto = (produto, updateItem) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalhe
        produto={produto}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          if (Utils.isFunction(updateItem)) {
            updateItem(produto.quantidade);
          }
        }}
        history={this.props.history}
        empresa={this.state.empresa}
      />
    );
  };

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.props.parametros
    );
  }

  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
  }) {
    if (
      Utils.isValueValid(marcaFiltro) ||
      Utils.isValueValid(idDepartamentoSecaoFiltro)
    ) {
      this.props.history.push("/anuncio/produtos", {
        titulo,
        idAnuncioOferta,
        idDepartamentoSecaoFiltro,
        tipoDepartamentoSecao,
        marcaFiltro,
        empresa: this.state.empresa,
        possuiProdutos,
      });
    }
  }

  getItensBanner() {
    let { anuncios } = this.state || {};
    let especiais = Array.from(anuncios.especial || []);

    if (Utils.isValueValid(especiais)) {
      especiais = Utils.sort(especiais, "ordem");
    }

    let especiaisPagina = Utils.quebrarArray(especiais, 3);

    let itens = [];

    especiaisPagina.forEach((itemPagina) => {
      itens.push(
        <Div inline="end" style={{ height: 300 }}>
          <Div
            margin="2"
            handlerClick={() => this.visualizarProdutosAnuncio(itemPagina[0])}
          >
            <Image
              src={
                Utils.isValueValid(itemPagina[0]) ? itemPagina[0].urlFoto : ""
              }
              width="330px"
              styleImage={{ height: "300px" }}
            />
          </Div>
          <Div column margin="2">
            <If and value1={Utils.isValueValid(itemPagina[1])}>
              <Div
                marginTop="1"
                marginBottom="1"
                handlerClick={() =>
                  this.visualizarProdutosAnuncio(itemPagina[1])
                }
              >
                <Image
                  src={
                    Utils.isValueValid(itemPagina[1])
                      ? itemPagina[1].urlFoto
                      : ""
                  }
                  width="320px"
                  styleImage={{
                    height: itemPagina[1].tipo === "DESTAQUE" ? "300px" : "",
                  }}
                />
              </Div>
            </If>
            <If and value1={Utils.isValueValid(itemPagina[2])}>
              <Div
                marginTop="1"
                marginBottom="1"
                handlerClick={() =>
                  this.visualizarProdutosAnuncio(itemPagina[2])
                }
              >
                <Image
                  src={
                    Utils.isValueValid(itemPagina[2])
                      ? itemPagina[2].urlFoto
                      : ""
                  }
                  width="320px"
                  styleImage={{
                    height: itemPagina[1].tipo === "DESTAQUE" ? "300px" : "",
                  }}
                />
              </Div>
            </If>
          </Div>
        </Div>
      );
    });

    return [...itens];
  }

  getItensBannerTopo() {
    const { anuncios } = this.state;
    let itens = [];

    let itensTopo = Array.from(anuncios.banner || []);

    if (Utils.isValueValid(itensTopo)) {
      itensTopo.forEach((item) => {
        console.log(item.exibirVideo, item.urlVideo);
        itens.push(
          <>
            <img
              src={item.urlFoto}
              width="100%"
              className="cursor-pointer"
              onClick={() => this.visualizarProdutosAnuncio(item)}
              alt="banner anuncio"
            />
            <If and value1={item.exibirVideo}>
              <video
                src={item.urlVideo}
                width="500px"
                height="400px"
                controls
                style={{
                  position: "absolute",
                  right: 20,
                }}
              />
            </If>
          </>
        );
      });
    }

    return itens;
  }

  getCaracteristicasDepartamento = () => {
    let map = new Map();
    map.set("CIMENTO QUEIMADO", [
      "Efeito perolizado",
      "Fácil aplicação",
      "Ótimo rendimento",
      "Pronto apra uso",
      "Sem cheiro",
      "Excelente cobertura",
      "Efeito brilhante e aveludado",
      "Secagem rápida",
    ]);

    map.set("TINTAS LISAS", [
      "Acabamento fosco ou brilhante",
      "Uso interno e externo",
      "Fácil aplicação",
      "Resistente as intempéries",
      "Alto rendimento",
      "Secagem rápida",
      "Ótima cobertura",
    ]);

    map.set("REVESTIMENTOS", [
      "Acabamento texturizado",
      "Uso interno e externo",
      "Preenche superfícies porosas",
      "Corrige imperfeições",
      "Aplicável sobre reboco, blocos de concreto, fibrocimento, massa corrida",
      "Pode ser utilizado em repinturas sobre PVA ou acrílico",
    ]);

    map.set("COMPLEMENTOS", [
      "Fácil aplicação",
      "Proporciona uniformidade",
      "Excelente secagem",
      "Resistente a alcalinidade",
      "Ótimo poder de cobertura",
      "Grande poder de penetração",
      "Melhora a durabilidade de tintas e massas",
    ]);

    map.set("MASSAS", [
      "Secagem rápida",
      "Excelente poder de preenchimento",
      "Fácil aplicação",
      "Ótima aderência",
      "Acabamento sofisticado",
    ]);

    return map;
  };

  renderTecnologiaProdutos = () => {
    let corSecundaria = EmpresaUtils.getCorSecundaria(this.state.empresa);
    let mapDepartamentos = this.getCaracteristicasDepartamento();
    let keys = Array.from(mapDepartamentos.keys());

    return (
      <Div>
        <Div inline="center">
          <Div
            //style={{ position: "absolute", transform: `translateX(-335px)` }}
            className="d-flex flex-column"
          >
            <Div
              style={{
                backgroundColor: corSecundaria,
                width: 400,
                padding: 40,
                borderRadius: 20,
                color: "white",
                fontSize: 25,
              }}
              className="d-flex flex-column"
            >
              {keys.map((depto, index) => {
                return (
                  <Label
                    pointer
                    value={depto}
                    handlerClick={() =>
                      this.setState({ caracteristicaSelecionada: depto })
                    }
                    key={index}
                    family="SemiBold"
                    style={
                      depto === this.state.caracteristicaSelecionada
                        ? {
                            backgroundColor: "#A0525E",
                            padding: 5,
                            borderRadius: 10,
                          }
                        : null
                    }
                  />
                );
              })}
            </Div>
          </Div>
          <span
            style={{
              border: `1px dashed ${Color.ECOMMERCE.CINZA_ESCURO}`,
              width: 120,
              position: "absolute",
              transform: `rotate(135deg) translate(-230px, 0px)`,
            }}
          />
          <span
            style={{
              border: `1px dashed ${Color.ECOMMERCE.CINZA_ESCURO}`,
              width: 350,
              position: "absolute",
              transform: `translate(383px, -205px)`,
            }}
          />
          <Image
            src="https://www.colorau.mobioh.com.br/imgs/cimentocolorauu.webp"
            width="400px"
          />
          <span
            style={{
              border: `1px dashed ${Color.ECOMMERCE.CINZA_ESCURO}`,
              width: 120,
              position: "absolute",
              transform: `rotate(45deg) translate(225px, 0px)`,
            }}
          />
          <span
            style={{
              border: `1px dashed ${Color.ECOMMERCE.CINZA_ESCURO}`,
              width: 350,
              position: "absolute",
              transform: `translate(380px, 203px)`,
            }}
          />

          <Div
            //style={{ position: "absolute", transform: `translateX(335px)` }}
            className="d-flex flex-column ml-3 mt-5 align-self-start"
          >
            <Label
              value="Principais características"
              family="Bold"
              style={{ fontSize: 25, color: "#674B4F" }}
            />
            <Div
              className="d-flex flex-column align-items-start "
              style={{ width: 400 }}
            >
              {Array.from(
                mapDepartamentos.get(this.state.caracteristicaSelecionada)
              ).map((caract, index) => {
                return (
                  <Label
                    value={`- ${caract}`}
                    family="SemiBold"
                    style={{
                      fontSize: 20,
                      color: Color.ECOMMERCE.CINZA_ESCURO,
                      listStyleType: "circle",
                    }}
                    key={index}
                  />
                );
              })}
            </Div>
          </Div>
        </Div>
      </Div>
    );
  };

  render() {
    return (
      <>
        <Div className="w-100 overflow-hidden" marginBottom="3">
          <CarouselBannerGenerico
            timeSlide={7000}
            itens={this.getItensBannerTopo()}
            slidesPerPage={1}
            dots
          />
        </Div>
        <ProdutosDestaque
          history={this.props.history}
          handlerClick={this.listarProdutosSimilares}
          empresa={this.state.empresa}
          handlerAddItemCarrinho={(produto) =>
            this.adicionarItemNoCarrinho(produto)
          }
        />
        {Utils.isValueValid(this.state.empresa) &&
          this.renderTecnologiaProdutos()}
        <Div id="divDepartamentosViewPrincipal" />
        <ContatoWhatsapp empresa={this.state.empresa} />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.parametrosPedidoState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { atualizarCarrinho, setProdutos, setEmpresa, setParametrosPedido },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrincipalColorauWeb);
