import React, { Component } from "react";
import PropTypes from "prop-types";
import { rounded, colorText } from "../css";

class Dropdown extends Component {

  render() {
    return (
      <div className={`col-${this.props.col}`}>
        <div className="dropdown">
          {this.props.dropdown}
          {this.props.children}
        </div>
      </div>
    );
  }

  getClassDefault() {
    let classe = " dropdown-toggle ";
    classe = classe.concat(colorText(this.props));
    classe = classe.concat(rounded(this.props));

    return classe;
  }
}

Dropdown.propTypes = {
  rounded: PropTypes.string,
  color: PropTypes.string
};

export default Dropdown;
