import React from "react";

import { className, backGround, col } from "../css";

const getClassDefaultCell = (props) => {
  let classe = "";
  classe = classe.concat(className(props));
  classe = classe.concat(col(props));
  classe = classe.concat(backGround(props));
  return classe;
};

const TableCell = (props) => {
  return (
    <tr className={getClassDefaultCell(props)}>
      {props.value || props.children}
    </tr>
  );
};

export default TableCell;
