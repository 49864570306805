import React, { Component } from "react";
import { Row, Div } from "../..";
import NavbarTabItem from "./NavbarTabItem";

import { className, active } from "../../css";

class NavbarTab extends Component {
  getClassDefault() {
    let classe = "navtab-background nav nav-tabs small ";
    classe = classe.concat(active(this.props));
    classe = classe.concat(className(this.props));

    return classe;
  }

  render() {
    return (
      <Row>
        <Div style={this.props.customStyles}>
          <ul className={this.getClassDefault()}>
            {Array.from(this.props.itens).map((item, key) => {
              return (
                <NavbarTabItem
                  handlerClick={(e) => this.props.handlerClick(item)}
                  key={key}
                  target={item.target}
                  value={item.value}
                  active={item.active}
                />
              );
            })}
          </ul>
        </Div>
      </Row>
    );
  }
}

export default NavbarTab;
