import React, { Component } from "react";
import { ModalCustom } from "../../components";
import ModalProdutosRelacionadosContent from "./ModalProdutosRelacionadosContent";

class ModalProdutosRealacionados extends Component {
  constructor(props) {
    super(props);

    this.state = {
     
    };
  }

  render() {

    return (
      <ModalCustom
        title="Adicionar Produtos Similares"
        showModal={this.props.closeModal}
        setShowModal={this.props.setCloseModal}
        confirm={() => this.props.setCloseModal()}
      >
        <ModalProdutosRelacionadosContent /> 
      </ModalCustom>
    )
  }
}
export default ModalProdutosRealacionados;