import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppDashboard from '../AppDashboard';

function isLogin() {
  if (sessionStorage.getItem("auth")) {
    return true
  }
  return false;
}


const PrivateRoute = ({ Component, ...rest }) => {
  return (

    isLogin() ? <Route {...rest} render={props => {
      return (
        <AppDashboard {...props}>
          <Component />
        </AppDashboard>
      );
    }

    } /> : <Redirect to="/admin/login" />
  );
};

export default PrivateRoute;