import React from "react";
import { Div, I, Label } from "../../components";
import { Color, Type } from "../../utilities";

const PagamentoProcessado = (props) => {
  return (
    <Div style={{ padding: 20 }} column rounded shadow >
      <Label
        style={{ fontSize: 30, color: Color.ECOMMERCE.CINZA_ESCURO }}
        family="SemiBold"
      >
        <I icon={Type.ICON.REFRESH} style={{ marginRight: 10 }} />
        {"Pagamento em processamento!"}
      </Label>
      <Label
        value="Feche esta tela para continuar"
        style={{ fontSize: 30, color: Color.ECOMMERCE.LARANJA }}
        family="SemiBold"
      />
    </Div>
  );
};

export default PagamentoProcessado;
