import React, { Component } from "react";
import { Color, Utils } from "../../utilities";
import Div from "../layout/div/Div";
import Span from "../texts/span/Span";

import "./carousel.css";

const styles = {
  labelTopLeft: {
    bottom: 20,
    left: 30,
    width: "100%",
    textAlign: "start",
  },
  subTitle: {
    color: Color.ECOMMERCE.LARANJA,
    fontWeight: "bold",
  },
};

class CarouselAnunciosEspeciaisMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      next: false,
      previos: true,
      itens: props.itens || [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ itens: props.itens });
  }

  render() {
    const ID_CAROUSEL = Utils.uuidString();
    const { next, previos } = this.state;

    const { itens } = this.state;

    return (
      <Div>
        <Div className="carousel slide" id={ID_CAROUSEL} dataRide="carousel">
          <Div className="carousel-inner">
            {Array.from(itens).map((item, index) => {
              return (
                <Div
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  key={index}
                >
                  <Div
                    className="carousel-captiond-md-block"
                    style={styles.labelTopLeft}
                  >
                    {item}
                  </Div>
                </Div>
              );
            })}
          </Div>
          <Div inline="center">
            <a
              href={`#${ID_CAROUSEL}`}
              role="button"
              data-slide="prev"
              onClick={() => this.setState({ previos: true, next: false })}
              className={`icon-prev-carousel-oferta-especial-mobile d-flex align-items-center justify-content-end m-1 ${
                previos ? "active" : ""
              }`}
              style={{ position: "absolute", top: "45%", left: 0 }}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              href={`#${ID_CAROUSEL}`}
              role="button"
              data-slide="next"
              onClick={() => this.setState({ previos: false, next: true })}
              className={`icon-prev-carousel-oferta-especial-mobile d-flex align-items-center justify-content-start m-1 ${
                next ? "active" : ""
              }`}
              style={{ position: "absolute", top: "45%", right: 0 }}
            >
              <Span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></Span>
            </a>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default CarouselAnunciosEspeciaisMobile;
