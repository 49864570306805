import React from 'react'
import './breadcrumb.css'

const getClasseDefault = (props) => {
    let classe = 'breadcrumb-item '
    classe = classe.concat(props.active ? 'text-bege ' : '')
    classe = classe.concat(props.className ? props.className : '')
    return classe
}


const BreadCrumbItem = (props) => {
    
    return (
        <li className={getClasseDefault(props)} aria-current="page" onClick={props.handlerClick}>
            <i>
                {props.value}
            </i>
            {
                props.separator && <i className="fa fa-angle-double-right mx-2" aria-hidden="true"></i>
            }
        </li>
    )

}

export default BreadCrumbItem