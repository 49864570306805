import React, { Component } from "react";
import { Utils } from "../../utilities";
import Div from "../layout/div/Div";
import CarouselBannerGenericoMobile from "./CarouselBannerGenericoMobile";

import "./carousel.css";

const WIDTH_IMAGE = window.innerWidth - 30;


class CarouselAnuncioOfertaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };
  }

  visualizarProdutosAnuncio(anuncio) {
    this.props.history.push("/anuncio/produtos", {
      ...anuncio,
    });
  }

  getWidthImageMobile() {
    let isViewMobileGrande = Utils.isViewMobileGrande();
    let isViewTablet = Utils.isViewTablet();
    let isViewTabletGrande = Utils.isViewTabletGrande();
    let width = WIDTH_IMAGE;

    if (isViewMobileGrande) width = 300;
    if (isViewTablet) width = 330;
    if (isViewTabletGrande) width = 400;

    return width;
  }

  render() {
    return (
      <Div>
        <CarouselBannerGenericoMobile
          timeSlide={7000}
          itens={this.props.itens}
          slidesPerPage={1}
          dots
          widthDots={WIDTH_IMAGE}
        />
      </Div>
    );
  }
}

export default CarouselAnuncioOfertaMobile;
