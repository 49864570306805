import React, {createContext, useEffect, useState} from 'react';
import { bindActionCreators } from "redux";
import { setEmpresa } from '../redux/actions';
import { connect } from 'react-redux';
import { EmpresaUtils } from '../views';

export const ThemesContext = createContext({});

const ThemeContextProvider = ({children, empresa}) => {
  const [state, setState] = useState({});

  const setColor = () => {
		let corPrimaria = EmpresaUtils.getCorPrimaria(empresa)
		let corSecundaria = EmpresaUtils.getCorSecundaria(empresa)
		let corTerciaria = EmpresaUtils.getCorTerciaria(empresa)
		let corRodape = EmpresaUtils.getCorRodape(empresa)

    // SET CORES GLOBAIS PARA CSS
    document.documentElement.style.setProperty('--cor-primaria', corPrimaria);
    document.documentElement.style.setProperty('--cor-secundaria', corSecundaria);
    document.documentElement.style.setProperty('--cor-terciaria', corTerciaria);
    document.documentElement.style.setProperty('--cor-rodape', corRodape);

		setState({
			corPrimaria,
			corSecundaria,
			corTerciaria,
			corRodape,
		})
	}
  useEffect(() => {
    if (Object.keys(empresa).length !== 0) { 
      setColor()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa])

  return (
      <ThemesContext.Provider value={state}>
        {children}
      </ThemesContext.Provider>
  );
};

const mapStateToProps = (store) => ({
	...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ setEmpresa },
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ThemeContextProvider);
