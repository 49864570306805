import React, { Component } from "react";
import { Div } from "../../components";
import { Color, Utils } from "../../utilities";
import If from "../if/If";
import Label from "../texts/label/Label";
import "./modal.css";

class ModalMessage extends Component {
  DBLOCK = "d-block";
  ID_MODAL = "modal-mobioh";

  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      show: props.show,
    };

    this.closed = props.closed || false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    Utils.pararScroolTelaPrincipal();
    this.setState({ show: props.show });
  }

  componentDidMount() {
    Utils.pararScroolTelaPrincipal();
  }

  componentWillUnmount() {
    Utils.ativarScroolTelaPrincipal();
  }

  getClassModal = () => {
    let classe = "modal ";
    classe = classe.concat(this.state.show ? this.DBLOCK : "d-none");
    return classe;
  };

  onDismiss() {
    document.getElementById(this.ID_MODAL).classList.toggle(this.DBLOCK);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.closed === false;
  }

  componentDidUpdate(props, state) {
    if (!this.closed) {
      this.onDismiss();
    }
  }

  cancel(event) {
    this.onDismiss();
    this.componentWillUnmount();
    if (this.props.handlerCancel) {
      this.props.handlerCancel(event);
    }
  }

  confirm(event) {
    this.onDismiss();
    this.componentWillUnmount();
    this.props.handlerConfirm(event);
  }

  render() {

    return (
      <Div className={this.getClassModal()} id={this.ID_MODAL}>
        <Div
          inline="center"
          className={`table-wrapper-scroll-y my-custom-scrollbar mx-auto`}
          responsive={this.props.responsive ? this.props.responsive : "6"}
          style={{ height: "100%" }}
        >
          <Div
            className="modal-content"
            style={{ backgroundColor: this.props.bg, color: this.props.color }}
          >
            <If and value1={Utils.isValueValid(this.props.title)}>
              <Div
                className="modal-header"
                inline="center"
                style={{ ...this.props.styleHeader }}
              >
                <Label
                  className="modal-title"
                  family="Bold"
                  style={{
                    fontSize: 25,
                    color: Color.ECOMMERCE.CINZA_ESCURO,
                    ...this.props.styleTitle,
                  }}
                  value={this.props.title}
                />
              </Div>
            </If>
            <Div className="modal-body" column>
              {this.props.body || this.props.children}
            </Div>
            <If and value1={!Utils.isValueValid(this.props.hideButtons)}>
              <Div
                className="d-flex flex-row justify-content-center"
                style={{ height: 60, padding: "10px 0", marginBottom: "15px" }}
              >
                <If and value1={Utils.isFunction(this.props.handlerCancel)}>
                  <Label
                    handlerClick={(e) =>
                      this.setState({ show: false }, () => this.cancel(e))
                    }
                    family="SemiBold"
                    style={{
                      backgroundColor: Color.ECOMMERCE.CINZA,
                      color: "white",
                      // width: widthButtons,
                      minWidth: "130px",
                      borderRadius: "5px",
                      textAlign: "center",
                      height: "100%",
                      ...this.props.styleButtonCancel,
                    }}
                    className="d-flex justify-content-center align-items-center"
                    value={
                      this.props.labelButtonCancel
                        ? this.props.labelButtonCancel
                        : "Cancelar"
                    }
                    pointer
                  />
                </If>
                <If and value1={Utils.isFunction(this.props.handlerConfirm)}>
                  <Label
                    handlerClick={(e) =>
                      this.setState({ show: false }, () => this.confirm(e))
                    }
                    family="SemiBold"
                    style={{
                      backgroundColor: Color.EMPRESA.PRIMARIA,
                      color: "white",
                      //width: widthButtons,
                      minWidth: "130px",
                      borderRadius: "5px",
                      marginLeft: 20,
                      padding: 10,
                      height: "100%",
                      ...this.props.styleButtonConfirm,
                    }}
                    className="d-flex justify-content-center align-items-center"
                    value={
                      this.props.labelButtonConfirm
                        ? this.props.labelButtonConfirm
                        : "Confirmar"
                    }
                    pointer
                  />
                </If>
              </Div>
            </If>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default ModalMessage;
