import React, { Component } from "react";
import { Div, If, NavbarTab, Row } from "../../components";
import OrcamentoService from "../../service/OrcamentoService";
import { Color, Type } from "../../utilities";
import BuscarPedido from "./BuscarPedido";
import DetalhesPedido from "./DetalhesPedido";

class GestorOrcamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemActive: {
        target: "AGUARDANDO SEPARAÇÃO",
        value: "AGUARDANDO SEPARAÇÃO",
        active: true,
      },

      abasStatus: {
        EM_ABERTO: 0, // AGUARDANDO SEPARACAO
        FALHA_PROCESSAMENTO: 0, // PENDENTE
        FALHA_PGTO_ON_LINE: 0, // PENDENTE
        AGUARDANDO_PGTO_ON_LINE: 0, // PENDENTE
        AGUARDANDO_RETIRADA: 0, // ENTREGA
        AGUARDANDO_HORARIO_ENTREGA: 0, // ENTREGA
        SAIU_ENTREGA: 0, // ENTREGA
        PROBLEMAS_ENTREGA: 0, 
        EM_SEPARACAO: 0,
        CONCLUIDO: 0,
        CANCELADO: 0,
      },
      atualizar: false,
      diasLancamento: "3",
      idPedido: 0,
    };

    this.setIdPedido = this.setIdPedido.bind(this);
    //  this.setAtualizar = this.setAtualizar.bind(this)
    this.setAbasStatus = this.setAbasStatus.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setAbasStatus("3");
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.atualizar !== this.state.atualizar) {
  //     if(!this.state.atualizar){
  //       return
  //     }
  //     this.setAbasStatus("1")
  //   }
  // }

  // setAtualizar(atualizar) {
  //   this.setState({
  //     atualizar
  //   })
  // }

  setAbasStatus(diasLancamento) {
    let obj = {
      diasLancamento,
    };

    OrcamentoService.consultarQuantidadePosicao(obj).then((data) => {
      let newAbas = this.state.abasStatus;
      if (data) {
        data.forEach((item) => {
          switch (item.posicao) {
            case Type.ORCAMENTO.ABERTO:
              newAbas.EM_ABERTO = item.quantidade;
              break;

            case Type.ORCAMENTO.SEPARACAO:
              newAbas.EM_SEPARACAO = item.quantidade;
              break;

            case Type.ORCAMENTO.CONCLUIDO:
              newAbas.CONCLUIDO = item.quantidade;
              break;

            case Type.ORCAMENTO.CANCELADO:
              newAbas.CANCELADO = item.quantidade;
              break;

            default:
              break;
          }
        });

        this.setState({
          abasStatus: {
            ...newAbas,
          },
          diasLancamento,
          atualizar: false,
        });
      }
    });
  }

  setIdPedido(idPedido) {
    this.setState({
      idPedido,
    });
  }

  compontentAba(nome, numero) {
    return (
      <span>
        <span>{nome}</span>
        {numero !== 0 && (
          <span
            style={{
              color: "#fff",
              backgroundColor: Color.ECOMMERCE.CINZA,
              textAlign: "center",
              lineHeight: "25px",
              borderRadius: "4px",
              margin: "5px",
              marginRight: 0,
              padding: "2px 7px",
            }}
          >
            {numero}
          </span>
        )}
      </span>
    );
  }

  getItens = () => {
    let item = this.state.itemActive;
    let abasStatus = this.state.abasStatus;

    return [
      {
        target: "AGUARDANDO SEPARAÇÃO",
        value: this.compontentAba("AGUARDANDO SEPARAÇÃO", abasStatus.EM_ABERTO),
        active: item.value === "AGUARDANDO SEPARAÇÃO",
      },

      {
        target: "EM_SEPARACAO",
        value: this.compontentAba("EM SEPARAÇÃO", abasStatus.EM_SEPARACAO),
        active: item.value === "EM_SEPARACAO",
      },

      {
        target: "PENDENTE",
        value: this.compontentAba(
          "PENDENTE",
          abasStatus.AGUARDANDO_RETIRADA
        ),
        active: item.value === "AGUARDANDO_RETIRADA",
      },

      {
        target: "ENTREGA",
        value: this.compontentAba(
          "ENTREGA",
          abasStatus.FALHA_PROCESSAMENTO
        ),
        active: item.value === "ENTREGA",
      },

      {
        target: "SAIU_ENTREGA",
        value: this.compontentAba("SAIU ENTREGA", abasStatus.SAIU_ENTREGA),
        active: item.value === "SAIU_ENTREGA",
      },

      {
        target: "PROBLEMAS_ENTREGA",
        value: this.compontentAba(
          "PROBLEMAS ENTREGA",
          abasStatus.PROBLEMAS_ENTREGA
        ),
        active: item.value === "PROBLEMAS_ENTREGA",
      },

      {
        target: "CANCELADO",
        value: this.compontentAba("CANCELADO", abasStatus.CANCELADO),
        active: item.value === "CANCELADO",
      },

      {
        target: "FINALIZADO",
        value: this.compontentAba("FINALIZADO", abasStatus.CONCLUIDO),
        active: item.value === "FINALIZADO",
      },
    ];
  };

  validNavAbas(itemActive) {
    // itemActive.stopPropagation();
    // itemActive.preventDefault();
    let item = this.state.itemActive;
    let formValidar = this.validaFormProd();

    if (formValidar) {
      this.setState({ itemActive });
    }

    if (!formValidar) {
      item.value = "AGUARDANDO SEPARAÇÃO";
      item.target = "AGUARDANDO SEPARAÇÃO";
    }
  }

  validaFormProd() {
    var valida = true;

    // if (!this.state.tipo) {
    //   Notification.danger("Selecione um tipo de Pesquisa");
    //   return false;
    // }

    // if (this.state.descricao === "") {
    //   Notification.danger("Escreve uma descrição para pesquisa");
    //   return false;
    // }

    // if (!this.state.datavalidade) {
    //   Notification.danger("Selecione uma data de validade");
    //   return false;
    // }

    return valida;
  }

  render() {
    let { target } = this.state.itemActive;
    const { idPedido, diasLancamento } = this.state;

    return (
      <Div
        margin="3"
        padding="3"
        borderLeft={Color.NODE.PRIMARY}
        bg={Color.NODE.WHITE}
        shadow
      >
        <NavbarTab
          itens={this.getItens()}
          handlerClick={(itemActive) => {
            this.validNavAbas(itemActive);
          }}
        />

        <If and value1={target === "AGUARDANDO SEPARAÇÃO"}>
          <Row responsive="12" padding="0">
            <Div responsive="4" padding="0">
              <BuscarPedido
                setAbasStatus={this.setAbasStatus}
                diasLancamento={diasLancamento}
                setIdPedido={this.setIdPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                idPedido={idPedido}
              />
            </Div>

            <Div responsive="8" padding="0">
              <DetalhesPedido
                idPedido={this.state.idPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                setAbasStatus={this.setAbasStatus}
              />
            </Div>
          </Row>
        </If>

        <If and value1={target === "SAIU_ENTREGA"}>
          <Row responsive="12" padding="0">
            <Div responsive="4" padding="0">
              <BuscarPedido
                setAbasStatus={this.setAbasStatus}
                diasLancamento={diasLancamento}
                setIdPedido={this.setIdPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                idPedido={idPedido}
              />
            </Div>

            <Div responsive="8" padding="0">
              <DetalhesPedido
                idPedido={this.state.idPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                setAbasStatus={this.setAbasStatus}
              />
            </Div>
          </Row>
        </If>

        <If and value1={target === "PROBLEMAS_ENTREGA"}>
          <Row responsive="12" padding="0">
            <Div responsive="4" padding="0">
              <BuscarPedido
                setAbasStatus={this.setAbasStatus}
                diasLancamento={diasLancamento}
                setIdPedido={this.setIdPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                idPedido={idPedido}
              />
            </Div>

            <Div responsive="8" padding="0">
              <DetalhesPedido
                idPedido={this.state.idPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                setAbasStatus={this.setAbasStatus}
              />
            </Div>
          </Row>
        </If>


        <If and value1={target === "PENDENTE"}>
          <Row responsive="12" padding="0">
            <Div responsive="4" padding="0">
              <BuscarPedido
                setAbasStatus={this.setAbasStatus}
                diasLancamento={diasLancamento}
                setIdPedido={this.setIdPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                idPedido={idPedido}
              />
            </Div>

            <Div responsive="8" padding="0">
              <DetalhesPedido
                idPedido={this.state.idPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                setAbasStatus={this.setAbasStatus}
              />
            </Div>
          </Row>
        </If>

        <If and value1={target === "ENTREGA"}>
          <Row responsive="12" padding="0">
            <Div responsive="4" padding="0">
              <BuscarPedido
                setAbasStatus={this.setAbasStatus}
                diasLancamento={diasLancamento}
                setIdPedido={this.setIdPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                idPedido={idPedido}
              />
            </Div>

            <Div responsive="8" padding="0">
              <DetalhesPedido
                idPedido={this.state.idPedido}
                posicao={Type.ORCAMENTO.ABERTO}
                setAbasStatus={this.setAbasStatus}
              />
            </Div>
          </Row>
        </If>

        <If and value1={target === "EM_SEPARACAO"}>
          <Row responsive="12" padding="0">
            <Div responsive="4" padding="0">
              <BuscarPedido
                setIdPedido={this.setIdPedido}
                diasLancamento={diasLancamento}
                posicao={Type.ORCAMENTO.SEPARACAO}
                setAbasStatus={this.setAbasStatus}
                idPedido={idPedido}
              />
            </Div>

            <Div responsive="8" padding="0">
              <DetalhesPedido
                idPedido={this.state.idPedido}
                posicao={Type.ORCAMENTO.SEPARACAO}
                setAbasStatus={this.setAbasStatus}
              />
            </Div>
          </Row>
        </If>

        <If and value1={target === "CANCELADO"}>
          <Row responsive="12" padding="0">
            <Div responsive="4" padding="0">
              <BuscarPedido
                setIdPedido={this.setIdPedido}
                diasLancamento={diasLancamento}
                posicao={Type.ORCAMENTO.CANCELADO}
                setAbasStatus={this.setAbasStatus}
                idPedido={idPedido}
              />
            </Div>

            <Div responsive="8" padding="0">
              <DetalhesPedido
                idPedido={this.state.idPedido}
                posicao={Type.ORCAMENTO.CANCELADO}
                setAbasStatus={this.setAbasStatus}
              />
            </Div>
          </Row>
        </If>

        <If and value1={target === "FINALIZADO"}>
          <Row responsive="12" padding="0">
            <Div responsive="4" padding="0">
              <BuscarPedido
                setIdPedido={this.setIdPedido}
                diasLancamento={diasLancamento}
                posicao={Type.ORCAMENTO.CONCLUIDO}
                setAbasStatus={this.setAbasStatus}
                idPedido={idPedido}
              />
            </Div>

            <Div responsive="8" padding="0">
              <DetalhesPedido
                idPedido={this.state.idPedido}
                posicao={Type.ORCAMENTO.CONCLUIDO}
                setAbasStatus={this.setAbasStatus}
              />
            </Div>
          </Row>
        </If>
      </Div>
    );
  }
}

export default GestorOrcamento;
