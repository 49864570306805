import React from "react";
import { Div,  InputDefault,  Select } from "../../components";
import { Color } from "../../utilities";

const TemplateFilterAssinaturas = ({
  dataInicial,
  dataExpiracao,
  handleChangeInput,
  tipoSelecionado,
  tipoFormaPagamento,
  selectPosicao,
  posicao,
  selectPagamento,
  selectFormaPagamento,
  tipoPagamento,
  selectTipoFrequencia,
  pedidosConsulta,
  selectPedidos,
  selectEntrega,
  tipoEntrega,
  handlePedidos
}) => {
  return (
    <Div rounded col="12" bg={Color.NODE.LIGHT} textAlign="left">
      <Div col="12" padding="2" inline="start">
        {/* <Div col="6" padding="2" inline="start">
          <Label style={{ marginLeft: "15px" }} value="Cliente" />
          <InputSearch
            required
            label="Cliente"
            className="unstyled"
            //responsive="6"
            handlerChange={(e) => handler(e.target.value, "cliente")}
            value={cliente}
            //handlerBlur={(e) => handlerSearch(e.target.value)}
            //hnadlerIcon={(e) => handlerSearch(e.target.value)}
            col="12"
          />
        </Div> */}
        <InputDefault
          required
          label="Data Inicial"
          date="date"
          datetime="datetime"
          className="unstyled"
          max="1979-12-31"
          responsive="3"
          handlerChange={(e) => handleChangeInput("dataFiltroInicial", e.target.value)}
          value={dataInicial}
        />
        <InputDefault
          required
          label="Data da Final"
          date="date"
          datetime="datetime"
          className="unstyled"
          max="1979-12-31"
          responsive="3"
          handlerChange={(e) => handleChangeInput("dataFiltroFinal", e.target.value)}
          value={dataExpiracao}
        />
        <Select
          col="3"
          label="Forma de Pagamento"
          values={selectFormaPagamento}
          valueSelected={tipoFormaPagamento}
          handlerChange={(e) => { handleChangeInput("tipoFormaPagamento", e.target.value) }}
        />
        <Select
          col="3"
          label="Opções de Pagamento"
          values={selectPagamento}
          valueSelected={tipoPagamento}
          handlerChange={(e) => { handleChangeInput("tipoPagamento", e.target.value) }}
        />
        
      </Div>

      <Div col="12" padding="2" inline="start">
        <Select
          col="3"
          label="Opções de Entrega"
          values={selectEntrega}
          valueSelected={tipoEntrega}
          handlerChange={(e) => { handleChangeInput("tipoEntrega", e.target.value) }}
        />
        
        <Select
          col="3"
          label="Frequência"
          values={selectTipoFrequencia}
          valueSelected={tipoSelecionado}
          handlerChange={(e) => { handleChangeInput("tipo", e.target.value) }}
        />

        <Select
          col="3"
          label="Pedidos"
          values={selectPedidos}
          valueSelected={pedidosConsulta}
          handlerChange={(e) => { handlePedidos(e.target.value) }}
        />

        <Select
          col="3"
          label="Posição"
          values={selectPosicao}
          valueSelected={posicao}
          handlerChange={(e) => { handleChangeInput("posicao", e.target.value)}}
        />
      </Div>

    </Div>
  );
};

export default TemplateFilterAssinaturas;
