export default class Response {
    status;
    message;
    data;
  
    constructor() {
      this.status = true;
      this.message = null;
      this.data = null;
    }
  }
  