import React, { Component } from "react";
import { Div } from "..";
import { TableCell, TableColumn } from "../../components";
import { Utils } from "../../utilities";
import "./table.css";

class TableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: props.columns,
      data: props.data
    };
  }

  getClassHeader(column) {
    let classe = "bordernone p-1 ";
    classe = classe.concat(
      this.props.bgHeader ? `bg-${this.props.bgHeader} ` : ""
    );
    classe = classe.concat(
      this.props.colorHeader ? `text-${this.props.colorHeader} ` : ""
    );
    classe = classe.concat(`text-${column.align} `)
    return classe;
  }

  //Renderiza o header das colunas
  renderHeader() {
    let columns = Array.from(this.state.columns);

    let header = columns.map((column, index) => {
      return (
        <th key={index} className={this.getClassHeader(column)}>
          {column.name}
        </th>
      );
    });

    return header;
  }

  renderData() {
    let { data } = this.state;

    let fields = Array.from(this.getFieldsNameTable(data));
    return Array.from(data).map((data, keyCell) => {
      return (
        <TableCell key={keyCell}>
          {fields.map((field, key) => {
            return <TableColumn key={key} value={data[field.name]} className={`text-${field.align}`}></TableColumn>;
          })}
        </TableCell>
      );
    });
  }

  renderFooter() {
    return (
      <TableCell className="bg-secondary text-white">
        <TableColumn className="" value="Subtotal"></TableColumn>
        <TableColumn></TableColumn>
        <TableColumn></TableColumn>
        <TableColumn></TableColumn>
        <TableColumn></TableColumn>
        <TableColumn>{this.props.valueFooter}</TableColumn>
      </TableCell>
    );
  }

  getFieldsNameTable(data) {
    let fields = [];

    if (Utils.isArrayNotEmpty(this.props.fields)) {
      fields = this.props.fields;
    } else {
      let dados = Array.from(data);
      if (Utils.isArrayNotEmpty(data)) {
        fields = Object.keys(Object.values(dados)[0]);
      }
    }

    return fields;
  }

  getClassContainer() {
    let classe = "border-none ";
    classe = classe.concat(this.props.col ? `col-${this.props.col} ` : "");
    classe = classe.concat(
      this.props.orientation ? `text-${this.props.orientation} ` : ""
    );
    return classe;
  }

  getClassTable() {
    let classe = "table border-none";
    return classe;
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { data } = nextProps;
    if (Utils.isValueValid(data)) {
      this.setState({
        data
      });
    }
  }

  render() {
    return (
      <Div className="p-3 col-12 rounded" bgwhite>
        <Div className={this.getClassContainer()} id="table-mobioh">
          <Div className="table-responsive rounded">
            <table className={this.getClassTable()}>
              <thead>
                <tr>{this.renderHeader()}</tr>
              </thead>
              <tbody>{this.renderData()}</tbody>
              {this.props.footer && <tfoot>{this.renderFooter()}</tfoot>}
            </table>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default TableList;
