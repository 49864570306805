import { Http } from '../utilities';

class ConcorrentesService {
  static async getConcorrentes() {

    let res = null;
    await Http.get(`/concorrente`).then(
      response => {
        res = response;
      },
    );
    return res;
  }

  static async getConcorrentesPreco(paginacao, obj) {

    let res = null;
    await Http.post("/concorrente/produtos/listar?tamlote=" + paginacao, obj ).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }


  static async postConcorrentes(obj) {
    let res = null;

    await Http.put('/concorrente/sincronizar', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async createConcorrentesPrecos(obj) {
    let res = null;

    await Http.post('/concorrente/precos/sincronizar', obj).then(response => {
      res = response;
    });

    return res;
  }


  

  static async removerConcorrentePreco(obj) {
    let res = null;
    await Http.delete("/concorrente/precos", obj).then(
      response => {
        res = response;
      }
    );
    return res;
  }

  static async removerConcorrente(obj) {
    let res = null;
    await Http.delete("/concorrente", obj).then(
      response => {
        res = response;
      }
    );
    return res;
  }
}


export default ConcorrentesService;
