import React, { Component } from "react";
import { Div, InputNumberBR, ModalCustom, Notification, Row, Select, SelectValue } from "../../components";
import HorariosService from "../../service/HorariosService";

class HorarioEditarTaxas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kmInicial: 0,
      kmFinal: 0,
      valorTaxaEntrega: 0,
      status: [
        new SelectValue(0, "SELECIONE", true),
        new SelectValue(true, "ATIVO", true),
        new SelectValue(false, "DESATIVADO", true)
      ],
      ativo: true,
      idEmpresaTaxaEntrega: "",
    };
  }

  UNSAFE_componentWillMount() {
    const { kmFinal, kmInicial, valorTaxaEntrega, idEmpresaTaxaEntrega } = this.props;
    this.setState({
      kmInicial,
      kmFinal,
      valorTaxaEntrega,
      idEmpresaTaxaEntrega,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { kmFinal, kmInicial, valorTaxaEntrega, idEmpresaTaxaEntrega } = this.props;
      this.setState({
        kmInicial,
        kmFinal,
        valorTaxaEntrega,
        idEmpresaTaxaEntrega,
      })
    }
  }


  atualizarHorario() {
    const { ativo, kmInicial, kmFinal, valorTaxaEntrega, idEmpresaTaxaEntrega  } = this.state;
    let editHorario = {
      idEmpresaTaxaEntrega,
      kmInicial,
      kmFinal,
      valorTaxaEntrega,
      ativo,
    }

    HorariosService.createHorariosTaxas([editHorario]).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.props.setLoadingHorarios(true);
      } else {
        Notification.danger(data.mensagem);
      }
    })
  }

  handlerSelectStatus = (e) => {
    this.setState({
      ativo: e.target.value
    })
  }

  render() {

    return (
      <ModalCustom
        title="Editar Taxas"
        showModal={this.props.closeModal}
        setShowModal={this.props.setCloseModal}
        confirm={() => this.atualizarHorario()}
      >
        <Div col="12">
          <Row col="12">
            <InputNumberBR
              //placeholder="número max"
              responsive="3"
              isInputGroup={false}
              label="Km Inicial"
              name="kmInicial"
              value={this.state.kmInicial}
              handlerChange={
                e => this.setState({
                  kmInicial: e.target.value,
                })}
            />
            <InputNumberBR
              //placeholder="número max"
              responsive="3"
              isInputGroup={false}
              label="Km Final"
              name="kmFinal"
              value={this.state.kmFinal}
              handlerChange={
                e => this.setState({
                  kmFinal: e.target.value,
                })}
            />
            <InputNumberBR
              //placeholder="número max"
              responsive="3"
              isInputGroup={false}
              label="Valor da Taxa de Entrega"
              name="valorTaxaEntrega"
              value={this.state.valorTaxaEntrega}
              handlerChange={
                e => this.setState({
                  valorTaxaEntrega: e.target.value,
                })}
            />
            <Select
              required
              responsive="3"
              label="Status"
              handlerChange={(e) => {
                this.handlerSelectStatus(e);
              }}
              values={this.state.status}
            />
            <Div
              col="3"
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "17px"
              }}
            >
            </Div>

          </Row>
        </Div>
      </ModalCustom>
    )
  }
}
export default HorarioEditarTaxas;