import React, { Component } from "react";
import NavbarTop from "../../components/navbar/menu-top/NavbarTop";
import Id from "../../utilities/enuns/Id";
import Div from "../layout/div/Div";
import { connect } from "react-redux";

class HeaderDashboard extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: props.user
    }
  }

  componentDidMount() {
    this.setState({
      user: JSON.parse(sessionStorage.getItem('auth'))
    })
  }

  render() {
    return (
      <Div className="pb-0 mb-0 bg-white">
        <NavbarTop
          {...this.props}
          title={this.props.title}
          id={Id.HTML.NAVBARTOP}
          active={this.props.active}
          handlerClick={this.props.handlerClick}
          username={this.state.user.nome}
          email={this.state.user.email}
        />
      </Div>
    );
  }
}

const mapStateToProps = store => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(HeaderDashboard);

