import { Http } from '../utilities';

class ProdutosService {
  static async getProdutos() {

    let res = null;
    await Http.get(`/produtos`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async buscarProdutos(obj) {
    let res = null;

    await Http.post('/produto/busca/filtro/admin', obj).then(response => {
      res = response;
    });

    return res;

  }
}

export default ProdutosService;
