import React from "react";
import PropTypes from "prop-types";

import {
  button,
  float,
  margin,
  padding,
  colorText,
  pointer,
  col,
  className,
  offset,
  inline
} from "../css";

const Button = props => {
  return (
    <button
      type={props.type}
      className={getClassDefault(props)}
      onClick={props.handlerClick}
      disabled={props.disabled}
      id={props.id}
      data-toggle={props.toggle}
      data-dismiss={props.dismiss}
      data-target={props.target}
      active={props.active}
      style={props.style}
    >
      {props.value || props.children}
    </button>
  );
};

const getClassDefault = props => {
  let classe = "btn ";
  classe = classe.concat(button(props));
  classe = classe.concat(props.size ? `btn-${props.size} ` : "");
  classe = classe.concat(float(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(colorText(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(col(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(offset(props));
  classe = classe.concat(className(props));

  return classe;
};

Button.propTypes = {
  colorText: PropTypes.string,
  bg: PropTypes.string,
  value: PropTypes.node,
  handlerClick: PropTypes.func,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  size: PropTypes.string,
  btn: PropTypes.string
};

export default Button;
