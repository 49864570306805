import React, { Component } from "react";
import { Type, Utils } from "../../utilities";
import Div from "../layout/div/Div";

import "./carousel.css";

class CarouselProdutosCombo extends Component {
  getClassNameIconNext = () => {
    let classe = Type.ICON.ARROW_ANGLE_RIGHT;

    classe = classe.concat(
      window.innerWidth > 600 ? " icon-nex-carousel-produto-combo" : ""
    );

    return classe;
  };

  getClassNameIconPrev = () => {
    let classe = Type.ICON.ARROW_ANGLE_LEFT;
    return classe;
  };

  render() {
    const ID_CAROUSEL = this.props.id || Utils.uuidString();

    return (
      <Div>
        <Div className="carousel slide" id={ID_CAROUSEL} dataRide="carousel">
          <a
            className="carousel-control-prev"
            href={`#${ID_CAROUSEL}`}
            role="button"
            data-slide="prev"
          >
            <i
              style={{
                fontSize: 60,
                color: "#C4C4C4",
                transform: `translateX(-120px)`,
              }}
              className={this.getClassNameIconPrev()}
            />
          </a>
          <Div className="carousel-inner">
            {Array.from(this.props.itens).map((item, index) => {
              return (
                <Div
                  className={`carousel-item ${item.active ? "active" : ""}`}
                  key={index}
                >
                  {item.value}
                </Div>
              );
            })}
          </Div>
          <a
            className="carousel-control-next"
            href={`#${ID_CAROUSEL}`}
            role="button"
            data-slide="next"
          >
            <i
              style={{ fontSize: 60, color: "#C4C4C4" }}
              className={this.getClassNameIconNext()}
            />
          </a>
        </Div>
      </Div>
    );
  }
}

export default CarouselProdutosCombo;
