export const CARRINHO_ITENS = "CARRINHO_ITENS";
export const CARRINHO = "CARRINHO";
export const ADICIONAR_ITEM_CARRINHO = "ADICIONAR_ITEM_CARRINHO";
export const REMOVER_ITEM = "REMOVER_ITEM";
export const SET_PRODUTOS = "SET_PRODUTOS";
export const ALTERAR_PRODUTO = "ALTERAR_PRODUTO";
export const SET_EMPRESA = "SET_EMPRESA";
export const SET_PARAMETROS = "SET_PARAMETROS";
export const SET_PEDIDO_FINALIZADO ="SET_PEDIDO_FINALIZADO";
export const SET_ENDERECO_ENTREGA = "SET_ENDERECO_ENTREGA";
export const SET_LISTAS_DE_COMPRA = "SET_LISTAS_DE_COMPRA";
export const SET_MENUS = "SET_MENUS";
export const SET_ANUNCIOS = "SET_ANUNCIOS";

export const LOGIN = "LOGIN"