import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Checkbox,
  Div,
  I,
  If,
  Input,
  Label,
  ModalNotification,
} from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";
import CadastrarCliente from "./CadastrarCliente";
import RecuperarSenha from "./RecuperarSenha";

import { setEnderecoEntrega } from "../../redux/actions";
import { bindActionCreators } from "redux";
import UsuarioUtils from "../utils/UsuarioUtils";
import { EmpresaUtils } from "..";
import TagManagerUtils from "../../utilities/utils/TagManagerUtils";

const styles = {
  btnCloseLoginMenu: {
    position: "absolute",
    top: 5,
    fontSize: 25,
    right: 5,
    color: Color.ECOMMERCE.LARANJA,
    zIndex: 1010,
  },
};

class LoginClienteMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuario: this.initUsuario(),
      mensagemErro: "",
      recuperarSenha: false,
    };
  }

  initUsuario() {
    let usuarioSalvo = UsuarioUtils.getDadosAcessoUsuario();

    if (Utils.isValueValid(usuarioSalvo)) {
      return usuarioSalvo.usuario;
    } else {
      return { usuario: "", senha: "" };
    }
  }

  renderCadastrarCliente() {
    ModalNotification.renderModalLogin(
      <>
        <Label
          className="modal-title"
          style={{
            color: Color.ECOMMERCE.LARANJA,
            fontSize: 25,
            marginTop: 20,
          }}
          family="Bold"
          value="Preencha as informações para realizar o cadastro"
        />
      </>,
      <CadastrarCliente responsive="8" empresa={this.props.empresa} />,
      () => {},
      "FINALIZAR CADASTRO"
    );
  }

  setEnderecoPrincipalEntrega(cliente) {
    let enderecos = Array.from(cliente.enderecos);

    if (Utils.isValueValid(enderecos)) {
      let enderecoPrincipal = enderecos.filter((end) => end.principal)[0];
      this.props.setEnderecoEntrega(enderecoPrincipal);
    }
  }

  setEnderecoSelecionado(cliente) {
    let enderecos = Array.from(cliente.enderecos);

    if (Utils.isValueValid(enderecos)) {
      let enderecoPrincipal = enderecos.filter((end) => end.principal)[0];
      let carrinho = {
        ...this.props.carrinho,
        enderecoEntrega: enderecoPrincipal,
      };

      this.props.setEnderecoEntrega(carrinho);
    }
  }

  logarUsuario() {
    const { usuario } = this.state;

    this.setState({ mensagemErro: "" });

    Http.post("/cliente/login", usuario)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.eventLogin(usuario.login)
          if (data.status) {
            this.setEnderecoSelecionado(data);
            UsuarioUtils.setUsuarioLojaWeb(data);
            //  localStorage.setItem(
            //     Keys.KEY_USUARIO_LOJA_WEB,
            //     JSON.stringify(data)
            //   );
            Utils.refreshPage();
            this.props.handlerCloseMenu()
          } else {
            this.setState({ mensagemErro: data.mensagem });
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  setSalvarAcessoLogin(salvar) {
    if (salvar) {
      UsuarioUtils.setDadosAcessoUsuario(this.state.usuario);
    } else {
      UsuarioUtils.removerDadosAcessoUsuario();
    }
  }

  getSalvarAcesso() {
    let acesso = UsuarioUtils.getDadosAcessoUsuario();
    return Utils.isValueValid(acesso);
  }

  renderViewLogin() {
    const { usuario } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);

    let logginOk =
      Utils.isValueValid(usuario.login) && Utils.isValueValid(usuario.senha);
    return (
      <>
        <I
          icon={Type.ICON.CLOSEX}
          pointer
          style={styles.btnCloseLoginMenu}
          handlerClick={() => this.props.handlerCloseMenu()}
        />
        <Div column>
          <Label
            style={{
              color: corPrimaria,
              fontSize: 25,
              marginTop: 20,
            }}
            family="Bold"
            value="Dados de acesso"
          />
          <Label
            value="Esqueci meus dados!"
            style={{
              color: Color.EMPRESA.TERCIARIA,
            }}
            className="hover-link"
            family="Light"
            handlerClick={() => this.setState({ recuperarSenha: true })}
          />
        </Div>
        <Div column margin="2">
          <Input
            placeholder="E-mail ou telefone com DDD"
            handlerChange={(e) =>
              this.setState(
                {
                  usuario: { ...usuario, login: e.target.value },
                },
                () => {
                  if (this.getSalvarAcesso())
                    UsuarioUtils.setDadosAcessoUsuario(this.state.usuario);
                }
              )
            }
            value={usuario.login}
            style={{
              width: "100%",
              height: 20,
              border: "none",
              fontSize: 25,
              textAlign: "center",
              backgroundColor: "transparent",
              margin: 10,
            }}
            className="border-bottom"
          />
          <Input
            type="password"
            style={{
              width: "100%",
              height: 20,
              border: "none",
              fontSize: 25,
              textAlign: "center",
              backgroundColor: "transparent",
              margin: 10,
            }}
            className="border-bottom"
            placeholder="Senha"
            handlerChange={(e) =>
              this.setState(
                {
                  usuario: { ...usuario, senha: e.target.value },
                },
                () => {
                  if (this.getSalvarAcesso())
                    UsuarioUtils.setDadosAcessoUsuario(this.state.usuario);
                }
              )
            }
            value={usuario.senha}
          />
          <Div column marginTop="2">
            <Checkbox
              label="Salvar acesso"
              checked={this.getSalvarAcesso()}
              handlerChange={({ checked }) =>
                this.setSalvarAcessoLogin(checked)
              }
            />
            <Label
              value="Novo por aqui? Cadastre-se"
              style={{
                color: Color.EMPRESA.TERCIARIA,
              }}
              className="hover-link"
              handlerClick={() => {
                this.props.history.push("/cadastrarcliente", {
                  empresa: this.props.empresa,
                });
                Utils.refreshPage();
              }}
            />
          </Div>
          <If and value1={Utils.isValueValid(this.state.mensagemErro)}>
            <Label
              value={this.state.mensagemErro}
              className="text-danger p-1 w-100 text-center"
              rounded
              family="Bold"
            />
          </If>
        </Div>

        <Div
          className="modal-footer w-100"
          marginTop="4"
          inline="between"
          style={{
            backgroundColor: logginOk ? corPrimaria : "#C4C4C4",
            borderRadius: 10,
            fontSize: 25,
          }}
          pointer
          handlerClick={(e) => {
            if (logginOk) {
              this.logarUsuario();
            }
          }}
        >
          <Label />
          <Label family="SemiBold" value="Entrar" style={{ color: "white" }} />
          <I
            icon={Type.ICON.ARROW_CHEV_RIGHT}
            style={{ color: "white", fontSize: 20 }}
          />
        </Div>
      </>
    );
  }

  renderViewRecuperarSenha() {
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    return (
      <Div column>
        <I
          icon={Type.ICON.CLOSEX}
          sizeIcon="2"
          style={{ ...styles.btnCloseLoginMenu, color: corPrimaria }}
          handlerClick={() => this.setState({ recuperarSenha: false })}
          pointer
        />
        <Label
          className="modal-title"
          style={{
            color: corPrimaria,
            fontSize: 25,
            marginTop: 20,
          }}
          family="Bold"
          value="Recuperar senha"
        />
        <Label value="Informe o usuário (email)" style={{color: corPrimaria}} family="Light"/>
        <RecuperarSenha corPrimaria={corPrimaria} />
      </Div>
    );
  }

  render() {
    return (
      <Div style={{ width: 500 }}>
        <If and value1={this.state.recuperarSenha}>
          {this.renderViewRecuperarSenha()}
        </If>
        <If and value1={!this.state.recuperarSenha}>
          {this.renderViewLogin()}
        </If>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEnderecoEntrega }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginClienteMenu);
