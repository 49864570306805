import BreadParam from "../../components/breadcrumb/BreadParam";


export default class PathRoute {
  static PATH_DEFAULT = "/admin/";

  static PATH = {
    
    HORARIO_LISTAR: PathRoute.getPath("horarios"),
    HORARIO_CADASTRAR: PathRoute.getPath("horarios/cadastrar"),

    PESQUISA_CONCORRENTE_CADASTRAR: PathRoute.getPath("pesquisas/concorrentes/cadastrar"),
    PESQUISA_CONCORRENTE: PathRoute.getPath("pesquisas/concorrentes"),
    PESQUISA: PathRoute.getPath("pesquisas/listar"),
   
    PERSONALIZACAO: PathRoute.getPath("personalizacao"),
    PERSONALIZACAO_GERAL: PathRoute.getPath("personalizacao/geral"),
    PERSONALIZACAO_CORES: PathRoute.getPath("personalizacao/cores"),
    
    LOGIN: "/login",
    ERP: "/erp",
  };

  static getPath(path) {
    return PathRoute.PATH_DEFAULT.concat(path);
  }

  static getBreads(route) {
    let paths = String(route)
      .split("/")
      .slice(1);
    let breads = [];
    let pathConcat = "";
    paths.forEach((path, index) => {
      pathConcat = pathConcat.concat("/" + path);

      let pathName =
        index === 0 ? (
          "Início"
        ) : (
          path
        );
      breads.push(new BreadParam(pathConcat, pathName, false));
    });
    return breads;
  }
}
