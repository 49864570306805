import React, { Component } from "react";
import { Type, Utils } from "../../utilities";
import Div from "../layout/div/Div";

import "./carousel.css";
import "./carouselprodutogenerico.css";

import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { I, If } from "..";

class CarouselBannerGenericoMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itens: props.itens || [],
      index: 0,
    };
  }

  componentWillReceiveProps({ itens }) {
    this.setState({ itens });
  }

  getDots() {
    let dots = [];
    Array.from(this.props.itens).forEach((item) => {
      dots.push(<li className={`indicator-carousel-banner-top`}></li>);
    });
    return dots;
  }

  getArrowLeft() {
    return this.props.arrows ? (
      <I
        style={{
          fontSize: this.props.isMobile ? 40 : 60,
          color: "#C4C4C4",
          transform: `translateY(${this.props.isMobile ? "-65px" : "-40px"})`,
        }}
        className={Type.ICON.ARROW_ANGLE_LEFT}
        pointer
        handlerClick={() => this.setState({ index: this.state.index - 1 })}
      />
    ) : null;
  }

  getArrowRight() {
    return this.props.arrows ? (
      <I
        style={{
          fontSize: this.props.isMobile ? 40 : 60,
          color: "#C4C4C4",
          transform: `translateY(-40px)`,
        }}
        className={Type.ICON.ARROW_ANGLE_RIGHT}
        pointer
        handlerClick={() => this.setState({ index: this.state.index + 1 })}
      />
    ) : null;
  }

  render() {
    return (
      <Div style={{ width: window.innerWidth }}>
        <Carousel
          infinite
          slidesPerPage={this.props.slidesPerPage}
          value={this.state.index}
          onChange={(index) => this.setState({ index })}
          stopAutoPlayOnHover
          autoPlay={this.props.timeSlide || 5000}
          arrowLeft={this.getArrowLeft()}
          arrowRight={this.getArrowRight()}
          offset={this.props.offset}
          animationSpeed={1000}
        >
          {this.state.itens}
        </Carousel>
        <If and value1={Utils.isValueValid(this.props.dots)}>
          <ol style={{ listStyleType: "none", marginTop: 10 }}>
            <Div inline="start">
              {Array.from(this.props.itens).map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => this.setState({  index })}
                    className={`indicator-carousel-banner-top-mobile m-1 ${
                      this.state.index === index ? "active" : ""
                    }`}
                  />
                );
              })}
            </Div>
          </ol>
        </If>
      </Div>
    );
  }
}

export default CarouselBannerGenericoMobile;
