import React, { Component } from "react";
import {
  Div,
  InputDefault,
  InputInteger,
  Label,
  InputCartao,
  ModalNotification,
} from "../../components";
import { Color, EndPoints, Http, Utils } from "../../utilities";

const styles = {
  bandeiraSelecinada: {
    borderRadius: 5,
    backgroundColor: Color.ECOMMERCE.LARANJA_CLARO,
  },
  inputCartao: {
    paddingLeft: "20px",
    paddingBottom: "10px",
    borderRadius: "8px",
  },
};

class TemplateCartaoNovo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recebimentoPedido: {
        idEmpresaFormaPagamento: "",
        tipo: "",
        quantidadeParcelas: "",
        bandeiraCartao: "",
        numeroCartao: "",
        titularCartao: "",
        dataValidadeCartao: "",
        codigoSegurancaCartao: "",
        anoValidadeCartao: "",
        mesValidadeCartao: '',
      },
    };
  }

  renderMessagemBinInvalido() {
    ModalNotification.renderMessage({
      body: <Label value="Cartão não autorizado para esta transação" />,
      title: "Erro ao validar o cartão",
      labelButtonCancel: "Fechar",
      handlerCancel: () => {},
    });
  }

  validarCartao = (numeroCartao) => {
    let num = numeroCartao;

    if (num.length >= 13) {
      this.consultarBinCartao();
    } else {
      this.renderMessagemBinInvalido();
    }
  };

  consultarBinCartao() {
    const { recebimentoPedido } = this.props;
    Http.get(
      EndPoints.PEDIDO_BIN_CARTAO.replace(
        "%s",
        String(recebimentoPedido.numeroCartao).substr(0, 7).replace(".", "")
      )
    )
      .then(({ status, data }) => {
        if (status) {
          if (data.situacao === "AUTORIZADA") {
            //   let bandeiras = Array.from(this.props.bandeirasSelect);
            let bandeiras = Array.from(this.props.bandeiras);
            let bandeira = bandeiras.filter(
              (b) => String(b.descricao).replace(" ", "") === data.bandeira
            )[0];

            this.props.handlerRecebimento({
              ...recebimentoPedido,
              numeroCartao: recebimentoPedido.numeroCartao,
              bandeiraCartao: bandeira.tipoBandeira || bandeira.descricao,
              idEmpresaFormaPagamento: Utils.isValueValid(bandeira)
                ? bandeira.idEmpresaFormaPagamento
                : null,
            });
          } else {
            this.props.handlerRecebimento({
              ...recebimentoPedido,
              numeroCartao: "",
              idEmpresaFormaPagamento: "",
            });
            this.renderMessagemBinInvalido();
          }
        }
      })
      .catch((error) => {
        console.log(error);

        this.props.handlerRecebimento({
          ...recebimentoPedido,
          numeroCartao: "",
          idEmpresaFormaPagamento: "",
        });
        this.renderMessagemBinInvalido();
      });
  }

  handleChangeCartao(name, e) {
    this.setState({
      recebimentoPedido: {
        ...this.state.recebimentoPedido,
        [name]: e.target.value
      }
    })
  }

  render() {
    const { recebimentoPedido } = this.props;


    return (
      <Div
        style={{
          backgroundColor: "rgb(231, 232, 234)",
          borderRadius: "8px",
          margin: "0px 15px",
        }}
      >
        <InputDefault
          value={this.state.recebimentoPedido.titularCartao}
          handlerChange={(e) => {
            this.props.handlerRecebimento({
              ...recebimentoPedido,
              titularCartao: e.target.value,
            });
            this.handleChangeCartao('titularCartao', e)
          }}

          autoComplete="off"
          responsive="12"
          placeholder="Nome escrito no cartão"
          styleContainer={{ marginBottom: "0" }}
          style={styles.inputCartao}
        />
        <InputCartao
          responsive="12"
          fontSizeLabel="20"
          placeholder="Número do cartão"
          autoComplete="off"
          value={this.state.recebimentoPedido.numeroCartao}
          handlerChange={(e) => {
            let numeroCartao = String(e.target.value);
            this.props.handlerRecebimento({
              ...recebimentoPedido,
              numeroCartao,
            });
            this.handleChangeCartao('numeroCartao', e)
          }}
          handlerBlur={() => {
            recebimentoPedido.numeroCartao
              ? this.validarCartao(recebimentoPedido.numeroCartao)
              : console.log(recebimentoPedido.numeroCartao);
          }}
          style={styles.inputCartao}
        />

        <Div className="w-100" padding="0" inline="start">
          <InputInteger
            maxLength="2"
            placeholder="Mês"
            autoComplete="off"
            responsive="2"
            value={this.state.recebimentoPedido.mesValidadeCartao}
            handlerChange={(e) => {
              this.props.handlerRecebimento({
                ...recebimentoPedido,
                mesValidadeCartao: e.target.value,
              });
              this.handleChangeCartao('mesValidadeCartao', e)
            }}
            styleContainer={{ display: "flex" }}
            style={styles.inputCartao}
          />
          <InputInteger
            maxLength="2"
            placeholder="Ano"
            autoComplete="off"
            responsive="2"
            value={this.state.recebimentoPedido.anoValidadeCartao}
            handlerChange={(e) => {
              this.props.handlerRecebimento({
                ...recebimentoPedido,
                anoValidadeCartao: e.target.value,
              });
              this.handleChangeCartao('anoValidadeCartao', e)
            }}
            styleContainer={{ display: "flex" }}
            style={styles.inputCartao}
          />

          <InputInteger
            maxLength="4"
            placeholder="Código de segurança (CVC/CVV)"
            autoComplete="off"
            responsive="4"
            value={this.state.recebimentoPedido.codigoSegurancaCartao}
            handlerChange={(e) => {
              this.props.handlerRecebimento({
                ...recebimentoPedido,
                codigoSegurancaCartao: e.target.value,
              });
              this.handleChangeCartao('codigoSegurancaCartao', e)
            }}
            styleContainer={{ display: "flex" }}
            style={styles.inputCartao}
          />
        </Div>
      </Div>
    );
  }
}

export default TemplateCartaoNovo;
