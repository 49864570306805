import React, { Component } from "react";

import {
  Div,
  Label,
  I,
  InputDefault,
  InputNumberBR,
} from "../../components";


import { Color, Type } from "../../utilities";



class PrecoEstoque extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ProdutoInfo: [{
        titulo: "titulo",
        descricao: "aqui seria uma descrição"
      }],
      titulo: "",
      descricao: "",
      info: "",
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handlerClick = () => {
    const { titulo, descricao, ProdutoInfo } = this.state;

    let newObj = {
      titulo,
      descricao,
    }

    this.setState({
      ProdutoInfo: [
        ...ProdutoInfo,
        newObj
      ]
    })

  }

  handleChanceTextArea = (name, value) => {
    this.setState({
      [name]: value,
    })
  }


  render() {
    const { produto } = this.props;

    return (
      <Div marginBottom="2">
        <Div>
          <Div
            padding="1"
            inline="start"
            bg={Color.NODE.WHITE}
            colorText={Color.NODE.DEFAULT}
            className="titulo-precificacao"
          >
            <I icon={Type.ICON.USD} marginLeft="2" marginBottom="2" />
            <Label bold value="Preço ERP" rounded marginLeft="2" />
          </Div>
          <Div>
            <Div shadow inline="start" paddingTop="2" paddingBottom="2" >
              <InputNumberBR
                handlerChange={(e) => console.log(e)}
                responsive={"3"}
                value={produto.precoUnitario}
                label="Preço unitário"
                disabled
              />
              <InputNumberBR
                handlerChange={(e) => console.log(e)}
                responsive={"3"}
                value={produto.precoVenda}
                label="Preço Venda"
                disabled
              />
              <InputNumberBR
                handlerChange={(e) => console.log(e)}
                responsive={"3"}
                value={produto.precoAtacado}
                label="Preço atacado"
                disabled
              />

             
              <InputDefault
                handlerChange={(e) => console.log(e)}
                responsive={"3"}
                value={`${produto.quantidadeMinimaAtacado}`}
                disabled
                label="Qtde mínima atacado"
              />

              <InputDefault
                handlerChange={(e) => console.log(e)}
                responsive={"3"}
                value={`${produto.quantidadeEstoqueAtual}`}
                disabled
                label="Estoque Atual"
              />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default PrecoEstoque;