import React, { Component } from "react";
import { Div, I, If, Image, Label } from "../../../components";

import { Color, Type, Utils } from "../../../utilities";
import { connect } from "react-redux";
import ItemProdutoAbaixouPrecoMobile from "../ItemProdutoAbaixouPrecoMobile";
import ProdutoUtils from "../ProdutoUtils";
import SpaceUtils from "../../utils/SpaceUtils";
import CarouselProdutoGenericoNovo from "../../../components/carousel/CarouselProdutoGenericoNovo";
import { EmpresaUtils } from "../..";

const styles = {
  title: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 12,
    marginLeft: 5,
  },
  labelDepartamento: {
    fontSize: 15,
    fontWeight: "bold",
  },
  imageIcon: {
    width: 28,
    height: 28,
    marginBottom: 15,
    marginRight: 5,
  },
};

const WIDTH_CONTAINER = SpaceUtils.MOBILE_CONTAINER_ATUAL;

class ProdutosMaisAcessadosMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: new Map(),
      menus: props.menus,
    };
  }

  UNSAFE_componentWillMount() {
    let produtos = this.props.produtos;
    let produtosAgrupados = Utils.groupBy(produtos, "descricaoDepartamento");

    this.setState({
      produtos: produtosAgrupados,
      menus: this.props.menus,
    });
  }

  componentWillReceiveProps(props) {
    let produtos = props.produtos;

    let produtosAgrupados = Utils.groupBy(produtos, "descricaoDepartamento");

    this.setState({
      produtos: produtosAgrupados,
      menus: props.menus,
    });
  }

  getProdutosPorDepartamento = () => {
    let itensParaRenderizar = [];

    for (const item of this.state.produtos) {
      let itens = [];

      let departamento = item[0];
      let produtos = Array.from(item[1]);

      Array.from(produtos).forEach((prod) => {
        itens.push(
          <Div className="d-flex justify-content-start align-items-start align-self-start">
            <ItemProdutoAbaixouPrecoMobile
              image={prod.urlImagemPrincipalThumbnail}
              descricao={prod.descricao}
              precoUnitario={prod.precoUnitario}
              precoVenda={prod.precoVenda}
              precoAtacado={prod.precoAtacado}
              codigoBarras={prod.codigoBarras}
              quantidade={prod.quantidade}
              empresa={this.props.empresa}
              history={this.props.history}
              estoqueDisponivel={prod.estoqueDisponivel}
              fatorEmGrama={prod.fatorEmGrama}
              possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
              percentualEconomia={prod.percentualEconomia}
              quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
              precosConcorrentes={prod.precosConcorrentes || []}
              disponivel={prod.disponivel}
              handlerClick={(updateItem) =>
                this.props.handlerClick(prod, updateItem)
              }
              //   handlerClick={() =>
              //      this.props.visualizarDetalhesProduto(prod)
              //   }
              handlerAddItemCarrinho={(quantidade) => {
                prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
                this.props.handlerAddItemCarrinho(prod);
              }}
            />
          </Div>
        );
      });

      if (Utils.isValueValid(itens)) {
        itensParaRenderizar.push({ produtos: itens, departamento });
      }
    }

    return itensParaRenderizar;
  };

  renderProdutosMaisAcessados() {
    let corSecundaria = EmpresaUtils.getCorSecundaria(this.props.empresa)
    return (
      <Div style={{ width: WIDTH_CONTAINER, marginTop: 20 }}>
        <Div inline="start" col="12">
          <I
            icon={Type.ICON.ROCKET}
            style={{
              fontSize: 15,
              color: Color.ECOMMERCE.CINZA_ESCURO,
              marginBottom: 10,
            }}
          />
          <Label
            value="PRODUTOS MAIS ACESSADOS"
            style={styles.title}
            family="Bold"
          />
        </Div>
        {Array.from(this.getProdutosPorDepartamento()).map((data, index) => {
          let departamento = {};

          if (Utils.isValueValid(this.props.menus)) {
            departamento = Array.from(this.props.menus).filter(
              (depto) =>
                String(depto.descricao).toUpperCase() ===
                String(data.departamento).toUpperCase()
            )[0];
          }

          return (
            <Div className="d-flex flex-column" key={index}>
              <Div
                inline="start"
                pointer
                col="12"
                handlerClick={() => {
                  this.props.history.push(
                    `/produto/departamento/${departamento.descricao}`,
                    { departamento, isSecao: false }
                  );
                }}
              >
                <Image
                  src={departamento.urlFoto}
                  style={styles.imageIcon}
                  className="color-icon-menu"
                  pointer
                  width="25px"
                  alt={data.departamento}
                />
                <Label
                  pointer
                  family="SemiBold"
                  style={{...styles.labelDepartamento, color: corSecundaria }}
                  value={data.departamento}
                />
              </Div>
              <Div className="d-flex flex-column" col="12">
                <Label className="border-label-departamento" />
                <Label className="border-bottom border-label-departamento-bottom" />
              </Div>
              <CarouselProdutoGenericoNovo
                timeSlide={7000}
                isMobile
                itens={data.produtos}
                slidesPerPage={SpaceUtils.getQuantidadeItensView()}
                offset={30}
                arrows
              />
            </Div>
          );
        })}
      </Div>
    );
  }

  render() {
    return (
      <If and value1={this.state.produtos.size > 0}>
        {this.renderProdutosMaisAcessados()}
      </If>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.menusState
  };
};

export default connect(mapStateToProps)(ProdutosMaisAcessadosMobile);
