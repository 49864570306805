import React from "react";
import { Div, Span } from "..";
import "./tooltip.css";

const Tooltip = props => {
  return (
    <Div
      style={props.style}
      className={`tooltips`}
      column
      responsive={props.responsive}
      col={props.col}
      show={props.show}
    >
      <Span className={props.className}>{props.value || props.children}</Span>
    </Div>
  );
};

export default Tooltip;
