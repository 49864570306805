import React from "react";
import { Div, Image } from "../..";
import { Mask } from "../../../utilities";
import InputDefault from "./InputDefault";

const InputInteger = (props) => {
  return (
    <>
      <Div style={{ position: "absolute" , right: 20, transform: `translateY(-10px)`}}>
        <Image src={props.imagem} width="50px" />
      </Div>
      <InputDefault
        {...props}
        formcontrol
        type="text"
        autoFocus={props.autoFocus}
        handlerChange={(e) => {
          Mask.maskCartao(e);
          return props.handlerChange(e);
        }}
        handlerBlur={props.handlerBlur}
      />
    </>
  );
};

export default InputInteger;
