export default class Id {

    static HTML = {
        MAIN: 'main',
        NAVBAR: 'navbar',
        NAVBARTOP: 'navbartop',
        MENU: 'menu',
        DROP_DOWN_MENU_BUTTON: 'dropdownMenuButton'
    }

    static TABLE = {
        FILTER: "collapseFilterTable"
    }

    static CHART = {
        CHART_LINE: "charLineMobioh"
    }

    static COLLAPSE = {
        BODY_COLLAPSE: "bodyCollapseCardapio"
    }


}