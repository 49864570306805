import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function isLogin() {
  if (sessionStorage.getItem("auth")) {
    return true
  }
  return false;
}

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (
      isLogin() && restricted ?
        <Redirect to="/admin/dashboard" />
        : <Component {...props} />
    )} />
  );
};

export default PublicRoute;