import React, { Component, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./App.css";
import { Div, If, Label, Parent } from "./components";
import { EndPoints, Http, Keys, Utils } from "./utilities";

import {
  atualizarCarrinho,
  setProdutos,
  setEmpresa,
  setListasDeCompra,
  setMenus,
  setAnuncios,
  setParametrosPedido,
} from "./redux/actions";
import HeaderLojaWebMobile from "./views/header/HeaderLojaWebMobile";
import RodapeMobile from "./views/rodape/RodapeMobile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const IS_LOJA_TESTE = window.location.hostname === "www.maiitre.com.br";

const styles = {
  containerViews: {
    marginTop: "150px",
  },
};

function AppMobileFc(props) {
  const { children } = props;

 // window.outerWidth = 1158;

  const [state, setState] = useState({
    menus: [],
    abrirMenuDepartamentos: false,
    portrait: true,
  });
  const [loading, setLoading] = useState(true);

  function atualizarPaginaNovaVersao() {
    let reloadPage = localStorage.getItem(Keys.KEY_RELOAD_PAGE);
    if (
      !Utils.isValueValid(reloadPage) ||
      String(reloadPage) !== Keys.KEY_VERSION_PAGE
    ) {
      localStorage.setItem(Keys.KEY_RELOAD_PAGE, Keys.KEY_VERSION_PAGE);
      localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB);
      localStorage.removeItem(Keys.KEY_USUARIO_LOJA_WEB);
      window.location.reload(true);
    }
  }

  const consultarAnuncios = async () => {
    try {
      const response = await Http.get("oferta");
      if (response.status) {
        props.setAnuncios(response.data);
        setLoading(false);
      } else {
        console.error("Erro ao consultar anuncios:", response.status);
      }
    } catch (error) {
      console.error("Erro ao consultar anuncios:", error);
    }
  };

  const consultarEmpresa = useCallback(async () => {
    try {
      const response = await Http.get("empresa");
      if (response.status) {
        props.setEmpresa(response.data);
      } else {
        console.error("Erro ao consultar empresa:", response.status);
      }
    } catch (error) {
      console.error("Erro ao consultar empresa:", error);
    }
  }, [props]);

  const listarMenus = async () => {
    try {
      const response = await Http.get(EndPoints.MENU);
      if (response.status) {
        props.setMenus(response.data);
      } else {
        console.error("Erro ao listar menus:", response.status);
      }
    } catch (error) {
      console.error("Erro ao listar menus:", error);
    }
  };

  useEffect(() => {
    //setLoading(true);
    const fetchData = async () => {
      await Promise.all([
        consultarEmpresa(),
        listarMenus(),
        consultarAnuncios(),
      ]);
    };

    atualizarPaginaNovaVersao();
    fetchData();
  }, []);

  return (
    <Parent>
      <ToastContainer position="bottom-right" />
      {!loading && (
        <>
          <If
            and
            value1={props.showHeader}
            value2={Utils.isValueValid(props.empresa)}
          >
            <HeaderLojaWebMobile
              empresa={props.empresa}
              menus={props.menus}
              history={props.history}
              handlerToggleDepartamentos={() =>
                setState({
                  ...state,
                  abrirMenuDepartamentos: state.abrirMenuDepartamentos,
                })
              }
              handlerAbrirPesquisa={() => {
                setState({
                  ...state,
                  abrirMenuDepartamentos: false,
                });
              }}
            />
          </If>

          <Div
            inline="center"
            style={
              props.showHeader ? styles.containerViews : null
            }
          >
            <If and value1={IS_LOJA_TESTE}>
              <Div
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  width: "100%",
                  height: 100,
                  padding: 10,
                }}
                inline="center"
              >
                <Label
                  family="Bold"
                  style={{ fontSize: 20, textAlign: "justify" }}
                  value="Esta é uma loja de demonstração para fins de teste - As compras realizadas não são válidas"
                />
              </Div>
            </If>
            {children}
          </Div>
          <If
            and
            value1={props.showRodape}
            value2={Utils.isValueValid(props.empresa)}
          >
            <RodapeMobile empresa={props.empresa} />
          </If>
        </>
      )}
    </Parent>
  );
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.empresaState,
    ...store.menusState,
    ...store.listasDeCompraState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      atualizarCarrinho,
      setProdutos,
      setEmpresa,
      setListasDeCompra,
      setMenus,
      setAnuncios,
      setParametrosPedido,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileFc);
