import React, { Component } from "react";
import { Div, I, Image, Label } from "../../components";
import { Color, Type, Utils } from "../../utilities";

import "./deptolojainstantanea.css";

const styles = {
  labelDepartamento: {
    fontStyle: "normal",
    fontFamily: "MetropolisLight",
    fontSize: "18px",
    color: "#878787",
    textTransform: "uppercase",
    width: window.innerWidth - 50,
  },
  iconLabel: {
    color: Color.EMPRESA.PRIMARIA,
  },
  imageIcon: {
    width: 28,
    height: 28,
  },
  iconMaisDepartamentos: {
    color: Color.ECOMMERCE.LARANJA,
  },
};

const getDepartamentos = (menus) => {
  let itens = [];

  let menusDepartamentoOrdenados = Utils.sort(
    Array.from(menus).filter((m) => m.tipo === "DEPARTAMENTO"),
    "ordem"
  );

  let menuDestaque = Array.from(menus).filter((m) => m.tipo === "DESTAQUES");
  let menusOrdenados = [...menuDestaque, ...menusDepartamentoOrdenados];

  if (Utils.isValueValid(menusOrdenados)) {
    menusOrdenados.forEach((depto) => {
      itens.push({
        label: depto.descricao,
        image: depto.urlFoto,
        idDepartamentoSecao: depto.idDepartamentoSecao,
        descricao: depto.descricao,
        tipo: depto.tipo,
        chavePesquisa: depto.chavePesquisa,
      });
    });
  }

  return itens;
};

class DepartamentosLojaInstantaneaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = { css: props.css, open: false, empresa: props.empresa };

    this.toggleMenuFiltros = this.toggleMenuFiltros.bind(this);
  }

  toggleMenuFiltros = () => {
    this.setState({ open: !this.state.open }, () => {
      Utils.toggleScrollBody(this.state.open ? "hidden" : "auto");
      // this.props.handlerOpenDepartamento();
      Utils.scrollTop();
    });
  };

  UNSAFE_componentWillReceiveProps({ css, empresa }) {
    this.setState({ css, empresa });
  }
  render() {
    const ID_DEPARTAMENTOS = Utils.uuidString();
    let menus = Array.from(getDepartamentos(this.props.menus));
    return (
      <Div
        inline="start"
        handlerClick={() => {
          this.toggleMenuFiltros();
        }}
        //  toggle="collapse"
        // target={`#${ID_DEPARTAMENTOS}`}
      >
        {/* <Div
          padding="2"
          marginTop="2"
          inline="start"
          className="label-departamentos"
          pointer
        >
          <I
            marginBottom="2"
            icon={Type.ICON.ARROW_ANGLE_DOWN}
            style={styles.iconLabel}
          />
          <Label
            value="Departamentos"
            style={styles.labelDepartamento}
            family="Bold"
            pointer
            marginLeft="1"
          />
        </Div> */}

        <Div
          //className="collapse"
          id={ID_DEPARTAMENTOS}
          style={{
            maxHeight: "70vh",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <Div className="d-flex flex-column align-items-start justify-content-start">
            {menus.map((depto, index) => {
              return (
                <Div
                  inline="start"
                  key={index}
                  marginLeft="2"
                  marginRight="2"
                  pointer
                  style={{
                    height: 50,
                    width: window.innerWidth,
                    paddingBottom: index === menus.length - 1 ? 100 : 0,
                  }}
                  handlerClick={() => {
                    //  this.props.handlerOpenDepartamento();
                    this.props.history.push(
                      `/produto/departamento/${depto.descricao}`,
                      { departamento: depto, empresa: this.state.empresa }
                    );
                  }}
                >
                  <Image
                    src={depto.image}
                    // className="color-icon-menu"
                    width="25px"
                    pointer
                    alt={depto.descricao}
                  />
                  <Label
                    value={depto.label}
                    margin="2"
                    family="Light"
                    style={{
                      textTransform: "uppercase",
                      color: "#878787",
                    }}
                    pointer
                  />
                </Div>
              );
            })}
          </Div>
        </Div>
      </Div>
    );
  }
}

export default DepartamentosLojaInstantaneaMobile;
