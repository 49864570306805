import React from "react";
import { Component } from "react";
import { Div, I, Image, Label } from "../../components";
import { Color, Type, Utils } from "../../utilities";

class TemplateFormasPagamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formasDePagamento: this.getFormasOrdenadas(props.formasDePagamento),
    };
  }

  getFormasOrdenadas = (formasDePagamento) => {
    let formas = formasDePagamento || [];
    if (Utils.isValueValid(formas)) {
      Array.from(formas).forEach((forma) => {
        forma.quantidadeModalidades = String(forma.modalidades).split(
          ";"
        ).length;
      });
    }
    return Utils.sort(formas, "quantidadeModalidades");
  };

  componentWillReceiveProps(props) {
    this.setState({
      formasDePagamento: this.getFormasOrdenadas(props.formasDePagamento),
    });
  }

  render() {
    console.log("PASODJFPOASJDOFKPA");
    return (
      <Div>
        <Div inline="between">
          <Label value="Descrição" family="SemiBold" style={{ width: 200 }} />
          <Label
            value="Pagamento online"
            family="SemiBold"
            style={{ width: 150 }}
          />
          <Label
            value="Pagamento offline"
            family="SemiBold"
            style={{ width: 150 }}
          />
          <Label value="Entrega" family="SemiBold" style={{ width: 60 }} />
          <Label value="Retirada" family="SemiBold" style={{ width: 60 }} />
          <Div style={{ width: 150 }} inline="start">
            <Label value="Modalidades" family="SemiBold" />
          </Div>
        </Div>

        {Array.from(this.state.formasDePagamento).map((forma, index) => {
          return (
            <Div inline="between" key={index} className="border-bottom">
              <Div inline="start" style={{ width: 200 }}>
                <Image src={forma.urlFoto} width="40px" margin="1" />
                <Label value={forma.descricao} family="Light" paddingTop="2" />
              </Div>
              <Div inline="center" style={{ width: 150 }}>
                <I
                  icon={forma.onLine ? Type.ICON.SUCCESS : Type.ICON.CLOSEX}
                  colorText={
                    forma.onLine ? Color.NODE.SUCCESS : Color.NODE.DANGER
                  }
                  style={{ fontSize: 20 }}
                />
              </Div>
              <Div inline="center" style={{ width: 150 }}>
                <I
                  icon={forma.offLine ? Type.ICON.SUCCESS : Type.ICON.CLOSEX}
                  colorText={
                    forma.offLine ? Color.NODE.SUCCESS : Color.NODE.DANGER
                  }
                  style={{ fontSize: 20 }}
                />
              </Div>
              <Div inline="center" style={{ width: 60 }}>
                <I
                  icon={forma.entrega ? Type.ICON.SUCCESS : Type.ICON.CLOSEX}
                  colorText={
                    forma.entrega ? Color.NODE.SUCCESS : Color.NODE.DANGER
                  }
                  style={{ fontSize: 20 }}
                />
              </Div>
              <Div inline="center" style={{ width: 60 }}>
                <I
                  icon={forma.retirada ? Type.ICON.SUCCESS : Type.ICON.CLOSEX}
                  colorText={
                    forma.retirada ? Color.NODE.SUCCESS : Color.NODE.DANGER
                  }
                  style={{ fontSize: 20 }}
                />
              </Div>
              <Div
                style={{ width: 150, paddingTop: 10 }}
                className="d-flex flex-column justify-content-start align-items-start align-self-start"
              >
                {String(forma.modalidades)
                  .split(";")
                  .map((modalidade, index) => {
                    return (
                      <Label
                        key={index}
                        value={modalidade}
                        family="Light"
                        style={{ lineHeight: "14px" }}
                      />
                    );
                  })}
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  }
}

export default TemplateFormasPagamento;
