import React from 'react'
import { Component } from 'react';
import { Div, Label } from '../../components';

class ErrorApplication extends Component {


    render() {
        return (
            <Div>
                <Label value="TESTE TESTE"/>
            </Div>
        )
    }

}

export default ErrorApplication;