import React, { Component } from "react";
import moment from "moment";
import {
  Button,
  Checkbox,
  Checkbutton,
  Div,
  I,
  If,
  InputDefault,
  Label,
  ModalNotification,
  RadioCustom,
  Row,
  Select,
  SelectValue,
  TextArea,
  Title
} from "../../../components";
import CheckbuttonLight from "../../../components/checkbutton/CheckbuttonLight";
import AssinaturaService from "../../../service/AssinaturaService";
import { Color, DateUtils, Mask, Type, Utils } from "../../../utilities";
import UsuarioUtils from "../../utils/UsuarioUtils";

const styles = {
  enderecoSelecionado: {
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "white",
  },
  filialSelecionada: {
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
  },
  filialNaoSelecionada: {
    backgroundColor: "#FFF",
    color: "#878787",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
  },
  labelInfo: {
    color: "#575757",
    fontSize: 18,
  },
  box: {
    padding: "15px 10px",
  },
  boxInfo: {
    marginBottom: "50px",
    padding: "10px 0 20px 0",
    backgroundColor: "#FFF",
    alignItems: "end"
  },
  boxButton: {
    marginBottom: "50px",
    padding: "10px 10px 20px",
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "center"
  },
  boxButtonMobile: {
    marginBottom: "50px",
    padding: "10px 10px 20px",
    backgroundColor: "#FFF",
    justifyContent: "center",
    flexDirection: "column"
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
  },
  labelValidacao: {
    fontSize: "12px",
    margin: "-14px 0px 5px 5px"
  }
}

class ContainerAssinatura extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectPagamento: [
        new SelectValue(Type.PAGAMENTO.DINHEIRO, "Dinheiro", true),
        new SelectValue(Type.PAGAMENTO.CREDITO, "Crédito", false),
        new SelectValue(Type.PAGAMENTO.DEBITO, "Débito", false),
      ],
      selectFormaPagamento: [
        new SelectValue(Type.PAGAMENTO.ONLINE, "Pagamento Online", false),
        new SelectValue(Type.PAGAMENTO.OFFLINE, "Pagamento na Entrega/Retirada", true),
      ],
      quantidadeParcelas: 1,
      pagamento: Type.PAGAMENTO.DINHEIRO,
      horarioSelecionado: "",
      tipoSelecionado: Type.ASSINATURA.MENSAL,
      dataPadrao: this.addDiasDataHojeBr(this.props.paramentrosAssinatura.diasPreparacaoPedido),
      obs: "",
      tipoEntrega: true,
      tipoPagamento: "OFF_LINE",
      tipoFormaPagamento: Type.PAGAMENTO.OFFLINE,
      tipoBandeira: "",
      idClienteEndereco: "",
      idFilial: "",
      idListaCompraAssinatura: "",
      motivoCancelamento: "",
      motivoPausa: "",
      ativo: true,
      idClienteCartao: null,
      paramentros: {},
      boolOnline: false,
      horarioValido: false,
      dataValido: "",
      formValido: "",
      cartaoValido: "",
      dataProximoPedido: "",
      textoCondicoes: "",
      aceitarTermos: true
    }
  }



  componentDidUpdate(prevProps) {
    if (prevProps.lista !== this.props.lista) {
      const assinatura = this.props.lista.assinatura;
      if (Utils.isNotNull(assinatura)) {
        this.setState({
          idListaCompraAssinatura: assinatura.idListaCompraAssinatura,
          tipoSelecionado: assinatura.tipo,
          dataPadrao: assinatura.dataPrimeiroPedido,
          obs: assinatura.obs,
          tipoEntrega: assinatura.tipoEntrega === "ENTREGA" ? true : false,
          tipoFormaPagamento: assinatura.tipoPagamento,
          pagamento: assinatura.tipoFormaPagamento,
          tipoBandeira: assinatura.idEmpresaFormaPagamento,
          idClienteEndereco: assinatura.idClienteEndereco,
          horarioSelecionado: assinatura.idEmpresaHorarioEntrega,
          idFilial: assinatura.idFilial,
          ativo: assinatura.ativo,
          quantidadeParcelas: assinatura.quantidadeParcelas,
          dataProximoPedido: moment(assinatura.dataProximoPedido).format("YYYY-MM-DD")
        })
      } else {
        this.setState({
          obs: "",
          tipoSelecionado: Type.ASSINATURA.MENSAL,
          dataPadrao: this.addDiasDataHojeBr(this.props.paramentrosAssinatura.diasPreparacaoPedido),
          horarioSelecionado: "",
          pagamento: Type.PAGAMENTO.DINHEIRO,
          tipoFormaPagamento: Type.PAGAMENTO.OFFLINE,
          tipoBandeira: "",
          quantidadeParcelas: this.state.quantidadeParcelas,
          dataProximoPedido: ""
        })
      }
    }
  }

  componentDidMount() {
    const assinatura = this.props.lista.assinatura;
    if (Utils.isNotNull(assinatura)) {
      this.setState({
        idListaCompraAssinatura: assinatura.idListaCompraAssinatura,
        tipoSelecionado: assinatura.tipo,
        dataPadrao: assinatura.dataPrimeiroPedido,
        pagamento: assinatura.tipoFormaPagamento,
        horarioSelecionado: assinatura.idEmpresaHorarioEntrega,
        tipoBandeira: assinatura.idEmpresaFormaPagamento,
        obs: assinatura.obs,
        tipoEntrega: assinatura.tipoEntrega === "ENTREGA" ? true : false,
        tipoFormaPagamento: assinatura.tipoPagamento,
        idClienteEndereco: assinatura.idClienteEndereco,
        idFilial: assinatura.idFilial,
        ativo: assinatura.ativo,
        quantidadeParcelas: assinatura.quantidadeParcelas,
        dataProximoPedido: moment(assinatura.dataProximoPedido).format("YYYY-MM-DD")
      })
    } else {
      this.setState({
        obs: "",
        tipoSelecionado: Type.ASSINATURA.MENSAL,
        dataPadrao: this.addDiasDataHojeBr(this.props.paramentrosAssinatura.diasPreparacaoPedido),
        horarioSelecionado: "",
        pagamento: Type.PAGAMENTO.DINHEIRO,
        tipoFormaPagamento: Type.PAGAMENTO.OFFLINE,
        tipoBandeira: "",
        quantidadeParcelas: this.state.quantidadeParcelas,
        dataProximoPedido: ""
      })
    }
  }

  getNumberTipoAssinatura(tipo) {
    switch (tipo) {
      case Type.ASSINATURA.MENSAL:
        return 30
      case Type.ASSINATURA.QUINZENAL:
        return 15
      case Type.ASSINATURA.SEMANAL:
        return 7
      default:
        return 30
    }
  }

  getHorarios() {
    let horarios = this.props.paramentrosAssinatura.horarios
    let newHorarios = [new SelectValue("", "Selecione", true)]
    let tipoEntrega = this.state.tipoEntrega ? Type.OPCOES_ENTREGA.ENTREGA : Type.OPCOES_ENTREGA.RETIRADA

    horarios.forEach(horario => {
      if (tipoEntrega === horario.tipoEntrega) {
        newHorarios.push(new SelectValue(horario.idEmpresaHorarioEntrega, horario.descricao, false))
      }
    })

    if (newHorarios.length === 1) {
      return [new SelectValue(0, "Horarios não disponivel", true)]
    }

    return newHorarios
  }

  getPagamentos() {
    let formasPagamentos = this.props.paramentrosAssinatura.formasPagamentos
    let newPagamentos = [new SelectValue("", "Selecione a Bandeira do Cartão", true)]
    let boolDebito = Type.PAGAMENTO.DEBITO === this.state.pagamento
    let boolCredito = Type.PAGAMENTO.CREDITO === this.state.pagamento

    if (boolDebito) {
      formasPagamentos.forEach(pagamento => {
        if (pagamento.debito) {
          newPagamentos.push(new SelectValue(pagamento.idEmpresaFormaPagamento, pagamento.descricao, false))
        }
      })
    } else if (boolCredito) {
      formasPagamentos.forEach(pagamento => {
        if (pagamento.credito) {
          newPagamentos.push(new SelectValue(pagamento.idEmpresaFormaPagamento, pagamento.descricao, false))
        }
      })
    }

    return newPagamentos
  }

  handleChanceInput = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({ horario: { ...this.state.horario, [name]: value } });
  };

  addDiasDataHojeBr(days) {

    // Date.prototype.addDays = function (days) {
    //   const date = new Date(this.valueOf());
    //   date.setDate(date.getDate() + days);
    //   return date;
    // };

    let date = new Date();
    let newDate = DateUtils.addDays(date, days)
    return moment(newDate).format("YYYY-MM-DD");
  }

  validation() {
    let valido = true;
    let {
      dataPadrao,
      tipoSelecionado,
      horarioSelecionado,
      tipoFormaPagamento,
      pagamento,
      idClienteCartao,
      tipoBandeira,
      tipoEntrega,
    } = this.state;

    let textoData = ""
    let assinaturaAtiva = Utils.isNotNull(this.props.lista.assinatura) ? this.props.lista.assinatura.ativo : false

    let boolDebito = Type.PAGAMENTO.DEBITO === pagamento
    let boolCredito = Type.PAGAMENTO.CREDITO === pagamento
    let boolValor = this.props.valorTotal < this.props.paramentrosAssinatura.valorMinimoPedidoEntrega

    let diasPreparacao = tipoEntrega ? this.props.paramentrosAssinatura.diasPreparacaoPedido : this.props.paramentrosAssinatura.diasPreparacaoPedidoRetirada
    let dataHoje = this.addDiasDataHojeBr(diasPreparacao)

    if (boolValor) {
      this.setState({
        formValido: `O valor da lista é menor ${Mask.formatValueBr(this.props.valorTotal)}`
      })

      valido = false
    }

    if (!Utils.isNotNull(tipoSelecionado)) {
      this.setState({
        formValido: "Selecione o tipo de assinatura!"
      })

      valido = false
    }

    if (!Utils.isNotNull(dataPadrao)) {
      textoData = "Escolha uma data para o primeiro pedido!"
      this.setState({
        dataValido: textoData,
        formValido: "Erros no formulario*"
      })
      valido = false
    }

    if (dataPadrao < dataHoje && Utils.isNotNull(dataPadrao) && !assinaturaAtiva) {
      textoData = "Intervalo permitido entre " + DateUtils.addDiasDataHojeBr(diasPreparacao) + " até " + DateUtils.addDiasDataHojeBr(30)

      this.setState({
        dataValido: textoData,
        formValido: "Erros no formulario*"
      })

      valido = false
    }

    if (dataPadrao > this.addDiasDataHojeBr(30) && Utils.isNotNull(dataPadrao)) {
      textoData = "Intervalo permitido entre " + DateUtils.addDiasDataHojeBr(diasPreparacao) + " até " + DateUtils.addDiasDataHojeBr(30)

      this.setState({
        dataValido: textoData,
        formValido: "Erros no formulario*"
      })

      valido = false
    }

    if (!Utils.isNotNull(horarioSelecionado)) {
      this.setState({
        horarioValido: true,
        formValido: "Erros no formulario*"
      })

      valido = false
    }

    if (Type.PAGAMENTO.ONLINE === tipoFormaPagamento) {
      if (!Utils.isNotNull(idClienteCartao)) {

        this.setState({
          formValido: "Selecione um Cartão Cadastrado"
        })

        valido = false
      }
    }

    if (boolCredito || boolDebito) {
      if (!Utils.isNotNull(tipoBandeira)) {

        this.setState({
          cartaoValido: "Selecione a bandeira do Cartão",
          formValido: "Erros no formulario*"
        })

        valido = false
      }
    }

    if (!this.state.aceitarTermos) {
      this.setState({
        formValido: "Erros no formulario*"
      })

      valido = false
    }

    return valido
  }


  getObjectAssinatura() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();

    let assinatura = {
      idListaCompraAssinatura: this.state.idListaCompraAssinatura,
      idListaCompra: this.props.lista.idListaCompra,
      idClienteCartao: null,
      idEmpresaFormaPagamento: this.state.tipoBandeira,
      idCliente: cliente.idCliente,
      idClienteEndereco: this.state.idClienteEndereco,
      idFilial: this.state.idFilial,
      idEmpresaHorarioEntrega: this.state.horarioSelecionado,
      tipo: this.state.tipoSelecionado,
      tipoEntrega: this.state.tipoEntrega ? "ENTREGA" : "RETIRADA",
      tipoPagamento: this.state.tipoFormaPagamento,
      tipoFormaPagamento: this.state.pagamento,
      quantidadeParcelas: this.state.quantidadeParcelas,
      frequencia: this.getNumberTipoAssinatura(this.state.tipoSelecionado),
      pausado: false,
      descricao: this.state.tipoSelecionado,
      dataPrimeiroPedido: this.state.dataPadrao,
      ativo: true,
      obs: this.state.obs,
      dataProximoPedido: this.state.dataProximoPedido
    }
    return assinatura
  }

  confirmarAssinatura() {
    let novaAssintatura = this.getObjectAssinatura();

    novaAssintatura.idListaCompraAssinatura = Utils.uuidv4()

    console.log("confirmando", novaAssintatura)

    if (this.validation()) {
      AssinaturaService.createAssinatura(novaAssintatura).then(({ data, status }) => {
        if (status) {
          ModalNotification.renderMessage({
            title: "Mensagem",
            body: (
              <Label value={data.mensagem} />
            ),
            handlerCancel: () => { Utils.refreshPage() },
            labelButtonCancel: "Fechar",
          });
        }
      })
    }
  }

  salvarAssinatura() {
    let assinatura = this.getObjectAssinatura();
    assinatura.dataPrimeiroPedido = this.props.lista.assinatura.dataPrimeiroPedido
    assinatura.dataProximoPedido = null

    if (this.validation()) {
      AssinaturaService.putAssinatura(assinatura).then(({ data, status }) => {
        if (status) {
          ModalNotification.renderMessage({
            title: "Mensagem",
            body: (
              <Label value={data.mensagem} />
            ),
            handlerCancel: () => { Utils.refreshPage() },
            labelButtonCancel: "Fechar",
          });
        }
      })
    }
  }

  clickTermosCondicoes() {

    ModalNotification.renderMessage({
      title: "Termos e Condições",
      body: (
        <Label style={{ textAlign: "justify" }}>
          1. A assinatura da lista de compras é um serviço gratuito que traz conforto e comodidade, permitindo a possibilidade de fechar automaticamente seus pedidos, a partir de uma lista de compra, nas condições assinadas como: Frequência, Entrega, Endereços, Horários e Opções de pagamentos;<br />
          2. Sem taxa de adesão;<br />
          3. Você pode fazer alterações na sua assinatura sempre que quiser(alterar produtos, forma de pagamento, frequência de entrega, etc.);<br />
          4. Você pode 'Pausar' sua assinatura por tempo indeterminado, reative quando quiser;<br />
          5. Você pode cancelar a qualquer momento sem taxa de cancelamento;<br />
          6. Preços, promoções e estoques de produtos poderão sofrer alterações, visto que será aplicado a tabela de preço do dia do fechamento de pedido.<br />
          7. Sugerimos nossa própria lista de compra com a possibilidade de assinatura;
        </Label> 
      ),
      handlerCancel: () => { },
      labelButtonCancel: "Fechar",
    });
  }

  pausarAssintaura() {
    let assinatura = this.getObjectAssinatura();

    let pausaAssinatura = {
      idListaCompraAssinatura: assinatura.idListaCompraAssinatura,
      pausado: true,
      motivoPausa: this.state.motivoPausa
    }

    if (this.validation()) {
      AssinaturaService.pausarAssinatura(pausaAssinatura).then(({ data, status }) => {
        if (status) {
          ModalNotification.renderMessage({
            title: "Mensagem",
            body: (
              <Label value={data.mensagem} />
            ),
            handlerCancel: () => { Utils.refreshPage() },
            labelButtonCancel: "Fechar",
          });
        }
      })
    }
  }

  ativarAssintaura() {
    let assinatura = this.getObjectAssinatura();

    let pausaAssinatura = {
      idListaCompraAssinatura: assinatura.idListaCompraAssinatura,
      pausado: false,
      motivoPausa: ""
    }

    if (this.validation()) {
      AssinaturaService.pausarAssinatura(pausaAssinatura).then(({ data, status }) => {
        if (status) {
          ModalNotification.renderMessage({
            title: "Mensagem",
            body: (
              <Label value={data.mensagem} />
            ),
            handlerCancel: () => { Utils.refreshPage() },
            labelButtonCancel: "Fechar",
          });
        }
      })
    }
  }

  cancelarAssintaura() {
    let assinatura = this.getObjectAssinatura();

    let cancelarAssinatura = {
      idListaCompraAssinatura: assinatura.idListaCompraAssinatura,
      motivoCancelamento: this.state.motivoCancelamento
    }

    if (this.validation()) {
      AssinaturaService.cancelarAssinatura(cancelarAssinatura).then(({ data, status }) => {
        if (status) {
          ModalNotification.renderMessage({
            title: "Mensagem",
            body: (
              <Label value={data.mensagem} />
            ),
            handlerCancel: () => { Utils.refreshPage() },
            labelButtonCancel: "Fechar",
          });
        }
      })
    }
  }

  modalCancelamento() {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Div col="12">
          <Label style={{ ...styles.labelCinza, marginBottom: "17px" }} value="Deseja cancelar a assinatura?" />
          <TextArea
            paddingLeft="0"
            paddingRight="0"
            responsive="12"
            placeHolder="Preencha um motivo para o cancelamento"
            style={{ minHeight: "124px" }}
            handlerChange={(e) => {
              this.setState({
                motivoCancelamento: e.target.value
              })
            }}
          />
        </Div>
      ),
      handlerConfirm: () => this.cancelarAssintaura(),
      handlerCancel: () => { },
      labelButtonCancel: "Fechar",
    });
  }

  modalPausarAssinatura() {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Div col="12">
          <Label style={{ ...styles.labelCinza, marginBottom: "17px" }} value="Deseja pausar a assinatura?" />
          <TextArea
            paddingLeft="0"
            paddingRight="0"
            responsive="12"
            placeHolder="Preencha caso tenha alguma observação do motivo da pausa"
            style={{ minHeight: "124px" }}
            handlerChange={(e) => {
              this.setState({
                motivoPausa: e.target.value
              })
            }}
          />
        </Div>
      ),
      handlerConfirm: () => this.pausarAssintaura(),
      handlerCancel: () => { },
      labelButtonCancel: "Fechar",
    });
  }


  modalAtivarAssinatura() {
    ModalNotification.renderMessage({
      title: "Ativar Assinatura",
      body: (
        <Div col="12">
          <Label style={{ ...styles.labelCinza, marginBottom: "17px" }} value="Deseja ativar a assinatura?" />
        </Div>
      ),
      handlerConfirm: () => this.ativarAssintaura(),
      handlerCancel: () => { },
      labelButtonCancel: "Fechar",
    });
  }

  getQuantidadeParcelamentoCredito = (valorTotal) => {
    let valorTotalPedido = Number(valorTotal);
    let quantidadeMaximaParcelas = this.props.paramentrosAssinatura.quantidadeMaximaParcelas || 0

    let quantidades = [];

    let i = 0;

    for (i = 1; i <= quantidadeMaximaParcelas; i++) {
      quantidades.push(
        new SelectValue(
          i,
          `Em ${i}x de ${Mask.formatValueBr(valorTotalPedido / i)}`
        )
      );
    }
    return quantidades;
  };


  render() {

    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    let enderecos = Array.from(usuario.enderecos);
    let filiais = this.props.empresa.filiais || [];

    let pausaData = this.props.lista.assinatura ? moment(this.props.lista.assinatura.dataPausa).format("YYYY-MM-DD") : ""
    let motivoPausa = this.props.lista.assinatura ? this.props.lista.assinatura.motivoPausa : ""

    let assinaturaAtiva = Utils.isNotNull(this.props.lista.assinatura) ? this.props.lista.assinatura.ativo : false
    let assinaturaPausa = Utils.isNotNull(this.props.lista.assinatura) ? this.props.lista.assinatura.pausado : false

    let boolDebito = Type.PAGAMENTO.DEBITO === this.state.pagamento
    let boolCredito = Type.PAGAMENTO.CREDITO === this.state.pagamento
    let boolCartoes = this.props.paramentrosAssinatura.cartoes.length === 0 && Type.PAGAMENTO.ONLINE === this.state.tipoFormaPagamento

    return (
      <Div
        shadow
        rounded
        className="border border-custom"
        style={styles.box}
      >
        {/* <Label
          style={{
            fontWeight: "bolder",
            marginLeft: "20px",
          }}
          value="Opções de Entrega/Retirada"
        /> */}
        <If and value1={!assinaturaPausa}>
          <Row col="12" padding="0" marginTop="2">
            <Div responsive="6" padding="0">
              <Row col="12">
                <Title
                  value="Frequência"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={{ ...styles.labelCinza, marginBottom: "18px" }}
                  family="Bold"
                />
              </Row>
              <Row col="12" marginTop="2" inline="between"
                className={this.props.modal ? "flex-column" : ""}
              >
                <CheckbuttonLight
                  title="Mensal"
                  col={this.props.modal ? "12" : "3"}
                  style={{ marginBottom: "15px", width: this.props.modal ? "100%" : "" }}
                  Icon={
                    <I
                      pointer
                      icon={Type.ICON.CALENDAR}
                      style={{
                        color: Color.ECOMMERCE.CINZA_ESCURO,
                        fontSize: 20,
                        margin: "2px 17px 0px 0px",
                      }}
                    />
                  }
                  checked={this.state.tipoSelecionado === Type.ASSINATURA.MENSAL ? true : false}
                  onClick={() => {
                    this.handleChanceInput("tipoSelecionado", Type.ASSINATURA.MENSAL)
                  }}
                />

                <CheckbuttonLight
                  title="Quinzenal"
                  col={this.props.modal ? "12" : "3"}
                  style={{ marginBottom: "15px", width: this.props.modal ? "100%" : "" }}
                  Icon={
                    <I
                      pointer
                      icon={Type.ICON.CALENDAR}
                      style={{
                        color: Color.ECOMMERCE.CINZA_ESCURO,
                        fontSize: 20,
                        margin: "2px 17px 0px 0px",
                      }}
                    />
                  }
                  checked={this.state.tipoSelecionado === Type.ASSINATURA.QUINZENAL ? true : false}
                  onClick={() => {
                    this.handleChanceInput("tipoSelecionado", Type.ASSINATURA.QUINZENAL)
                  }}
                />

                <CheckbuttonLight
                  title="Semanal"
                  col={this.props.modal ? "12" : "3"}
                  style={{ marginBottom: "15px" }}
                  Icon={
                    <I
                      pointer
                      icon={Type.ICON.CALENDAR}
                      style={{
                        color: Color.ECOMMERCE.CINZA_ESCURO,
                        fontSize: 20,
                        margin: "2px 17px 0px 0px",
                      }}
                    />
                  }
                  checked={this.state.tipoSelecionado === Type.ASSINATURA.SEMANAL ? true : false}
                  onClick={(e) => {
                    this.handleChanceInput("tipoSelecionado", Type.ASSINATURA.SEMANAL)
                  }}
                />
              </Row>

              <Row responsive="12" padding="0">
                <Div responsive="12">
                  <Row col="12" padding="0">
                    <Title
                      value={!assinaturaAtiva ? "Data do primeiro pedido" : "Assinante desde"}
                      type="h6"
                      className="label-valor-total-carrinho"
                      style={{ ...styles.labelCinza, marginBottom: "0px" }}
                      family="Bold"
                    />
                  </Row>
                  <Row col="12" padding="0" marginTop="2">
                    <InputDefault
                      disabled={assinaturaAtiva}
                      style={{ color: "#878787" }}
                      padd="0"
                      required
                      responsive="12"
                      date="date"
                      datetime="datetime"
                      className="unstyled"
                      max="1979-12-31"
                      value={this.state.dataPadrao}
                      //style={{ width: width }}
                      handlerChange={(e) => {
                        this.setState({ dataPadrao: e.target.value, dataValido: "" })
                      }}
                    />
                  </Row>
                  <If and value1={this.state.dataValido !== ""}>
                    <Row col="12">
                      <Title
                        value={this.state.dataValido}
                        type="h6"
                        className="label-valor-total-carrinho text-danger"
                        style={{ ...styles.labelValidacao, margin: "-16px 0px 10px -10px" }}
                        family="Bold"
                      />
                    </Row>
                  </If>
                </Div>

                <If and value1={assinaturaAtiva} >
                  <Div responsive="12">
                    <Row col="12" padding="0">
                      <Title
                        value={"Data do próximo Pedido"}
                        type="h6"
                        className="label-valor-total-carrinho"
                        style={{ ...styles.labelCinza, marginBottom: "0px" }}
                        family="Bold"
                      />
                    </Row>
                    <Row col="12" padding="0" marginTop="2">
                      <InputDefault
                        disabled={assinaturaAtiva}
                        style={{ color: "#878787" }}
                        padd="0"
                        required
                        responsive="12"
                        date="date"
                        datetime="datetime"
                        className="unstyled"
                        max="1979-12-31"
                        value={this.state.dataProximoPedido}
                        //style={{ width: width }}
                        handlerChange={(e) => {
                          this.setState({ dataProximoPedido: e.target.value, dataValido: "" })
                        }}
                      />
                    </Row>
                  </Div>
                </If>

                <Div responsive="12" padding="0">
                  <Row col="12" padding="0">
                    <Row col="12">
                      <Title
                        value={`Horário de ${this.state.tipoEntrega ? "Entrega" : "Retirada"}`}
                        type="h6"
                        className="label-valor-total-carrinho"
                        style={{ ...styles.labelCinza, marginBottom: "24px" }}
                        family="Bold"
                      />
                    </Row>
                    <Select
                      marginTop="2"
                      col="12"
                      values={this.getHorarios()}
                      valueSelected={this.state.horarioSelecionado}
                      handlerChange={(e) => {
                        this.setState({
                          horarioSelecionado: e.target.value,
                          horarioValido: false
                        })
                      }}
                    />
                    <If and value1={this.state.horarioValido}>
                      <Row col="12">
                        <Title
                          value={`Selecione um Horário para ${this.state.tipoEntrega ? "Entrega" : "Retirada"}`}
                          type="h6"
                          className="label-valor-total-carrinho text-danger"
                          style={{ ...styles.labelValidacao }}
                          family="Bold"
                        />
                      </Row>
                    </If>
                  </Row>
                </Div>
              </Row>

              <Row col="12" marginTop="2" padding="0">
                <Row col="12">
                  <Title
                    value="Opções de Pagamento"
                    type="h6"
                    className="label-valor-total-carrinho"
                    style={{ ...styles.labelCinza, marginBottom: "24px" }}
                    family="Bold"
                  />
                </Row>
                {/* <Select
                col="12"
                values={this.state.selectFormaPagamento}
                valueSelected={this.state.tipoFormaPagamento}
                handlerChange={(e) => {
                  this.setState({
                    tipoFormaPagamento: e.target.value
                  })
                }}
              /> */}

                <Row col="12" style={{ alignItems: "center", justifyContent: "center" }}>
                  <Checkbutton
                    corPrimaria={Color.EMPRESA.PRIMARIA}
                    checked={this.state.boolOnline}
                    title="Pagamento Online"
                    onClick={() =>
                      this.setState({ boolOnline: true, tipoFormaPagamento: Type.PAGAMENTO.ONLINE })
                    }
                    style={{
                      margin: "9px 15px 9px 0",
                      width: "196px",
                      height: "55px",
                      fontSize: "15px",
                      flexDirection: "row",
                      justifyContent: "start",
                      border: "1px solid #dee2e6"
                    }}
                    Icon={
                      <I
                        pointer
                        icon={Type.ICON.SHOPPING_CART}
                        style={{
                          color:
                            this.state.boolOnline
                              ? Color.ECOMMERCE.WHITE
                              : Color.EMPRESA.PRIMARIA,
                          fontSize: 23,
                          marginRight: "10px"
                        }}
                      />
                    }
                  />

                  <Checkbutton
                    corPrimaria={Color.EMPRESA.PRIMARIA}
                    checked={!this.state.boolOnline}
                    title="Pagamento Entrega/Retirada"
                    onClick={() =>
                      this.setState({
                        boolOnline: false, tipoFormaPagamento: Type.PAGAMENTO.OFFLINE
                      })
                    }
                    style={{
                      margin: "10px 15px 10px 0",
                      width: "196px",
                      height: "55px",
                      fontSize: "15px",
                      flexDirection: "row",
                      justifyContent: "start",
                      border: "1px solid #dee2e6"
                    }}
                    Icon={
                      <I
                        pointer
                        icon={Type.ICON.SHOOPPING_BAG}
                        style={{
                          color:
                            !this.state.boolOnline
                              ? Color.ECOMMERCE.WHITE
                              : Color.EMPRESA.PRIMARIA,
                          fontSize: 23,
                          marginRight: "10px"
                        }}
                      />
                    }
                  />

                </Row>
              </Row>
              <If and value1={this.state.tipoFormaPagamento === Type.PAGAMENTO.OFFLINE}>
                <Row col="12" marginTop="0" padding="0">
                  <Row col="12">
                    <Title
                      value="Formas de Pagamento"
                      type="h6"
                      className="label-valor-total-carrinho"
                      style={{ ...styles.labelCinza, marginBottom: "24px" }}
                      family="Bold"
                    />
                  </Row>
                  <Select
                    col="12"
                    values={this.state.selectPagamento}
                    valueSelected={this.state.pagamento}
                    handlerChange={(e) => {
                      this.setState({
                        pagamento: e.target.value
                      })
                    }}
                  />
                </Row>
              </If>

              <If and value1={boolCredito || boolDebito}>
                <Row col="12" marginTop="1">
                  <Row col="12">
                    <Title
                      value="Bandeira do Cartão"
                      type="h6"
                      className="label-valor-total-carrinho"
                      style={{ ...styles.labelCinza, marginBottom: "24px" }}
                      family="Bold"
                    />
                  </Row>
                  <Select
                    col="12"
                    values={this.getPagamentos()}
                    valueSelected={this.state.tipoBandeira}
                    handlerChange={(e) => {
                      this.setState({
                        tipoBandeira: e.target.value,
                        cartaoValido: ""
                      })
                    }}
                  />

                  <If and value1={this.state.cartaoValido !== ""}>
                    <Row col="12">
                      <Title
                        value={this.state.cartaoValido}
                        type="h6"
                        className="label-valor-total-carrinho text-danger"
                        style={{ ...styles.labelValidacao, margin: "-14px 0px 14px 0px" }}
                        family="Bold"
                      />
                    </Row>
                  </If>

                </Row>
              </If>

              <If and value1={this.state.pagamento === Type.PAGAMENTO.CREDITO}>
                <Row col="12" marginTop="1">
                  <Row col="12">
                    <Title
                      value="Quantidade de Parcelas"
                      type="h6"
                      className="label-valor-total-carrinho"
                      style={{ ...styles.labelCinza, marginBottom: "24px" }}
                      family="Bold"
                    />
                  </Row>
                  <Select
                    col="12"
                    values={this.getQuantidadeParcelamentoCredito(this.props.valorTotal)}
                    valueSelected={this.state.quantidadeParcelas}
                    handlerChange={(e) => {
                      this.setState({
                        quantidadeParcelas: e.target.value,
                      });
                    }}
                  />
                </Row>
              </If>

              <If and value1={boolCartoes}>
                <Row col="12">
                  <Title
                    value="Nenhum Cartão Cadastrado!!"
                    type="h6"
                    className={`label-valor-total-carrinho ${Color.TEXT.DANGER}`}
                    style={{ ...styles.labelCinza, marginBottom: "24px", marginLeft: "15px" }}
                    family="Bold"
                  />
                </Row>
              </If>

            </Div>

            <Div responsive="6">
              <Row col="12">
                <Title
                  value="Opções de Entrega/Retirada"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={{ ...styles.labelCinza, marginBottom: "24px" }}
                  family="Bold"
                />
              </Row>
              <Row col="12" style={{ alignItems: "center", justifyContent: "center" }}>
                <Checkbutton
                  corPrimaria={Color.EMPRESA.PRIMARIA}
                  checked={this.state.tipoEntrega}
                  title="Receber o pedido"
                  onClick={() =>
                    this.setState({ tipoEntrega: !this.state.tipoEntrega })
                  }
                  style={{
                    margin: "9px 15px 9px 0",
                    width: "196px",
                    height: "55px",
                    fontSize: "15px",
                    flexDirection: "row",
                    justifyContent: "start",
                    border: "1px solid #dee2e6"
                  }}
                  Icon={
                    <I
                      pointer
                      icon={Type.ICON.HOME}
                      style={{
                        color:
                          this.state.tipoEntrega
                            ? Color.ECOMMERCE.WHITE
                            : Color.EMPRESA.PRIMARIA,
                        fontSize: 23,
                        marginRight: "10px"
                      }}
                    />
                  }
                />


                <Checkbutton
                  corPrimaria={Color.EMPRESA.PRIMARIA}
                  checked={!this.state.tipoEntrega}
                  title="Retirar na loja"
                  onClick={() =>
                    this.setState({
                      tipoEntrega: !this.state.tipoEntrega
                    })
                  }
                  style={{
                    margin: "10px 15px 10px 0",
                    width: "196px",
                    height: "55px",
                    fontSize: "15px",
                    flexDirection: "row",
                    justifyContent: "start",
                    border: "1px solid #dee2e6"
                  }}
                  Icon={
                    <I
                      pointer
                      icon={Type.ICON.MAP_MARKER}
                      style={{
                        color:
                          !this.state.tipoEntrega
                            ? Color.ECOMMERCE.WHITE
                            : Color.EMPRESA.PRIMARIA,
                        fontSize: 23,
                        marginRight: "10px"
                      }}
                    />
                  }
                />

              </Row>

              <If and value1={!this.state.tipoEntrega}>
                <Row col="12">
                  <Row col="12">
                    <Title
                      value="Selecione uma das Lojas"
                      type="h6"
                      className="label-valor-total-carrinho"
                      style={{ ...styles.labelCinza, marginBottom: "24px" }}
                      family="Bold"
                    />
                  </Row>
                  {Array.from(filiais).map((filial, index) => {

                    let idFilial = this.state.idFilial === "" ? filial.idFilial : this.state.idFilial
                  //  this.state.idFilial = idFilial

                    return (
                      <RadioCustom
                        checked={idFilial === filial.idFilial}
                        handlerChange={(e) => {
                          this.setState({ idFilial: filial.idFilial });
                        }}
                        left
                        value={
                          <>
                            <Label
                              value={filial.fantasia}
                              style={{
                                lineHeight: "15px",
                                fontSize: 13,
                                width: "100%",
                                color: Color.ECOMMERCE.CINZA_ESCURO,
                              }}
                              family="Bold"
                              className="text-ellipsis"
                              pointer
                            />
                            <Label
                              value={`${filial.logradouro}, ${filial.bairro}`}
                              style={{
                                lineHeight: "13px",
                                fontSize: 13,
                                width: "100%",
                                color: Color.ECOMMERCE.CINZA,
                              }}
                              pointer
                              family="Light"
                              className="text-ellipsis"
                            />
                          </>
                        }
                      />
                    );
                  })}
                </Row>
              </If>

              <If and value1={this.state.tipoEntrega}>
                <Row col="12">
                  <Row col="12">
                    <Title
                      value="Local de Entrega"
                      type="h6"
                      className="label-valor-total-carrinho"
                      style={{ ...styles.labelCinza, marginBottom: "24px" }}
                      family="Bold"
                    />
                  </Row>

                  {enderecos.map((end, index) => {
                    let idEndereco = this.state.idClienteEndereco === "" ? end.idClienteEndereco : this.state.idClienteEndereco
                 //   this.state.idClienteEndereco = idEndereco

                    return (
                      <RadioCustom
                        checked={idEndereco === end.idClienteEndereco}
                        handlerChange={(e) => {
                          this.setState({ idClienteEndereco: end.idClienteEndereco });
                        }}
                        left
                        value={
                          <>
                            <Label
                              value={end.descricao}
                              style={{
                                lineHeight: "15px",
                                fontSize: 13,
                                width: "100%",
                                color: Color.ECOMMERCE.CINZA_ESCURO,
                              }}
                              family="Bold"
                              className="text-ellipsis"
                              pointer
                            />
                            <Label
                              value={`${end.logradouro}, ${end.bairro}`}
                              style={{
                                lineHeight: "13px",
                                fontSize: 13,
                                width: "100%",
                                color: Color.ECOMMERCE.CINZA,
                              }}
                              pointer
                              family="Light"
                              className="text-ellipsis"
                            />
                          </>
                        }
                      />
                    );
                  })}
                </Row>
              </If>

              <Row col="12" style={styles.boxInfo}>
                <Row col="12">
                  <Title
                    value="Observações"
                    type="h6"
                    className="label-valor-total-carrinho"
                    style={{ ...styles.labelCinza }}
                    family="Bold"
                  />
                </Row>

                <Div col="12">
                  <TextArea
                    paddingLeft="0"
                    paddingRight="0"
                    responsive="12"
                    placeHolder="Preencha caso tenha alguma observação no pedido"
                    style={{ minHeight: "124px" }}
                    value={this.state.obs}
                    handlerChange={(e) => {
                      this.setState({
                        obs: e.target.value
                      })
                    }}
                  />
                </Div>
              </Row>
            </Div>
          </Row>
        </If>

        <If and value1={assinaturaPausa}>
          <Row responsive="6" style={styles.boxInfo}>
            <Row col="12">
              <Title
                value="Assinatura Pausada"
                type="h6"
                className="label-valor-total-carrinho"
                style={{ ...styles.labelCinza }}
                family="Bold"
              />
            </Row>

            <Div responsive="6">
              <Row col="12" padding="0">
                <Title
                  value={"Assinante desde"}
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={{ ...styles.labelCinza, marginBottom: "0px" }}
                  family="Bold"
                />
              </Row>
              <Row col="12" padding="0" marginTop="2">
                <InputDefault
                  disabled={assinaturaAtiva}
                  style={{ color: "#878787" }}
                  padd="0"
                  required
                  responsive="12"
                  date="date"
                  datetime="datetime"
                  className="unstyled"
                  max="1979-12-31"
                  value={this.state.dataPadrao}
                  //style={{ width: width }}
                  handlerChange={(e) => {
                    this.setState({ dataProximoPedido: e.target.value, dataValido: "" })
                  }}
                />
              </Row>
            </Div>

            <Div responsive="6">
              <Row col="12" padding="0">
                <Title
                  value={"Assinatura Pausada desde"}
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={{ ...styles.labelCinza, marginBottom: "0px" }}
                  family="Bold"
                />
              </Row>
              <Row col="12" padding="0" marginTop="2">
                <InputDefault
                  disabled={assinaturaAtiva}
                  style={{ color: "#878787" }}
                  padd="0"
                  required
                  responsive="12"
                  date="date"
                  datetime="datetime"
                  className="unstyled"
                  max="1979-12-31"
                  value={pausaData}
                  //style={{ width: width }}
                  handlerChange={(e) => {
                    this.setState({ dataProximoPedido: e.target.value, dataValido: "" })
                  }}
                />
              </Row>
            </Div>
            <If and value1={motivoPausa !== ""}>
              <Div col="6" >
                <Row col="12" padding="0">
                  <Title
                    value="Motivo da Pausa:"
                    type="h6"
                    className="label-valor-total-carrinho"
                    style={{ ...styles.labelCinza, marginBottom: "5px" }}
                    family="Bold"
                  />
                </Row>
                <Row col="12" padding="0">
                  <Label style={{ marginTop: "15px", color: Color.ECOMMERCE.CINZA }} value={motivoPausa} />
                </Row>
              </Div>
            </If>
          </Row>
        </If>

        <Row col="12">
          <Checkbox
            label={
              <Label
                handlerClick={() => this.clickTermosCondicoes()}
                style={{
                  marginTop: "12px",
                  color: Color.ECOMMERCE.AZUL
                }}
                value="Termos e Condições"
                pointer
              />
            }
            handlerChange={(e) =>
              this.setState({
                aceitarTermos: e.checked
              })
            }
            margin="2"
            checked={this.state.aceitarTermos}
          //danger
          />
        </Row>

        <Row col="12" inline="center " style={this.props.mobile ? styles.boxButtonMobile : styles.boxButton}>
          <If and value1={this.state.formValido !== ""}>
            <Row col="12" inline="center">
              <Title
                value={this.state.formValido}
                type="h6"
                className="label-valor-total-carrinho text-danger"
                style={{ ...styles.labelValidacao, margin: "0px 0px 25px -15px" }}
                family="Bold"
              />
            </Row>
          </If>
          <If and value1={!this.state.aceitarTermos}>
            <Row col="12" inline="center">
              <Title
                value={"precisa aceitar os termos e condições"}
                type="h6"
                className="label-valor-total-carrinho text-danger"
                style={{ ...styles.labelValidacao, margin: "0px 0px 25px -15px" }}
                family="Bold"
              />
            </Row>
          </If>
          <If and value1={!assinaturaAtiva}>
            <Button
              type="button"
              responsive="4"
              value="Confirmar Assinatura"
              btn="primary"
              float="right"
              marginRight="3"
              marginBottom="3"
              style={{ backgroundColor: Color.EMPRESA.PRIMARIA, borderColor: Color.EMPRESA.PRIMARIA }}
              handlerClick={() => this.confirmarAssinatura()}
            ></Button>
          </If>

          <If and value1={assinaturaAtiva && !assinaturaPausa}>
            <Button
              type="button"
              responsive="4"
              value="Salvar"
              btn="primary"
              float="right"
              marginRight="3"
              marginBottom="3"
              style={{ backgroundColor: Color.EMPRESA.PRIMARIA, borderColor: Color.EMPRESA.PRIMARIA }}
              handlerClick={() => this.salvarAssinatura()}
            ></Button>
          </If>

          <If and value1={assinaturaAtiva && !assinaturaPausa}>
            <Button
              type="button"
              responsive="4"
              value="Pausar Assinatura"
              btn="light"
              float="right"
              marginRight="3"
              marginBottom="3"
              className="border"
              handlerClick={() => this.modalPausarAssinatura()}
            ></Button>
          </If>

          <If and value1={assinaturaPausa}>
            <Button
              type="button"
              responsive="4"
              value="Ativar Assinatura"
              btn="success"
              float="right"
              marginRight="3"
              marginBottom="3"
              className="border"
              handlerClick={() => this.modalAtivarAssinatura()}
            ></Button>
          </If>

          <If and value1={assinaturaAtiva}>
            <Button
              type="button"
              responsive="4"
              value="Cancelar"
              btn="danger"
              float="right"
              marginRight="3"
              marginBottom="3"
              handlerClick={() => this.modalCancelamento()}
            ></Button>
          </If>
        </Row>
      </Div>
    )
  }
}

export default ContainerAssinatura;