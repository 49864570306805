import React, { Component } from "react";
import { Div, ModalNotification } from "../../components";
import TextAreaWithState from "../../components/forms/textarea/TextAreaWithState";
import { Mask, Utils, Color } from "../../utilities";
import EmpresaUtils from "../utils/EmpresaUtils";
import "./contatoWhatsappGenerico.css";

const initContatoWhatsapp = {
  telefone: "",
  mensagem: "",
};

export default class ContatoWhatsappGenerico extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contatoWhatsapp: initContatoWhatsapp,
    };
  }

  enviarMensagemWhatsapp() {
    const { contatoWhatsapp } = this.state;
    let options = `top=${window.innerHeight / 4},left=${
      window.innerWidth / 4
    },location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=0`;

    let empresa = this.props.empresa || {};
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    window.open(
      `https://api.whatsapp.com/send?phone=55${Mask.clearMask(
        parametros.telefoneWhatsapp
      )}&text=${contatoWhatsapp.mensagem}`,
      "self",
      options
    );

    //  Utils.refreshPage();
  }

  renderConversarWhatsapp() {
    const { contatoWhatsapp } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    ModalNotification.renderMessage({
      title: this.props.title || "Entre em contato conosco",
      styleTitle: { fontSize: 15 },
      styleButtonCancel: { fontSize: 15 },
      styleButtonConfirm: { 
        fontSize: 15, 
        backgroundColor: corPrimaria ? corPrimaria : Color.ECOMMERCE.LARANJA 
      },
      body: (
        <Div col="12">
          <TextAreaWithState
            label="Mensagem"
            rows="5"
            value={contatoWhatsapp.mensagem}
            handlerChange={(mensagem) => {
              this.setState({
                contatoWhatsapp: {
                  ...contatoWhatsapp,
                  mensagem,
                },
              });
            }}
          />
        </Div>
      ),
      responsive: "6",
      handlerConfirm: () => {
        this.setState({ contatoWhatsapp: initContatoWhatsapp });
        this.enviarMensagemWhatsapp();
        this.forceUpdate();
      },
      handlerCancel: () => {
        this.setState({ contatoWhatsapp: initContatoWhatsapp });
      },
      labelButtonConfirm: "Enviar",
    });
  }

  render() {
    return (
      <Div
        className="contato-whatsapp-generico"
        style={{
          backgroundColor: this.props.corPrimaria
            ? this.props.corPrimaria
            : Color.ECOMMERCE.MARRON_LARANJA,
          color: "#FFF",
          width: this.props.width ? this.props.width : "95px",
          height: this.props.height ? this.props.height : "100px",
        }}
        handlerClick={() => this.renderConversarWhatsapp()} 
      >
        <span>CONSULTE ENTREGA EXPRESS!</span>
      </Div>
    );
  }
}
