import React from "react";
import Alert from "./Alert";
import ReactDOM from "react-dom";

export default class Notification {
  static ID = "header-mobioh";

  static info(message) {
    ReactDOM.render(<Alert timer="4" message={message} info/>, document.getElementById(Notification.ID))
  }

  static success(message) {
    ReactDOM.render(<Alert timer="4" message={message} success/>, document.getElementById(Notification.ID))
  }

  static danger(message) {
    ReactDOM.render(<Alert timer="4" message={message} danger/>, document.getElementById(Notification.ID))
  }

  static warning(message) {
    ReactDOM.render(<Alert timer="4" message={message} warning/>, document.getElementById(Notification.ID))
  }

}

