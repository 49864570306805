import React, { Component } from "react";
import { Div, InputInteger, ModalCustom, Notification, Row, Select, SelectValue } from "../../components";
import HorariosService from "../../service/HorariosService";

class HorarioEditar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEmpresa: "",
      horario: {
        tipo: "ENTREGA",
        descricao: "",
        inicio: "",
        fim: "",
      },
      listaHoras: [],
      quantidadeMaxima: 0,
      status: [
        new SelectValue(0, "SELECIONE", true), 
        new SelectValue(true, "ATIVO", true), 
        new SelectValue(false, "DESATIVADO", true)
      ],
      ativo: true,
      idEmpresaHorarioEntrega: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      listaHoras: this.props.listaHoras ? this.props.listaHoras : [],
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.horario !== this.props.horario) {
      const { horario } = this.props;
      this.setState({
        idEmpresa: horario.idEmpresa,
        quantidadeMaxima: this.props.quantidadeMaxima ? this.props.quantidadeMaxima : 0,
        idEmpresaHorarioEntrega: this.props.idEmpresaHorarioEntrega ? this.props.idEmpresaHorarioEntrega : "",
        listaHoras: this.props.listaHoras ? this.props.listaHoras : [],
        horario: {
          tipo: this.props.tipo,
          descricao: "",
          inicio: horario.inicio,
          fim: horario.fim,
        }
      })
    }
  }


  atualizarHorario() {
    const { horario, ativo, quantidadeMaxima, idEmpresaHorarioEntrega } = this.state;
    let editHorario = {
      idEmpresaHorarioEntrega: idEmpresaHorarioEntrega,
      tipo: this.props.tipo,
      horarioInicial: horario.inicio,
      horarioFinal: horario.fim,
      ativo: ativo,
      quantidadeMaxima: quantidadeMaxima,
    }
    
    HorariosService.createHorarios([editHorario]).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.props.setLoadingHorarios(true);
      } else {
        Notification.danger(data.mensagem);
      }
    })
  }

  handlerSelectStatus = (e) => {
    this.setState({
      ativo: e.target.value
    })
  }

  handleChangeInicio = e => {
    let { name, value } = e.target;
    const { horario } = this.state;

    let horas = Array.from(this.state.listaHoras);
    let indexHoraInicio = horas.indexOf(horas.filter(hora => hora.id === value)[0])
    let indexHoraFim = horas.indexOf(horas.filter(hora => hora.id === horario.fim)[0])

    if (indexHoraInicio < indexHoraFim) {
      this.setState({ horario: { ...this.state.horario, [name]: value } });
    } else {
      this.setState({ horario: { ...this.state.horario, [name]: value, fim: horas[indexHoraInicio + 1].id } });
    }

  };

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({ horario: { ...this.state.horario, [name]: value } });
  };

  render() {
    const { horario } = this.state;
    let listaHoras = Array.from(this.state.listaHoras);
    let listaHorasInicio = listaHoras.filter((hora, index) => index < listaHoras.length - 1);
    let horaInicio = listaHoras.indexOf(Array.from(listaHoras).filter(hora => horario.inicio === hora.id)[0])
    let listaHorasFim = listaHoras.filter((hora, index) => index > horaInicio);

    return (
      <ModalCustom
        title="Editar Horário"
        showModal={this.props.closeModal}
        setShowModal={this.props.setCloseModal}
        confirm={() => this.atualizarHorario()}
      >
        <Div col="12">
          <Row col="12">
            <Select
              responsive="3"
              label="Das"
              values={listaHorasInicio}
              name="inicio"
              handlerChange={this.handleChangeInicio}
              required
              valueSelected={horario.inicio}
            ></Select>
            <Select
              responsive="3"
              label="Até"
              values={listaHorasFim}
              name="fim"
              handlerChange={this.handleChange}
              required
              valueSelected={horario.fim}
            ></Select>
            <Select
              required
              responsive="3"
              label="Status"
              handlerChange={(e) => {
                this.handlerSelectStatus(e);
              }}
              values={this.state.status}
            />
            <InputInteger
              //placeholder="número max"
              responsive="3"
              isInputGroup={false}
              label="Quantidade Máxima"
              name="quantidadeMaxima"
              value={this.state.quantidadeMaxima}
              handlerChange={
                e => this.setState({
                  quantidadeMaxima: e.target.value,
                })}
            />
            <Div
              col="3"
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "17px"
              }}
            >
            </Div>

          </Row>
        </Div>
      </ModalCustom>
    )
  }
}
export default HorarioEditar;