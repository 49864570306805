import React from "react";

import {
  colorText,
  pointer,
  margin,
  padding,
  display,
  offset,
  backGround,
  className,
  float,
  inline,
  col
} from "../../css";

const getClassSpan = props => {
  let classe = "";
  classe = classe.concat(colorText(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(display(props));
  classe = classe.concat(offset(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(float(props));
  classe = classe.concat(col(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(className(props));
  return classe;
};

const Span = props => {
  return (
    <span
      onFocus={props.handlerFocus}
      onClick={props.handlerClick}
      className={getClassSpan(props)}
      style={props.style}
    >
      {props.value || props.children}
    </span>
  );
};

export default Span;
