import React from "react";
import { TableColumn, Select } from "../..";

const TableColumnSelectedBox = props => {

  return (
    <TableColumn>
      <Select
        required
        responsive="12"
        // label={selected.label}
       // valueSelected={props.value}
        handlerChange={props.handlerSelectedBox}
        values={props.getItens()}
      />
    </TableColumn>
  );
};

export default TableColumnSelectedBox;
