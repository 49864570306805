import React, { Component } from "react";
import {
  Checkbox,
  Div,
  I,
  Label,
  ModalNotification,
} from "../../components";
import { Color, EndPoints, Http, Type, Utils } from "../../utilities";

import ItemProdutoAbaixouPreco from "./ItemProdutoAbaixouPreco";

import "./produtos.css";

import ProdutoDetalhe from "./web/ProdutoDetalhe";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { atualizarCarrinho, setProdutos } from "../../redux/actions";

import { EmpresaUtils, ProdutoUtils } from "..";
import BotaoPaginaInicial from "../utils/BotaoPaginaInicial";
import ContatoWhatsapp from "../ContatoWhatsapp";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";
import { toast } from "react-toastify";

const styles = {
  title: {
    color: "#878787",
  },
  labelFiltros: {
    color: Color.EMPRESA.TERCIARIA,
    paddingTop: 12,
    paddingLeft: 10,
  },
  labelResultado: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 25,
  },
  labelPrecoActive: {
    border: "2px solid #E94E25",
    width: "100%",
    margin: 3,
    color: "#878787",
  },
  labelPreco: {
    width: "100%",
    margin: 3,
    color: "#878787",
  },
};

class ProdutosPesquisa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosFiltrados: [],
      produtosSimilares: [],
      classificacoes: [],
      textoPesquisa: "",

      resultadoPesquisa: {
        termoPesquisado: "",
        totalEncontrado: 1,
        sucessoPesquisa: true,
        listaProdutos: [],
        listaProdutosSugeridos: null,
        filtros: []
      },
      ...props.location.state,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
    this.listarProdutosSimilares = this.listarProdutosSimilares.bind(this);
  }

  listarProdutosSimilares(produto) {
    Http.get(EndPoints.PRODUTOS_SIMILARES.replace("%s", produto.idProdutoWeb))
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtosSimilares: data }, () =>
            this.visualizarProduto(produto)
          );
        } else {
          this.setState({ produtosSimilares: [] }, () =>
            this.visualizarProduto(produto)
          );
        }
      })
      .catch((error) => console.log(error));
  }

  sortByTerm = (data, term) => {
    let dataSort = Utils.sort(data, "descricao");
    let termString = String(term).toLowerCase().trim();

    let dataEquals = dataSort.filter((d) => {
      let descricaoFormatada = String(d.descricao)
        .split(" ")[0]
        .toLowerCase()
        .trim();
      return descricaoFormatada === termString && d.disponivel;
    });

    let dataStartsWith = dataSort.filter((d) => {
      let descricaoFormatada = String(d.descricao)
        .split(" ")[0]
        .toLowerCase()
        .trim();
      return descricaoFormatada !== termString && d.disponivel;
    });

    let dataIndisponivel = dataSort.filter((d) => !d.disponivel);

    dataStartsWith.sort(function (a, b) {
      let descricaoFormatada = String(a.descricao)
        .split(" ")[0]
        .toLowerCase()
        .trim();

      return descricaoFormatada.startsWith(termString) && a.disponivel ? -1 : 1;
    });

    return [...dataEquals, ...dataStartsWith, ...dataIndisponivel];
  };

  UNSAFE_componentWillReceiveProps(props) {
    let state = props.location.state;

    if (!state) {
      state = this.state;

      let urlPesquisa = this.props.location.pathname.replace("/produto/ean/", "")

      this.realizarPesquisa(urlPesquisa);

    }

    if (state) {
      let produtos = state.resultadoPesquisa.listaProdutos;
      let produtosFiltrados = this.sortByTerm(produtos, state.textoPesquisa);

      this.setState(
        {
          resultadoPesquisa: state.resultadoPesquisa,
          produtos: produtos,
          empresa: state.empresa,
          produtosFiltrados,
          textoPesquisa: state.textoPesquisa,
          classificacoes: state.classificacoes,
        },
        () => Utils.scrollTop()
      );
    }



  }

  componentDidMount() {
    let produtos = this.state.resultadoPesquisa.listaProdutos;
    let produtosFiltrados = this.sortByTerm(produtos, this.state.textoPesquisa);

    this.setState({
      produtos: produtos,
      produtosFiltrados,
    });

    this.handlerResizeView();
    this.consultarParametrosPedido();
    Utils.scrollTop();
  }

  realizarPesquisa(texto) {
    Http.get(`/busca/${texto}`)
      .then(({ status, data }) => {
        console.log("pesquisa resultado", data)
        if (status) {
          this.setState(
            {
              resultadoPesquisa: data,
              produtos: data.listaProdutos,
              empresa: this.props.empresa,
              produtosFiltrados: this.sortByTerm(data.listaProdutos, texto),
              textoPesquisa: texto,
              classificacoes: this.state.classificacoes,
            },
            () => {
              if (data.listaProdutos.length === 1) {
                this.visualizarProduto(data.listaProdutos[0])
              }
            }
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  handlerResizeView() {
    let abc = this;
    window.onresize = function (event) {
      abc.forceUpdate();
    };
  }

  limparFiltros() {
    let resultadoPesquisa = this.state.resultadoPesquisa;
    Array.from(resultadoPesquisa.filtros).forEach((filtro) => {
      Array.from(filtro.itens).map((item) => (item.active = false));
    });

    this.setState({ resultadoPesquisa }, () => this.aplicarFiltros());
  }

  aplicarFiltros() {
    let produtos = JSON.parse(
      JSON.stringify(this.props.location.state.resultadoPesquisa.listaProdutos)
    );

    let filtroPreco = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];

    let itensPrecoMarcados = Array.from(filtroPreco.itens).filter(
      (item) => item.active
    );

    let filtroDepartamento = Array.from(
      this.state.resultadoPesquisa.filtros
    ).filter((filtro) => filtro.tipo === "DEPARTAMENTO")[0];

    let itensDepartamentoMarcados = Array.from(filtroDepartamento.itens).filter(
      (item) => item.active
    );

    let filtroSecao = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];

    let itensSecaoMarcados = Array.from(filtroSecao.itens).filter(
      (item) => item.active
    );

    let filtroMarca = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    let itensMarcaMarcados = Array.from(filtroMarca.itens).filter(
      (item) => item.active
    );

    let produtosFiltrados = [];

    if (Utils.isValueValid(itensDepartamentoMarcados)) {
      let filtros = itensDepartamentoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.departamento)) {
            produtosFiltrados.push(produto);
          }
        });
      }
    }

    if (Utils.isValueValid(itensSecaoMarcados)) {
      let filtros = itensSecaoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.secao)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (Utils.isValueValid(itensMarcaMarcados)) {
      let filtros = itensMarcaMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.marca)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (!Utils.isValueValid(produtosFiltrados)) {
      produtosFiltrados = produtos;
    }

    if (Utils.isValueValid(itensPrecoMarcados)) {
      let filtros = itensPrecoMarcados.map((item) => item.idFiltro);

      let produtosExcluir = [];

      if (Utils.isValueValid(filtros)) {
        produtosFiltrados.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (!filtros.includes(filtroProduto.preco)) {
            produtosExcluir.push(produto);
          }
        });

        produtosExcluir.forEach((produto) => {
          produtosFiltrados.splice(produtosFiltrados.indexOf(produto), 1);
        });
      }
    }

    produtosFiltrados = this.sortByTerm(
      produtosFiltrados,
      this.state.textoPesquisa
    );

    this.setState({
      produtosFiltrados,
    });
  }

  getFiltrosMapeados() {
    let resultadoPesquisa = this.state.resultadoPesquisa;
    const { filtros } = resultadoPesquisa;

    let templates = [];

    let filtroDepartamento = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "DEPARTAMENTO"
    )[0];
    let filtroSecao = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];
    let filtroPreco = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];
    let filtroMarca = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    if (Utils.isValueValid(filtroPreco)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Preços" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroPreco.itens, "descricao").map((item, index) => {
            return (
              <Label
                key={index}
                value={item.descricao}
                padding="2"
                bg={Color.NODE.LIGHT}
                family="Light"
                pointer
                style={
                  item.active ? styles.labelPrecoActive : styles.labelPreco
                }
                handlerClick={() => {
                  item.active = !item.active;
                  this.setState({ resultadoPesquisa });
                  this.aplicarFiltros();
                }}
              />
            );
          })}
        </Div>
      );
    }

    if (Utils.isValueValid(filtroDepartamento)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Departamentos" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroDepartamento.itens, "descricao").map(
            (item, index) => {
              return (
                <Checkbox
                  value={item.idFiltro}
                  label={`${item.descricao} (${item.quantidadeEncontrada})`}
                  key={index}
                  danger
                  handlerChange={({ checked }) => {
                    item.active = checked;
                    this.setState({ resultadoPesquisa });
                    this.aplicarFiltros();
                  }}
                  checked={item.active}
                />
              );
            }
          )}
        </Div>
      );
    }

    if (Utils.isValueValid(filtroSecao)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Categorias" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroSecao.itens, "descricao").map((item, index) => {
            return (
              <Checkbox
                label={`${item.descricao} (${item.quantidadeEncontrada})`}
                danger
                key={index}
                handlerChange={({ checked }) => {
                  item.active = checked;
                  this.setState({ resultadoPesquisa });
                  this.aplicarFiltros();
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      );
    }

    if (Utils.isValueValid(filtroMarca)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Marca" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroMarca.itens, "descricao").map((item, index) => {
            return (
              <Checkbox
                label={`${item.descricao} (${item.quantidadeEncontrada})`}
                danger
                key={index}
                handlerChange={({ checked }) => {
                  item.active = checked;
                  this.setState({ resultadoPesquisa });
                  this.aplicarFiltros();
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      );
    }

    return templates;
  }

  getProdutosPorDepartamento = () => {
    let itensParaRenderizar = [];

    for (const item of this.state.produtosFiltrados) {
      let departamento = item[0];
      let produtos = Utils.sort(item[1], "descricao");

      let produtosSeparados = produtos;

      itensParaRenderizar.push({ produtos: produtosSeparados, departamento });

      itensParaRenderizar = Utils.sortReverse(itensParaRenderizar, "tamanho");
    }

    return itensParaRenderizar;
  };

  visualizarProduto = (produto) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalhe
        produto={produto}
        produtosSimilares={this.state.produtosSimilares}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          toast.info("Produto adicionado no carrinho!", {
            position: "top-right",
          });
        }}
        history={this.props.history}
        empresa={this.state.empresa}
        listasDeCompraCliente={this.props.listasDeCompra}
      />
    );
  };



  renderProdutos() {
    return (
      <Div inline="start">
        {/* <If
          and
          value1={!Utils.isValueValid(this.state.resultadoPesquisa.listaProdutos.length > 0)}
        >
          <Div inline="center">
            <I
              icon={Type.ICON.INFO}
              sizeIcon="3"
              style={{ color: Color.ECOMMERCE.LARANJA }}
              margin="2"
            />
            <Label
              value={this.getMsgNenhumProdutoEncontrado()}
              family="Light"
              style={{ fontSize: 25 }}
              margin="2"
            />
          </Div>
        </If> */}
        {Array.from(this.state.produtosFiltrados).map((prod, index) => {
          return (
            <Div
              className="d-flex justify-content-start align-items-start align-self-start"
              marginTop="2"
              key={index}
            >
              <ItemProdutoAbaixouPreco
                history={this.props.history}
                image={prod.urlImagemPrincipalThumbnail}
                descricao={prod.descricao}
                precoUnitario={prod.precoUnitario}
                precoVenda={prod.precoVenda}
                precoAtacado={prod.precoAtacado}
                codigoBarras={prod.codigoBarras}
                quantidade={prod.quantidade}
                precosConcorrentes={prod.precosConcorrentes}
                possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
                fatorEmGrama={prod.fatorEmGrama}
                disponivel={prod.disponivel}
                proibidaVenda={prod.proibidaVenda}
                empresa={this.state.empresa}
                quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
                handlerClick={() => this.listarProdutosSimilares(prod)}
                handlerAddItemCarrinho={(quantidade, callback) => {
                  prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
                  this.adicionarItemNoCarrinho(prod, callback);
                }}
              />
            </Div>
          );
        })}
      </Div>
    );
  }

  async adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;

    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.state.parametros
    );
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState({ parametros: data });
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  getMarginTop() {
    let margin = 30;
    if (window.innerWidth < 1000) margin = 100;

    return margin;
  }

  render() {
    const { textoPesquisa } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);
    return (
      <Div col="12" style={{ margin: this.getMarginTop() }}>
        <Div className="d-flex align-self-start justify-content-start">
          <Div marginLeft="3" marginRight="3">
            <Div inline="center">
              <I
                pointer
                icon={Type.ICON.FILTER}
                style={{
                  color: Color.EMPRESA.TERCIARIA,
                  fontSize: 20,
                }}
              />
              <Label
                value="FILTROS"
                style={styles.labelFiltros}
                family="SemiBold"
              />
            </Div>
            <Div
              inline="center"
              pointer
              handlerClick={() => this.limparFiltros()}
              className="bg-light border"
              rounded
              padding="2"
            >
              <I
                icon={Type.ICON.CLOSEX}
                colorText={Color.NODE.DANGER}
                paddingBottom="2"
                pointer
                handlerClick={() => this.limparFiltros()}
              />
              <Label
                value="Limpar filtros selecionados"
                family="Light"
                marginLeft="2"
                pointer
                handlerClick={() => this.limparFiltros()}
              />
            </Div>
            {this.getFiltrosMapeados().map((item, index) => (
              <Div key={index}>{item}</Div>
            ))}
          </Div>
          <Div marginLeft="5" style={{ marginTop: "60px" }} responsive="9">
            <Div inline="between">
              <Label
                value={textoPesquisa !== "" ? `Resultados da busca por: "${textoPesquisa}"` : ""}
                style={styles.labelResultado}
                family="SemiBold"
              />
              <Div>
                <BotaoPaginaInicial
                  history={this.props.history}
                  color={corPrimaria}
                />
              </Div>
            </Div>


            {this.renderProdutos()}
            {/**
               * {Array.from(this.getProdutosPorDepartamento()).map(
              (data, index) => {
                return (
                  <Div className="d-flex flex-column" marginTop="2" key={index}>
                    <Div inline="start">
                      <Image
                        src={IconsDepartamento.getIcon(data.departamento)}
                        style={styles.imageIcon}
                        width="25px"
                      />
                      <Label
                        style={styles.labelDepartamento}
                        value={data.departamento}
                        margin="2"
                      />
                    </Div>
                    <Label className="border-label-departamento" />
                    <Label className="border-bottom border-label-departamento-bottom" />
                    <Div inline="start">
                      {Array.from(data.produtos).map((produtos, subindex) => {
                        let produtosParaRenderizar =
                          Array.from(produtos).length > 1
                            ? produtos
                            : [produtos];

                        return (
                          <Div className="d-flex justify-content-start align-items-start align-self-start">
                            {produtosParaRenderizar.map((prod) => {
                              return (
                                <ItemProdutoAbaixouPreco
                                  key={subindex}
                                  history={this.props.history}
                                  image={prod.urlImagemPrincipalThumbnail}
                                  descricao={prod.descricao}
                                  precoUnitario={prod.precoUnitario}
                                  precoVenda={prod.precoVenda}
                                  precoAtacado={prod.precoAtacado}
                                  codigoBarras={prod.codigoBarras}
                                  quantidade={prod.quantidade}
                                  precosConcorrentes={prod.precosConcorrentes}
                                  possuiQuantidadeFracionada={
                                    prod.possuiQuantidadeFracionada
                                  }
                                  fatorEmGrama={prod.fatorEmGrama}
                                  disponivel={prod.disponivel}
                                  proibidaVenda={prod.proibidaVenda}
                                  empresa={this.state.empresa}
                                  quantidadeMinimaAtacado={
                                    prod.quantidadeMinimaAtacado
                                  }
                                  handlerClick={() =>
                                    this.listarProdutosSimilares(prod)
                                  }
                                  handlerAddItemCarrinho={(
                                    quantidade,
                                    callback
                                  ) => {
                                    prod = ProdutoUtils.calcularValorProduto(
                                      prod,
                                      quantidade
                                    );
                                    this.adicionarItemNoCarrinho(
                                      prod,
                                      callback
                                    );
                                  }}
                                />
                              );
                            })}
                          </Div>
                        );
                      })}
                    </Div>
                  </Div>
                );
              }
            )}
               */}
          </Div>
        </Div>
        <ContatoWhatsapp empresa={this.state.empresa} />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.listasDeCompraState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProdutosPesquisa);
