import React from "react";
import { Div } from "../../components";
import "./anuncio.css";

const WIDTH_IMAGE_OFERTA = 240;


const ItemOfertaPrioridade = ({
  image,
  handlerClick,
  widthImage,
  heightImage,
  labelsBanners,
  pagina
}) => {
  return (
    <Div
      style={{ marginLeft: 5, marginBottom: 5 }}
      handlerClick={() => handlerClick()}
    >
      { labelsBanners && labelsBanners(pagina) }
      <img
        src={image}
        alt="Imagem da oferta"
        style={{
          width: widthImage || WIDTH_IMAGE_OFERTA,
          height: heightImage,
          borderRadius: 10,
        }}
      />
    </Div>
  );
};

export default ItemOfertaPrioridade;
