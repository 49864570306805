export default class NavTabParam {

    target
    value

    constructor(target, value) {
        this.target = target
        this.value = value
    }

}