import React from "react";
import PropTypes from "prop-types";
import Div from "../layout/div/Div";
import { Utils, Color, Type } from "../../utilities";
import { Label, I } from "..";


const getClasseTitleHeader = props => {
  let classe = "card-header rounded-0  ";
  classe = classe.concat(props.danger ? "border-left-danger " : "");
  classe = classe.concat(props.bege ? "border-left-bege " : "");
  classe = classe.concat(props.primary ? "border-left-primary " : "");
  classe = classe.concat(props.success ? "border-left-success " : "");
  classe = classe.concat(props.default ? "border-left-default " : "");
  classe = classe.concat(props.bgwhite ? "bg-white " : "");
  classe = classe.concat(props.className ? `${props.className} ` : "");
  return classe;
};

const Collapse = props => {
  let idHeader = Utils.uuidString();
  return (
    <Div className={`col-` + props.col}>
      <Div>
        <Div className="card">
          <Div
            inline="between"
            className={getClasseTitleHeader(props)}
            id={idHeader}
            pointer
            expanded={true}
            controls={props.id}
            toggle="collapse"
            target={`#` + props.id}
          >
            {props.title && <Label value={props.title}></Label>}
            <I
              padding="2"
              colorText={Color.NODE.SECONDARY}
              icon={Type.ICON.ARROW_CHEV_DOWN}
              size={Type.ICON.X}
              handlerClick={e => e.stopPropagation()}
            />
          </Div>

          <Div
            id={props.id}
            collapse
            show={props.show}
            labelledby={idHeader}
            parent={`#${props.idParent}`}
          >
            <Div className="card-body">{props.body || props.children}</Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

Collapse.propTypes = {
  body: PropTypes.node.isRequired,
  title: PropTypes.node,
  id: PropTypes.string.isRequired,
  idParent: PropTypes.string.isRequired,
  col: PropTypes.string,
  color: PropTypes.string
};

export default Collapse;
