export default class StatusPedidoLojaWeb {

    static EM_ABERTO = 'RECEBIDO';
    static EM_SEPARACAO = 'EM SEPARACAO';
    static PAGAMENTO_CONFIRMADO = 'PAGAMENTO CONFIRMADO';
    static AGUARDANDO_RETIRADA = 'AGUARDANDO RETIRADA';
    static AGUARDANDO_ENTREGA = 'AGUARDANDO ENTREGA';
    static SAIU_ENTREGA = 'SAIU PARA ENTREGA';
    static PROBLEMAS_ENTREGA = 'PROBLEMAS NA ENTREGA';
    static CONCLUIDO = 'CONCLUIDO';
    static CANCELADO = 'CANCELADO';

  
  }
  