import React, { Component } from "react";
import { Color, Http } from "../../utilities";

import {
  Row,
  InputDefault,
  Form,
  Notification,
  SectionForm, SectionButtonForm,
  Button,
  Div,
  Image,
  SelectValue,
  Select,
} from "../../components";
import { v4 as uuidv4 } from 'uuid';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FileUpload from "../../components/forms/input/FileUpload";
import ImagemIndisponivel from "../../assets/images/imgindisponivel_thumb.webp";

const styles = {
  imageIcon: {
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
    maxWidth: "100%",
  },
}

class ConcorrenteCadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      urlFoto: "",
      status: [
        new SelectValue(0, "SELECIONE", true), 
        new SelectValue(true, "ATIVO", true), 
        new SelectValue(false, "DESATIVADO", true)
      ],
      ativo: null,
      ...this.props.location.state
    };

    this.submit = this.submit.bind(this);

  }

  UNSAFE_componentWillMount() {

    let concorrente = this.state.concorrente;

    if (this.state.editar) {
      this.setState({
        nameButton: "Alterar",
        idConcorrente: concorrente.idConcorrente,
        descricao: concorrente.descricao,
        urlFoto: concorrente.urlFoto,
      });
    }
  }

  handleFileUpload = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handlerSelectConcorrente = (e) => {
    this.setState({
      ativo: e.target.value
    })
  }


  listaConcorrente() {
    this.props.history.push({ pathname: `/admin/pesquisas/concorrentes` });
  }


  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idConcorrente === undefined) {
        let obj = {
          idConcorrente: uuidv4(),
          descricao: this.state.descricao,
          urlFoto: this.state.urlFoto,
          ativo: this.state.ativo
        }
        this.cadastrar(obj);
      } else {

        let obj = {
          idConcorrente: this.state.idConcorrente,
          descricao: this.state.descricao,
          urlFoto: this.state.urlFoto,
          ativo: this.state.ativo
        }
        this.update(obj);
      }
    }
  }

  cadastrar(concorrente) {
    Http.post("/concorrente/sincronizar", concorrente).then(({ status, data }) => {
      console.log(data)
      if (status) {
        Notification.success(data.mensagem);
        this.listaConcorrente();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }


  update(concorrente) {
    Http.post("/concorrente/sincronizar", concorrente).then(({ data, status }) => {
      console.log(data)
      if (status) {
        Notification.success(data.mensagem);
        this.listaConcorrente();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }


  render() {
    return (
      <Div>
        <Form id="formCadastroConcorrente" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="8">
              <InputDefault required
                label="Descrição"
                name="descricao"
                responsive="8"
                maxLength="200"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({ descricao: e.target.value });
                }}
              />

              <Select
                required
                responsive="4"
                label="Status"
                handlerChange={(e) => {
                  this.handlerSelectConcorrente(e);
                }}
                values={this.state.status}
              />
            </Row>

            <Row col="12" >
              <Div col="4" style={{ marginTop: "36px" }}>
                <Div col="10" style={{ textAlign: "center" }} >
                  <Image
                    src={this.state.urlFoto ? this.state.urlFoto : ImagemIndisponivel}
                    style={styles.imageIcon}
                    styleImage={{ maxWidth: "100%" }}
                    //className="color-icon-menu"
                    pointer

                  />
                </Div>
                <FileUpload col="10"
                  isIcon
                  label="Foto Concorrente"
                  handlerChange={e => this.handleFileUpload({ name: "urlFoto", value: e.fileUpload })}
                  classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
                  name="urlFoto"
                  tipos={["png"]}>

                </FileUpload>
              </Div>
            </Row>


            <SectionButtonForm style={{
              display: "flex",
              justifyContent: "end"
            }}>

              <Button
                btn={Color.NODE.DANGER}
                value="Cancelar"
                col="1"
                handlerClick={(_e) => this.listaConcorrente()}
                margin="1"
              />

              <Button
                btn={Color.NODE.PRIMARY}
                value={this.state.nameButton}
                col="1"
                margin="1"
              />
            </SectionButtonForm>
          </SectionForm>
        </Form>

      </Div>

    )
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default withRouter(connect(mapStateToProps)(ConcorrenteCadastrar));
