import React from "react";
import { Div, Label } from "..";
import "./messagespinner.css";
import {
  backGround,
  colorText,
  border,
  className,
  margin,
  padding,
  rounded
} from "../css";

const getClassDefault = props => {
  let classe = "d-flex align-items-center justify-content-center ";
  classe = classe.concat(backGround(props));
  classe = classe.concat(colorText(props));
  classe = classe.concat(border(props));
  classe = classe.concat(rounded(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(className(props));
  return classe;
};

const MessageSpinner = props => {
  return (
    <Div className={getClassDefault(props)} style={props.style} inline="center" col="12">
      <Label>{props.value || props.children}</Label>
      <Div className="lds-ellipsis mb-1">
        <Div></Div>
        <Div></Div>
        <Div></Div>
        <Div></Div>
      </Div>
    </Div>
  );
};

export default MessageSpinner;
