import React, { Component } from "react";
import {
  Div,
  I,
  If,
  Image,
  InputDefault,
  Label,
  ModalNotification,
} from "../../components";
import ItemProdutoAbaixouPreco from "../produtos/ItemProdutoAbaixouPreco";

import { Color, Mask, Type, Utils } from "../../utilities";
import ProdutoDetalheMobile from "../produtos/mobile/ProdutoDetalheMobile";
import "./carrinho.css";
import CarrinhoUtils from "../utils/CarrinhoUtils";

import {ReactComponent as IconProduto} from "../../assets/images/icon-produto.svg";
import {ReactComponent as IconCalendario} from "../../assets/images/icon-calendario.svg";

const styles = {
  labelLoja: {
    color: "#878787",
    fontSize: 13,
  },
  containterMostrarTodos: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    padding: 15,
  },
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
    width: 115,
  },
  labelSubTotalItem: {
    fontSize: 13,
    color: Color.ECOMMERCE.LARANJA,
    marginTop: 10,
  },
};

class TemplateItensCarrinhoMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarTodos: props.mostrarTodos || false,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ mostrarTodos, parametros }) {
    this.setState({ mostrarTodos, parametros });
  }

  getItensCarrinho = () => {
    const { itens } = this.props.carrinho;
    let itensFiltrados = [];

    if (Array.from(itens).length > 6 && this.state.mostrarTodos) {
      itensFiltrados = itens;
    } else {
      itensFiltrados = Array.from(itens).slice(0, 6);
    }

    return itensFiltrados;
  };

  visualizarProduto = (produto) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalheMobile
        produto={produto}
        handlerAddItem={this.adicionarItemNoCarrinho}
      />
    );
  };

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;

    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.props.parametros
    );

    if (produto.quantidade === 0) {
      this.forceUpdate();
    }
  }

  renderProdutos = (produtos) => {
    return Array.from(produtos).map((prod, index) => {
      return (
        <ItemProdutoAbaixouPreco
          key={index}
          image={prod.urlImagemPrincipalThumbnail}
          descricao={prod.descricao}
          precoUnitario={prod.precoUnitario}
          precoVenda={prod.precoVenda}
          precoAtacado={prod.precoAtacado}
          codigoBarras={prod.codigoBarras}
          quantidade={prod.quantidade}
          handlerClick={() => this.visualizarProduto(prod)}
          handlerAddItemCarrinho={(quantidade) => {
            prod.quantidade = quantidade;
            prod.valorSubTotalItem = (
              Number(quantidade) * Number(prod.precoVenda)
            ).toFixed(2);
            prod.selecionado = true;
            this.adicionarItemNoCarrinho(prod);
          }}
        />
      );
    });
  };

  renderObservacaoItem = (id, idParent, produto) => {
    return (
      <Div
        id={id}
        className="collapse w-100"
        bg={Color.NODE.LIGHT}
        parent={`#${idParent}`}
      >
        <InputDefault
          label={`Observação do item ${produto.descricao}`}
          fontSizeLabel={10}
          value={produto.obs}
          handlerChange={(e) => {
            produto.obs = e.target.value;
            this.adicionarItemNoCarrinho(produto);
            this.forceUpdate();
          }}
        />
      </Div>
    );
  };

  getQuantidadeLabel(produto) {
    let isAtacado =
      Number(produto.quantidadeMinimaAtacado) > 0 &&
      Number(produto.precoAtacado) > 0 &&
      Number(produto.quantidade) >= Number(produto.quantidadeMinimaAtacado);
    let preco = isAtacado
      ? Number(produto.precoAtacado)
      : Number(produto.precoVenda);

    return produto.possuiQuantidadeFracionada
      ? `${Number(produto.quantidade)}g`
      : `${produto.quantidade}un x ${Number(preco).toLocaleString()}`;
  }

  addQuantidadeItem(item) {
    CarrinhoUtils.addQuantidadeItem(item);
    this.adicionarItemNoCarrinho(item);
    this.props.handlerAddItem();
  }

  removeQuantidadeItem(item) {
    CarrinhoUtils.removeQuantidadeItem(item);
    this.adicionarItemNoCarrinho(item);
    this.props.handlerAddItem();
  }

  renderProdutosLista = (produtos) => {
    let idParent = Utils.uuidString();
    return (
      <Div className="w-100" id={idParent}>
        {Array.from(produtos).map((prod, index) => {
          let idObservacaoItem = Utils.uuidString();
          return (
            <Div
              style={{
                borderBottom: "1px solid #f2f2f2",
                padding: 5,
                width: window.innerWidth - 20,
              }}
            >
              <Div inline="start">
                <Image
                  width="60px"
                  border
                  src={prod.urlImagemPrincipalThumbnail}
                />
                <Div column>
                  <Div style={{ width: 200 }} inline="start">
                    <Label
                      className="text-ellipsis descricao-item"
                      value={prod.descricao}
                      marginLeft="2"
                      family="Light"
                      col="12"
                      style={{ fontSize: 10 }}
                    />
                  </Div>

                  <Div inline="between" col="12" key={index}>
                    <Div inline="center" style={styles.containerAddItem}>
                      <I
                        icon={Type.ICON.MINUS}
                        pointer
                        style={{
                          color: Color.ECOMMERCE.LARANJA,
                          fontSize: 15,
                          padding: 5,
                        }}
                        handlerClick={() => {
                          prod.selecionado = true;
                          this.removeQuantidadeItem(prod);
                        }}
                      />
                      <Label
                        value={this.getQuantidadeLabel(prod)}
                        family="Light"
                        margin="1"
                        className="mx-auto"
                        style={{ fontSize: 10, padding: 2 }}
                      />
                      <I
                        icon={Type.ICON.PLUS}
                        pointer
                        style={{
                          color: Color.ECOMMERCE.LARANJA,
                          fontSize: 15,
                          padding: 5,
                        }}
                        handlerClick={() => {
                          prod.selecionado = true;
                          this.addQuantidadeItem(prod);
                        }}
                      />
                    </Div>

                    <Div inline="start" style={{ marginLeft: 10 }}>
                      <Label
                        value={Mask.formatValueBr(prod.valorSubTotalItem)}
                        family="SemiBold"
                        style={styles.labelSubTotalItem}
                      />
                    </Div>
                  </Div>
                </Div>
                <Div
                  style={{
                    position: "absolute",
                    right: 15,
                    transform: `translateY(-15px)`,
                    zIndex: 1001,
                  }}
                >
                  <I
                    icon={Type.ICON.INFO}
                    style={{
                      color: Color.EMPRESA.PRIMARIA,
                      fontSize: 25,
                    }}
                    pointer
                    toggle="collapse"
                    target={`#${idObservacaoItem}`}
                  />
                </Div>
              </Div>

              {this.renderObservacaoItem(idObservacaoItem, idParent, prod)}
            </Div>
          );
        })}
      </Div>
    );
  };

  getEntrega() {
    let parametros = this.props.parametros || {};
    let entrega = {};
    if (Utils.isValueValid(parametros)) {
      entrega = parametros.entrega;
    }

    return entrega;
  }

  getPrevisaoEntrega() {
    let previsao = "";

    if(Utils.isValueValid(this.props.parametros)) {
      let horarios = Array.from(this.props.parametros.horarios);
      previsao = `${horarios[0].dataEntrega} - ${horarios[horarios.length - 1].dataEntrega}`
    }

    return previsao;
  }

  render() {
    let itens = this.getItensCarrinho();

    if (Array.from(itens).length === 0) this.props.history.replace("/");

    return (
      <>
        <Div
          className="d-flex flex-column align-self-start align-items-start justify-content-between"
          style={{ marginBottom: 150 }}
        >
          <Div inline="start" col="12">
            <Div inline="center">
              <IconCalendario style={{ width:"20px", margin:"6"}} />
              <Label
                value={`Previsão entrega: ${this.getPrevisaoEntrega()}`}
                style={styles.labelLoja}
                family="Light"
                marginTop="3"
              />
            </Div>
            <Div inline="center">
              <IconProduto style={{ width:"15px", margin:"6"}} />
              <Label
                value={`${this.props.carrinho.itens.length} produtos`}
                style={styles.labelLoja}
                family="Light"
                marginTop="3"
              />
            </Div>
            <Label style={styles.labelBorda} />
          </Div>
          {this.renderProdutosLista(itens || [])}
        </Div>
        <If and value1={!this.state.mostrarTodos}></If>
      </>
    );
  }
}

export default TemplateItensCarrinhoMobile;
