import React, { createContext, useEffect } from 'react';
import { bindActionCreators } from "redux";
import { setEmpresa } from '../redux/actions';
import { connect } from 'react-redux';
import { EmpresaUtils } from '../views';
import TagManager from 'react-gtm-module';
import TagManagerAdmin from 'react-gtm-module';
import { Utils } from '../utilities';

export const TagManagerContext = createContext({});

const TagManagerContextProvider = ({ children, empresa }) => {

  const setGoogleTagManager = () => {
    let gtm = EmpresaUtils.getParametro(empresa, "googleTagManager");
    if (Utils.isValueValid(gtm)) {
      const tagManagerArgs = {
        gtmId: gtm
      }
      TagManager.initialize(tagManagerArgs)
    }

    let mobiohGtm = EmpresaUtils.getParametro(empresa, "mobiohGoogleTagManager");
    if (Utils.isValueValid(mobiohGtm)) {
      const tagManagerArgs = {
        gtmId: mobiohGtm
      }
      TagManagerAdmin.initialize(tagManagerArgs)

    }
  };

  function tagManagerAddToCard(produto) {
    console.log("produto", produto)
  } 

  useEffect(() => {
    if (Object.keys(empresa).length !== 0) {
      setGoogleTagManager()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa])

  return (
    <TagManagerContext.Provider value={tagManagerAddToCard}>
      {children}
    </TagManagerContext.Provider>
  );
};

const mapStateToProps = (store) => ({
  ...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setEmpresa },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TagManagerContextProvider);
