import { Keys, Utils } from "../../utilities";
import {
  CARRINHO,
  REMOVER_ITEM,
  SET_ENDERECO_ENTREGA,
  SET_PEDIDO_FINALIZADO,
} from "../actions/ActionTypes";

import CryptoJS from "crypto-js";
import CarrinhoUtils from "../../views/utils/CarrinhoUtils";
import { EntregaRetiradaUtils } from "../../views";

const carrinhoState = () => {
  let carrinho = localStorage.getItem(Keys.KEY_CARRINHO_LOJA_WEB);
  let carrinhoEncrypt = null;

  if (Utils.isValueValid(carrinho)) {
    try {
      carrinhoEncrypt = CryptoJS.AES.decrypt(
        carrinho,
        Keys.KEY_CARRINHO_SECRET_LOJA_WEB
      ).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      if (String(error).includes("Malformed UTF-8 data")) {
        console.log(
          "Erro ao realizar parse do carrinho de compras, provavelmente houve alteração na estrutura do carrinho"
        );
        localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB)
        Utils.refreshPage();
      }
    }
  }

  return JSON.parse(carrinhoEncrypt);
};

const initialState = {
  carrinho: carrinhoState() || {
    cabecalho: {
      valorTotal: 0,
      valorTaxaEntrega: 0,
      valorDescontoCupom: 0,
      valorTaxaServico: 0,
      previsaoEntrega: null,
      quantidadeProdutos: 0,
      idLancamentoPedidoApp: Utils.uuidv4(),
      pedidoFinalizado: false,
    },
    itens: [],
    tipoEntregaRetirada: EntregaRetiradaUtils.TIPO_ENTREGA
  },
};

const calcularValoresCabecalho = (carrinho) => {
  let itens = Array.from(carrinho.itens);
  let valorTotal = 0.0;
  let quantidadeProdutos = 0.0;

  Array.from(itens).forEach((item) => {
    if (item.possuiQuantidadeFracionada) {
      valorTotal = Number(valorTotal) + Number(item.valorSubTotalItem || 0);
      quantidadeProdutos =
        quantidadeProdutos + Number(item.quantidade) / Number(1000);
    } else {
      valorTotal = Number(valorTotal) + Number(item.valorSubTotalItem || 0);
      quantidadeProdutos = quantidadeProdutos + Number(item.quantidade);
    }
  });

  carrinho.cabecalho = {
    ...carrinho.cabecalho,
    valorTotal,
    quantidadeProdutos,
  };
};

const removerItem = (state, action) => {
  let carrinho = state.carrinho;
  let itens = Array.from(carrinho.itens);
  itens.splice(itens.indexOf(action.item), 1);

  if (Utils.isValueValid(itens)) {
    localStorage.setItem(
      Keys.KEY_CARRINHO_LOJA_WEB,
      JSON.stringify({
        ...action.carrinho,
      })
    );
  } else {
    localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB);
  }
};

const setCarrinho = (action) => {
  if (Utils.isValueValid(action.carrinho.itens)) {
    let itens = Array.from(action.carrinho.itens).filter(i => i.quantidade > 0);
    action.carrinho.itens = itens;

    CarrinhoUtils.setCarrinhoNavegador(action.carrinho);
  } else {
    CarrinhoUtils.removeCarrinhoNavegador();
  }
};

export const carrinhoReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARRINHO:
      calcularValoresCabecalho(action.carrinho);
      setCarrinho(action);
      return {
        ...state,
        carrinho: action.carrinho,
      };
    case REMOVER_ITEM: {
      removerItem(state, action);
      break;
    }
    case SET_ENDERECO_ENTREGA: {
      let carrinhoEncrypt = CryptoJS.AES.encrypt(
        JSON.stringify({
          ...action.carrinho,
        }),
        Keys.KEY_CARRINHO_SECRET_LOJA_WEB
      );
  
      localStorage.setItem(Keys.KEY_CARRINHO_LOJA_WEB, carrinhoEncrypt);
      return {
        ...state,
        carrinho: action.carrinho,
      };
    }
    case SET_PEDIDO_FINALIZADO: {
      // let carrinho = action.carrinho;
      // carrinho.cabecalho.pedidoFinalizado = true;
      localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB);
      return {
        ...state,
        carrinho: {
          cabecalho: {
            valorTotal: 0,
            valorTaxaEntrega: 0,
            valorDescontoCupom: 0,
            valorTaxaServico: 0,
            previsaoEntrega: null,
            quantidadeProdutos: 0,
            idLancamentoPedidoApp: Utils.uuidv4(),
            pedidoFinalizado: false,
          },
          itens: [],
          tipoEntregaRetirada: EntregaRetiradaUtils.TIPO_ENTREGA
        },
      };
    }
    default:
      return state;
  }
};
