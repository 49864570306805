import React from "react";
import "./pagination.css";

const getClassPaginationItem = props => {
  let classe = "btn pagelink ";
  classe = classe.concat(props.pageIconSm ? "btn-sm " : "");
  classe = classe.concat(props.color ? `btn-outline-${props.color} ` : "");
  return classe;
};

const PaginationItem = props => {
  return (
    <li className={getClassTableItem(props)} onClick={props.handlerClick}>
      <button className={getClassPaginationItem(props)}>{props.pagina}</button>
    </li>
  );
};

const getClassTableItem = props => {
  let classe = "page-item ";
  classe = classe.concat(props.active ? "active " : "");
  return classe;
};

export default PaginationItem;
