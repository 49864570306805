import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Div, Header } from "../../components";
import { Http, Utils } from "../../utilities";
import PesquisaProduto from "../pesquisa/PesquisaProduto";

import { atualizarCarrinho, setProdutos } from "../../redux/actions";
import "./header.css";

import HeaderNavegacaoWeb from "./web/HeaderNavegacaoWeb";
import UsuarioUtils from "../utils/UsuarioUtils";
import CarrinhoUtils from "../utils/CarrinhoUtils";

const ID_PESQUISA = Utils.uuidString();

class HeaderLojaWeb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zIndexHeader: 1002,
      menus: props.menus || [],
      resultadoPesquisa: [],
      textoPesquisa: "",
      realizarLogin: false,
      mostrarMenuUsuario: false,
      parametros: {},
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ menus: nextProps.menus });
    }
  }

  UNSAFE_componentWillReceiveProps({ empresa }) {
    this.setState({ empresa });
  }

  componentDidMount() {
    this.consultarParametrosPedido();
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState({ parametros: data }, () =>
              CarrinhoUtils.calcularValorTaxaEntrega(
                this.state.parametros,
                this.props.carrinho,
                this.props.atualizarCarrinho
              )
            );
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  adicionarItemNoCarrinho(produto) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      null,
      this.state.parametros
    );
  }

  removerItemCarrinho(produto) {
    let { carrinho, produtos } = this.props;

    produto.quantidade = 0;

    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      null,
      this.state.parametros
    );
  }

  render() {
    return (
      <Div
        responsive="12"
        style={{
          backgroundColor: "white",
          position: "fixed",
          top: 0,
          zIndex: this.state.zIndexHeader,
        }}
        className="border-bottom"
        inline="center"
      >
        <Header history={this.props.history}>
          <HeaderNavegacaoWeb
            idPesquisa={ID_PESQUISA}
            empresa={this.state.empresa}
            {...this.props}
            handlerRemoveItem={(produto) => {
              this.removerItemCarrinho(produto);
            }}
            handlerAddItem={this.adicionarItemNoCarrinho}
            handlerLogin={() => this.setState({ realizarLogin: true })}
            handlerMenuCliente={() =>
              this.setState({ mostrarMenuUsuario: true })
            }
            textoPesquisa={this.state.textoPesquisa}
          />
        </Header>

        <PesquisaProduto
          id={ID_PESQUISA}
          handlerRemoveBuscas={() => this.forceUpdate()}
          resultadoPesquisa={this.state.resultadoPesquisa}
          empresa={this.state.empresa}
          textoPesquisa={this.state.textoPesquisa}
          history={this.props.history}
          handlerFecharMenuBusca={() => this.setState({ textoPesquisa: "" })}
        />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLojaWeb);
