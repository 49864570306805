import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ButtonIconOutLine,
  Div,
  InputDefault,
  Row,
  SelectValue,
  Title,
} from "../../components";
import { Color,  Utils } from "../../utilities";
import { bindActionCreators } from "redux";
import { setEmpresa } from "../../redux/actions";
import { withRouter } from "react-router-dom";

const styles = {
  boxInfo: {
    marginBottom: "50px",
    padding: "10px 0 20px 0",
    backgroundColor: "#FFF",
    alignItems: "end",
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
  },
  imageIcon: {
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
  },
  checkedBox: {
    margin: "8px 5px",
  },
  colorInput: {
    color: "#c5c5c5",
  },
};

class EditarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAtendimento: "",
      horarioFuncionamento: "",
      tituloPagina: "",
      empresaParams: {},
      empresa: {},
      aceitaPagamentoOnline: false,
      pagamentoOnlineTipo: null,
      pagamentoOnlineTipoOptions: [
        new SelectValue(null, "SELECIONE", true),
        new SelectValue("CIELO", "CIELO", true),
      ],
      urlFoto: "",
      urlFavIcon: "",
      urlLogoEmail: "",
      urlBackground: "",
      obsRodape: "",
      sobre: "",
      politica: "",
      telefoneAtendimento: "",
      telefoneWhatsapp: "",
      urlAppStore: "",
      urlFacebook: "",
      urlInstagram: "",
      urlPlayStore: "",

      chavePix: "",
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.empresa).length !== 0) {
      this.setState({
        empresa: { ...this.props.empresa },
      });
    }

    //  this.getParametrosEmpresa();
    //   this.getEmpresaPolitica();
    //   this.getEmpresaSobre();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.empresa !== this.props.empresa) {
      this.setState({
        empresa: { ...this.props.empresa },
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileUpload = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handleChanceTextArea = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChangeCheckedPagamento = (e) => {
    const { name, checked } = e;
    this.setState({ [name]: checked });
  };

  getParametros = (empresa) => {
    let parametros = {};
    if (Utils.isValueValid(empresa)) {
      parametros = { ...empresa.parametros };
    }

    return parametros;
  };

  render() {
    return (
      <Div>
        <Div col="12">
          <Row
            col="12"
            shadow
            className="border-left-primary"
            style={styles.boxInfo}
          >
            <Title
              value="Editar Menu"
              type="h6"
              className="label-valor-total-carrinho"
              style={styles.labelCinza}
              family="Bold"
              col="12"
            />

            <InputDefault
              responsive="6"
              label="Descrição"
              name="emailAtendimento"
              handlerChange={this.handleChange}
              value={this.state.emailAtendimento}
              style={styles.colorInput}
            ></InputDefault>

            <InputDefault
              responsive="6"
              label="Icone"
              name="chavePix"
              handlerChange={this.handleChange}
              value={this.state.chavePix}
              style={styles.colorInput}
            ></InputDefault>
          </Row>

          <Div
            col="12"
            style={{
              textAlign: "right",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end ",
              margin: "-25px 0 35px 0",
              padding: "0",
            }}
          >
            <ButtonIconOutLine
              value="Voltar"
              //icon={Type.ICON.PLUS}
              btn={Color.NODE.LIGHT}
              handlerClick={() => {
								this.props.history.replace("/admin/menus");
							}}
            />
            <ButtonIconOutLine
              value="Salvar"
              //icon={Type.ICON.PLUS}
              btn={Color.NODE.PRIMARY}
              //  handlerClick={() => this.submitParametros()}
            />
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
  ...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEmpresa }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditarMenu));
