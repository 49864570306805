export default class Clients {

  static IP_BACKOFFICE = "127.0.0.1";

  static BACKOFFICE = {
    LOCALHOST: "localhost",
    PORT: "8383",
    CONTEXT: "/erp/v1/"
  };

  static SACCARIA = {
    FILIAL_1: "192.168.1.194",
    FILIAL_2: "192.168.2.250",
    FILIAL_4: "192.168.4.200"
  };

  static PRIME = {
    FILIAL_1: "192.168.7.243"
  }

  static PICO = {
    FILIAL_1: "192.168.5.148"
  }

  static NOVO = {
    FILIAL_1: "192.168.1.241"
  }

  static getUrlClientLocalHost = () => {
    return `http://${Clients.BACKOFFICE.LOCALHOST}:${Clients.BACKOFFICE.PORT}${Clients.BACKOFFICE.CONTEXT}`;
  };

  static getUrlBackoffice = (ip) => {
    return `http://${ip}:${Clients.BACKOFFICE.PORT}${Clients.BACKOFFICE.CONTEXT}`;
  };

}
