import React from "react";
import {
  Div,
  Row,
  NavbarItemMenu,
  Navbar,
  NavbarSubMenu,
  NavbarItem,
  BreadParam,
  NavTabParam,
  NavbarTab,
  Breadcrumb,
  NavbarTop
} from "..";
import { Id } from "../../utilities";

export default class NavbarDefault extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: false
    };

    this.itens = [
      new BreadParam("#", "Home", false),
      new BreadParam("#", "Dashboard", true)
    ];

    this.itensTab = [
      new NavTabParam("#home", "Home"),
      new NavTabParam("#dashboard", "Dashboard"),
      new NavTabParam("#services", "Services")
    ];

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar = () => {
    this.setState({
      activeMenu: !this.state.activeMenu
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Div col="12">
            <NavbarTop
              title="Título da página"
              id={Id.HTML.NAVBARTOP}
              active={this.state.activeMenu}
              stylle="light"
              light
              handlerClick={e => this.toggleNavbar()}
            />
          </Div>
        </Row>

        <div>
          <Navbar title="Mobioh" id="navbar" active={this.state.activeMenu}>
            <NavbarItemMenu iconLeft="dashboard" href="#" value="Dashboard" />
            <NavbarItemMenu
              iconLeft="gift"
              iconRight="arrow"
              href="#"
              value="UI Elements"
              target="#products"
            />
            <NavbarSubMenu id="products">
              <NavbarItem href="#" value="CSS3 Animation" />
              <NavbarItem href="#" value="General" />
              <NavbarItem href="#" value="Buttons" />
              <NavbarItem href="#" value="Tabs  Accordions" />
            </NavbarSubMenu>

            <NavbarItemMenu
              iconLeft="globe"
              href="#"
              iconRight="arrow"
              target="#service"
              value="Services"
            />
            <NavbarSubMenu id="service">
              <NavbarItem href="#" value="New Service 1" />
              <NavbarItem href="#" value="New Service 2" />

              <NavbarItem target="#users" value="New Service 3" />
              <NavbarSubMenu id="users" identation="20">
                <NavbarItem href="#" value="General" />
                <NavbarItem href="#" value="General" />
                <NavbarItem href="#" value="General" />
                <NavbarItem href="#" value="General" />
                <NavbarItem href="#" value="General" target="#abc" />
                <NavbarSubMenu id="abc">
                  <NavbarItem href="#" value="General" />
                  <NavbarItem href="#" value="General" />
                  <NavbarItem href="#" value="General" />
                </NavbarSubMenu>
              </NavbarSubMenu>
            </NavbarSubMenu>
          </Navbar>
        </div>

        <Row>
          <Div col="12">
            <NavbarTab
              itens={this.itensTab}
              active={this.state.activeMenu}
              className={Id.HTML.MAIN}
            ></NavbarTab>
            <Breadcrumb
              itens={this.itens}
              active={this.state.activeMenu}
              className={Id.HTML.MAIN}
            ></Breadcrumb>
          </Div>
        </Row>
      </div>
    );
  }
}
