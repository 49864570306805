import React from "react";
import { Div, InputDefault, Select } from "../../../components";
import { Color } from "../../../utilities";

const TemplateFilterProdutosLista = ({
  handler,
  concorrentes,
  dataInicial,
  dataExpiracao
}) => {
  return (
    <Div rounded col="12" bg={Color.NODE.LIGHT} textAlign="left">
      <Div col="12" padding="2" inline="start">
        <Select
          required
          responsive="3"
          label="Concorrentes"
          multi={true}
          handlerChange={(e) => handler(e.target.value, "concorrentes")}
          values={concorrentes}
        />
        <InputDefault
          required
          label="Data Inicial"
          date="date"
          datetime="datetime"
          className="unstyled"
          max="1979-12-31"
          responsive="3"
          handlerChange={(e) => handler(e.target.value, "dataInicial")}
          value={dataInicial}
        />
        <InputDefault
          required
          label="Data de expiração"
          date="date"
          datetime="datetime"
          className="unstyled"
          max="1979-12-31"
          responsive="3"
          handlerChange={(e) => handler(e.target.value, "dataExpiracao")}
          value={dataExpiracao}
        />
      </Div>
    </Div>
  );
};

export default TemplateFilterProdutosLista;
