export default class SelectValue {
  value;
  id;
  selected;

  constructor(id, value, selected, obj = []) {
    this.id = id;
    this.value = value;
    this.selected = selected;
    this.obj = obj
  }

  getId() {
    return this.id
  }
}
