import React, { Component } from "react";
import { Div, Radio, Label } from "../../../components";
import { Utils } from "../../../utilities";
import { className } from "../../css";

class RadioGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      radios: props.radios
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ radios: props.radios });
  }

  setChecked(key, event) {
    let radios = Array.from(this.state.radios);
    radios.forEach(radio => {
      radio.checked = false;
    });
    radios[key].checked = event.target.checked;

    this.setState({
      radios
    });
  }

  getChecked(radio) {
    return this.state.radioChecked.checked === radio.checked;
  }

  getClassRadioGroup() {
    let classe = "d-flex flex-column ";
    classe = classe.concat(className(this.props));
    return classe;
  }

  render() {
    const { radios } = this.state;
    return (
      <Div col={this.props.col} className={this.getClassRadioGroup()}>
        {this.props.label && <Label value={this.props.label}></Label>}
        <Div display={this.props.display}>
          {Array.from(radios).map((radio, key) => {
            return (
              <Radio
                col={this.props.col}
                key={key}
                id={Utils.uuidString()}
                label={radio.label}
                name={this.props.name}
                checked={radio.checked}
                inline={true}
                handlerChange={e => {
                  this.setChecked(key, e);
                  this.props.handlerChange(radio);
                }}
              ></Radio>
            );
          })}
        </Div>
      </Div>
    );
  }
}

export default RadioGroup;
