import React from "react";
import { className } from "../css";
import { Div } from "..";

const SectionButtonForm = props => {
  return (
    <Div col="12" className={className(props)} style={props.style}>
      {props.children}
    </Div>
  );
};

export default SectionButtonForm;
