import React from "react";
import ReactDOM from "react-dom";
import {
  Div,
  Video,
  Image,
  Notification,
  InputFile,
  Label,
  I
} from "../..";
import { Utils, Type } from "../../../utilities";

const fileReader = (e, props, idDisplay, idInputFile) => {
  let fileUpload = e.target.files[0];

  if (Utils.isValueValidFileUpload(fileUpload)) {
    let isVideo = String(fileUpload.type)
      .trim()
      .includes("video");
    let isImage = String(fileUpload.type)
      .trim()
      .includes("image");

    if (tipoEstaValido(fileUpload, props)) {
      let file = {
        fileUpload,
        video: isVideo,
        image: isImage
      };
      setImageBase64(file, props, idDisplay, idInputFile);
    } else {
      clearInputFile(idInputFile);

      if (Utils.isValueValidFileUpload(props.display)) {
        ReactDOM.render(null, document.getElementById(idDisplay));
      }
    }
  }
};

const setImageBase64 = (file, props, idDisplay, idInputFile) => {
  return toBase64(file.fileUpload).then(imageBase64 => {
    file.fileUpload = imageBase64;

    if (Utils.isValueValidFileUpload(props.display)) {
      displayPreview(file, props, idDisplay);
    }

    if (props.autoremove) {
      clearInputFile(idInputFile);
    }
    props.handlerChange(file);
  });
};

const clearInputFile = idInputFile => {
  document.getElementById(idInputFile).value = null;
};

const displayPreview = (file, props, idDisplay) => {
  let display = document.getElementById(idDisplay);
  if (file.video) {
    ReactDOM.render(<Video src={file.fileUpload} />, display);
  } else {
    ReactDOM.render(<Image border responsive src={file.fileUpload} />, display);
  }
};

const tipoEstaValido = (file, props) => {
  let tipoFile = String(file.type)
    .trim()
    .toLocaleLowerCase()
    .split("/")[1];
  let tiposAceitos = Array.from(props.tipos);
  let tipoValido = Utils.isValueValidFileUpload(
    tiposAceitos.filter(tipo => tipo === tipoFile)
  );
 
  //Se o tipo foi definido e é inválido , senão aceita todos
  if (Utils.isValueValidFileUpload(tiposAceitos) && !tipoValido) {
    Notification.danger(getMensagemArquivoInvalido(props));
  } else {
    return true;
  }

  return tipoValido;
};

const getMensagemArquivoInvalido = props => {
  let tipos = "Formato de arquivo inválido ";
  let tiposAceitos = Array.from(props.tipos);
  if (Utils.isValueValidFileUpload(tiposAceitos)) {
    tipos = tipos.concat("utilize: ");
    tiposAceitos.forEach(tipo => {
      tipos = tipos.concat(tipo).concat(" ");
    });
  }

  return tipos;
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const getClassInputUpload = props => {
  return props.isIcon ? "d-none" : "";
};

const getIconFileUpload = (props, idInputFile) => {
  return (
    <Div className={props.classNameIcon}>
      {props.label && (
        <Label left value={props.label} pointer for={idInputFile}></Label>
      )}
      {props.isIcon && (
        <Label for={idInputFile} pointer>
          <I icon={Type.ICON.UPLOAD} size={Type.ICON.X} className="pl-2"></I>
        </Label>
      )}
    </Div>
  );
};

const FileUpload = props => {
  let idDisplay = Utils.uuidString();
  let idInputFile = Utils.uuidString();
  return (
    <Div
      col={props.col}
      inline={props.inline}
    >
      {getIconFileUpload(props, idInputFile)}
      <InputFile
        id={idInputFile}
        icon
        col="12"
        className={getClassInputUpload(props)}
        handlerChange={e => {
          fileReader(e, props, idDisplay, idInputFile);
        }}
        accept={props.accept}
        name={props.name}
        value={props.value}
        file={props.file}
      ></InputFile>
      {props.display && <Div className="mt-3" col="12" id={idDisplay}></Div>}
    </Div>
  );
};

export default FileUpload;