import React, { Component } from "react";
import { Div, If, Label, NavbarTab, Row } from "../../../components";
import { Color } from "../../../utilities";


class ProdutoTabsDetalhes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemActive: {
        target: "DETALHES",
        value: "DETALHES",
        active: true,
      },
    }
  }

  getItens = () => {
    let item = this.state.itemActive;

    let infoAbas = [
      {
        target: "INFORMACOES",
        value: "INFORMAÇÕES GERAIS",
        active: item.value === "INFORMACOES",
      },
      {
        target: "DETALHES",
        value: "DETALHES DO PRODUTO",
        active: item.value === "DETALHES",
      },
    ]

    let detalhesAbas = [
      {
        target: "DETALHES",
        value: "DETALHES DO PRODUTO",
        active: item.value === "DETALHES",
      },
    ]


    return this.props.info ? infoAbas : detalhesAbas;
  };

  validNavAbas(itemActive) {
    // itemActive.stopPropagation();
    // itemActive.preventDefault();
    let item = this.state.itemActive;
    let formValidar = this.validaFormProd();

    if (formValidar) {
      this.setState({ itemActive });
    }

    if (!formValidar) {
      item.value = "INFORMACOES";
      item.target = "INFORMAÇÕES GERAIS"
    }
  }

  validaFormProd() {
    var valida = true;

    // if (!this.state.tipo) {
    //   Notification.danger("Selecione um tipo de Pesquisa");
    //   return false;
    // }

    // if (this.state.descricao === "") {
    //   Notification.danger("Escreve uma descrição para pesquisa");
    //   return false;
    // }

    // if (!this.state.datavalidade) {
    //   Notification.danger("Selecione uma data de validade");
    //   return false;
    // }

    return valida;
  }

  render() {
    let { target } = this.state.itemActive;

    return (
      <Div
        margin="3"
        padding="3"
        style={{
          width: "100%",
        }}
      >
        <NavbarTab
          styleItem={{
            fontSize: 13,
            color: "#c4c4c4",
            fontWeight: "bold",
          }}
          customStyles={{
            width: "100%"
          }}
          itens={this.getItens()}
          handlerClick={(itemActive) => {
            this.validNavAbas(itemActive);
          }}
        />

        <If and value1={target === "INFORMACOES"}>
          <Div
            margin="3"
            padding="3"
          >
            {this.props.infoText}
          </Div>
        </If>

        <If and value1={target === "DETALHES"}>
          <Div
            padding="3"
          >
            <Row col="12">
              {
                this.props.detalhes.map(item => {
                  return (
                    <Div col="6" responsive="3">
                      <Label value={item.nome} style={{ fontWeight: "bold", fontSize: "1.4rem", color: Color.EMPRESA.PRIMARIA }} />
                      <Div style={{ fontWeight: "bold", marginBottom: "40px", color: Color.ECOMMERCE.CINZA }}>
                        {item.valor}
                      </Div>
                    </Div>
                  )
                })
              }
            </Row>
          </Div>
        </If>
      </Div>
    )
  }
}

export default ProdutoTabsDetalhes;