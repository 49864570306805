import React from "react";
import { TableColumn } from "../..";
import Div from "../../layout/div/Div";
import InputNumberBR from "../../forms/input/InputNumberBR";
import InputInteger from "../../forms/input/InputInteger";
import If from "../../if/If";
import InputDefault from "../../forms/input/InputDefault";

const TableColumnInput = ({
  width,
  type,
  value,
  name,
  data,
  handlerChangeInput,
}) => {
  return (
    <TableColumn width={width}>
      <Div inline="center" marginTop="2" marginLeft="2" marginRight="2">
        <If and value1={type === "number"}>
          <InputNumberBR
            isInputGroup={false}
            value={value}
            handlerChange={(e) => {
              data[name] = Number(e.target.value);
              handlerChangeInput(data);
            }}
          />
        </If>
        <If and value1={type === "integer"}>
          <InputInteger
            style={{ width: width }}
            value={value}
            handlerChange={(e) => {
              data[name] = parseInt(e.target.value);
              handlerChangeInput(data);
            }}
          />
        </If>
        <If and value1={type === "date" || type === "datetime"}>
          <InputDefault
            date={type === "date"}
            datetime={type === "datetime"}
            className="unstyled"
            max="1979-12-31"
            value={value}
            style={{ width: width }}
            handlerChange={(e) => {
              data[name] = e.target.value;
              handlerChangeInput(data);
            }}
          />
        </If>
      </Div>
    </TableColumn>
  );
};

export default TableColumnInput;
