import React, { Component } from "react";
import { Div, I, Image, Label } from "../../components";
import { Color, Mask, Type, Utils } from "../../utilities";

import IconCarrinho from "../../assets/images/icon-menu-carrinho.png";

import ImagemQRCODE from "../../assets/images/bandeiras/icon-qrcode.png";
import ImagemVisa from "../../assets/images/bandeiras/icon-visa.png";
import ImagemMasterCard from "../../assets/images/bandeiras/icon-mastercard.png";
import ImagemAmericanExpress from "../../assets/images/bandeiras/icon-amex.png";
import ImagemHiperCard from "../../assets/images/bandeiras/icon-hipercard.png";
import ImagemElo from "../../assets/images/bandeiras/icon-elo.png";
import ImagemDiners from "../../assets/images/bandeiras/icon-diners.png";
import ImagemDinheiro from "../../assets/images/bandeiras/icon-dinheiro.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setPedidoFinalizado } from "../../redux/actions";

const getImagemPagamento = (pagamento) => {
  switch (pagamento) {
    case "VISA":
      return ImagemVisa;
    case "MASTER CARD":
      return ImagemMasterCard;
    case "HIPERCARD":
      return ImagemHiperCard;
    case "AMERICAN EXPRESS":
      return ImagemAmericanExpress;
    case "QRCODE":
      return ImagemQRCODE;
    case "ELO":
      return ImagemElo;
    case "DINERS CLUB":
      return ImagemDiners;
    default:
      return ImagemDinheiro;
  }
};

class FinalizacaoPedido extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.props.setPedidoFinalizado(this.props.carrinho);
  }

  render() {
    let pedido = this.props.location ? this.props.location.state.pedido : {};
    let cabecalho = pedido.cabecalho || {};

    let itens = [];

    if (Utils.isValueValid(pedido)) {
      itens = Array.from(pedido.itens);
    }

    return (
      <Div>
        <Div inline="center">
          <Image
            src={this.state.logoEmpresa}
            responsive
            style={{ position: "absolute", top: 10, left: 10 }}
            width="200px"
          />
        </Div>
        <Div>
          <Label
            value="Pedido realizado com sucesso!"
            style={{ fontSize: 30, color: "#b7b5b5" }}
            family="SemiBold"
          />
          <Div inline="center">
            <Image
              src={IconCarrinho}
              style={{
                backgroundColor: Color.EMPRESA.PRIMARIA,
                width: 50,
                textAlign: "center",
                padding: 10,
                borderRadius: 5,
              }}
            />
            <Div className="d-flex flex-column justify-content-start align-items-start">
              <Label
                value={`Compra realizada com ${itens.length} itens no carrinho`}
                margin="2"
                style={{ lineHeight: "5px" }}
                family="Light"
              />
              <Label
                value={`Valor total: ${Mask.formatValueBr(
                  Number(cabecalho.valorTotal) +
                    Number(cabecalho.valorTaxaEntrega) +
                    Number(cabecalho.valorTaxaServico) -
                    Number(pedido.valorDescontoCupom) -
                    Number(cabecalho.valorDescontoTaxaEntrega || 0) -
                    Number(cabecalho.valorDescontoTaxaServico || 0)
                )}`}
                style={{ lineHeight: "5px", margin: 2 }}
                margin="2"
                family="Light"
              />
            </Div>
          </Div>
          <Div
            inline="center"
            marginTop="4"
            bg={Color.NODE.LIGHT}
            style={{ color: Color.ECOMMERCE.MARRON }}
            padding="2"
          >
            <Div inline="center" rounded col="12">
              <Label
                value={`PAGAMENTO REALIZADO: ${
                  pedido.recebimento
                    ? pedido.recebimento.bandeiraCartao ||
                      pedido.recebimento.tipo
                    : ""
                }`}
                margin="2"
                style={{ lineHeight: "5px" }}
                family="Light"
              />
              <Image
                src={getImagemPagamento(
                  pedido.recebimento ? pedido.recebimento.bandeiraCartao : ""
                )}
                width="50px"
              />
            </Div>
          </Div>
          <Div
            marginTop="3"
            style={{
              backgroundColor: Color.EMPRESA.PRIMARIA,
              color: "white",
              textAlign: "center",
              padding: 10,
              borderRadius: 5,
            }}
            pointer
            handlerClick={() => {
              Utils.refreshPage();
              this.props.history.replace("/");
            }}
          >
            <Label
              value="Continuar comprando"
              family="Bold"
              style={{ fontSize: 25 }}
              pointer
            />
            <I icon={Type.ICON.SALE_CART} sizeIcon="2" margin="2" pointer />
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setPedidoFinalizado }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinalizacaoPedido);
