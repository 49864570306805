import React from "react";
import { Mask } from "../../../utilities";
import InputDefault from "./InputDefault";

const InputInteger = props => {
  return (
    <InputDefault
      {...props}
      formcontrol
      type="text"
      autoFocus={props.autoFocus}
      handlerChange={e => {
        Mask.maskNumberInteger(e)
        return props.handlerChange(e);
      }}
      handlerBlur={props.handlerBlur}
    />
  );
};

export default InputInteger;
