export default class RecebimentoPedidoLojaWeb {

    idEmpresaFormaPagamento;
    tipo;
    quantidadeParcelas;
    bandeiraCartao;
    numeroCartao;
    titularCartao;
    dataValidadeCartao;
    codigoSegurancaCartao;

}