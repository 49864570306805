import Http from "./Http";
import CryptoJS from "crypto-js";

class Auth {

  static async login(user) {
    let loggedUser = {};
    user.senha = CryptoJS.MD5(user.senha).toString();
    await Http.post("/cliente/login/admin", user).then(user => {
      loggedUser = user;
    });
    return loggedUser;
  }
}

export default Auth;
