import { Modal } from "../../components";
import React from "react";
import ReactDOM from "react-dom";
import ModalLoginLoja from "./ModalLoginLoja";
import ModalMessage from "./ModalMessage";

const DBLOCK = "d-block";

export default class ModalNotification {
  static ID = "modal-root";

  static confirmarComHandlerCancel(
    title,
    body,
    handlerConfirm,
    handlerCancel,
    state
  ) {
    ReactDOM.render(
      ModalNotification.getModal(
        title,
        body,
        handlerConfirm,
        handlerCancel,
        null
      ),
      document.getElementById(ModalNotification.ID)
    );
  }

  static confirm(title, body, handlerConfirm) {
    ReactDOM.render(
      ModalNotification.getModal(title, body, handlerConfirm, null),
      document.getElementById(ModalNotification.ID)
    );
  }

  static render(title, body) {
    ReactDOM.render(
      ModalNotification.getModal(title, body, null, null, true),
      document.getElementById(ModalNotification.ID)
    );
  }

  static renderMessage(params) {
    ReactDOM.render(
      this.getModalMessage(params),
      document.getElementById(ModalNotification.ID)
    );
  }

  static getModalMessage({
    body,
    title,
    background,
    handlerConfirm,
    handlerCancel,
    labelButtonCancel,
    labelButtonConfirm,
    responsive,
    styleTitle,
    styleHeader,
    styleButtonCancel,
    styleButtonConfirm,
    styleContainerModal,
    verticalStart
  }) {
    return (
      <ModalMessage
        body={body}
        title={title}
        show
        bg={background || "white"}
        color={background ? "white" : "black"}
        handlerCancel={handlerCancel}
        handlerConfirm={handlerConfirm}
        labelButtonCancel={labelButtonCancel}
        labelButtonConfirm={labelButtonConfirm}
        responsive={responsive}
        styleTitle={styleTitle}
        styleHeader={styleHeader}
        styleButtonCancel={styleButtonCancel}
        styleButtonConfirm={styleButtonConfirm}
        styleContainerModal={styleContainerModal}
        className="bg-success"
        verticalStart={verticalStart}
      />
    );
  }

  static renderModalLogin(title, body, handlerConfirm, labelButton) {
    ReactDOM.render(
      <ModalLoginLoja
        title={title}
        body={body}
        handlerConfirm={handlerConfirm}
        show
        responsive="5"
        labelButton={labelButton}
        hide
      />,
      document.getElementById(ModalNotification.ID)
    );
  }

  static fecharModal() {
    document.getElementById(ModalNotification.ID).classList.toggle(DBLOCK);
  }

  static removeModal() {
    ReactDOM.render(null, document.getElementById(ModalNotification.ID));
  }

  static getModal(title, body, handlerConfirm, handlerCancel, hide) {
    return (
      <Modal
        title={title}
        body={body}
        show
        handlerConfirm={handlerConfirm}
        handlerCancel={handlerCancel}
        hide={hide}
        responsive="10"
      ></Modal>
    );
  }
}
