import React, { useEffect, useCallback } from "react";
import { Button, Div } from "..";

const ModalCustom = ({
  showModal,
  setShowModal,
  children,
  title,
  body,
  confirm,
  hideButtons,
}) => {
  const closeModal = (e) => {
    setShowModal(false);
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
        console.log("I pressed");
      }
    },
    [setShowModal, showModal]
  );

  const handlerConfirm = () => {
    confirm();
    closeModal();
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal && (
        <Div className="modal d-block" id="modal-mobioh">
          <Div
            inline="center"
            className="vertical-center table-wrapper-scroll-y my-custom-scrollbar mx-auto"
            responsive="10"
            style={{ overflow: "hidden"}}
          >
            <Div className="modal-content">
              <Div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <Button className="close" handlerClick={(e) => closeModal(e)}>
                  <span aria-hidden="true">&times;</span>
                </Button>
              </Div>
              <Div className="modal-body">{body || children}</Div>
              {hideButtons ? null : (
                <Div className="modal-footer ">
                  <Button
                    handlerClick={(e) => closeModal(e)}
                    value="Cancelar"
                    btn="secondary"
                  />
                  <Button
                    handlerClick={(e) => handlerConfirm(e)}
                    value="Confirmar"
                    btn="primary"
                  />
                </Div>
              )}
            </Div>
          </Div>
        </Div>
      )}
    </>
  );
};

export default ModalCustom;
