import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Div,
  Image,
  Label,
  I,
  Checkbox,
  InputGroup,
  If,
  InputDefault,
  ModalNotification,
  Row,
  Input,
  TextArea,
} from "../../../../components";
import { Color, Http, Type, Utils, Mask, Routes } from "../../../../utilities";
import CarrinhoUtils from "../../../utils/CarrinhoUtils";
import EmpresaUtils from "../../../utils/EmpresaUtils";
import UsuarioUtils from "../../../utils/UsuarioUtils";
import ProdutoUtils from "../../ProdutoUtils";

import { setListasDeCompra } from "../../../../redux/actions";
import "./listaCompra.css"
import ContainerAssinaturas from "../ContainerAssinaturas";
import AssinaturaService from "../../../../service/AssinaturaService";
import ListasComprasSugeridas from "./ListasComprasSugeridas";
import moment from "moment";

const styles = {
  lista: {
    height: 50,
    border: `1px solid ${Color.ECOMMERCE.CINZA}`,
    margin: 5,
    color: Color.ECOMMERCE.CINZA,
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
  },
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
  },
  labelSubTotalItem: {
    fontSize: 25,
    color: Color.ECOMMERCE.LARANJA,
    marginTop: 10,
    marginRight: 15,
  },
  textoRiscado: {
    textDecoration: "line-through",
  },
  menuDrop: {
    width: "100%",
  },
  menuDropAtivo: {
    backgroundColor: "var(--cor-primaria)",
    color: "#fff",
  },
  assinaturaAtiva: {
    backgroundColor: Color.EMPRESA.PRIMARIA,
    color: Color.ECOMMERCE.WHITE,
  },
  boxList: {
    padding: "15px 10px",
    borderBottom: "1px solid #dee2e6",

  },
  assinatura: {
    padding: "10px",
    background: Color.ECOMMERCE.CINZA_ESCURO,
    color: "#FFF",
    fontWeight: "bolder",
    textAlign: "center",
  }
};

class ListaCompraCliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.location.state,
      editarDescricao: false,
      listas: [],
      listaSelecionada: {},
      showResultadosPesquisa: false,
      textoPesquisa: null,
      produtosPesquisa: [],
      produtosSelecionadosPesquisa: new Map(),
      sucessoPesquisa: true,
      nomeNovaLista: null,
      listaAlterada: false,
      assinaturaLista: "Assinar lista",
      assinaturaListaModal: "Assinar lista",
      assinaturaAtivaModal: false,
      pesquisa: "",
      listaIndex: 999,
      isAssinatura: true,
      assinaturaAtiva: false,
      listaCompraAssinatura: [],
      paramentrosAssinatura: {},
      listaPesquisa: [],
      motivoCancelamento: "",
      modificado: {},
    };
  }

  componentDidMount() {
    //this.consultarListasDeCompra();
    Utils.ativarScroolTelaPrincipal();

    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    let urlFotoEmpresa = EmpresaUtils.getLogoEmpresa(this.props.empresa);
    this.setState({
      corPrimaria,
      urlFotoEmpresa,
    });
    //this.getListaCompraClienteAssinatura();
    this.consultarListasDeCompra()
    this.getParamentros();
  }

  getParamentros() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    AssinaturaService.getParamentros(cliente.idCliente).then(({ data, status }) => {
      if (status) {
        this.setState({
          paramentrosAssinatura: data
        })
      }
    })
  }

  

  getListaCompraClienteAssinatura() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    AssinaturaService.getListaDeCompraCliente(cliente.idCliente).then(data => {
      if (Utils.isNotNull(data)) {
        this.setState({
          listaCompraAssinatura: data,
          listas: data,
        })
      }
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    let corPrimaria = EmpresaUtils.getCorPrimaria(props.empresa);
    let urlFotoEmpresa = EmpresaUtils.getLogoEmpresa(props.empresa);
    this.setState({
      corPrimaria,
      urlFotoEmpresa,
    });
  }

  consultarListasDeCompra() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    Http.get(`listacompra/cliente/${cliente.idCliente}`)
      .then(({ status, data }) => {
        if (status) {
          Array.from(data).forEach((lista) => {
            Array.from(lista.itens).forEach((p) => {
              p.quantidade = ProdutoUtils.getQuantidadeItemView(p);
              p = ProdutoUtils.calcularValorProduto(p, p.quantidade);
            });
          });

          let listaSelecionada = Array.from(data).filter(
            (lista) =>
              lista.idListaCompra === this.state.listaSelecionada.idListaCompra
          )[0];

          this.setState(
            {
              listas: data,
              listaSelecionada: listaSelecionada || data[0],
              nomeNovaLista: "",
              listaCompraAssinatura: data
            },
            () => this.props.setListasDeCompra(data)
          );
        } else {
          this.setState({
            listas: [],
            listaSelecionada: {},
            listaCompraAssinatura: [],
            nomeNovaLista: "",
          });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getQuantidadeLabel(produto) {
    let preco = produto.precoVenda;
    let quantidade = produto.quantidade || 0;
    return produto.possuiQuantidadeFracionada
      ? `${Number(produto.quantidade)}g`
      : `${quantidade}un x ${Mask.formatValueBr(preco)}`;
  }

  atualizarProdutoLista(produto) {
    Http.put("/listacompra/produto", produto)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.forceUpdate();
          } else {
            ModalNotification.renderMessage({
              title: "Ocorreu um erro ao atualizar a lista",
              body: "Falha ao atualizar produto da lista",
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  deletarProdutoLista(produto) {
    Http.delete("/listacompra/produto", produto)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.consultarListasDeCompra();
          } else {
            ModalNotification.renderMessage({
              title: "Ocorreu um erro ao atualizar a lista",
              body: "Falha ao atualizar produto da lista",
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  deletarListaCompra(lista) {

    let removerLista = {
      idListaCompra: lista.idListaCompra,
      idListaCompraAssinatura: lista.assinatura ? lista.assinatura.idListaCompraAssinatura : null,
      motivoCancelamento: this.state.motivoCancelamento
    }


    Http.delete("/assinatura/listacompra", removerLista)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.consultarListasDeCompra();
          } else {
            ModalNotification.renderMessage({
              title: "Ocorreu um erro ao remover a lista",
              body: "Falha ao remover a lista",
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  colocarProdutosNoCarrinho(lista) {
    //let itens = JSON.parse(Utils.isNotUndefined(lista) ? JSON.stringify(lista.itens) : JSON.stringify(this.state.listaSelecionada.itens));
    //console.log("colocar no carrinho", this.state.listaSelecionada.itens)
    let itens = lista.itens

    if (Utils.isValueValid(itens)) {
      Array.from(itens).forEach((item) => {
        CarrinhoUtils.addQuantidadeItemJaNoCarrinhoLista(this.props.carrinho, item);
        CarrinhoUtils.adicionarItemNoCarrinhoDeCompras(
          item,
          this.props.carrinho,
          this.props.atualizarCarrinho,
          null,
          this.state.parametrosPedido
        );
      });
      this.props.history.push(Routes.CARRINHO, {
        parametros: this.state.parametrosPedido,
        logoEmpresa: this.state.urlFotoEmpresa,
        empresa: this.state.empresa,
      });
    } else {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label value="Nenhum produto na lista para colocar no carrinho!" />
        ),
        handlerCancel: () => { },
        labelButtonCancel: "Fechar",
      });
    }
  }


  solicitarRemocaoProdutoLista(prod) {
    ModalNotification.renderMessage({
      title: "Remover produto da lista",
      body: `Deseja realmente remover o produto ${prod.descricao} da lista`,
      handlerCancel: () => { },
      handlerConfirm: () => this.deletarProdutoLista(prod),
    });
  }

  solicitarRemoverLista(lista) {
    let assinatura = lista.assinatura
    let data = ""
    
    if (assinatura) {
      data = moment(assinatura.dataPrimeiroPedido).format("DD/MM/YYYY");
    }

    let textoModal = lista.itens.length > 0 ?
    assinatura ?  `Essa lista de compra está assinada desde do dia ${data}, a exclusão da lista de compra cancelará sua assinatura. Deseja continuar?` :
      `Essa lista possui ${lista.itens.length} itens que facilita as suas compras futuras. Deseja continuar?` :
    "Essa lista de compras será removida do sistema, deseja remover a lista"

    ModalNotification.renderMessage({
      title: "Remover Lista de Compras",
      body: (
        <Div col="12">
          <Label style={{ ...styles.labelCinza, marginBottom: "17px" }} value={textoModal} />
          <TextArea
            paddingLeft="0"
            paddingRight="0"
            responsive="12"
            placeHolder="Preencha caso tenha alguma observação do motivo do cancelamento"
            style={{ minHeight: "124px" }}
            handlerChange={(e) => {
              this.setState({
                motivoCancelamento: e.target.value
              })
            }}
          />
        </Div>
      ),
      handlerCancel: () => { },
      handlerConfirm: () => this.deletarListaCompra(lista),
    });
  }

  getButtonUserDropDown() {
    const { assinaturaLista, assinaturaAtiva } = this.state;
    return (
      <Div
        inline="center"
        className="border p-1 m-1"
        id="dropdownMenuButton"
        toggle="dropdown"
        rounded
        pointer
        paddingLeft="3"
        paddingRight="3"
        style={assinaturaAtiva ? styles.assinaturaAtiva : {}}
      //handlerClick={() => alert("EM DESENVOLVIMENTO")}
      >
        <I
          icon={Type.ICON.CALENDAR}
          pointer
          style={{
            fontSize: 25,
            color: assinaturaAtiva ? Color.ECOMMERCE.WHITE : Color.EMPRESA.PRIMARIA,
          }}
        />
        <Label
          family="Light"
          value={assinaturaLista}
          pointer
          marginTop="2"
          marginLeft="2"
        />
      </Div>

    );
  }

  checkedMenuActive(assinatura) {
    const { assinaturaAtivaModal, assinaturaListaModal } = this.state;

    return assinatura === assinaturaListaModal && assinaturaAtivaModal ? styles.menuDropAtivo : styles.menuDrop
  }

  iconCheckedMenuActive(assinatura) {
    const { assinaturaAtivaModal, assinaturaListaModal } = this.state;

    return assinatura === assinaturaListaModal && assinaturaAtivaModal ? "#FFF" : "var(--cor-primaria)"
  }


  renderProdutos = (lista) => {
    let { corPrimaria, listaAlterada, modificado } = this.state;
    let produtos = Array.from(lista.itens || []);
    let plural = `${Object.keys(modificado).length < 2 ? "" : "s"}`    
    let textoModificado = `${Utils.isNotObjectEmpty(modificado) ? Object.keys(modificado).length : 0} produto${plural} modificado${plural}`

    let totalItens = produtos
      .map((p) => Number(p.precoVenda * p.quantidade))
      .reduce((a, b) => a + b, 0);

    let idParent = Utils.uuidString();
    return (
      <Div
        col="12"
        id={idParent}
        padding="2"
        shadow
        rounded
        className="border border-custom"
      >
        <Div col="12" inline="between">
          <If and value1={this.state.editarDescricao}>
            <InputDefault
              responsive="8"
              handlerChange={(e) => {
                this.setState({
                  listaSelecionada: {
                    ...lista,
                    descricao: e.target.value,
                  },
                  listaAlterada: true,
                });
              }}
              value={lista.descricao}
            />
          </If>

          <If and value1={!this.state.editarDescricao}>
            <Div col="12" inline="start" >
              <Label
                family="Light"
                value={lista.descricao}
                className="text-ellipsis"
                style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 18, margin: "15px", fontWeight: "bolder" }}
              />

              <I
                icon={Type.ICON.EDIT}
                pointer
                style={{ fontSize: 25, color: Color.ECOMMERCE.CINZA_ESCURO }}
                title="Editar nome da lista"
                handlerClick={() => this.setState({ editarDescricao: !this.state.editarDescricao })}
              />
            </Div>
          </If>

        </Div>
        <Div inline="start" style={{ marginLeft: "25px", marginBottom: "20px" }}>
          <Div
            inline="center"
            className="border m-1"
            style={{ padding: "5px 20px"  }}
            rounded
            pointer
            handlerClick={() => this.renderPesquisarProdutos()}
          >
            <I
              icon={Type.ICON.PLUS_SQUARE}
              pointer
              style={{ fontSize: 25, color: corPrimaria }}
            />
            <Label
              family="Light"
              value="Adicionar produto"
              pointer
              marginTop="2"
              marginLeft="2"
            />
          </Div>

          <Div inline="center">

            <Div
              inline="center"
              className="border m-1"
              rounded
              pointer
              style={{ padding: "5px 20px"  }}
              handlerClick={() => this.colocarProdutosNoCarrinho(this.state.listaSelecionada)}
            >
              <I
                icon={Type.ICON.SALE_CART}
                pointer
                style={{ fontSize: 25, color: corPrimaria }}
              />
              <Label
                family="Light"
                value="Finalizar Pedido"
                pointer
                marginTop="2"
                marginLeft="2"
              />
            </Div>
            <Div
              inline="center"
              className="border "
              rounded
              pointer
              handlerClick={() =>
                Utils.isValueValid(produtos)
                  ? this.atualizarListaCompra()
                  : this.solicitarRemoverLista(lista)
              }
              style={{
                backgroundColor: listaAlterada
                  ? this.state.corPrimaria
                  : "#f2f2f2",
                color: listaAlterada ? "white" : Color.ECOMMERCE.CINZA_ESCURO,
                opacity: 0.9,
                marginLeft: 10,
                padding: "5px 20px"
              }}
            >
              <I
                icon={
                  Utils.isValueValid(produtos)
                    ? Type.ICON.REFRESH
                    : Type.ICON.CLOSEX
                }
                pointer
                style={{ fontSize: 25 }}
              />
              <Label
                family="Light"
                value={
                  Utils.isValueValid(produtos)
                    ? "Confirmar Atualização"
                    : "Remover lista"
                }
                pointer
                marginTop="2"
                marginLeft="2"
              />

            </Div>
            <If and value1={Object.keys(modificado).length >= 1}>
              <Label
                family="Light"
                value={textoModificado}
                pointer
                style={{
                  color: Color.ECOMMERCE.LARANJA,
                  fontWeight: "bolder"
                }}
                marginTop="2"
                marginLeft="3"
              />
            </If>
          </Div>
        </Div>
        {produtos.map((prod, index) => {
          return (
            <Div key={index}>
              <Div
                inline="between"
                padding="2"
                col="12"
                style={{ borderBottom: "1px solid #f2f2f2" }}
              >
                <Div inline="start" col="6">
                  <Image
                    border
                    src={prod.urlImagemPrincipalThumbnail}
                    width="70px"
                  />
                  <Label
                    className="text-ellipsis descricao-item"
                    value={prod.descricao}
                    marginLeft="2"
                    family="Light"
                    style={
                      prod.quantidade > 0
                        ? { width: "70%" }
                        : { width: "70%", ...styles.textoRiscado }
                    }
                  />
                </Div>
                <Div inline="center" col="3" style={styles.containerAddItem}>
                  <I
                    icon={Type.ICON.MINUS}
                    pointer
                    style={{ color: corPrimaria, fontSize: 20 }}
                    handlerClick={() => {
                      if (prod.quantidade > 0) {
                        this.removeQuantidadeItemLista(lista, prod);
                      }

                      if (prod.quantidade === 0) {
                        ModalNotification.renderMessage({
                          title: "Remover Produto da Lista de Compras",
                          body: "Esse Produto será removido da lista de compras. Deseja continuar?",
                          handlerCancel: () => { this.addQuantidadeItemLista(lista, prod) },
                          handlerConfirm: () => this.deletarProdutoLista(prod),
                        });
                      }

                      if(modificado[index]){
                        if(modificado[index].quantidade === prod.quantidade){
                          delete modificado[index]
                        }
                      }
                      
                    }}
                  />
                  <Label
                    value={this.getQuantidadeLabel(prod)}
                    family="Light"
                    margin="3"
                    className="mx-auto"
                  />
                  <I
                    icon={Type.ICON.PLUS}
                    pointer
                    style={{ color: corPrimaria, fontSize: 20 }}
                    handlerClick={() => {
                      this.addQuantidadeItemLista(lista, prod);
                      modificado[index] = prod
                    }}
                  />
                </Div>
                <Div col="3">
                  <Label
                    value={Mask.formatValueBr(Number(prod.valorSubTotalItem))}
                    col="1"
                    family="SemiBold"
                    style={{ ...styles.labelSubTotalItem, color: corPrimaria }}
                  />
                </Div>
                <I
                  icon={Type.ICON.CLOSEX}
                  style={{ position: "absolute", right: 20, color: "red" }}
                  pointer
                  handlerClick={
                    () => this.solicitarRemocaoProdutoLista(prod)
                  }
                />
              </Div>
            </Div>
          );
        })}
        <Div
          inline="between"
          style={{ width: "99%", backgroundColor: "#f2f2f2" }}
          margin="2"
          padding="2"
          shadow
          className="border"
          rounded
        >
          <Label
            value="Total da lista"
            style={{
              color: Color.ECOMMERCE.CINZA_ESCURO,

              fontSize: 20,
            }}
            family="Bold"
          />
          <Label
            value={Mask.formatValueBr(Number(totalItens))}
            style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 20 }}
            family="Bold"
          />
        </Div>
      </Div>
    );
  };

  listarProdutosPesquisa() {
    Http.get(`/busca/${this.state.textoPesquisa}`)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              produtosPesquisa: data.listaProdutos,
              sucessoPesquisa: data.sucessoPesquisa,
            },
            () => this.renderPesquisarProdutos()
          );
        }
      })
      .catch((error) => console.log(error));
  }

  getProdutosSelecionados() {
    let itens = [];
    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );
    produtosSelecionadosPesquisa.forEach((p) => itens.push(p));
    return itens;
  }

  removeQuantidadeItemPesquisa(item) {
    let quantidade = Number(item.quantidade);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );

    if (quantidade > 0) {
      produtosSelecionadosPesquisa.set(item.codigoBarras, item);
    } else {
      produtosSelecionadosPesquisa.delete(item.codigoBarras);
    }

    this.setState({ produtosSelecionadosPesquisa }, () =>
      this.renderPesquisarProdutos()
    );
  }

  removeQuantidadeItemLista(listaSelecionada, item) {
    let quantidade = Number(item.quantidade);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.setState({ listaSelecionada, listaAlterada: true });
  }

  addQuantidadeInicialPesquisa(item) {
    let quantidade = Number(item.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = Number(item.fatorEmGrama);
    } else {
      quantidade = 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );
    produtosSelecionadosPesquisa.set(item.codigoBarras, item);

    this.setState({ produtosSelecionadosPesquisa }, () =>
      this.renderPesquisarProdutos()
    );
  }

  addQuantidadeItemLista(listaSelecionada, item) {
    let quantidade = Number(item.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.setState({ listaSelecionada, listaAlterada: true });
  }

  addQuantidadeItemPesquisa(item) {
    let quantidade = Number(item.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );
    produtosSelecionadosPesquisa.set(item.codigoBarras, item);

    this.setState({ produtosSelecionadosPesquisa }, () =>
      this.renderPesquisarProdutos()
    );
  }

  getListaCompraAtualizacao() {
    let { produtosSelecionadosPesquisa, listaSelecionada } = this.state;
    let itensLista = Array.from(listaSelecionada.itens);

    produtosSelecionadosPesquisa = new Map(produtosSelecionadosPesquisa);

    itensLista.forEach((item) => {
      item.preco = Number(item.precoVenda);
      item.quantidade = ProdutoUtils.getQuantidadeItem(item);
    });

    if (produtosSelecionadosPesquisa.size > 0) {
      produtosSelecionadosPesquisa.forEach((produto) => {
        let itemNaLista = itensLista.filter(
          (i) => i.codigoBarras === produto.codigoBarras
        )[0];
        if (!Utils.isValueValid(itemNaLista)) {
          itensLista.push({
            idListaCompraProduto: Utils.uuidv4(),
            idListaCompra: listaSelecionada.idListaCompra,
            idProdutoWeb: produto.idProdutoWeb,
            preco: Number(produto.valorSubTotalItem),
            quantidade: ProdutoUtils.getQuantidadeItem(produto),
            descricao: produto.descricao,
            ordem: 1,
          });
        }
      });
    }

    listaSelecionada.itens = itensLista;

    return listaSelecionada;
  }

  atualizarListaCompra(pesquisa = false) {
    Http.put("/listacompra", this.getListaCompraAtualizacao())
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.setState(
              {
                produtosSelecionadosPesquisa: new Map(),
                produtosPesquisa: [],
                listaAlterada: false,
              },
            );
            if (!pesquisa) {
              ModalNotification.renderMessage({
                title: "Mensagem",
                body: <Label value="Produtos adicionados com sucesso!" />,
                handlerCancel: () => { Utils.refreshPage() },
                labelButtonCancel: "Fechar",
              });
            }
          } else {
            ModalNotification.renderMessage({
              title: "Falha ao atualizar lista de compras",
              body: <Label value={data.mensagem} />,
              handlerCancel: () => { },
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }

  cadastrarNovaListaCompra() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    let listaParam = {
      idListaCompra: Utils.uuidv4(),
      descricao: this.state.nomeNovaLista,
      tipo: "CLIENTE",
      idCliente: cliente.idCliente,
      inicioVigencia: new Date().getTime(),
      fimVigencia: new Date().getTime(),
      ativo: true,
      ordem: 1,
      preco: 0,
      precoOferta: 0,
    };

    Http.post("/listacompra", listaParam)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.consultarListasDeCompra();
          } else {
            ModalNotification.renderMessage({
              title: "Erro ao cadastrar lista",
              body: data.mensagem,
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          }
        } else {
          console.log("Falha ao cadastrar lista de compra");
        }
      })
      .catch((erro) => console.log(erro));
  }

  renderCadastrarNovaLista() {
    ModalNotification.renderMessage({
      title: "Nova lista de compras",
      responsive: "8",
      body: (
        <Div
          className="d-flex align-items-start justify-content-start align-self-start"
          style={{ width: "100%" }}
          column
        >
          <Div col="12">
            <InputDefault
              handlerChange={(e) => {
                if (Utils.isNotUndefined(this.setState)) {

                  this.setState({ nomeNovaLista: e.target.value }, () =>
                    this.renderCadastrarNovaLista()
                  );
                }
              }}
              label="Informe o nome da lista"
              placeholder="Nome"
              value={this.state.nomeNovaLista}
              maxLength="200"
            />
          </Div>

        </Div>
      ),
      handlerConfirm: () => this.cadastrarNovaListaCompra(),
      handlerCancel: () => this.setState({ nomeNovaLista: "" }),
    });
  }

  renderPesquisarProdutos() {
    let produtos = this.state.produtosPesquisa;
    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );

    ModalNotification.renderMessage({
      title: "Selecione os produtos para adicionar na lista",
      responsive: "10",
      body: (
        <Div
          className="d-flex align-items-start justify-content-start align-self-start"
          style={{ width: "100%" }}
        >

          <Div col="7">
            <InputGroup
              prepend={false}
              append
              placeholder="Nome do produto"
              value={this.state.textoPesquisa}
              icon={Type.ICON.SEARCH}
              label="Pesquise produtos para adicionar na lista"
              handlerChange={(e) => {
                this.setState(
                  { textoPesquisa: e.target.value, sucessoPesquisa: true },
                  () => this.renderPesquisarProdutos()
                );
              }}
              handlerKeyUp={(e) => {
                if (e.keyCode === 13) this.listarProdutosPesquisa();
              }}
              handlerIcon={() => this.listarProdutosPesquisa()}
            />
            <If and value1={!this.state.sucessoPesquisa}>
              <Label
                value={`Produto não encontrado para a pesquisa: '${this.state.textoPesquisa}'`}
                family="Light"
                style={{ color: this.state.corPrimaria }}
              />
            </If>
            <Div style={{ height: window.innerHeight / 2, overflow: "auto" }}>
              {Array.from(produtos).map((produto, index) => {
                return (
                  <Div inline="between" key={index} className="border-bottom">
                    <Div inline="start">
                      <Image
                        styleImage={{ width: 70, height: 70 }}
                        src={produto.urlImagemPrincipalThumbnail}
                      />
                      <Label
                        family="Light"
                        value={produto.descricao}
                        className="text-ellipsis"
                        style={{ width: 200 }}
                      />
                      <Label
                        family="Light"
                        marginLeft="2"
                        style={{ color: this.state.corPrimaria, fontSize: 20 }}
                        value={Mask.formatValueBr(produto.precoVenda)}
                      />
                    </Div>
                    <Checkbox
                      handlerChange={({ checked }) => {
                        if (checked) {
                          produtosSelecionadosPesquisa.set(
                            produto.codigoBarras,
                            produto
                          );
                        } else {
                          produtosSelecionadosPesquisa.delete(
                            produto.codigoBarras
                          );
                        }
                        this.setState({ produtosSelecionadosPesquisa }, () => {
                          if (checked) {
                            this.addQuantidadeInicialPesquisa(produto);
                          } else {
                            this.renderPesquisarProdutos();
                          }
                        });
                      }}
                      checked={Utils.isValueValid(
                        produtosSelecionadosPesquisa.get(produto.codigoBarras)
                      )}
                      marginLeft="3"
                    />
                  </Div>
                );
              })}
            </Div>
          </Div>
          <Div
            col="5"
            className="border-left border-right"
            style={{ height: window.innerHeight / 1.8, overflow: "auto" }}
            column
          >
            <Label
              value="Produtos selecionados"
              family="Light"
              style={{ color: Color.ECOMMERCE.CINZA_ESCURO }}
            />
            <If and value1={produtosSelecionadosPesquisa.size === 0}>
              <Label
                value="Nenhum produto selecionado"
                family="Light"
                style={{ color: this.state.corPrimaria }}
              />
            </If>
            <If and value1={produtosSelecionadosPesquisa.size > 0}>
              {this.getProdutosSelecionados().map((produto, index) => {
                return (
                  <Div inline="between" key={index} className="border-bottom">
                    <Div inline="start" style={{ width: "100%" }}>
                      <Div col="4" column>
                        <Image
                          styleImage={{ width: 50, height: 50 }}
                          src={produto.urlImagemPrincipalThumbnail}
                        />
                        <Label
                          family="Light"
                          value={produto.descricao}
                          className="text-ellipsis"
                          style={{ width: 150 }}
                        />
                      </Div>
                      <Div
                        inline="center"
                        col="6"
                        style={styles.containerAddItem}
                      >
                        <I
                          icon={Type.ICON.MINUS}
                          pointer
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 20,
                          }}
                          handlerClick={() => {
                            if (
                              Utils.isValueValid(produto.quantidade) &&
                              produto.quantidade > 0
                            ) {
                              this.removeQuantidadeItemPesquisa(produto);
                            }
                          }}
                        />
                        <Label
                          value={this.getQuantidadeLabel(produto)}
                          family="Light"
                          margin="3"
                          className="mx-auto"
                        />
                        <I
                          icon={Type.ICON.PLUS}
                          pointer
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 20,
                          }}
                          handlerClick={() => {
                            this.addQuantidadeItemPesquisa(produto);
                          }}
                        />
                      </Div>
                      <Div col="2">
                        <Label
                          family="Light"
                          marginLeft="2"
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 15,
                          }}
                          value={Mask.formatValueBr(produto.valorSubTotalItem)}
                        />
                      </Div>
                    </Div>
                  </Div>
                );
              })}
            </If>
          </Div>
        </Div>
      ),
      handlerConfirm: () => {
        if (produtosSelecionadosPesquisa.size > 0) {
          this.atualizarListaCompra();
        } else {
          this.setState({
            produtosPesquisa: [],
            textoPesquisa: "",
          });
        }
      },
      handlerCancel: () => {
        this.setState({
          produtosSelecionadosPesquisa: new Map(),
          produtosPesquisa: [],
          textoPesquisa: "",
        });
      },
    });
  }

  handlerListaSelecionada(index, lista) {
    let close = index === this.state.listaIndex ? null : index
    this.setState({
      listaIndex: close,
      listaSelecionada: lista
    })
  }

  colorAssinaturas(lista) {
    let assinatura = lista.assinatura

    if (assinatura) {
      if (assinatura.pausado) {
        return Color.ECOMMERCE.LARANJA_CLARO
      } else {
        return Color.RGBA.VERDE
      }
    }

    return Color.ECOMMERCE.CINZA_ESCURO
  }


  rowLista = () => {
    const { isAssinatura } = this.state;
    return (
      this.state.listas.map((lista, index) => {
        let valorTotal = lista.itens.map((p) => Number(p.precoVenda * p.quantidade)).reduce((a, b) => a + b, 0)
        return (
          <>
            <Row
              col="12"
              style={styles.boxList}
              className={""}
            >
              <Label
                style={{
                  width: "40%",
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  fontWeight: "bolder",
                }}
                value={lista.descricao}
                pointer
                handlerClick={
                  () => {
                    this.setState({
                      isAssinatura: false
                    },
                      this.handlerListaSelecionada(index, lista))
                  }
                }
              />
              <Label
                style={{
                  width: "10%",
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  fontWeight: "bolder",
                }}
                value={lista.itens.length ? lista.itens.length : "0"}
                pointer
                handlerClick={
                  () => {
                    this.setState({
                      isAssinatura: false
                    },
                      this.handlerListaSelecionada(index, lista))
                  }
                }
              />

              <Div
                style={{
                  width: "10%",
                  color: Color.EMPRESA.PRIMARIA
                }}
              >
                <Label
                  style={{
                    fontWeight: "bolder",
                    color: Color.EMPRESA.PRIMARIA
                  }}
                  pointer
                  handlerClick={
                    () => {
                      this.setState({
                        isAssinatura: false
                      },
                        this.handlerListaSelecionada(index, lista))
                    }
                  }
                  value={Mask.formatValueBr(valorTotal)}
                />
              </Div>
              <Label style={{ width: "15%", textTransform: "lowercase" }}>
                <Div
                  col="10"
                  className="rounded"
                  pointer
                  style={{
                    ...styles.assinatura,
                    background: this.colorAssinaturas(lista),
                  }}
                  handlerClick={
                    () => {
                      let close = index === this.state.listaIndex && isAssinatura ? null : index
                      this.setState({
                        isAssinatura: true,
                        listaIndex: close,
                        listaSelecionada: lista
                      },
                      )
                    }
                  }
                >
                  {
                    lista.assinatura ?
                      lista.assinatura.pausado ? lista.assinatura.tipo + " - Pausado" :
                        lista.assinatura.tipo : "ASSINAR LISTA"
                  }
                </Div>
              </Label>
              <Div pointer>
                <I
                  icon={Type.ICON.SALE_CART}
                  pointer
                  title="Finalizar Pedido"
                  style={{ margin: "0px 10spx 0 0 ", fontSize: 25, color: Color.EMPRESA.PRIMARIA }}
                  handlerClick={
                    () => {
                      this.setState({
                        listaSelecionada: lista
                      },
                        this.colocarProdutosNoCarrinho(lista)
                      )
                    }
                  }
                />
                <I
                  title="Remover lista"
                  icon={Type.ICON.DELETE}
                  pointer
                  style={{ margin: "0px 15px", fontSize: 25, color: "red" }}
                  handlerClick={
                    () => {
                      this.solicitarRemoverLista(lista)
                    }
                  }
                />
              </Div>

              <I
                icon={index === this.state.listaIndex ? Type.ICON.ARROW_ANGLE_DOWN : Type.ICON.ARROW_ANGLE_RIGHT}
                pointer
                style={{
                  fontSize: 25,
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  width: "10%",
                  textAlign: "center"
                }}
                handlerClick={
                  () => {
                    this.setState({
                      isAssinatura: false
                    },
                      this.handlerListaSelecionada(index, lista))
                  }
                }
              />
            </Row>
            <Div col="12" padding="0">
              <If and value1={Utils.isValueValid(this.state.listaSelecionada) && index === this.state.listaIndex}>
                <ul className="navtab-background nav nav-tabs small" style={{ marginTop: "-43px", border: "none" }}>
                  <li
                    className="nav-item "
                    onClick={
                      () => {                       
                        this.setState({
                          isAssinatura: false,
                          listaIndex: index,
                          listaSelecionada: lista
                        },
                        )
                      }
                    }
                  >
                    <span
                      className={
                        !isAssinatura ? "nav-link navtab-color-item float-right active" : "nav-link navtab-color-item float-right"
                      }
                      data-toggle="tab"
                      style={{ color: "#495057" }}
                    >
                      PRODUTOS
                    </span>
                  </li>
                  <li
                    className="nav-item "
                    onClick={
                      () => {
                        let close = index === this.state.listaIndex && isAssinatura ? null : index
                        this.setState({
                          isAssinatura: true,
                          listaIndex: close,
                          listaSelecionada: lista
                        },
                        )
                      }
                    }
                  >
                    <span
                      className={
                        isAssinatura ? "nav-link navtab-color-item float-right active" : "nav-link navtab-color-item float-right"
                      }
                      data-toggle="tab"
                      style={{ color: "#495057" }}
                    >
                      ASSINATURA
                    </span>
                  </li>
                </ul>
              </If>
              <If and value1={Utils.isValueValid(this.state.listaSelecionada) && index === this.state.listaIndex && !isAssinatura}>
                <Div col="12" padding="0">{this.renderProdutos(Utils.isNotObjectEmpty(this.state.listaSelecionada) ? this.state.listaSelecionada : lista)}</Div>
              </If>
              <If and value1={Utils.isValueValid(this.state.listaSelecionada) && index === this.state.listaIndex && isAssinatura}>
                <ContainerAssinaturas
                  ativo={this.state.assinaturaAtiva}
                  lista={lista}
                  empresa={this.props.empresa}
                  paramentrosAssinatura={this.state.paramentrosAssinatura}
                  valorTotal={valorTotal}
                />
              </If>
            </Div>
          </>
        )
      })
    )
  }

  boxLista = () => {
    const { listaCompraAssinatura } = this.state;
    return (
      <Div col="12" paddingBottom="4">
        <Row col="12" style={styles.boxList} >
          <Label style={{ width: "40%", color: Color.ECOMMERCE.CINZA, }}>Nome da Lista</Label>
          <Label style={{ width: "10%", color: Color.ECOMMERCE.CINZA, }}>Itens</Label>
          <Label style={{ width: "10%", color: Color.ECOMMERCE.CINZA, }}>Valor</Label>
          <Label style={{ width: "15%", color: Color.ECOMMERCE.CINZA, }}>Assinatura</Label>
        </Row>
        {this.rowLista()}

        <If and value1={!listaCompraAssinatura.length > 0}>
          <Div column inline="center" col="12">
            <Div
              className="rounded"
              style={{
                padding: "10px",
                marginTop: 20,
                border: `1px solid ${Color.EMPRESA.PRIMARIA}`,
                background: "#FFF",
                color: Color.EMPRESA.PRIMARIA,
                fontWeight: "bolder",
                textAlign: "center",
                fontSize: "18px",
                width: "400px"
              }}
              pointer
              handlerClick={() => this.renderCadastrarNovaLista()}
            >
              <I
                icon={Type.ICON.PLUS_SQUARE}
                pointer
                style={{ fontSize: 25, color: Color.EMPRESA.PRIMARIA, }}
              />
              <Label
                family="Light"
                value="Criar Uma nova Lista de Compra"
                pointer
                marginTop="2"
                marginLeft="2"
              />
            </Div>
          </Div>

        </If>
      </Div>
    )
  }

  containerLista() {
    const { pesquisa, listaCompraAssinatura } = this.state;
    return (
      <Div padding="3">
        <Row
          col="12"
          padding="3"
        >
          <Div col="12">
            <Label
              value="Minhas Listas"
              style={{
                fontSize: "22px",
                fontWeight: "bolder",
                color: Color.ECOMMERCE.CINZA_ESCURO
              }}
            />

            <If and value1={listaCompraAssinatura.length > 0}>
              <Row style={{ padding: "5px 20px" }}>
                <Div
                  className="rounded"
                  style={{
                    padding: "10px",
                    background: Color.EMPRESA.PRIMARIA,
                    color: "#FFF",
                    fontWeight: "bolder",
                    textAlign: "center",
                    width: "200px"
                  }}
                  pointer
                  handlerClick={() => this.renderCadastrarNovaLista()}
                >
                  <I
                    icon={Type.ICON.PLUS_SQUARE}
                    pointer
                    style={{ fontSize: 25, color: "#FFF" }}
                  />
                  <Label
                    family="Light"
                    value="Criar Nova Lista"
                    pointer
                    marginTop="2"
                    marginLeft="2"
                  />
                </Div>

                <Div
                  style={{
                    margin: "16px 20px",
                    width: "300px"
                  }}
                >
                  <I
                    pointer
                    handlerClick={(e) => { }}
                    icon={Type.ICON.SEARCH}
                    width="25px"
                    style={{
                      position: "absolute",
                      color: Color.EMPRESA.PRIMARIA,
                      margin: "2px 0px 0px 3px",
                      fontSize: "20px",
                      transform: `rotate(90deg)`,
                    }}
                  />
                  <Input
                    className="input-search-lojainstantanea pl-5"
                    placeholder="Pesquisar Lista de compras"
                    style={{
                      fontFamily: "MetropolisLight",
                      borderBottom: `1px solid ${Color.EMPRESA.PRIMARIA}`,
                      paddingLeft: "44px !important",
                      paddingBottom: "4px",
                    }}
                    handlerChange={(e) => {
                      let { listas, listaCompraAssinatura } = this.state;
                      let search = e.target.value
                      let newLista = []

                      if (search !== "") {
                        newLista = listas.filter(item =>
                          (item.descricao !== null && item.descricao.toLowerCase().indexOf(search.toLowerCase()) > -1)
                        )
                        this.setState({
                          listas: newLista,
                          pesquisa: search
                        })
                      } else if (search === "") {
                        this.setState({
                          listas: listaCompraAssinatura,
                          pesquisa: search
                        })
                      }
                    }}
                    value={pesquisa}
                  />
                </Div>
              </Row>
            </If>
          </Div>
          <Div
            padding="3"
            style={{

              marginTop: "25px",
            }}
            col="12"
          >
            {this.boxLista()}
          </Div>
        </Row>
      </Div>
    )
  }

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.state.parametrosPedido
    );
  }

  render() {
    
    return (
      <Div style={{ width: "100%", marginBottom: 100 }}>
        <Div>
          <Image
            src={this.state.urlFotoEmpresa}
            styleImage={{ width: "100px" }}
            style={{ position: "absolute", left: 15, top: 15 }}
            handlerClick={() => this.props.history.replace("/")}
          />
          <Div
            style={{
              color: "white",
              backgroundColor: this.state.corPrimaria,
              padding: 5,
              borderRadius: 5,
              position: "absolute",
              right: 15,
              top: 15,
              width: 170,
              textAlign: "center",
              opacity: 0.9,
            }}
          >
            <I icon={Type.ICON.SIGNOUT} style={{ fontSize: 20 }} />
            <Label
              style={{ fontSize: 20 }}
              pointer
              value="Página inicial"
              handlerClick={() => this.props.history.replace("/")}
            />
          </Div>
        </Div>

        <Div col="12" style={{ display: "flex", justifyContent: "center" }} >
          <ListasComprasSugeridas
            listaCompraCliente={true}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
            empresa={this.props.empresa}
            history={this.props.history}
          />
        </Div>

        <Div style={{ width: "100%", marginBottom: 100 }}>
          {this.containerLista()}
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setListasDeCompra }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCompraCliente);
