import React, { Component } from "react";
import { Type, Utils } from "../../utilities";
import Image from "../image/Image";
import Div from "../layout/div/Div";
import ImageWithZoom from "../../components/image/ImageWithZoom";

import "./carousel.css";

class CarouselImagens extends Component {

  getClassNameIconNext = () => {
    let classe = Type.ICON.ARROW_ANGLE_RIGHT;

    classe = classe.concat(
      Utils.isViewMobile() ? " icon-nex-carousel-produto-combo" : ""
    );

    return classe;
  };

  render() {
    const ID_CAROUSEL = this.props.id || Utils.uuidString();

    return (
      <>
        <Div
          className="carousel"
          id={ID_CAROUSEL}
          dataRide="carousel"
          inline="center"
        >
          <Div>
            {Array.from(this.props.itens).map((item, index) => {
              return (
                <Div
                  className={`carousel-item text-center ${
                    item.active ? "active" : ""
                  }`}
                  key={index}
                >
                  <ImageWithZoom
                    src={item.url}
                    width={item.width}
                    height={item.height}
                    className="img-thumbnail bg-light"
                  />
                  {/**
                    *  <Image
                    src={item.url}
                    width={item.width}
                    height={item.height}
                    className="img-thumbnail bg-light"
                  />
                    */}
                </Div>
              );
            })}
          </Div>
          <Div
            className={"d-flex align-items-center justify-content-center"}
            margin="2"
          >
            {Array.from(this.props.itens).map((item, index) => {
              const ID_IMAGE = Utils.uuidString();
              return (
                <Image
                  key={index}
                  id={ID_IMAGE}
                  target={`#${ID_CAROUSEL}`}
                  dataSlideTo={index}
                  handleMouseEnter={() =>
                    document.getElementById(ID_IMAGE).click()
                  }
                  className={`cursor-pointer`}
                  src={item.url}
                  width="40px"
                  border
                  alt="Banner"
                />
              );
            })}
          </Div>
        </Div>
      </>
    );
  }
}

export default CarouselImagens;
