import React from "react";

const getClassDefault = (props) => {
  let classe = ''
  classe = classe.concat(props.className ? props.className : '')
  return classe
}

const Ul = props => {
  return <ul className={getClassDefault(props)}>{props.children}</ul>;
};

export default Ul;
