import React, { Component } from "react";
import {
  
  Checkbox,
  Div,
  I,
  If,
  InputCep,
  InputCpfCnpj,
  InputDefault,
  InputInteger,
  InputPhone,
  Label,
  ModalNotification,
  
} from "../../components";
import NavbarTab from "../../components/navbar/tab/NavbarTab";
import { Color, Http, Mask, Type, Utils } from "../../utilities";
import UsuarioUtils from "../utils/UsuarioUtils";
import ClienteLogin from "./ClienteLogin";

const ID_ENDERECO_ENTREGA = Utils.uuidString();

class CadastrarCliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarEnderecoCasa: true,
      mostrarEnderecoEntrega: false,
      cliente: new ClienteLogin(),
      erroCadastro: "",
      enderecoCasaIgualEntrega: false,
    };
  }

  componentDidMount() {
    this.setState({
      cliente: {
        ...this.state.cliente,
        idCidadeIbge: this.props.empresa.idCidadeIbge,
        idCidadeIbgeEntrega: this.props.empresa.idCidadeIbge,
      },
    });
  }

  handlerChange({ name, value }) {
    this.setState({
      cliente: {
        ...this.state.cliente,
        [name]: value,
      },
    });
  }

  validarCadastro() {
    const { cliente } = this.state;
    let erro = "";

    if (!Utils.isValueValid(cliente.logradouro))
      erro = "Preencha o campo lougradouro do endereço de casa!";
    if (!Utils.isValueValid(cliente.logradouroEntrega))
      erro = "Preencha o campo logradouro do endereço de entrega!";
    if (!Utils.isValueValid(cliente.numero))
      erro = "Preencha o campo numero do endereço de casa!";
    if (!Utils.isValueValid(cliente.numeroEntrega))
      erro = "Preencha o campo numero do endereço de entrega!";
    if (!Utils.isValueValid(cliente.bairro))
      erro = "Preencha o campo bairro do endereço de casa!";
    if (!Utils.isValueValid(cliente.bairroEntrega))
      erro = "Preencha o campo bairro do endereço de entrega!";
    if (!Utils.isValueValid(cliente.email)) erro = "Preencha o campo email!";
    if (!Utils.isValueValid(cliente.senha)) erro = "Preencha o campo senha!";
    if (!Utils.isValueValid(cliente.telefone))
      erro = "Preencha o campo telefone!";
    if (!Utils.isValueValid(cliente.cpfCnpj))
      erro = "Preencha o campo CPF / CNPJ!";
    if (!Utils.isValueValid(cliente.nome)) erro = "Preencha o campo nome!";

    this.setState({ erroCadastro: erro });

    return !Utils.isValueValid(erro);
  }

  renderEnderecoCasa() {
    const { cliente } = this.state;
    return (
      <Div col="12" className="border" rounded inline="start">
        <Div inline="start">
          <InputDefault
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Logradouro"
            responsive="4"
            name="logradouro"
            value={cliente.logradouro}
          />
          <InputInteger
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Número"
            responsive="2"
            name="numero"
            value={cliente.numero}
          />
          <InputDefault
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Complemento"
            responsive="3"
            name="complemento"
            value={cliente.complemento}
          />
          <InputDefault
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Bairro"
            responsive="3"
            name="bairro"
            value={cliente.bairro}
          />
          <InputCep
            handlerChange={(e) => this.handlerChange(e.target)}
            label="CEP"
            responsive="4"
            name="cep"
            value={cliente.cep}
          />
          <InputDefault
            label="Cidade"
            value={this.props.empresa.nomeCidade}
            disabled
            responsive="4"
          />
          <InputDefault
            label="Referência"
            value={cliente.referencia}
            responsive="4"
            name="referencia"
            handlerChange={(e) => this.handlerChange(e.target)}
          />
          <Div responsive="12" inline="start">
            <Checkbox
              handlerChange={({ checked }) =>
                this.setState({ enderecoCasaIgualEntrega: checked }, () => {
                  if (checked) {
                    document.getElementById(ID_ENDERECO_ENTREGA).click();
                    this.setState({
                      cliente: {
                        ...cliente,
                        logradouroEntrega: cliente.logradouro,
                        numeroEntrega: cliente.numero,
                        bairroEntrega: cliente.bairro,
                        cepEntrega: cliente.cep,
                      },
                    });
                  } else {
                    this.setState({
                      cliente: {
                        ...cliente,
                        logradouroEntrega: null,
                        numeroEntrega: null,
                        bairroEntrega: null,
                        cepEntrega: null,
                      },
                    });
                  }
                })
              }
              danger
              label="Este é o endereço de entrega ?"
              checked={this.state.enderecoCasaIgualEntrega}
            />
          </Div>
        </Div>
      </Div>
    );
  }

  renderEnderecoEntrega() {
    const { cliente } = this.state;

    return (
      <Div col="12" className="border" rounded inline="start">
        <Div inline="start">
          <InputDefault
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Logradouro"
            responsive="4"
            name="logradouroEntrega"
            value={cliente.logradouroEntrega}
          />
          <InputInteger
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Número"
            responsive="2"
            name="numeroEntrega"
            value={cliente.numeroEntrega}
          />
          <InputDefault
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Complemento"
            responsive="3"
            name="complementoEntrega"
            value={cliente.complementoEntrega}
          />
          <InputDefault
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Bairro"
            responsive="3"
            name="bairroEntrega"
            value={cliente.bairroEntrega}
          />
          <InputCep
            handlerChange={(e) => this.handlerChange(e.target)}
            label="CEP"
            responsive="4"
            name="cepEntrega"
            value={cliente.cepEntrega}
          />
          <InputDefault
            label="Cidade"
            value={this.props.empresa.nomeCidade}
            disabled
            responsive="4"
          />
          <InputDefault
            label="Referência"
            value={cliente.referenciaEntrega}
            responsive="4"
            name="referenciaEntrega"
            handlerChange={(e) => this.handlerChange(e.target)}
          />
        </Div>
      </Div>
    );
  }

  getDataCliente(cliente) {
    return {
      nome: cliente.nome,
      cpfCnpj: Mask.clearMask(cliente.cpfCnpj),
      telefone: Mask.clearMask(cliente.telefone),
      email: cliente.email,
      senha: cliente.senha,
      tipoPessoa:
        Mask.clearMask(cliente.cpfCnpj).length > 11 ? "JURIDICA" : "FISICA",
      enderecos: [
        {
          descricao: Utils.uuidString(),
          logradouro: cliente.logradouro,
          numero: cliente.numero,
          complemento: cliente.complemento,
          bairro: cliente.bairro,
          idCidadeIbge: cliente.idCidadeIbge,
          cep: Mask.clearMask(cliente.cep),
          referencia: cliente.referencia,
          principal: true,
        },
        {
          descricao: Utils.uuidString(),
          logradouro: cliente.logradouroEntrega,
          numero: cliente.numeroEntrega,
          complemento: cliente.complementoEntrega,
          bairro: cliente.bairroEntrega,
          idCidadeIbge: cliente.idCidadeIbgeEntrega,
          cep: Mask.clearMask(cliente.cepEntrega),
          referencia: cliente.referenciaEntrega,
          principal: false,
        },
      ],
    };
  }

  logarUsuario() {
    const { cliente } = this.state;

    Http.post("/cliente/login", { login: cliente.email, senha: cliente.senha })
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            UsuarioUtils.setUsuarioLojaWeb(data);
            //localStorage.setItem(Keys.KEY_USUARIO_LOJA_WEB, data);
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  cadastrarCliente() {
    Http.post("/cliente", this.getDataCliente(this.state.cliente))
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.logarUsuario();
          } else {
            ModalNotification.render(
              "Mensagem de erro",
              <Label value={data.mensagem} />
            );
          }
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { cliente } = this.state;

    return (
      <Div style={{ paddingBottom: 100 }}>
        <If and value1={Utils.isValueValid(this.state.erroCadastro)}>
          <Div
            rounded
            className="bg-danger text-white w-100 border"
            padding="3"
          >
            <I icon={Type.ICON.EXCLAMATION} sizeIcon="2" />
            <Label value={this.state.erroCadastro} marginLeft="3" />
          </Div>
        </If>
        <InputDefault
          handlerChange={(e) => this.handlerChange(e.target)}
          label="Descrição"
          responsive="12"
          name="descricao"
          value={cliente.descricao}
        />
        <Div inline="between">
          <InputDefault
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Nome completo"
            responsive="5"
            name="nome"
            value={cliente.nome}
          />
          <InputCpfCnpj
            handlerChange={(e) => this.handlerChange(e.target)}
            label="CPF / CNPJ"
            responsive="4"
            name="cpfCnpj"
            value={cliente.cpfCnpj}
          />
          <InputPhone
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Telefone"
            responsive="3"
            name="telefone"
            value={cliente.telefone}
          />
          <InputDefault
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Email"
            responsive="6"
            name="email"
            value={cliente.email}
          />
          <InputDefault
            password
            handlerChange={(e) => this.handlerChange(e.target)}
            label="Senha"
            responsive="6"
            name="senha"
            value={cliente.senha}
          />
          <Div col="12">
            <NavbarTab
              itens={[
                {
                  target: "ENDERECO_CASA",
                  value: (
                    <Div inline="center" pointer>
                      <Div inline="center">
                        <Label
                          value="Endereço de casa"
                          pointer
                          style={{ color: Color.ECOMMERCE.MARRON_ESCURO }}
                          family="Light"
                        />
                        <I
                          icon="fa fa-home"
                          sizeIcon="2"
                          marginLeft="3"
                          marginBottom="2"
                          style={{
                            color: this.state.mostrarEnderecoCasa
                              ? Color.ECOMMERCE.LARANJA
                              : "#E5E5E5",
                          }}
                        />
                      </Div>
                    </Div>
                  ),
                  active: true,
                },
                {
                  target: "ENDERECO_ENTREGA",
                  value: (
                    <Div inline="center" pointer id={ID_ENDERECO_ENTREGA}>
                      <Div inline="center">
                        <Label
                          value="Endereço de Entrega"
                          style={{ color: Color.ECOMMERCE.MARRON_ESCURO }}
                          family="Light"
                          pointer
                        />
                        <I
                          icon={Type.ICON.MOTORCYCLE}
                          sizeIcon="2"
                          marginLeft="3"
                          marginBottom="2"
                          style={{
                            color: this.state.mostrarEnderecoEntrega
                              ? Color.ECOMMERCE.LARANJA
                              : "#E5E5E5",
                          }}
                        />
                      </Div>
                    </Div>
                  ),
                },
              ]}
              handlerClick={({ target }) => {
                this.setState({
                  mostrarEnderecoCasa: target === "ENDERECO_CASA",
                  mostrarEnderecoEntrega: target === "ENDERECO_ENTREGA",
                });
              }}
            />

            <If and value1={this.state.mostrarEnderecoCasa}>
              {this.renderEnderecoCasa()}
            </If>
            <If and value1={this.state.mostrarEnderecoEntrega}>
              {this.renderEnderecoEntrega()}
            </If>
          </Div>
          <Div
            className="modal-footer w-100 mt-2"
            inline="center"
            style={{ backgroundColor: "#C4C4C4" }}
          >
            <Label
              handlerClick={(e) => {
                if (this.validarCadastro()) {
                  this.cadastrarCliente();
                }
              }}
              value="FINALIZAR CADASTRO"
              style={{ color: "white", fontSize: 20 }}
              family="Bold"
            />
          </Div>
        </Div>
      </Div>
    );
  }
}

export default CadastrarCliente;
