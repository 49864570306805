import React from "react";
import "./video.css";
import { Div } from "..";

const Video = props => {
  return (
    <Div className="embed-responsive embed-responsive-16by9">
      <video
        className="embed-responsive-item"
        width={props.width}
        height={props.height}
        id="videoPlay"
        autoPlay="autoplay"
        muted={PaymentResponse.muted}
        controls
        src={props.src}
        type={props.type}
      />
    </Div>
  );
};

export default Video;
