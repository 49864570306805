import React from "react";
import PropTypes from "prop-types";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(props.className ? `${props.className} ` : "");
  classe = classe.concat(props.col ? `${props.col} ` : "");
  classe = classe.concat(props.right ? "float-right " : "");
  classe = classe.concat(props.left ? "float-left " : "");
  classe = classe.concat(props.formcontrol ? "form-control " : "");

  return classe;
};

const validateKeyPress = e => {
  if (e.key === "Enter" || e.key === "ENTER") {
    e.preventDefault();
  }
};

const InputDefaultValue = props => {
  return (
    <input
      autoFocus={props.autofocus || props.autoFocus}
      className={getClassDefault(props)}
      type={props.type}
      disabled={props.disabled}
      placeholder={props.placeholder}
      onChange={props.handlerChange}
      onBlur={props.handlerBlur}
      onKeyDown={e => validateKeyPress(e)}
      onClick={props.handlerClick}
      name={props.name}
      multiple={props.multiple}
      checked={props.checked}
      id={props.id}
      required={props.required}
      pattern={props.pattern}
      title={props.title}
      defaultValue={props.defaultValue}
      accept={props.accept}
      style={props.styleInput}
      autoComplete={props.autoComplete}
      
    />
  );
};

InputDefaultValue.propTypes = {
  col: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handlerChange: PropTypes.func,
  handlerBlur: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string
};

InputDefaultValue.defaultProps = {
  col: "12",
  type: "text",
  disabled: false
};

export default InputDefaultValue;
