import React, { Component } from "react";
import { Input, I } from "../..";
import Div from "../../layout/div/Div";

class InputSearchTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      classSearch: "search-hide"
    };
  }

  getClassSearch() {
    return this.state.active ? "active" : "";
  }

  render() {
    return (
      <Div className="input-group" paddingRight="2">
        <Div col="12" className={`translateXmenos15 translateXmais15 input-group-prepend searchbar d-flex ${this.getClassSearch()}`}>
          <I
            icon="fa fa-search search-table"
            bg="secondary"
            colorText="white"
            handlerClick={e => {
              this.setState({
                active: !this.state.active
               
              });
            }}
            className="inputgrouptext d-flex"
          ></I>
          <Input
            display="block"
            type="search"
            placeholder="Pesquisar"
            className="inputsearch formcontrol"
            handlerChange={this.props.handlerChange}
          ></Input>
        </Div>
      </Div>
    );
  }
  
}

export default InputSearchTable;
