import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {  Div, InputDefault, Row,  Title } from "../../components";
import AssinaturaService from "../../service/AssinaturaService";

const styles = {
  boxInfo: {
    marginBottom: "50px",
    padding: "10px 0 20px 0",
    backgroundColor: "#FFF",
    alignItems: "end"
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
  },
  imageIcon: {
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
  },
  checkedBox: {
    margin: "8px 5px"
  },
  colorInput: {
    //color: "#c5c5c5",
  }
}


class ClienteEditar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "Nome Completo",
      tel: 62988887777,
      email: "email@email.com",
      cpf: 12312312323,
      valorTotal: 306,
      statusAssinatura: "MENSAL",
      assinatura: {
        idListaCompra: "",
        idCliente: "",
        dataCadastro: "",
        tipo: "",
        tipoEntrega: "ENTREGA",
        tipoPagamento: "OFF_LINE",
        tipoFormaPagamento: "",
        dataPrimeiroPedido: "2022-05-04",
        obs: "",
        pausado: false,
        dataPausa: null,
        motivoPausa: null,
        dataCancelamento: null,
        motivoCancelamento: null,
        nomeCliente: "...",
      },
      itens: [],
      ...this.props.history.location.state
    }
  }

  componentDidMount(){
    this.getProdutosLista()
  }

  getProdutosLista(){
    let assinatura = this.state.assinatura
    if(assinatura){
      AssinaturaService.adminGetProdutosLista(assinatura.idListaCompra).then( (data) => {
        this.setState({
          itens: data
        })
      });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { assinatura,  } = this.state;

    return (
      <Div
        padding="2"
        paddingRight="5"
        marginLeft="3"
        //bg={Color.NODE.WHITE}
        col="12"
        shadow
      >

        <Row col="12" marginTop="4" shadow className="border-left-primary" style={styles.boxInfo}>
          <Title
            value="Informações do Cliente"
            type="h6"
            className="label-valor-total-carrinho"
            style={styles.labelCinza}
            family="Bold"
            col="12"
          />

          <InputDefault
            disabled
            responsive="6"
            label="Nome"
            name="nomeCliente"
            handlerChange={this.handleChange}
            value={assinatura.nomeCliente}
            style={styles.colorInput}
          ></InputDefault>

          <InputDefault
            disabled
            responsive="6"
            label="Email"
            name="dataPrimeiroPedido"
            handlerChange={this.handleChange}
            value={assinatura.dataPrimeiroPedidoMask}
            style={styles.colorInput}
          ></InputDefault>

          <InputDefault
            disabled
            responsive="4"
            label="CPF / CNPJ"
            name="tipo"
            handlerChange={this.handleChange}
            value={assinatura.tipo}
            style={styles.colorInput}
          ></InputDefault>

          <InputDefault
            disabled
            responsive="4"
            label="Telefone"
            name="tipoFormaPagamento"
            handlerChange={this.handleChange}
            value={assinatura.tipoFormaPagamento}
            style={styles.colorInput}
          ></InputDefault>

          {/* <Div
            col="12"
            style={{
              textAlign: "right",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end ",
              margin: "0px -18px 15px",
              padding: "0",
            }}
          >
            <ButtonIconOutLine
              value="Cancelar Assinatura"
              //icon={Type.ICON.PLUS}
              btn={Color.NODE.DANGER}
            //handlerClick={() => this.submitParametros()}
            />
          </Div> */}
        </Row>
      </Div >
    )
  }
}

export default withRouter(ClienteEditar);
