import { Http } from '../utilities';

class OrcamentoService {
  
  static async consultarOrcamentosFiltro(obj) {
    let res = null;

    await Http.post('/orcamento/filtro', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async cadastrarOrcamento(obj) {
    let res = null;

    await Http.post('/orcamento/', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async cancelarOrcamento(obj) {
    let res = null;

    await Http.delete('/orcamento/', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async consultarQuantidadePosicao(obj) {
    let res = null;

    await Http.post('/orcamento/posicao/quantidades', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async getOrcamento(idOrcamento) {

    let res = null;
    await Http.get(`/orcamento/${idOrcamento}`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async atualizarEmSeparacao(obj) {
    let res = null;

    await Http.put('/orcamento/posicao/emseparacao', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async atualizarConcluido(obj) {
    let res = null;

    await Http.put('/orcamento/posicao/concluido', obj).then(response => {
      res = response.data;
    });

    return res;
  }

}

export default OrcamentoService;
