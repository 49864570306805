import TagManager from "react-gtm-module";

export default class TagManagerUtils {

  static tagManagerAdicionarNoCarrinho(produto) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null
      }
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "add_to_cart",
        ecommerce: {
          items: [{
            item_name: produto.descricao, // Name or ID is required.
            item_id: produto.codigoBarras,
            price: produto.precoVenda,
            item_brand: produto.marca,
            item_category: produto.descricaoDepartamento,
            item_category2: produto.descricaoSecao,          
            index: 1,
            quantity: produto.quantidade,
            currency: 'BRL'
          }]
        }
      }
    })
  }



  static tagManagerRemoverDoCarrinho(produto) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null
      }
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "remove_from_cart",
        ecommerce: {
          items: [{
            item_name: produto.descricao, // Name or ID is required.
            item_id: produto.codigoBarras,
            price: produto.precoVenda,
            item_brand: produto.marca,
            item_category: produto.descricaoDepartamento,
            item_category2: produto.descricaoSecao,          
            index: 1,
            currency: 'BRL',
            quantity: produto.quantidade
          }]
        }
      }
    })
  }


  static tagManagerVizualizouItem(produto) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null
      }
    })


    TagManager.dataLayer({
      dataLayer: {
        event: "view_item",
        ecommerce: {
          items: [{
            item_name: produto.descricao, // Name or ID is required.
            item_id: produto.codigoBarras,
            price: produto.precoVenda,
            item_brand: produto.marca,
            item_category: produto.descricaoDepartamento,
            item_category2: produto.descricaoSecao,          
            index: 1,
            currency: 'BRL',
            quantity: produto.quantidade
          }]
        }
      }
    })
  }


  static tagManagerConcluiuCompra(pedido, empresa) {
    const { 
      itens, 
      cupom, 
      valorTotal, 
      valorTaxaEntrega, 
      idLancamentoPedidoApp, 
      recebimento  
    } = pedido; 
    let newItems = []

    itens.forEach(produto => {
      newItems.push({
        item_name: "Indisponivel", // Name or ID is required.
        item_id: produto.idProdutoWeb,
        price: produto.precoUnitario,
        currency: 'BRL',
        quantity: produto.quantidade
      }) 
    });

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null
      }
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "purchase",
        ecommerce: {
          payment_type: recebimento,
          transaction_id: idLancamentoPedidoApp,
          affiliation: empresa,
          value: valorTotal,
          tax: valorTaxaEntrega,
          currency: "BRL",
          coupon: cupom,
          items: newItems,
        }
      }
    })
  }


  static InformacoesDePagamento(pedido, tipo, empresa) {
    const { 
      itens, 
      valorTotal,
    } = pedido; 
    let newItems = []

    itens.forEach(produto => {
      newItems.push({
        item_name: produto.descricao, // Name or ID is required.
        item_id: produto.codigoBarras,
        item_category: produto.descricaoDepartamento,
        price: produto.precoUnitario,
        currency: 'BRL',
        quantity: produto.quantidade
      }) 
    });

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null
      }
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "add_payment_info",
        ecommerce: {
          payment_type: tipo,
          affiliation: empresa,
          value: valorTotal,
          currency: "BRL",
          items: newItems,
        }
      }
    })
  }

  static eventLogin(email) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null
      }
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "login",
        userId: email
      }
    })
  }

  static tagManagerIrParaPagamento(produtos) {
    
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null
      }
    })

    let newItems = []

    produtos.forEach(item => {
      newItems.push({
        item_name: item.descricao, // Name or ID is required.
        item_id: item.idProdutoWeb,
        price: item.precoUnitario,
        currency: 'BRL',
        quantity: item.quantidade
      }) 
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "begin_checkout",
        ecommerce: {
          items: newItems,
        }
      }
    })
  }

  static vizualizouListadeItens(produtos) {
    
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null
      }
    })

    let newItems = []

    produtos.forEach(item => {
      newItems.push({
        item_name: item.descricao, // Name or ID is required.
        item_id: item.idProdutoWeb,
        price: item.precoUnitario,
        item_category: item.descricaoDepartamento,
        currency: 'BRL',
        quantity: item.quantidade
      }) 
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "view_item_list",
        ecommerce: {
          items: newItems,
        }
      }
    })
  }

}
