import { Utils } from "../utilities";

export const display = ({ display }) => {
  if (Utils.isValueValid(display)) {
    switch (display) {
      case "inline":
        return "d-inline ";
      case "none":
        return "d-none ";
      case "block":
        return "d-block ";
      case "table":
        return "d-table ";
      case "flex":
        return "d-flex ";
      case "inline-flex":
        return "d-inline-flex ";
      default:
        return "";
    }
  }
  return "";
};

export const margin = (props) => {
  let margin = "";
  if (props.margin) {
    margin = margin.concat(`m-${props.margin} `);
  }
  if (props.marginTop) {
    margin = margin.concat(`mt-${props.marginTop} `);
  }
  if (props.marginBottom) {
    margin = margin.concat(`mb-${props.marginBottom} `);
  }
  if (props.marginLeft) {
    margin = margin.concat(`ml-${props.marginLeft} `);
  }
  if (props.marginRight) {
    margin = margin.concat(`mr-${props.marginRight} `);
  }

  return margin;
};

export const padding = (props) => {
  let padding = "";
  if (props.padding) {
    padding = padding.concat(`p-${props.padding} `);
  }
  if (props.paddingTop) {
    padding = padding.concat(`pt-${props.paddingTop} `);
  }
  if (props.paddingBottom) {
    padding = padding.concat(`pb-${props.paddingBottom} `);
  }
  if (props.paddingLeft) {
    padding = padding.concat(`pl-${props.paddingLeft} `);
  }
  if (props.paddingRight) {
    padding = padding.concat(`pr-${props.paddingRight} `);
  }

  return padding;
};

export const offset = (props) => {
  let offset = "";
  if (props.offset) {
    offset = offset.concat(`offset-${props.offset} `);
  }
  if (props.offsetMd) {
    offset = offset.concat(`offset-md-${props.offsetMd} `);
  }
  if (props.offsetLg) {
    offset = offset.concat(`offset-lg-${props.offsetLg} `);
  }
  if (props.offsetXl) {
    offset = offset.concat(`offset-xl-${props.offsetXl} `);
  }
  if (props.offsetSm) {
    offset = offset.concat(`offset-sm-${props.offsetSm} `);
  }

  return offset;
};

export const border = (props) => {
  let border = "";
  if (props.border) {
    border = border.concat(`border-left-${props.border} `);
    border = border.concat(`border-right-${props.border} `);
    border = border.concat(`border-top-${props.border} `);
    border = border.concat(`border-bottom-${props.border} `);
  } else {
    if (props.borderLeft) {
      border = border.concat(`border-left-${props.borderLeft} `);
    }
    if (props.borderRight) {
      border = border.concat(`border-right-${props.borderRight} `);
    }
    if (props.borderTop) {
      border = border.concat(`border-top-${props.borderTop} `);
    }
    if (props.borderBottom) {
      border = border.concat(`border-bottom-${props.borderBottom} `);
    }
  }
  return border;
};

export const responsive = ({ responsive }) => {
  if (Utils.isValueValid(responsive)) {
    switch (responsive) {
      case "1":
        return "col-sm-12 col-12 col-xl-1 col-lg-1 ";
      case "2":
        return "col-sm-12 col-12 col-xl-2 col-lg-2 ";
      case "3":
        return "col-sm-12 col-12 col-xl-3 col-lg-3 ";
      case "4":
        return "col-sm-12 col-12 col-xl-4 col-lg-4 ";
      case "5":
        return "col-sm-12 col-12 col-xl-5 col-lg-5 ";
      case "6":
        return "col-sm-12 col-12 col-xl-6 col-lg-6 ";
      case "7":
        return "col-sm-12 col-12 col-xl-7 col-lg-7 ";
      case "8":
        return "col-sm-12 col-12 col-xl-8 col-lg-8 ";
      case "9":
        return "col-sm-12 col-12 col-xl-9 col-lg-9 ";
      case "10":
        return "col-sm-12 col-12 col-xl-10 col-lg-10 ";
      case "11":
        return "col-sm-12 col-12 col-xl-11 col-lg-11 ";
      case "12":
        return "col-sm-12 col-12 col-xl-12 col-lg-12 ";
      default:
        return "";
    }
  }
  return "";
};

export const inline = ({ inline }) => {
  if (Utils.isValueValid(inline)) {
    switch (inline) {
      case "between":
        return "d-flex align-items-center flex-wrap justify-content-between ";
      case "start":
        return "d-flex align-items-center flex-wrap justify-content-start ";
      case "end":
        return "d-flex align-items-center flex-wrap justify-content-end ";
      case "center":
        return "d-flex align-items-center flex-wrap justify-content-center ";
      case "around":
        return "d-flex align-items-center flex-wrap justify-content-center ";
      default:
        return "";
    }
  }
  return "";
};

export const col = ({ col, colLg, colSm, colMd, colXl }) => {
  let column = "";
  if (col) {
    column = `col-${col} `;
  }
  if (colLg) {
    column = `col-lg-${colLg} `;
  }
  if (colSm) {
    column = `col-sm-${colSm} `;
  }
  if (colMd) {
    column = `col-md-${colMd} `;
  }

  if (colXl) {
    column = `col-xl-${colXl} `;
  }
  return column;
};

export const column = ({ column }) => {
  return Utils.isValueValid(column)
    ? "d-flex flex-column align-items-center "
    : "";
};

export const colorText = ({ colorText }) => {
  return colorText ? `text-${colorText} ` : "";
};

export const disabled = ({ disabled }) => {
  return disabled ? "disabled " : "";
};

export const underline = ({ underline }) => {
  return underline ? "border-bottom " : "";
};

export const rounded = ({ rounded }) => {
  return rounded ? "rounded " : "";
};

export const pointer = ({ pointer }) => {
  return pointer ? "cursor-pointer " : "";
};

export const collapse = ({ collapse }) => {
  return collapse ? "collapse " : "";
};

export const active = ({ active }) => {
  return active ? "active " : "";
};

export const show = ({ show }) => {
  return show ? "show " : "";
};

export const shadow = ({ shadow }) => {
  return shadow ? "shadow " : "";
};

export const backGround = ({ bg }) => {
  return bg ? `bg-${bg} ` : "";
};

export const float = ({ float }) => {
  return float ? `float-${float} ` : "";
};

export const textAlign = ({ textAlign }) => {
  return textAlign ? `text-${textAlign} ` : "";
};

export const className = ({ className }) => {
  return className ? `${className} ` : "";
};

export const button = ({ btn, btnOutLine }) => {
  let button = "";
  if (btn) {
    button = `btn-${btn} `;
  }
  if (btnOutLine) {
    button = `btn-outline-${btnOutLine} `;
  }
  return button;
};

export const invisible = ({ invisible }) => {
  return invisible ? "invisible " : "";
};

export const sizeIcon = ({ sizeIcon }) => {
  return sizeIcon ? `fa-${sizeIcon}x ` : "fa-x ";
};

export const icon = ({ icon }) => {
  return icon ? `fa fa-${icon} ` : "";
};

export const slider = ({ slider }) => {
  return slider ? `slider-${slider} ` : "";
};

export const font = ({ font }) => {
  return font ? `font-${font} ` : "";
};

export const family = ({ family }) => {
  return family ? { fontFamily: `Metropolis${family}` } : {};
};
