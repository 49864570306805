import React, { Component } from "react";

import {
  Div,
  Notification,
  Table,
  ModalNotification,
} from "../../components";
import ProdutoCataloService from "../../service/ProdutoCatalogoService";
import { Type, Utils } from "../../utilities";
import ModalProdutosRealacionados from "./ModalProdutosRelacionados";

class ProdutosRelacionados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtros: [],
      selectFiltro: {},
      inputFiltro: "",
      dataFiltros: [{}],
      filtrosSelecionados: [],
      selecionarTodos: false,
      filtro: "",
      descricao: "",
      idProdutoWeb: "",
      idProdutoWebFiltro: "",
      adicionar: false,
      editar: false,
      closeModalProdutosRelacionados: false
    };

  }

  UNSAFE_componentWillMount() {
    if (this.props.filtros) {
      this.setState({
        idProdutoWeb: this.props.idProdutoWeb,
        filtros: this.props.filtros,
      });
    }

    this.getFiltrosGeral();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filtros !== this.props.filtros) {
      this.setState({
        idProdutoWeb: this.props.idProdutoWeb,
        filtros: this.props.filtros,
      });
      this.getFiltrosGeral();
    }
  }

  getFiltrosGeral() {
    ProdutoCataloService.getProdutosFiltros().then((response) => {
      if (Utils.isNotNull(response)) {
        if (!response.status || response.status === 404) return;

        let newData = [];

        response.data.forEach((filtro) => {
          newData.push({ value: filtro.nome, label: filtro.nome });
        });

        this.setState({
          dataFiltros: newData,
        });
      }
    });
  }

  confirmarFiltro() {
    const { selectFiltro, descricao, idProdutoWebFiltro } = this.state;

    let newFiltro = [
      {
        idProdutoWeb: this.state.idProdutoWeb,
        nome: Utils.isNotObjectEmpty(selectFiltro) ? selectFiltro.label : "",
        valor: descricao,
      },
    ];

    let editarFiltro = {
      nome: Utils.isNotObjectEmpty(selectFiltro) ? selectFiltro.label : "",
      idProdutoWebFiltro: idProdutoWebFiltro,
      valor: descricao,
    };

    if (!Utils.isNotObjectEmpty(selectFiltro)) {
      Notification.danger("Selecione ou Escreva um nome para o Filtro!");
      return;
    }

    if (selectFiltro.label.length < 3) {
      Notification.danger(
        "Escreva um nome para o filtro com 3 ou mais caracteres!"
      );
      return;
    }

    if (descricao.length < 3) {
      Notification.danger(
        "Escreva um valor para o filtro com 3 ou mais caracteres!"
      );
      return;
    }

    if (!this.state.editar) {
      ProdutoCataloService.criarNovoFiltro(newFiltro).then((data) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.setState(
            {
              selectFiltro: {},
              descricao: "",
              editar: false,
            },
            this.props.consultarProduto()
          );
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }

    if (this.state.editar) {
      ProdutoCataloService.atualizarFiltro(editarFiltro).then((data) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.setState(
            {
              selectFiltro: {},
              descricao: "",
              editar: false,
            },
            this.props.consultarProduto()
          );
        } else {
          Notification.danger(data.mensagem);
          this.setState({
            selectFiltro: {},
            descricao: "",
            editar: false,
          });
        }
      });
    }
  }

  editarFiltro(filtro) {
    this.setState({
      descricao: filtro.valor,
      idProdutoWeb: filtro.idProdutoWeb,
      idProdutoWebFiltro: filtro.idProdutoWebFiltro,
      editar: true,
      inputFiltro: filtro.nome,
      selectFiltro: { value: filtro.nome, label: filtro.nome },
    });
  }

  cancelarEdicao() {
    this.setState({
      inputFiltro: "",
      selectFiltro: {},
      descricao: "",
      idProdutoWebFiltro: "",
      editar: false,
    });
  }

  removerFiltro(filtro) {
    let remover = {
      idProdutoWebFiltro: filtro.idProdutoWebFiltro,
    };

    ProdutoCataloService.removerFiltro(remover).then((data) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.setState(
          {
            filtro: "",
            descricao: "",
            editar: false,
          },
          this.props.consultarProduto()
        );
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  modalRemoverFiltro(filtro) {
    ModalNotification.renderMessage({
      title: "Deseja Remover esse Filtro? ",
      body: `O Filtro ${filtro.nome} Será removido desse produto!`,
      handlerConfirm: () => this.removerFiltro(filtro),
      handlerCancel: () => {},
      labelButtonCancel: "Cancelar",
    });
  }

  handlerSelect(e) {
    this.setState({
      selectFiltro: e,
      inputFiltro: e.label,
    });
  }

  handlerInputSelect(value, action) {
    if (action.action === "input-change") {
      this.setState({
        inputFiltro: value,
        selectFiltro: { value: value, label: value },
      });
    }
  }

  setCloseModalProduto = (boolean) => {
    this.setState({
      closeModalProdutosRelacionados: boolean,
    });
  };

  render() {
    const {
      filtros,
      filtrosSelecionados,
    } = this.state;

    return (
      <Div marginBottom="2">

        <ModalProdutosRealacionados
          closeModal={this.state.closeModalProdutosRelacionados}
          setCloseModal={this.setCloseModalProduto}
        />

        <Table
          columns={[
            { value: "Foto", align: "center" },
            //   { value: "Cód. Produto", align: "center" },
            { value: "Cód. Barras", align: "center" },
            { value: "Descrição", align: "left" },
            { value: "Departamento", align: "left" },
            { value: "Seção", align: "left" },
            { value: "Preço de Venda", align: "center" },
            { value: "Preço Atacado", align: "center" },
            { value: "Estoque", align: "center" },
            { value: "Situação", align: "center" },
          ]}
          fields={[
            {
              value: "urlImagemPrincipalThumbnail",
              type: Type.DADO.IMG,
              align: "left",
              col: 2,
            },
            // {
            //   value: "codigoProduto",
            //   type: Type.DADO.STRING,
            //   align: "center",
            //   col: 2,
            // },
            {
              value: "codigoBarras",
              type: Type.DADO.STRING,
              align: "center",
              col: 2,
            },
            {
              value: "descricao",
              type: Type.DADO.STRING,
              align: "left",
              col: 3,
            },
            {
              value: "descricaoDepartamento",
              type: Type.DADO.STRING,
              align: "left",
              col: 2,
            },
            {
              value: "descricaoSecao",
              type: Type.DADO.STRING,
              align: "left",
              col: 2,
            },
            {
              value: "precoVenda",
              type: Type.DADO.CURRENCY,
              align: "center",
              col: 1,
            },
            {
              value: "precoVendaAtacado",
              type: Type.DADO.CURRENCY,
              align: "center",
              col: 1,
            },
            {
              value: "quantidadeEstoqueAtual",
              type: Type.DADO.INTEGER,
              align: "center",
              col: 1,
            },
            {
              value: "situacao",
              type: Type.DADO.LABEL,
              align: "center",
              color: "#FFF",
            },
          ]}
          title="Produtos Cadastrados"
          delete
          handlerDelete={(filtro) => this.modalRemoverFiltro(filtro)}
          bordered
          orientation="center"
          data={filtros}
          iconEdit={Type.ICON.EDIT}
          handlerEdit={(produto) => this.editarFiltro(produto)}
          edit
          rota="#"
          handlerRota={() => this.setCloseModalProduto(true)}
          error="Nenhum produto encontrado"
          //checkbox={{ nameChecked: "selected", valueChecked: true }}
          handlerCheckbox={(produto) => {
            if (Utils.isNotNull(produto)) {
              produto.selected = !produto.selected;
              let filtroEncontrado = filtrosSelecionados.filter(
                (p) => p.codigoBarras === produto.codigoBarras
              )[0];

              if (Utils.isValueValid(filtroEncontrado)) {
                filtroEncontrado = produto;
              } else {
                filtrosSelecionados.push(produto);
              }
            } else {
              produto.selected = false;
            }
            this.setState({
              filtrosSelecionados,
            });
          }}
          // labelCheckBox={
          //   <Checkbox
          //     width="50px"
          //     label="Selecione Todos"
          //     checked={selecionarTodos}
          //     handlerChange={({ checked }) => {

          //       let filtroList = [...filtros];

          //       if (Utils.isArrayNotEmpty(filtroList)) {
          //         filtroList.forEach((p) => {
          //           p.selected = checked;
          //         });

          //         this.setState({
          //           filtrosSelecionados: checked ? filtroList : [],
          //           selecionarTodos: checked,
          //         });
          //       } else {
          //         Notification.info(
          //           "Nenhum produto selecionado"
          //         );
          //       }

          //     }}
          //   ></Checkbox>
          // }
        ></Table>
      </Div>
    );
  }
}

export default ProdutosRelacionados;
