import React from "react";
import PropTypes from "prop-types";
import { Div } from "../../components";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(props.container ? "container " : "");
  classe = classe.concat(props.fluid ? "container-fluid" : "");
  classe = classe.concat(props.className ? `${props.className} ` : "");

  return classe;
};

const Container = props => {
  return <Div className={getClassDefault(props)}>{props.children}</Div>;
};

Container.propTypes = {
  fluid: PropTypes.bool
};

export default Container;
