import React, { Component } from "react";
import { Type, Utils } from "../../utilities";
import Div from "../layout/div/Div";

import "./carousel.css";
import "./carouselprodutogenerico.css";

class CarouselProdutosGenerico extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itens: props.itens || [],
    };
  }

  getClassNameButtonNext() {
    let classe = "carousel-control-next ";

    classe = classe.concat("icon-nex-carousel-produto-generico");

    return classe;
  }

  componentWillReceiveProps({ itens }) {
    this.setState({ itens });
  }

  render() {
    const ID_CAROUSEL = this.props.id || Utils.uuidString();

    return (
      <Div>
        <Div className="carousel slide" id={ID_CAROUSEL} dataRide="carousel">
          <a
            className="carousel-control-prev icon-prev-carousel-produto-generico"
            href={`#${ID_CAROUSEL}`}
            role="button"
            data-slide="prev"
          >
            <i
              style={{ fontSize: 60, color: "#C4C4C4" }}
              className={Type.ICON.ARROW_ANGLE_LEFT}
            />
          </a>
          <Div className="carousel-inner" inline="start">
            {Array.from(this.state.itens).map((item, index) => {
              return (
                <Div
                  className={`carousel-item ${item.active ? "active" : ""}`}
                  key={index}
                >
                  <Div inline="start">{item.value}</Div>
                </Div>
              );
            })}
          </Div>
          <a
            className={this.getClassNameButtonNext()}
            href={`#${ID_CAROUSEL}`}
            role="button"
            data-slide="next"
          >
            <i
              style={{ fontSize: 60, color: "#C4C4C4" }}
              className={Type.ICON.ARROW_ANGLE_RIGHT}
            />
          </a>
        </Div>
      </Div>
    );
  }
}

export default CarouselProdutosGenerico;
