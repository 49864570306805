import React from "react";
import { Utils } from "../../utilities";
import Input from "../forms/input/Input";
import If from "../if/If";
import Label from "../texts/label/Label";
import Span from "../texts/span/Span";

import "./radiocustom.css";

const RadioCustom = (props) => {
  return (
    <Label className="container d-flex justify-content-center align-items-start">
      <If and value1={Utils.isValueValid(props.left)}>
        {props.value}
      </If>
      <Input type="radio" checked={props.checked} name="radio" handlerChange={props.handlerChange} />
      <Span className="checkmark" />
      <If and value1={Utils.isValueValid(props.right)}>
        {props.value}
      </If>
    </Label>
  );
};

export default RadioCustom;
