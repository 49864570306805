import React from "react";
import { Component } from "react";
import {
  Div,
  If,
  InputCep,
  InputDefault,
  InputInteger,
  Label,
  ModalNotification,
  Select,
  SelectValue,
} from "../../../components";
import { Color, EndPoints, Http, Mask, Type, Utils } from "../../../utilities";
import axios from "axios";
import UsuarioUtils from "../../utils/UsuarioUtils";

const PATH_VIA_CEP = "https://viacep.com.br/ws/%s/json";

class NovoEnderecoCheckoutMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      endereco: {
        descricao: "Casa",
      },
      cliente: this.getCliente(),
    };
  }

  getCliente() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb() || {};
    cliente.cpfCnpj = Mask.getValueMaskCpfCnpj(cliente.cpfCnpj);
    cliente.cep = Mask.getValueMaskCep(cliente.cep);
    cliente.telefone = Mask.getValueMaskPhone(cliente.telefone);
    return cliente;
  }

  getNomesEndereco = () => {
    return [
      new SelectValue("Casa", "Casa"),
      new SelectValue("Trabalho", "Trabalho"),
      new SelectValue("Outro", "Outro"),
    ];
  };

  consultarCep(endereco) {
    axios
      .get(PATH_VIA_CEP.replace("%s", Mask.clearMask(endereco.cep)))
      .then(({ data }) => {
        if (data) {
          if (!Utils.isValueValid(data.erro)) {
            this.setState({
              endereco: {
                ...this.state.endereco,
                idCidadeIbge: data.ibge,
                bairro: data.bairro,
                complemento: data.complemento,
                cidade: data.localidade,
                logradouro: data.logradouro,
              },
              labelMensagemCepNaoEncontrado: "",
            });
          } else {
            this.setState({
              endereco: {
                ...this.state.endereco,
                idCidadeIbge: "",
                bairro: "",
                complemento: "",
                cidade: "",
                logradouro: "",
              },
              labelMensagemCepNaoEncontrado:
                "Endereço não encontrado para o CEP informado",
            });
          }
        } else {
          this.setState({
            endereco: {
              ...this.state.endereco,
              idCidadeIbge: "",
              bairro: "",
              complemento: "",
              cidade: "",
              logradouro: "",
            },
            labelMensagemCepNaoEncontrado:
              "Endereço não encontrado para o CEP informado",
          });
        }
      })
      .catch((error) => console.log(error));
  }

  getDataNovoEndereco() {
    let endereco = this.state.endereco;
    return {
      ...endereco,
      idCliente: this.state.cliente.idCliente,
      cep: Mask.clearMask(endereco.cep),
    };
  }

  adicionarNovoEnderecoMemoria(endereco) {
    let cliente = this.state.cliente;
    cliente.enderecos = [...cliente.enderecos, { ...endereco }];
    UsuarioUtils.setUsuarioLojaWeb(cliente);
  }

  consultarEnderecosCliente() {
    let cliente = this.state.cliente;
    Http.get(EndPoints.ENDERECOS_POR_CLIENTE.replace("%s", cliente.idCliente))
      .then(({ status, data }) => {
        if (status) {
          cliente.enderecos = data;
          UsuarioUtils.setUsuarioLojaWeb(cliente);
        } else {
          ModalNotification.renderMessage({
            title: "Falha ao consultar endereços",
            body: "Verifique a conexão com a internet",
            handlerCancel: () => {},
            styleButtonCancel: {
              backgroundColor: Color.ECOMMERCE.CINZA,
              color: "white",
            },
            labelButtonCancel: "Fechar",
          });
        }

        this.props.forceUpdate();
      })
      .catch((error) => console.log(error));
  }

  cadastrar(endereco) {
    Http.post(EndPoints.CLIENTE_ENDERECO, endereco)
      .then(({ status, data }) => {
        if (status) {
          this.setState({ adicionarEndereco: false }, () =>
            //this.adicionarNovoEnderecoMemoria(endereco)
            this.consultarEnderecosCliente()
          );
          ModalNotification.removeModal();
        } else {
          ModalNotification.renderMessage({
            title: "Falha ao adicionar endereço",
            styleButtonCancel: {
              backgroundColor: Color.ECOMMERCE.CINZA,
              color: Color.ECOMMERCE.WHITE,
            },
            labelButtonCancel: "Fechar",
            handlerCancel: () => {},
          });
        }

        this.props.handlerOutroEndereco(endereco);
        this.forceUpdate();
      })
      .catch((error) => console.log(error));
  }

  adicionarNovoEndereco() {
    let novoEndereco = this.getDataNovoEndereco();

    let endereco = Array.from(this.state.cliente.enderecos).filter(
      (end) => end.descricao === novoEndereco.descricao
    )[0];
    if (Utils.isValueValid(endereco)) {
      ModalNotification.renderMessage({
        body: `Já possui um endereço cadastrado do tipo [${novoEndereco.descricao}]`,
        title: "Falha ao adicionar endereço",
        styleButtonCancel: {
          backgroundColor: Color.ECOMMERCE.CINZA,
          color: Color.ECOMMERCE.WHITE,
        },
        labelButtonCancel: "Fechar",
        handlerCancel: () => {},
      });
    } else {
      this.cadastrar(novoEndereco);
    }
  }

  validarCadastro() {
    let endereco = this.state.endereco || {};
    let erro = "";

    if (!Utils.isValueValid(endereco.numero))
      erro = "Preencha o campo numero do endereço de casa!";
    if (!Utils.isValueValid(endereco.bairro))
      erro = "Preencha o campo bairro do endereço de casa!";
    if (!Utils.isValueValid(endereco.logradouro))
      erro = "Preencha o campo lougradouro do endereço!";
    if (!Mask.validarCep(endereco.cep)) erro = "Informe um CEP válido!";
    if (!Utils.isValueValid(endereco.descricao))
      erro = "Preencha o campo tipo do endereço!";

    if (Utils.isValueValid(erro)) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label
            value={erro}
            style={{ fontSize: 25, textAlign: "center" }}
            family="Light"
          />
        ),
        handlerConfirm: null,
        handlerCancel: () => {
          this.props.renderAgain();
        },
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return !Utils.isValueValid(erro);
  }

  render() {
    let endereco = this.state.endereco;
    return (
      <Div column rounded style={{ paddingBottom: 70 }}>
        <Label
          value="Informe os seguintes dados para adicionar o endereço"
          family="Light"
          style={{ fontSize: 15 }}
          col="12"
        />
        <Div inline="start">
          <If
            and
            value1={Utils.isValueValid(
              this.state.labelMensagemCepNaoEncontrado
            )}
          >
            <Label
              col="12"
              value={this.state.labelMensagemCepNaoEncontrado}
              style={{ color: Color.ECOMMERCE.LARANJA }}
            />
          </If>
          <Select
            values={this.getNomesEndereco()}
            label="Tipo de endereço"
            valueSelected={endereco.descricao}
            handlerChange={(e) => {
              endereco.descricao = e.target.value;
              this.setState({ endereco });
            }}
            responsive="4"
          />
          <InputCep
            handlerChange={(e) => {
              endereco.cep = e.target.value;
              this.setState({ endereco });
            }}
            handlerBlur={() => {
              if (Mask.validarCep(endereco.cep)) {
                this.setState(
                  {
                    labelMensagemCepNaoEncontrado: "",
                  },
                  () => this.consultarCep(endereco)
                );
              } else {
                let enderecoAlterado = {
                  ...endereco,
                  logradouro: "",
                  bairro: "",
                };
                this.setState({
                  endereco: enderecoAlterado,
                });
              }
            }}
            label="CEP"
            responsive="3"
            name="cep"
            value={endereco.cep || ""}
          />
          <InputDefault
            handlerChange={(e) => {
              endereco.logradouro = e.target.value;
              this.setState({ endereco });
            }}
            label="Logradouro"
            responsive="4"
            disabled={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
            name="logradouro"
            value={endereco.logradouro || ""}
          />
          <InputInteger
            handlerChange={(e) => {
              endereco.numero = e.target.value;
              this.setState({ endereco });
            }}
            label="Número"
            responsive="2"
            name="numero"
            value={endereco.numero || ""}
          />
          <InputDefault
            handlerChange={(e) => {
              endereco.bairro = e.target.value;
              this.setState({ endereco });
            }}
            disabled={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
            label="Bairro"
            responsive="3"
            name="bairro"
            value={endereco.bairro || ""}
          />
          <InputDefault
            handlerChange={(e) => {
              endereco.complemento = e.target.value;
              this.setState({ endereco });
            }}
            label="Complemento"
            responsive="3"
            name="complemento"
            value={endereco.complemento || ""}
          />

          <InputDefault
            handlerChange={(e) => {
              endereco.referencia = e.target.value;
              this.setState({ endereco });
            }}
            label="Referência"
            responsive="3"
            name="referencia"
            value={endereco.referencia || ""}
          />
        </Div>
        <Label
          style={{
            backgroundColor: this.props.corPrimaria,
            color: "white",
            padding: 15,
            fontSize: 15,
            width: 230,
            borderRadius: 10,
            textAlign: "center",
          }}
          pointer
          family="Light"
          value={this.props.labelBotaoConfirmar}
          icon={Type.ICON.REFRESH}
          handlerClick={() => {
            if (this.validarCadastro()) {
              this.adicionarNovoEndereco();
            }
          }}
        />
      </Div>
    );
  }
}

export default NovoEnderecoCheckoutMobile;
