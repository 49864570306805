import React from "react";
import PropTypes from "prop-types";

import {
  display,
  margin,
  padding,
  offset,
  colorText,
  pointer,
  className,
  backGround,
  rounded,
  sizeIcon,
  underline
} from "../css";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(props.icon ? `${props.icon} ` : "");
  classe = classe.concat(sizeIcon(props));
  classe = classe.concat(colorText(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(display(props));
  classe = classe.concat(rounded(props));
  classe = classe.concat(offset(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(underline(props));
  classe = classe.concat(className(props));

  return classe;
};

const I = props => {
  return (
    <i
      className={getClassDefault(props)}
      onClick={props.handlerClick}
      data-toggle={props.toggle}
      data-dismiss={props.dismiss}
      data-target={props.target}
      data-tip={props.tip}
      draggable={props.draggable}
      title={props.title}
      id={props.id}
      style={props.style}
    >
      {props.value || props.children}
    </i>
  );
};

I.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  body: PropTypes.string
};

I.defaultProps = {
  sizeIcon: 1
};

export default I;
