import React from "react";
import { Div, I } from "..";
import { Type } from "../../utilities";
import "./image.css";
import { sizeIcon } from "../css";

import Magnifier from "react-magnifier";

const Image = (props) => {
  return (
    <Div
      //className={getClassContainer(props)}
      handlerClick={props.handlerClick}
      style={props.style}
      handleMouseEnter={props.handleMouseEnter}
    >
      {/**
     *       <img
        className={getClasseDeafult(props)}
        src={props.src}
        width={props.width}
        height={props.height}
        alt={props.alt}
        id={props.id}
        data-toggle={props.toggle}
        data-target={props.target}
        data-slide-to={props.dataSlideTo}
        style={props.styleImage}
        onError={props.onError}
      />
     */}
      <Magnifier
        src={props.src}
        width={props.width}
        height={props.height}
        // className={getClasseDeafult(props)}
        className="bg-transparent"
      />
      {props.close && (
        <I
          icon={Type.ICON.CLOSE}
          size={sizeIcon(props)}
          className={`${props.classNameIconClose} position-absolute translatex10 pointer`}
          handlerClick={(e) => props.handlerClose(props.data)}
        ></I>
      )}
    </Div>
  );
};

export default Image;
