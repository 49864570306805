import React, { Component } from "react";
import Div from "../layout/div/Div";

class Header extends Component {
  
  render() {
    return <Div className="bg-white" style={this.props.style}>{this.props.children}</Div>;
  }
}

export default Header;
