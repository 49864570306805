import { SET_EMPRESA } from "../actions/ActionTypes";

const initialState = {
  empresa: {},
};

export const empresaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPRESA:
      return {
        ...state,
        empresa: action.empresa,
      };
    default:
      return state;
  }
};
