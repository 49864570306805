import API from "./api";
import { Utils, Response } from "..";

export default class Http {
  static connection() {
    Http.get("/connection");
  }

  static get(url) {
    return Http.execute(url, "get");
  }

  static post(url, object) {
    return Http.execute(url, "post", object);
  }

  static postFormData(url, object) {
    let headers = {
      "content-type": "multipart/form-data",
    };
    return Http.execute(url, "post", object, headers);
  }

  static delete(url, object) {
    return Http.execute(url, "delete", object);
  }

  static put(url, object) {
    return Http.execute(url, "put", object);
  }

  static getDataInstanceHttp = (url, method, object, headers, responseType) => {
    let data = {
      method: method,
      url: url,
      data: object,
      responseType,
    };

    if (Utils.isValueValid(headers)) {
      data.headers = headers;
    }

    return data;
  };

  static async execute(url, method, object, headers, responseType) {
    let instance = await API;
    let response = new Response();

    await instance(
      Http.getDataInstanceHttp(url, method, object, headers, responseType)
    )
      .then((res) => {
        response.data = res.data;
        response.status = Utils.isValueValid(res.data) && (res.data !== undefined);
      })
      .catch(async (error) => {
        response.status = false;

        if (error.response) {
          let msgErro = null;
          if (error.response.data.errors) {
            msgErro = `Status: ${error.response.status} [${error.response.data.errors[0].defaultMessage}]`;
            response.message = error.response.data.errors[0].defaultMessage;
          } else if (error.response.data.error) {
            msgErro = `Status: ${error.response.status} [${error.response.data.error_description}]`;
            response.message = error.response.data.error_description;
          } else {
            msgErro = `Status: ${error.response.status} [${error.response.data.message}]`;
          }

          if (Utils.isValueValid(msgErro)) {
            console.log(msgErro);
          }
        } else if (error.request) {
          response.message = "Falha na conexão, verifique o servidor";
        } else {
          response.message = error.message;
          console.log("Error", error.message);
        }
      });

    return response;
  }
}
