import React, { useEffect, useState } from "react";
import { Div } from "..";
import { Color } from "../../utilities";

import "./checkbutton.css";

const Checkbutton = ({ title, children, Icon, corPrimaria, checked, style, onClick}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <Div
      className="check-button"
      style={{
        backgroundColor: isChecked ? corPrimaria : "#FFF",
        color: isChecked ? "#FFF" : Color.ECOMMERCE.CINZA_ESCURO,
        ...style
      }}
      handlerClick={onClick}
    >
      {Icon ? Icon : null }
      {children}
      <span>{title}</span>
    </Div>
  );
};

export default Checkbutton;
