import IconDestaqueLaranja from "../../assets/images/departamentos/destaques-laranja.png";
import IconDestaqueCinza from "../../assets/images/departamentos/destaques-cinza.png";

import IconBebidasCinza from "../../assets/images/departamentos/bebidas-cinza.png";
import IconBebidasLaranja from "../../assets/images/departamentos/bebidas-laranja.png";

import IconLimpezaCinza from "../../assets/images/departamentos/limpeza-cinza.png";
import IconLimpezaLaranja from "../../assets/images/departamentos/limpeza-laranja.png";

import IconHigieneCinza from "../../assets/images/departamentos/higiene-cinza.png";
import IconHigieneLaranja from "../../assets/images/departamentos/higiene-laranja.png";

import IconNaturebaLaranja from "../../assets/images/departamentos/natureba-laranja.png";
import IconNaturebaCinza from "../../assets/images/departamentos/natureba-cinza.png";

import IconGuloseimasLaranja from "../../assets/images/departamentos/guloseimas-laranja.png";
import IconGuloseimasCinza from "../../assets/images/departamentos/guloseimas-cinza.png";

import IconAlimentoLaranja from "../../assets/images/departamentos/alimentos-laranja.png";
import IconAlimentoCinza from "../../assets/images/departamentos/alimentos-cinza.png";

import IconPetshopLaranja from "../../assets/images/departamentos/pets-laranja.png";
import IconPetshopCinza from "../../assets/images/departamentos/pets-cinza.png";

import IconBebesLaranja from "../../assets/images/departamentos/bebes-laranja.png";
import IconBebesCinza from "../../assets/images/departamentos/bebes-cinza.png";

import IconEmpresasCinza from "../../assets/images/departamentos/empresas-cinza.png";
import IconEmpresasLaranja from "../../assets/images/departamentos/empresas-laranja.png";

import IconUtilidadesCinza from "../../assets/images/departamentos/utilidades-cinza.png";
import IconUtilidadesLaranja from "../../assets/images/departamentos/utilidades-laranja.png";

import IconAcougueCinza from "../../assets/images/departamentos/acougue-cinza.png";
import IconAcougueLaranja from "../../assets/images/departamentos/acougue-laranja.png";

import IconPereciveisCinza from "../../assets/images/departamentos/pereciveis-cinza.png";
import IconPereciveisLaranja from "../../assets/images/departamentos/pereciveis-laranja.png";

import IconMatinaisCinza from "../../assets/images/departamentos/matinais-cinza.png";
import IconMatinaisLaranja from "../../assets/images/departamentos/matinais-laranja.png";

export default class IconsDepartamento {
  static getIcon(departamento) {
    let depto = String(departamento).toUpperCase();
    switch (depto) {
      case "LIMPEZA":
        return IconLimpezaCinza;
      case "BEBIDAS":
        return IconBebidasCinza;
      case "PETS":
        return IconPetshopCinza;
      case "HORTFRUTI":
      case "ALIMENTO":
      case "ALIMENTOS":
        return IconAlimentoCinza;
      case "NATUREBA":
        return IconNaturebaCinza;
      case "GOLUSEIMAS E SALGADINHOS":
        return IconGuloseimasCinza;
      case "DESTAQUES":
        return IconDestaqueCinza;
      case "HIGIENE":
      case "HIGIENE E BELEZA":
        return IconHigieneCinza;
      case "CARNES":
      case "AÇOUGUE":
      case "AÇOUGUE  E HORTIFRUTI":
        return IconAcougueCinza;
      case "BEBÊS":
      case "BEBES":
        return IconBebesCinza;
      case "PERECIVEIS":
        return IconPereciveisCinza;
      case "EMPRESAS":
        return IconEmpresasCinza;
      case "UTILIDADES":
      case "CASA E UTILIDADES":
        return IconUtilidadesCinza;
      case "MATINAIS":
        return IconMatinaisCinza;
      default:
        return IconDestaqueCinza;
    }
  }

  static getIconLaranja(departamento) {
    let depto = String(departamento).toUpperCase();
    switch (depto) {
      case "LIMPEZA":
        return IconLimpezaLaranja;
      case "BEBIDAS":
        return IconBebidasLaranja;
      case "PETS":
        return IconPetshopLaranja;
      case "HORTFRUTI":
      case "ALIMENTO":
        return IconAlimentoLaranja;
      case "NATUREBA":
        return IconNaturebaLaranja;
      case "GULOSEIMAS":
      case "GOLUSEIMAS E SALGADINHOS":
        return IconGuloseimasLaranja;
      case "DESTAQUES":
        return IconDestaqueLaranja;
      case "HIGIENE":
      case "HIGIENE E BELEZA":
        return IconHigieneLaranja;
      case "AÇOUGUE":
      case "AÇOUGUE  E HORTIFRUTI":
        return IconAcougueLaranja;
      case "BEBÊS":
      case "BEBES":
        return IconBebesLaranja;
      case "EMPRESAS":
        return IconEmpresasLaranja;
      case "UTILIDADES":
      case "CASA E UTILIDADES":
        return IconUtilidadesLaranja;
      case "PERECIVEIS":
        return IconPereciveisLaranja;
      case "MATINAIS":
        return IconMatinaisLaranja;
      default:
        return IconDestaqueLaranja;
    }
  }
}
