import React, { Component } from "react";
import moment from "moment";
import {
  Div,
  Table,
  InputSearch,
  Title,
  InputDefault,
  Select,
  SelectValue,
} from "../../components";
import { connect } from "react-redux";
import { Color, DateUtils, Type,  } from "../../utilities";
import "./orcamento.css";
import ProdutoCataloService from "../../service/ProdutoCatalogoService";
import OrcamentoService from "../../service/OrcamentoService";

const ENTER = 13;

class OrcamentoDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inicio: this.addDiasDataHojeBr(-30),
      fim: this.addDiasDataHojeBr(0),
      buscaCatalogo: Type.ORCAMENTO.TODOS,
      numeroPagina: 1,
      orcamento: [],
      posicaoValues: [
        new SelectValue(Type.ORCAMENTO.TODOS, "Todos", true),
        new SelectValue(Type.ORCAMENTO.ABERTO, "Em Aberto", false),
        new SelectValue(Type.ORCAMENTO.SEPARACAO, "Em Separação", false),
        new SelectValue(Type.ORCAMENTO.CANCELADO, "Cancelado", false),
        new SelectValue(Type.ORCAMENTO.CONCLUIDO, "Finalizado", false)
      ],
      serchText: "",
      depSelecionado: 0,
      loading: false
    };
  }

  UNSAFE_componentWillMount() {
    this.getOrcamentos();
  }

  componentDidUpdate() {
    if (this.state.loading) {
      this.getOrcamentos();
    }
  }

  addDiasDataHojeBr(days) {

    // Date.prototype.addDays = function (days) {
    //   const date = new Date(this.valueOf());
    //   date.setDate(date.getDate() + days);
    //   return date;
    // };

    let date = new Date();
    let newDate = DateUtils.addDays(date, days)
    return moment(newDate).format("YYYY-MM-DD");
  }


  getOrcamentos(descricao = "", posicao = this.state.buscaCatalogo) {
    let obj = {
      descricao,
      posicao,
      dataInicial: this.state.inicio,
      dataFinal: this.state.fim,
  //    diasLancamento: "1"
    }

    OrcamentoService.consultarOrcamentosFiltro(obj).then(data => {
      if (data) {
        this.setState({
          orcamento: data,
          loading: false,
          depSelecionado: 0,
          multiSelectSec: []
        })
      }
    })
  }

  editarOrcamento(orcamento) {
    this.props.history.push({
      pathname: `/admin/pedidos/${orcamento.idlancamentosOrcamento}`,
      state: { orcamento, edit: true },
    });
  }


  handleScroll = (e) => {
    let element = e.target;
    const { numeroPagina, orcamento, buscaCatalogo, depSelecionado, objSecao } = this.state
    objSecao.numeroPagina = numeroPagina + 1

    let obj = depSelecionado === 0 ? this.filtroProdutos(numeroPagina + 1, buscaCatalogo) : objSecao

    if (element.scrollHeight - element.scrollTop - element.clientHeight <= 1) {
      ProdutoCataloService.consultarProdutosFiltro(obj).then(data => {
        if (data) {
          this.setState({
            numeroPagina: numeroPagina + 1,
            orcamento: [
              ...orcamento,
              ...data
            ]
          })
        }
      })
    }
  };

  handlerData(value, type) {
    this.setState({
      [type]: value
    })
  }

  render() {
    const {
      inicio,
      fim,
      orcamento,
    } = this.state;

    return (
      <Div
        margin="3"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
        style={{
          overflow: "hidden",
          minHeight: "82vh",
          overflowY: "auto"
        }}
      //handlerScroll={(e) => this.handleScroll(e)}
      >
        <Title
          value="Pedidos"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        

        <Div col="12" inline="between">
          <Div responsive="6">
            <InputSearch
              // handlerChange={(e) =>
              //   this.buscarProduto(e.target.value)
              // }
              handlerBlur={(e) => this.getOrcamentos(e.target.value)}
              handlerIcon={(e) => this.getOrcamentos(e.target.value)}
              handlerKeyUp={(e) => {
                if (e.keyCode === ENTER) {
                  this.getOrcamentos(e.target.value)
                }
              }}
              bgIcon={Color.NODE.BEGE}
              colorText={Color.NODE.WHITE}
              label="Pesquise por cliente, código ou id"
            />
          </Div>
          <Div responsive="6" style={{ display: "flex" }} padding="0">
            <InputDefault
              //disabled={assinaturaAtiva}
              style={{ color: "#878787" }}
              label="Início"
              required
              responsive="6"
              date="date"
              datetime="datetime"
              className="unstyled"
              max="1979-12-31"
              value={inicio}
              //style={{ width: width }}
              handlerChange={(e) => {
                this.setState({ inicio: e.target.value, loading: true })
              }}
            />

            <InputDefault
              //disabled={assinaturaAtiva}
              label="Fim"
              style={{ color: "#878787" }}
              required
              responsive="6"
              date="date"
              datetime="datetime"
              className="unstyled"
              max="1979-12-31"
              value={fim}
              //style={{ width: width }}
              handlerChange={(e) => {
                this.setState({ fim: e.target.value, loading: true })
              }}
            />
          </Div>

          <Div responsive="12">
            <Select
              required
              responsive="4"
              label="Situação"
              paddingLeft="0"
              handlerChange={
                (e) =>
                  this.setState(
                    { buscaCatalogo: e.target.value, loading: true },
                  )
              }
              values={this.state.posicaoValues}
              valueSelected={this.state.buscaCatalogo}
            />
          </Div>
        </Div>

        <Div
          padding="2"

          style={{
            maxHeight: "53vh",
            overflow: "scroll",
            paddingTop: "0px !important"
          }}
        //  handlerScroll={(e) => this.handleScroll(e)}
        >
          <Table
            //rota={"produtos/cadastrar"}
            columns={[
              { value: "Referência", align: "center" },
              { value: "Cliente", align: "left" },
              { value: "Telefone", align: "left" },
              { value: "CPF", align: "left" },
              { value: "Email", align: "center" },
            ]}
            fields={[
              {
                value: "referencia",
                type: Type.DADO.STRING,
                align: "center",
                col: 2,
              },
              {
                value: "nomeCliente",
                type: Type.DADO.STRING,
                align: "center",
                col: 2,
              },
              {
                value: "telefoneCliente",
                type: Type.DADO.STRING,
                align: "left",
                col: 3,
              },
              {
                value: "cpfCnpj",
                type: Type.DADO.STRING,
                align: "left",
                col: 2,
              },
              {
                value: "emailCliente",
                type: Type.DADO.STRING,
                align: "left",
                col: 2,
              },
            ]}
            bordered
            orientation="center"
            data={orcamento}
            iconEdit={Type.ICON.EDIT}
            handlerEdit={(orc) => this.editarOrcamento(orc)}
            edit
            error="Nenhum pedido encontrado"

          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
});

export default connect(mapStateToProps)(OrcamentoDashboard);
