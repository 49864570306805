import { DateUtils } from "../../utilities";

export const getDiasMes = (ano, mes) => {
  let date = new Date(ano, mes, 1);
  let days = [];
  while (date.getMonth() === mes) {
    days.push(new Date(date).getDate());
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const meses = () => {
  return [
    { value: 0, label: "JAN" },
    { value: 1, label: "FEV" },
    { value: 2, label: "MAR" },
    { value: 3, label: "ABR" },
    { value: 4, label: "MAI" },
    { value: 5, label: "JUN" },
    { value: 6, label: "JUL" },
    { value: 7, label: "AGO" },
    { value: 8, label: "SET" },
    { value: 9, label: "OUT" },
    { value: 10, label: "NOV" },
    { value: 11, label: "DEZ" }
  ];
};

export const diasDaSemana = ["D", "S", "T", "Q", "Q", "S", "S"];

export const getDiaDaSemanaPrimeiroDiaDoMes = (ano, mes) => {
  return new Date(ano, mes, 1).getDay();
};

const getDiaSemana = (ano, mes, dia) => {
  return new Date(ano, mes, dia).getDay();
};

const adicionarDiasMesAnterior = (meses, ano, mes) => {
  let primeiroDiaDoMesNaSemana = getDiaDaSemanaPrimeiroDiaDoMes(ano, mes);
  let quantidade = primeiroDiaDoMesNaSemana;

  let mesAnterior = mes === 0 ? 11 : mes - 1;
  let diasDoMesAnterior = getDiasMes(ano, mesAnterior);

  let quantidadeDiasMesAnterior = diasDoMesAnterior.length;
  let index = 0;

  for (quantidade; quantidade > 0; quantidade--) {
    let dia = diasDoMesAnterior[quantidadeDiasMesAnterior - quantidade];
    meses.push({
      dia,
      //diaSemana: getDiaSemana(anoAtual, mesAnterior, dia),
      diaSemana: index++,
      mes: mesAnterior,
      ano
    });
  }
};

export const getMesAtualDatePicker = () => {
  let mesDatePicker = [];

  let mesAtual = new Date().getMonth();
  let anoAtual = new Date().getFullYear();
  let diasDoMes = getDiasMes(anoAtual, mesAtual);

  adicionarDiasMesAnterior(mesDatePicker, anoAtual, mesAtual);

  diasDoMes.forEach(dia => {
    mesDatePicker.push({
      dia,
      diaSemana: getDiaSemana(anoAtual, mesAtual, dia),
      mes: mesAtual,
      ano: anoAtual
    });
  });

  return {
    ano: anoAtual,
    mes: { value: mesAtual, label: DateUtils.getMesAtualLabel(++mesAtual) },
    dias: mesDatePicker
  };
};

export const getMesDatePicker = (ano, mes) => {
  let mesDatePicker = [];
  let diasDoMes = getDiasMes(ano, mes);

  adicionarDiasMesAnterior(mesDatePicker, ano, mes);

  diasDoMes.forEach(diaMes => {
    mesDatePicker.push({
      dia: diaMes,
      diaSemana: getDiaSemana(ano, mes, diaMes),
      mes,
      ano
    });
  });

  return {
    ano,
    mes: { value: mes, label: DateUtils.getMesAtualLabel(++mes) },
    dias: mesDatePicker
  };
};

export const getDiasMesAnterior = (quantidade, ano, mes) => {
  let mesDatePicker = [];
  let diasDoMes = getDiasMes(ano, mes);

  let mesAnterior = mes === 0 ? 11 : mes - 1;
  let diasDoMesAnterior = getDiasMes(ano, mesAnterior);

  let quantidaDiasMesAnterior = diasDoMesAnterior.length;

  for (quantidade; quantidade > 0; quantidade--) {
    let dia = diasDoMesAnterior[quantidaDiasMesAnterior - quantidade];
    mesDatePicker.push({
      dia,
      diaSemana: getDiaSemana(ano, mesAnterior, dia),
      mes: mesAnterior,
      ano
    });
  }

  diasDoMes.forEach(diaMes => {
    mesDatePicker.push({
      dia: diaMes,
      diaSemana: getDiaSemana(ano, mes, diaMes),
      mes,
      ano
    });
  });

  return {
    ano,
    mes: { value: mes, label: DateUtils.getMesAtualLabel(++mes) },
    dias: mesDatePicker
  };
};

export const getDiasMesPosterior = (quantidade, ano, mes) => {
  let mesDatePicker = [];
  let diasDoMes = getDiasMes(ano, mes);

  let mesPosterior = mes === 11 ? 0 : mes + 1;
  let diasDoMesPosterior = getDiasMes(ano, mesPosterior);

  let index = 0;

  for (index; index < quantidade; index++) {
    let dia = diasDoMesPosterior[index];
    mesDatePicker.push({
      dia,
      diaSemana: getDiaSemana(ano, mesPosterior, dia),
      mes: mesPosterior,
      ano
    });
  }

  diasDoMes.forEach(diaMes => {
    mesDatePicker.push({
      dia: diaMes,
      diaSemana: getDiaSemana(ano, mes, diaMes),
      mes,
      ano
    });
  });

  return {
    ano,
    mes: { value: mes, label: DateUtils.getMesAtualLabel(++mes) },
    dias: mesDatePicker
  };
};
