import React, { Component } from "react";
import moment from "moment";
import {
  Div,
  Image,
  Label,
  I,
  Checkbox,
  InputGroup,
  If,
  InputDefault,
  ModalNotification,
  SelectValue,
  Select,
  Row,
  TextArea,
} from "../../../../components";
import { Color, Http, Type, Utils, Mask, Routes } from "../../../../utilities";
import CarrinhoUtils from "../../../utils/CarrinhoUtils";
import UsuarioUtils from "../../../utils/UsuarioUtils";
import ProdutoUtils from "../../ProdutoUtils";
import { setListasDeCompra } from "../../../../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssinaturaService from "../../../../service/AssinaturaService";
import ContainerAssinaturas from "../ContainerAssinaturas";
import ListasComprasSugeridasMobile from "./ListasComprasSugeridasMobile";

const styles = {
  lista: {
    height: 50,
    border: `1px solid ${Color.ECOMMERCE.CINZA}`,
    margin: 5,
    color: Color.ECOMMERCE.CINZA,
  },
  buttonSelect: {
    padding: "10px",
    background: Color.EMPRESA.PRIMARIA,
    color: "#FFF",
    fontWeight: "bolder",
    textAlign: "center",
    minWidth: "135px",
  },
  button: {
    padding: "10px",
    background: "#FFF",
    color: Color.EMPRESA.PRIMARIA,
    border: `1px solid ${Color.EMPRESA.PRIMARIA}`,
    fontWeight: "bolder",
    textAlign: "center",
    minWidth: "135px",
  },
  menuDrop: {
    width: "100%",
  },
  menuDropAtivo: {
    backgroundColor: "var(--cor-primaria)",
    color: "#fff",
  },
  assinaturaAtiva: {
    backgroundColor: Color.EMPRESA.PRIMARIA,
    color: Color.ECOMMERCE.WHITE,
  },
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
    paddingLeft: 5,
    paddingRight: 5,
  },
  labelSubTotalItem: {
    fontSize: 18,
    color: Color.ECOMMERCE.LARANJA,
    marginTop: 10,
  },
  textoRiscado: {
    textDecoration: "line-through",
  },
};

class ListaCompraClienteMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.location.state,
      listas: [],
      listaSelecionada: {},
      showResultadosPesquisa: false,
      textoPesquisa: null,
      produtosPesquisa: [],
      produtosSelecionadosPesquisa: new Map(),
      sucessoPesquisa: true,
      nomeNovaLista: null,
      listaAlterada: false,
      mensagemConsultaLista: "",
      assinaturaLista: "Assinar lista",
      assinaturaListaModal: "Assinar lista",
      assinaturaAtivaModal: false,
      isBoxAssinatura: false,
      assinaturaAtiva: false,
      paramentrosAssinatura: {},
    };
  }

  componentDidMount() {
    this.consultarListasDeCompra();
    Utils.ativarScroolTelaPrincipal();
    this.getParamentros();
  }

  getParamentros() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    AssinaturaService.getParamentros(cliente.idCliente).then(({ data, status }) => {
      if (status) {
        this.setState({
          paramentrosAssinatura: data
        })
      }
    })
  }

  getListaCompraClienteAssinatura() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    AssinaturaService.getListaDeCompraCliente(cliente.idCliente).then( data => {
      console.log(data)
      if(data) {
        this.setState({
          listaCompraAssinatura: data,
          listas: data,
        })
      }
    })
  }

  consultarListasDeCompra() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    Http.get(`listacompra/cliente/${cliente.idCliente}`)
      .then(({ status, data }) => {
        if (status) {
          Array.from(data).forEach((lista) => {
            Array.from(lista.itens).forEach((p) => {
              p.quantidade = ProdutoUtils.getQuantidadeItemView(p);
              p = ProdutoUtils.calcularValorProduto(p, p.quantidade);
            });
          });

          let listaSelecionada = Array.from(data).filter(
            (lista) =>
              lista.idListaCompra === this.state.listaSelecionada.idListaCompra
          )[0];

          this.setState(
            {
              listas: data,
              listaSelecionada: listaSelecionada || data[0],
              nomeNovaLista: "",
            },
            () => () => this.props.setListasDeCompra(data)
          );
        } else {
          this.setState({
            listas: [],
            listaSelecionada: {},
            nomeNovaLista: "",
            mensagemConsultaLista: "Nenhuma lista cadastrada",
          });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getQuantidadeLabel(produto) {
    let preco = produto.precoVenda;
    let quantidade = produto.quantidade || 0;
    return produto.possuiQuantidadeFracionada
      ? `${Number(produto.quantidade)}g`
      : `${quantidade}un x ${Mask.formatValueBrSemCifrao(preco)}`;
  }

  atualizarProdutoLista(produto) {
    Http.put("/listacompra/produto", produto)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.forceUpdate();
          } else {
            ModalNotification.renderMessage({
              title: "Ocorreu um erro ao atualizar a lista",
              body: "Falha ao atualizar produto da lista",
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  deletarProdutoLista(produto) {
    console.log(produto);
    Http.delete("/listacompra/produto", produto)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.consultarListasDeCompra();
          } else {
            ModalNotification.renderMessage({
              title: "Ocorreu um erro ao atualizar a lista",
              body: "Falha ao atualizar produto da lista",
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  solicitarRemoverLista(lista) {
    let assinatura = lista.assinatura
    let data = ""
    
    if (assinatura) {
      data = moment(assinatura.dataPrimeiroPedido).format("DD/MM/YYYY");
    }

    let textoModal = lista.itens.length > 0 ?
    assinatura ?  `Essa lista de compra está assinada desde do dia ${data}, a exclusão da lista de compra cancelará sua assinatura. Deseja continuar?` :
      `Essa lista possui ${lista.itens.length} itens que facilita as suas compras futuras. Deseja continuar?` :
    "Essa lista de compras será removida do sistema, deseja remover a lista"

    ModalNotification.renderMessage({
      title: "Remover Lista de Compras",
      body: (
        <Div col="12">
          <Label style={{ ...styles.labelCinza, marginBottom: "17px" }} value={textoModal} />
          <TextArea
            paddingLeft="0"
            paddingRight="0"
            responsive="12"
            placeHolder="Preencha caso tenha alguma observação do motivo do cancelamento"
            style={{ minHeight: "124px" }}
            handlerChange={(e) => {
              this.setState({
                motivoCancelamento: e.target.value
              })
            }}
          />
        </Div>
      ),
      handlerCancel: () => { },
      handlerConfirm: () => this.deletarListaCompra(lista),
    });
  }

  deletarListaCompra(lista) {
    let removerLista = {
      idListaCompra: lista.idListaCompra,
      idListaCompraAssinatura: lista.assinatura ? lista.assinatura.idListaCompraAssinatura : null,
      motivoCancelamento: this.state.motivoCancelamento
    }
    Http.delete("/assinatura/listacompra", removerLista)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.consultarListasDeCompra();
          } else {
            ModalNotification.renderMessage({
              title: "Ocorreu um erro ao remover a lista",
              body: "Falha ao remover a lista",
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  colocarProdutosNoCarrinho() {
    let itens = JSON.parse(JSON.stringify(this.state.listaSelecionada.itens));
    if (Utils.isValueValid(itens)) {
      Array.from(itens).forEach((item) => {
        CarrinhoUtils.addQuantidadeItemJaNoCarrinhoLista(this.props.carrinho, item);
        CarrinhoUtils.adicionarItemNoCarrinhoDeCompras(
          item,
          this.props.carrinho,
          this.props.atualizarCarrinho,
          null,
          this.state.parametrosPedido
        );
      });
      this.props.history.push(Routes.CARRINHO, {
        parametros: this.state.parametrosPedido,
        logoEmpresa: this.state.urlFotoEmpresa,
        empresa: this.props.empresa,
      });
    } else {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label value="Nenhum produto na lista para colocar no carrinho!" />
        ),
        handlerCancel: () => { },
        labelButtonCancel: "Fechar",
      });
    }
  }

  getButtonUserDropDown() {
    const { assinaturaLista, assinaturaAtiva } = this.state;
    return (
      <Div
        inline="center"
        className="border p-1 m-1"
        id="dropdownMenuButton"
        toggle="dropdown"
        rounded
        pointer
        paddingLeft="3"
        paddingRight="3"
        style={assinaturaAtiva ? styles.assinaturaAtiva : {}}
      //handlerClick={() => alert("EM DESENVOLVIMENTO")}
      >
        <I
          icon={Type.ICON.CALENDAR}
          pointer
          style={{
            fontSize: 25,
            color: assinaturaAtiva ? Color.ECOMMERCE.WHITE : Color.EMPRESA.PRIMARIA,
          }}
        />
        <Label
          family="Light"
          value={assinaturaLista}
          pointer
          marginTop="2"
          marginLeft="2"
        />
      </Div>

    );
  }

  checkedMenuActive(assinatura) {
    const { assinaturaAtivaModal, assinaturaListaModal } = this.state;

    return assinatura === assinaturaListaModal && assinaturaAtivaModal ? styles.menuDropAtivo : styles.menuDrop
  }

  iconCheckedMenuActive(assinatura) {
    const { assinaturaAtivaModal, assinaturaListaModal } = this.state;

    return assinatura === assinaturaListaModal && assinaturaAtivaModal ? "#FFF" : "var(--cor-primaria)"
  }


  solicitarRemocaoProdutoLista(prod) {
    ModalNotification.renderMessage({
      title: "Remover produto da lista",
      body: `Deseja realmente remover o produto ${prod.descricao} da lista`,
      handlerCancel: () => { },
      handlerConfirm: () => this.deletarProdutoLista(prod),
    });
  }

  renderProdutos = (lista) => {
    let { corPrimaria, listaAlterada } = this.state;
    let produtos = Array.from(lista.itens || []);

    let totalItens = produtos
      .map((p) => Number(p.valorSubTotalItem))
      .reduce((a, b) => a + b, 0);

    let idConfig = Utils.uuidString();
    return (
      <Div col="12" padding="2" shadow rounded className="border">
        <Div inline="between">
          <Div inline="between" style={{ width: "100%" }}>
            <Label
              value={`Produtos da lista`}
              style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
              family="SemiBold"
            />
            <I
              icon={Type.ICON.COG}
              style={{ fontSize: 25, color: Color.ECOMMERCE.CINZA_ESCURO }}
              target={`#${idConfig}`}
              toggle="collapse"
              pointer
            />
          </Div>
          <Div
            className="collapse border"
            id={idConfig}
            style={{ width: "100%" }}
            shadow
            rounded
          >
            <Div
              col="12"
              inline="center"
              className="border p-1 mt-1"
              rounded
              pointer
              handlerClick={() => this.colocarProdutosNoCarrinho()}
            >
              <I
                icon={Type.ICON.SALE_CART}
                pointer
                style={{ fontSize: 25, color: corPrimaria }}
              />
              <Label
                family="Light"
                value="Finalizar Pedido"
                pointer
                marginTop="2"
                marginLeft="2"
              />
            </Div>
          </Div>
        </Div>
        <Div inline="between" style={{ width: "100%", marginTop: 10 }}>
          <Div
            inline="center"
            className="border p-1"
            col="6"
            rounded
            pointer
            handlerClick={() => this.renderPesquisarProdutosPasso1()}
          >
            <I
              icon={Type.ICON.PLUS_SQUARE}
              pointer
              style={{ fontSize: 25, color: corPrimaria }}
            />
            <Label
              family="Light"
              value="Adicionar"
              pointer
              marginTop="2"
              marginLeft="2"
            />
          </Div>
          <Div
            inline="center"
            className="border p-1"
            col="6"
            rounded
            pointer
            handlerClick={() =>
              Utils.isValueValid(produtos)
                ? this.atualizarListaCompra()
                : this.solicitarRemoverLista(lista)
            }
            style={{
              backgroundColor: listaAlterada
                ? this.state.corPrimaria
                : "#f2f2f2",
              color: listaAlterada ? "white" : Color.ECOMMERCE.CINZA_ESCURO,
              opacity: 0.9,
            }}
          >
            <I
              icon={
                Utils.isValueValid(produtos)
                  ? Type.ICON.REFRESH
                  : Type.ICON.CLOSEX
              }
              pointer
              style={{ fontSize: 25 }}
            />
            <Label
              family="Light"
              value={Utils.isValueValid(produtos) ? "Atualizar" : "Remover"}
              pointer
              marginTop="2"
              marginLeft="2"
            />
          </Div>
        </Div>
        <Div inline="between">
          <Div style={{ width: "100%" }}>
            <InputDefault
              handlerChange={(e) => {
                this.setState({
                  listaSelecionada: {
                    ...lista,
                    descricao: e.target.value,
                  },
                  listaAlterada: true,
                });
              }}
              label="Nome da lista"
              value={lista.descricao}
            />
          </Div>
        </Div>
        {produtos.map((prod, index) => {
          return (
            <Div key={index}>
              <Div
                inline="between"
                padding="2"
                style={{ borderBottom: "1px solid #f2f2f2" }}
              >
                <Div inline="start">
                  <Image
                    border
                    src={prod.urlImagemPrincipalThumbnail}
                    width="50px"
                  />
                  <Label
                    className="text-ellipsis descricao-item"
                    value={prod.descricao}
                    marginLeft="2"
                    family="Light"
                    style={
                      prod.quantidade > 0
                        ? { width: 180 }
                        : { width: 180, ...styles.textoRiscado }
                    }
                  />
                </Div>
                <Div inline="between" style={styles.containerAddItem}>
                  <I
                    icon={Type.ICON.MINUS}
                    pointer
                    style={{
                      color: corPrimaria,
                      fontSize: 18,
                      marginRight: 10,
                    }}
                    handlerClick={() => {
                      if (prod.quantidade > 0) {
                        this.removeQuantidadeItemLista(lista, prod);

                        if (prod.quantidade === 0) {
                          this.deletarProdutoLista(prod);
                        }
                      }
                    }}
                  />
                  <Label
                    value={this.getQuantidadeLabel(prod)}
                    family="Light"
                    margin="3"
                    className="mx-auto"
                  />
                  <I
                    icon={Type.ICON.PLUS}
                    pointer
                    style={{ color: corPrimaria, fontSize: 20, marginLeft: 10 }}
                    handlerClick={() => {
                      this.addQuantidadeItemLista(lista, prod);
                    }}
                  />
                </Div>
                <Label
                  value={Mask.formatValueBr(Number(prod.valorSubTotalItem))}
                  family="SemiBold"
                  style={{ ...styles.labelSubTotalItem, color: corPrimaria }}
                />
              </Div>
            </Div>
          );
        })}
        <Div
          inline="between"
          style={{ width: "99%", backgroundColor: "#f2f2f2" }}
          margin="2"
          padding="2"
          shadow
          className="border"
          rounded
        >
          <Label
            value="Total da lista"
            style={{
              color: Color.ECOMMERCE.CINZA_ESCURO,

              fontSize: 20,
            }}
            family="Bold"
          />
          <Label
            value={Mask.formatValueBr(Number(totalItens))}
            style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 20 }}
            family="Bold"
          />
        </Div>
      </Div>
    );
  };

  listarProdutosPesquisa() {
    Http.get(`/busca/${this.state.textoPesquisa}`)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              produtosPesquisa: data.listaProdutos,
              sucessoPesquisa: data.sucessoPesquisa,
            },
            () => this.renderPesquisarProdutosPasso1()
          );
        }
      })
      .catch((error) => console.log(error));
  }

  getProdutosSelecionados() {
    let itens = [];
    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );
    produtosSelecionadosPesquisa.forEach((p) => itens.push(p));
    return itens;
  }

  removeQuantidadeItemPesquisa(item, checkbox = false) {
    let quantidade = Number(item.quantidade);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );

    if (quantidade > 0) {
      produtosSelecionadosPesquisa.set(item.codigoBarras, item);
    } else {
      produtosSelecionadosPesquisa.delete(item.codigoBarras);
    }

    this.setState({ produtosSelecionadosPesquisa }, () =>
      checkbox
        ? this.renderPesquisarProdutosPasso1()
        : this.renderPesquisarProdutosPasso2()
    );
  }

  removeQuantidadeItemLista(listaSelecionada, item) {
    let quantidade = Number(item.quantidade);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.setState({ listaSelecionada, listaAlterada: true });
  }

  addQuantidadeInicialPesquisa(item, checkbox = false) {
    let quantidade = Number(item.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = Number(item.fatorEmGrama);
    } else {
      quantidade = 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );
    produtosSelecionadosPesquisa.set(item.codigoBarras, item);

    this.setState({ produtosSelecionadosPesquisa }, () =>
      checkbox
        ? this.renderPesquisarProdutosPasso1()
        : this.renderPesquisarProdutosPasso2()
    );
  }

  addQuantidadeItemLista(listaSelecionada, item) {
    let quantidade = Number(item.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.setState({ listaSelecionada, listaAlterada: true });
  }

  addQuantidadeItemPesquisa(item) {
    let quantidade = Number(item.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );
    produtosSelecionadosPesquisa.set(item.codigoBarras, item);

    this.setState({ produtosSelecionadosPesquisa }, () =>
      this.renderPesquisarProdutosPasso2()
    );
  }

  getListaCompraAtualizacao() {
    let { produtosSelecionadosPesquisa, listaSelecionada } = this.state;
    let itensLista = Array.from(listaSelecionada.itens);

    produtosSelecionadosPesquisa = new Map(produtosSelecionadosPesquisa);

    itensLista.forEach((item) => {
      item.preco = Number(item.valorSubTotalItem);
      item.quantidade = ProdutoUtils.getQuantidadeItem(item);
    });

    if (produtosSelecionadosPesquisa.size > 0) {
      produtosSelecionadosPesquisa.forEach((produto) => {
        let itemNaLista = itensLista.filter(
          (i) => i.codigoBarras === produto.codigoBarras
        )[0];
        if (!Utils.isValueValid(itemNaLista)) {
          itensLista.push({
            idListaCompraProduto: Utils.uuidv4(),
            idListaCompra: listaSelecionada.idListaCompra,
            idProdutoWeb: produto.idProdutoWeb,
            preco: Number(produto.valorSubTotalItem),
            quantidade: ProdutoUtils.getQuantidadeItem(produto),
            descricao: produto.descricao,
            ordem: 1,
          });
        }
      });
    }

    listaSelecionada.itens = itensLista;

    return listaSelecionada;
  }

  atualizarListaCompra(pesquisa = false) {
    Http.put("/listacompra", this.getListaCompraAtualizacao())
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.setState(
              {
                produtosSelecionadosPesquisa: new Map(),
                produtosPesquisa: [],
                listaAlterada: false,
              },
              () => this.consultarListasDeCompra()
            );
            if (!pesquisa) {
              ModalNotification.renderMessage({
                title: "Mensagem",
                body: (
                  <Label value="Lista de compras atualizada com sucesso!" />
                ),
                handlerCancel: () => { },
                labelButtonCancel: "Fechar",
              });
            }
          } else {
            ModalNotification.renderMessage({
              title: "Falha ao atualizar lista de compras",
              body: <Label value={data.mensagem} />,
              handlerCancel: () => { },
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }

  cadastrarNovaListaCompra() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    let listaParam = {
      idListaCompra: Utils.uuidv4(),
      descricao: this.state.nomeNovaLista,
      tipo: "CLIENTE",
      idCliente: cliente.idCliente,
      inicioVigencia: new Date().getTime(),
      fimVigencia: new Date().getTime(),
      ativo: true,
      ordem: 1,
      preco: 0,
      precoOferta: 0,
    };

    Http.post("/listacompra", listaParam)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.consultarListasDeCompra();
            ModalNotification.renderMessage({
              title: "Mensagem",
              body: data.mensagem,
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          } else {
            ModalNotification.renderMessage({
              title: "Erro ao cadastrar lista",
              body: data.mensagem,
              handlerCancel: () => { },
              labelButtonCancel: "Fechar",
            });
          }
        } else {
          console.log("Falha ao cadastrar lista de compra");
        }
      })
      .catch((erro) => console.log(erro));
  }

  renderCadastrarNovaLista() {
    ModalNotification.renderMessage({
      title: "Nova lista de compras",
      styleTitle: { fontSize: 15 },
      responsive: "8",
      body: (
        <Div
          className="d-flex align-items-start justify-content-start align-self-start"
          style={{ width: "100%" }}
        >
          <Div col="12">
            <InputDefault
              handlerChange={(e) => {
                this.setState({ nomeNovaLista: e.target.value }, () =>
                  this.renderCadastrarNovaLista()
                );
              }}
              label="Informe o nome da lista"
              placeholder="Nome"
              value={this.state.nomeNovaLista}
              maxLength="200"
            />
          </Div>
        </Div>
      ),
      handlerConfirm: () => this.cadastrarNovaListaCompra(),
      handlerCancel: () => this.setState({ nomeNovaLista: "" }),
    });
  }

  renderPesquisarProdutosPasso2() {
    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );

    ModalNotification.renderMessage({
      title: "Passo 2: Informe as quantidades",
      labelButtonCancelar: "Voltar",
      responsive: "11",
      styleTitle: { fontSize: 15 },
      body: (
        <Div
          className="d-flex align-items-start justify-content-start align-self-start"
          style={{ width: "100%", height: window.innerHeight - 200 }}
        >
          <Div
            style={{ height: window.innerHeight / 1.8, overflow: "auto" }}
            column
          >
            <Label
              value="Produtos selecionados"
              family="Light"
              style={{ color: Color.ECOMMERCE.CINZA_ESCURO }}
            />
            <If and value1={produtosSelecionadosPesquisa.size === 0}>
              <Label
                value="Nenhum produto selecionado"
                family="Light"
                style={{ color: this.state.corPrimaria }}
              />
            </If>
            <If and value1={produtosSelecionadosPesquisa.size > 0}>
              {this.getProdutosSelecionados().map((produto, index) => {
                return (
                  <Div
                    inline="between"
                    key={index}
                    className="border-bottom p-1"
                  >
                    <Div inline="start" style={{ width: "100%" }}>
                      <Div inline="center">
                        <Image
                          styleImage={{ width: 50, height: 50 }}
                          src={produto.urlImagemPrincipalThumbnail}
                        />
                        <Label
                          family="Light"
                          value={produto.descricao}
                          className="text-ellipsis"
                          style={{ width: 150 }}
                        />
                      </Div>
                      <Div inline="center" style={styles.containerAddItem}>
                        <I
                          icon={Type.ICON.MINUS}
                          pointer
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 20,
                            marginRight: 10,
                          }}
                          handlerClick={() => {
                            if (
                              Utils.isValueValid(produto.quantidade) &&
                              produto.quantidade > 0
                            ) {
                              this.removeQuantidadeItemPesquisa(produto);
                            }
                          }}
                        />
                        <Label
                          value={this.getQuantidadeLabel(produto)}
                          family="Light"
                          margin="3"
                          className="mx-auto"
                        />
                        <I
                          icon={Type.ICON.PLUS}
                          pointer
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 20,
                            marginLeft: 10,
                          }}
                          handlerClick={() => {
                            this.addQuantidadeItemPesquisa(produto);
                          }}
                        />
                      </Div>
                      <Div col="2">
                        <Label
                          family="Light"
                          marginLeft="2"
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 15,
                          }}
                          value={Mask.formatValueBr(produto.valorSubTotalItem)}
                        />
                      </Div>
                    </Div>
                  </Div>
                );
              })}
            </If>
          </Div>
        </Div>
      ),
      handlerConfirm: () => {
        if (produtosSelecionadosPesquisa.size > 0) {
          this.atualizarListaCompra();
        } else {
          this.setState({
            produtosPesquisa: [],
            textoPesquisa: "",
          });
        }
      },
      handlerCancel: () => {
        this.renderPesquisarProdutosPasso1();
      },
    });
  }

  renderPesquisarProdutosPasso1() {
    let produtos = this.state.produtosPesquisa;
    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );

    ModalNotification.renderMessage({
      title: "Passo 1: Selecione os produtos",
      labelButtonConfirm: "Continuar",
      responsive: "11",
      verticalStart: true,
      styleTitle: {
        fontSize: 15,
      },
      body: (
        <Div
          className="d-flex align-items-start justify-content-start align-self-start"
          style={{ width: "100%", overflow: "auto" }}
        >
          <Div>
            <Label>
              {`Pesquise produtos para adicionar na lista`}
              <If and value1={produtosSelecionadosPesquisa.size > 0}>
                <Label
                  marginLeft="1"
                  value={`[${produtosSelecionadosPesquisa.size} produtos selecionados]`}
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO }}
                />
              </If>
            </Label>
            <InputGroup
              prepend={false}
              append
              placeholder="Nome do produto"
              value={this.state.textoPesquisa}
              icon={Type.ICON.SEARCH}
              handlerChange={(e) => {
                this.setState(
                  { textoPesquisa: e.target.value, sucessoPesquisa: true },
                  () => this.renderPesquisarProdutosPasso1()
                );
              }}
              handlerBlur={() => this.listarProdutosPesquisa()}
              handlerKeyUp={(e) => {
                if (e.keyCode === 13) this.listarProdutosPesquisa();
              }}
              handlerIcon={() => this.listarProdutosPesquisa()}
            />
            <If and value1={!this.state.sucessoPesquisa}>
              <Label
                value={`Produto não encontrado para a pesquisa: '${this.state.textoPesquisa}'`}
                family="Light"
                style={{ color: this.state.corPrimaria }}
              />
            </If>
            <If and value1={Utils.isValueValid(produtos)}>
              <Div style={{ height: 200, overflow: "auto" }}>
                {Array.from(produtos).map((produto, index) => {
                  return (
                    <Div inline="between" key={index} className="border-bottom">
                      <Div inline="start">
                        <Image
                          styleImage={{ width: 50, height: 50 }}
                          src={produto.urlImagemPrincipalThumbnail}
                        />
                        <Label
                          family="Light"
                          value={produto.descricao}
                          className="text-ellipsis"
                          style={{ width: 200 }}
                        />
                      </Div>
                      <Div inline="between" style={{ width: "100%" }}>
                        <Label
                          family="Light"
                          marginLeft="2"
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 20,
                          }}
                          value={Mask.formatValueBr(produto.precoVenda)}
                        />
                        <Checkbox
                          handlerChange={({ checked }) => {
                            if (checked) {
                              produtosSelecionadosPesquisa.set(
                                produto.codigoBarras,
                                produto
                              );
                            } else {
                              produtosSelecionadosPesquisa.delete(
                                produto.codigoBarras
                              );
                            }
                            this.setState(
                              { produtosSelecionadosPesquisa },
                              () => {
                                if (checked) {
                                  this.addQuantidadeInicialPesquisa(
                                    produto,
                                    true
                                  );
                                } else {
                                  this.renderPesquisarProdutosPasso1();
                                }
                              }
                            );
                          }}
                          checked={Utils.isValueValid(
                            produtosSelecionadosPesquisa.get(
                              produto.codigoBarras
                            )
                          )}
                          marginLeft="3"
                        />
                      </Div>
                    </Div>
                  );
                })}
              </Div>
            </If>
          </Div>
        </Div>
      ),
      handlerConfirm: () => {
        this.renderPesquisarProdutosPasso2();
      },
      handlerCancel: () => {
        this.setState({
          produtosSelecionadosPesquisa: new Map(),
          produtosPesquisa: [],
          textoPesquisa: "",
        });
      },
    });
  }

  getListasSelect = () => {
    let { listas } = this.state;
    let itens = [];

    Array.from(listas).forEach((lista) => {
      itens.push(new SelectValue(lista.idListaCompra, lista.descricao));
    });

    return itens;
  };

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.props.parametros
    );
  }

  render() {
    let { mensagemConsultaLista, listaSelecionada, listas } = this.state;

    let produtos = Array.from(listaSelecionada.itens || []);

    let valorTotal = produtos
      .map((p) => Number(p.valorSubTotalItem))
      .reduce((a, b) => a + b, 0)


    return (
      <Div style={{ width: "100%", marginBottom: 100 }}>
        <Div>
          <Image
            src={this.state.urlFotoEmpresa}
            width="150px"
            style={{ position: "absolute", left: 15, top: 15 }}
            handlerClick={() => this.props.history.replace("/")}
          />
          <Div
            style={{
              color: "white",
              backgroundColor: this.state.corPrimaria,
              padding: 5,
              borderRadius: 5,
              position: "absolute",
              right: 15,
              top: 15,
              width: 100,
              textAlign: "center",
              opacity: 0.9,
            }}
          >
            <I icon={Type.ICON.SIGNOUT} style={{ fontSize: 20 }} />
            <Label
              style={{ fontSize: 20 }}
              pointer
              value="Voltar"
              handlerClick={() => this.props.history.replace("/")}
            />
          </Div>
        </Div>
        <Div col="12" column style={{ position: "absolute", top: 80 }}>
          
        <Div col="12" style={{ display: "flex", justifyContent: "center" }} >
          <ListasComprasSugeridasMobile
            listaCompraCliente={true}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
            empresa={this.props.empresa}
            history={this.props.history}
          />
        </Div>

          <Div style={{ width: "100%" }} shadow className="border" rounded>
            <Div inline="between" className="border-bottom">
              <Label
                value="Minhas listas"
                style={{
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  fontSize: 20,
                  margin: 5,
                }}
                family="Bold"
              />
              <Div
                inline="center"
                rounded
                pointer
                handlerClick={() => this.renderCadastrarNovaLista()}
              >
                <I
                  icon={Type.ICON.PLUS_SQUARE}
                  pointer
                  style={{ fontSize: 25, color: this.state.corPrimaria }}
                />
                <Label
                  family="Light"
                  value="Adicionar"
                  pointer
                  marginTop="2"
                  marginLeft="2"
                />
              </Div>
            </Div>
            <If and value1={!Utils.isValueValid(mensagemConsultaLista)}>
              <Label
                value={mensagemConsultaLista}
                family="Light"
                style={{ color: this.state.corPrimaria }}
              />
            </If>
            <Select
              col="12"
              values={this.getListasSelect()}
              valueSelected={listaSelecionada.idListaCompra}
              handlerChange={(e) => {
                let idListaCompra = e.target.value;
                let listaSelecionada = Array.from(listas).filter(
                  (lista) => lista.idListaCompra === idListaCompra
                )[0];
                this.setState({ listaSelecionada, isBoxAssinatura: false  });
              }}
            />
            {/**
                *  {Array.from(listas).map((item, index) => {
              return (
                <Div
                  pointer
                  key={index}
                  style={
                    listaSelecionada.idListaCompra === item.idListaCompra
                      ? {
                          ...styles.lista,
                          backgroundColor: this.state.corPrimaria,
                          color: "white",
                          opacity: 0.9,
                        }
                      : styles.lista
                  }
                  rounded
                  inline="start"
                  handlerClick={() =>
                    this.setState({
                      listaSelecionada: item,
                    })
                  }
                >
                  <Label
                    pointer
                    value={item.descricao}
                    marginLeft="4"
                    marginTop="2"
                    family="SemiBold"
                  />
                </Div>
              );
            })}
                */}
          </Div>
          
          <Row
            className="border"
            shadow
            col="12"
            style={{
              justifyContent: "space-around",
              margin: "15px 0px",
              padding: "15px",
            }}
          >
            <Div 
              rounded 
              pointer 
              style={ this.state.isBoxAssinatura ? styles.buttonSelect : styles.button}
              handlerClick={
                () => {
                  this.setState({
                    isBoxAssinatura: true,
                  })
                }
              }
            >
              Assinatura
            </Div>
            <Div 
              rounded 
              pointer 
              style={ !this.state.isBoxAssinatura ? styles.buttonSelect : styles.button}
              handlerClick={
                () => {
                  this.setState({
                    isBoxAssinatura: false,
                  })
                }
              }
            >
              Produtos
            </Div>
          </Row>

          <If and value1={Utils.isValueValid(listaSelecionada) && !this.state.isBoxAssinatura}>
            <Div style={{ width: "100%", marginBottom: 100 }}>
              <Div>{this.renderProdutos(listaSelecionada)}</Div>
            </Div>
          </If>
          <If and value1={!Utils.isValueValid(listaSelecionada)}>
            <Div style={{ width: "100%", marginBottom: 100 }}>
              <Div
                shadow
                rounded
                className="border"
                padding="4"
                inline="center"
              >
                <Label
                  value="Clique no botão adicionar para criar uma nova lista"
                  family="Light"
                  style={{ color: this.state.corPrimaria }}
                />
              </Div>
            </Div>
          </If>

          <Div style={{ width: "100%", marginBottom: 100 }}>
            <If and value1={Utils.isValueValid(listaSelecionada) && this.state.isBoxAssinatura}>
              <ContainerAssinaturas 
                ativo={this.state.assinaturaAtiva} 
                lista={this.state.listaSelecionada} 
                mobile={true}
                empresa={this.props.empresa}
                paramentrosAssinatura={this.state.paramentrosAssinatura}
                valorTotal={valorTotal}
              />
            </If>
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setListasDeCompra }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaCompraClienteMobile);
