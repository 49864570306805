import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./App.css";
import { Div, If, Parent } from "./components";
import { EndPoints, Http, Keys, Utils } from "./utilities";

import {
  atualizarCarrinho,
  setProdutos,
  setListasDeCompra,
  setEmpresa,
  setMenus,
  setAnuncios,
  setParametrosPedido,
} from "./redux/actions";
import Rodape from "./views/rodape/Rodape";
import HeaderLojaWeb from "./views/header/HeaderLojaWeb";
import TemplateTodosDepartamentos from "./views/departamentos/TemplateTodosDepartamentos";
import { DepartamentosLojaInstantanea } from "./views";
import PathImage from "./views/utils/PathImage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsuarioUtils from "./views/utils/UsuarioUtils";

function AppWeb(props) {
  const { empresa, children } = props;

  window.outerWidth = 1158;

  const [state, setState] = useState({
    menus: [],
    abrirMenuDepartamentos: false,
    portrait: true,
  });
  const [loading, setLoading] = useState(true);

  function atualizarPaginaNovaVersao() {
    let reloadPage = localStorage.getItem(Keys.KEY_RELOAD_PAGE);
    if (
      !Utils.isValueValid(reloadPage) ||
      String(reloadPage) !== Keys.KEY_VERSION_PAGE
    ) {
      localStorage.setItem(Keys.KEY_RELOAD_PAGE, Keys.KEY_VERSION_PAGE);
      localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB);
      localStorage.removeItem(Keys.KEY_USUARIO_LOJA_WEB);
      window.location.reload(true);
    }
  }

  const consultarAnuncios = async () => {
    try {
      const response = await Http.get("oferta");
      if (response.status) {
        props.setAnuncios(response.data);
        setLoading(false);
      } else {
        console.error("Erro ao consultar anuncios:", response.status);
      }
    } catch (error) {
      console.error("Erro ao consultar anuncios:", error);
    }
  };

  const consultarEmpresa = useCallback(async () => {
    try {
      const response = await Http.get("empresa");
      if (response.status) {
        props.setEmpresa(response.data);
      } else {
        console.error("Erro ao consultar empresa:", response.status);
      }
    } catch (error) {
      console.error("Erro ao consultar empresa:", error);
    }
  }, [props])

  const listarMenus = async () => {
    try {
      const response = await Http.get(EndPoints.MENU);
      if (response.status) {
        props.setMenus(response.data);
      } else {
        console.error("Erro ao listar menus:", response.status);
      }
    } catch (error) {
      console.error("Erro ao listar menus:", error);
    }
  };

  useEffect(() => {
    //setLoading(true);
    const fetchData = async () => {
      await Promise.all([
        consultarEmpresa(),
        listarMenus(),
        consultarAnuncios(),
      ]);
    };

    atualizarPaginaNovaVersao();
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (Utils.isValueValid(props.empresa)) {
  //     consultarAnuncios();
  //   }
  // }, [empresa]);

  return (
    <Parent>
      <ToastContainer />
      {!loading && (
        <>
          <header>
            <If
              and
              value1={props.children.props.showHeader}
              value2={Utils.isValueValid(props.empresa)}
            >
              <HeaderLojaWeb
                empresa={props.empresa}
                menus={props.menus}
                history={props.history}
                handlerToggleDepartamentos={() =>
                  setState({
                    ...state,
                    abrirMenuDepartamentos: !state.abrirMenuDepartamentos,
                  })
                }
                handlerAbrirPesquisa={() => {
                  setState({
                    ...state,
                    abrirMenuDepartamentos: false,
                  });
                }}
              />
              {props.children.props.showDepart && (
                <DepartamentosLojaInstantanea
                  history={props.history}
                  menus={props.menus}
                  empresa={props.empresa}
                  handlerToggleDepartamentos={() =>
                    setState({
                      ...state,
                      abrirMenuDepartamentos: !state.abrirMenuDepartamentos,
                    })
                  }
                />
              )}

              <Div>
                <If and value1={state.abrirMenuDepartamentos}>
                  <Div
                    style={{
                      height: "100%",
                      background: "black",
                      opacity: 0.5,
                      zIndex: 1002,
                      position: "fixed",
                      top: 0,
                      width: "100%",
                    }}
                    handlerClick={() =>
                      setState({
                        ...state,
                        abrirMenuDepartamentos: !state.abrirMenuDepartamentos,
                      })
                    }
                  />
                </If>
                <TemplateTodosDepartamentos
                  menus={props.menus}
                  open={state.abrirMenuDepartamentos}
                  history={props.history}
                  empresa={props.empresa}
                  handlerClose={() =>
                    setState({ abrirMenuDepartamentos: false })
                  }
                />
              </Div>
            </If>
          </header>

          <Div inline="center" style={{ marginTop: "189px" }}>
            {/* <If and value1={IS_LOJA_TESTE}>
          <Div
            style={{
              backgroundColor: "grey",
              color: "white",
              //   width: "100%",
              height: 100,
            }}
            inline="center"
          >
            <Label
              family="Bold"
              style={{ fontSize: 25 }}
              value="Esta é uma loja de demonstração para fins de teste - As compras realizadas não são válidas"
            />
          </Div>
        </If> */}

            {children}
          </Div>

          <If
            and
            value1={props.children.props.showRodape}
            value2={Utils.isValueValid(props.empresa)}
          >
            <Rodape empresa={props.empresa} />
          </If>
        </>
      )}
    </Parent>
  );
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.listasDeCompraState,
    ...store.menusState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      atualizarCarrinho,
      setProdutos,
      setListasDeCompra,
      setEmpresa,
      setMenus,
      setAnuncios,
      setParametrosPedido,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppWeb);
