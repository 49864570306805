import React, { Component } from "react";
import { Utils } from "../../utilities";

import Div from "../layout/div/Div";

import "./carousel.css";
import "./carouselprodutogenerico.css";

class CarouselProdutosGenericoMobile extends Component {
  getClassNameButtonNext() {
    let classe =
      "carousel-control-next icon-next-btn-carousel-produto-generico-mobile";
    return classe;
  }

  render() {
    const ID_CAROUSEL = Utils.uuidString();

    return (
      <Div className="carousel slide" id={ID_CAROUSEL} dataRide="carousel">
        <Div className="carousel-inner">
          {Array.from(this.props.itens).map((item, index) => {
            return (
              <Div
                className={`carousel-item ${item.active ? "active" : ""}`}
                key={index}
              >
                {item.value}
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }
}

export default CarouselProdutosGenericoMobile;
