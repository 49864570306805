import { SET_ENDERECO_ENTREGA } from ".";
import { CARRINHO, REMOVER_ITEM, SET_PEDIDO_FINALIZADO } from "./ActionTypes";

export const atualizarCarrinho = (carrinho) => ({
  type: CARRINHO,
  carrinho,
});

export const removerItemCarrinho = item => ({
  type: REMOVER_ITEM,
  item
})

export const setPedidoFinalizado = carrinho => ({
  type: SET_PEDIDO_FINALIZADO,
  carrinho
})

export const setEnderecoEntrega = carrinho => ({
  type: SET_ENDERECO_ENTREGA,
  carrinho
})