import React from "react";
import { Div, Row, Title } from "..";

import { backGround, className, rounded } from "../css";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(backGround(props));
  classe = classe.concat(rounded(props));
  classe = classe.concat(className(props));
  return classe;
};

const SectionForm = props => {
  return (
    <Div {...props} className={getClassDefault(props)} margin="2" padding="3">
      <Div col="12">
        <Row col="12">
          <Div col="12">
            {props.title && <Title underline type="h3" value={props.title} />}
          </Div>
        </Row>
        {props.children}
      </Div>
    </Div>
  );
};

SectionForm.defaultProps = {
  bg: "white"
};

export default SectionForm;
