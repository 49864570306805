import React, { Component } from "react";
import {
  Div,
  I,
  Label,
  Image,
  ModalNotification,
  If,
} from "../../../../components";

import { Color, EndPoints, Http, Type, Utils } from "../../../../utilities";

import "../../produtos.css";
import { connect } from "react-redux";
import CarouselProdutoGenericoNovo from "../../../../components/carousel/CarouselProdutoGenericoNovo";
import CarouselTagsListasSugeridas from "../../../../components/carousel/CarouselTagsListasSugeridas";

import ListasCombos from "../../../../assets/images/listascombos1.webp";
import ListaCompraSugeridaDetalheMobile from "../mobile/ListaCompraSugeridaDetalheMobile";
import SpaceUtils from "../../../utils/SpaceUtils";
import UsuarioUtils from "../../../utils/UsuarioUtils";
import EmpresaUtils from "../../../utils/EmpresaUtils";
import ProdutoUtils from "../../ProdutoUtils";

const styles = {
  itemListaActive: {
    backgroundColor: Color.ECOMMERCE.WHITE,
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 15,
    padding: 10,
    textAlign: "center",
    borderRadius: 10,
    marginTop: 10,
  },
  itemLista: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 15,
    padding: 10,
    textAlign: "center",
    borderRadius: 10,
    marginTop: 10,
  },
  descricao: {
    marginTop: 5,
    fontSize: "12px",
    lineHeight: "14px",
    height: 25,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
};

class ListasComprasSugeridasMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listasSugeridas: [],
      sugeridas: true,
      minhasListas: false,
      itemActive: "SUGERIDAS",
      listasTags: new Map(),
    };
  }

  componentDidMount() {
    this.listarListasSugeridas();
  }


  listarListasSugeridas() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();

    Http.get(cliente ? `/assinatura/listacompra/sugeridas/cliente/${cliente.idCliente}` : EndPoints.LISTA_COMPRA_SUGERIDAS)
      .then(({ status, data }) => {
        if (status) {
          Array.from(data).forEach((produto) => {
            
            Array.from(produto.itens).forEach((item) => {
              item.quantidadeInicial = Number(item.quantidade);
              item.valorSubTotalItem = Number(item.preco);
              item.quantidade = ProdutoUtils.getQuantidadeItemView(item);
            });
          });

          let listasTags = Utils.groupBy(
            JSON.parse(JSON.stringify(data)),
            "tag"
          );
          this.setState({ listasSugeridas: data, listasTags });
        }
      })
      .catch((erro) => console.log(erro));
  }

  visualizarListaCompra = (lista) => {
    ModalNotification.render(
      "Informações da lista sugerida",
      <ListaCompraSugeridaDetalheMobile
        produto={lista}
        handlerAddItem={(produto) => this.props.handlerAddItemCarrinho(produto)}
        history={this.props.history}
        empresa={this.props.empresa}
      />
    );
  };

  getListaTagSugerida() {
    let { listasSugeridas, sugeridas, itemActive, listasTags } = this.state;
    let listas = new Map(listasTags);

    return sugeridas ? listasSugeridas : listas.get(itemActive);
  }

  getProdutosListasSugeridas = () => {
    let { sugeridas, minhasListas } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);

    if (sugeridas || (!sugeridas && !minhasListas)) {
      let sugeridasTag = this.getListaTagSugerida();
      return Array.from(sugeridasTag).map((lista, index) => {
        let pausado = lista.assinatura ? lista.assinatura.pausado : false

        return (
          <Div
            column
            key={index}
            handlerClick={() => this.visualizarListaCompra(lista)}
            style={{ width: window.innerWidth / 3 }}
          >
            <Image
              src={lista.urlFoto}
              styleImage={{
                width: window.innerWidth / 3,
                height: window.innerWidth / 3,
              }}
              alt="Lista de Compras"
            />
            <If and value1={lista.assinatura}>
              <Label
                value={ !pausado ?  `ASSINATURA ${lista.assinatura ? lista.assinatura.tipo : ""}` : "ASSINATURA PAUSADA"}
                style={{
                  fontSize: 10,
                  color: !pausado ? Color.RGBA.VERDE : Color.ECOMMERCE.LARANJA_ESCUDO,
                  margin: "10px 0px 0px",
                }}
                family="Bold"
              />
            </If>
            <Label
              value={Utils.capitalize(String(lista.descricao).slice(0, 50))}
              style={styles.descricao}
              family="Light"
            />
          </Div>
        );
      });
    } else if (minhasListas) {
      return Array.from(this.props.listasDeCompra).map((lista, index) => {
        return (
          <Div
            className="d-flex flex-column"
            style={{ width: "100%", marginTop: 10 }}
            key={index}
          >
            <Label
              value={Utils.capitalize(lista.descricao)}
              pointer
              style={{
                ...styles.itemListaActive,
                color: corPrimaria,
                border: `1px solid ${corPrimaria}`,
              }}
              family="Bold"
              handlerClick={() => this.visualizarListaCompra(lista)}
            />
          </Div>
        );
      });
    }
  };

  getAgrupamentosListas = () => {
    let itemActive = this.state.itemActive;
    let listasTags = new Map(this.state.listasTags);

    let itens = this.props.listaCompraCliente ? [
      {
        value: "Sugeridas",
        target: "SUGERIDAS",
        active: itemActive === "SUGERIDAS",
        icon: Type.ICON.SHOOPPING_BAG,
      },
    ] : [
      {
        value: "Sugeridas",
        target: "SUGERIDAS",
        active: itemActive === "SUGERIDAS",
        icon: Type.ICON.SHOOPPING_BAG,
      },
      {
        value: "Minhas listas",
        target: "MINHAS_LISTAS",
        active: itemActive === "MINHAS_LISTAS",
        icon: Type.ICON.LIST_ALT,
      },
    ];

    Array.from(listasTags.keys()).forEach((key) => {
      itens.push({
        value: Utils.capitalize(key),
        target: key,
        active: itemActive === key,
      });
    });

    return itens;
  };

  renderAgrupamentosListas(usuarioLogado) {
    let tags = this.getAgrupamentosListas();

    if (!usuarioLogado) {
      tags.splice(1, 1);
    }

    if (tags.length > 2) {
      let itensRender = [];
      tags.forEach((item, index) => {
        itensRender.push(
          <Label
            pointer
            family="Light"
            handlerClick={() =>
              this.setState({
                sugeridas: item.target === "SUGERIDAS",
                minhasListas: item.target === "MINHAS_LISTAS",
                itemActive: item.target,
              })
            }
            key={index}
            style={item.active ? styles.itemListaActive : styles.itemLista}
          >
            <I
              icon={item.icon}
              style={{
                fontSize: 15,
                marginRight: 5,
              }}
            />
            {item.value}
          </Label>
        );
      });

      return (
        <CarouselTagsListasSugeridas
          itens={itensRender}
          slidesPerPage={2}
          arrowRight
        />
      );
    } else {
      return tags.map((item, index) => {
        return (
          <Label
            pointer
            family="Light"
            handlerClick={() =>
              this.setState({
                sugeridas: item.target === "SUGERIDAS",
                minhasListas: item.target === "MINHAS_LISTAS",
                itemActive: item.target,
              })
            }
            key={index}
            style={item.active ? styles.itemListaActive : styles.itemLista}
          >
            <I
              icon={item.icon}
              style={{
                fontSize: 15,
                transform: `translateY(-2px)`,
                marginRight: 5,
              }}
            />
            {item.value}
          </Label>
        );
      });
    }
  }

  render() {
    let listasDeCompra = this.getProdutosListasSugeridas();
    let usuarioLogado = Utils.isValueValid(UsuarioUtils.getUsuarioLojaWeb());
    let { sugeridas, minhasListas } = this.state;

    let sugeridasTag = sugeridas || (!sugeridas && !minhasListas);

    return (
      Utils.isValueValid(this.state.listasSugeridas) && (
        <Div
          style={{
            marginBottom: 30,
            backgroundColor: "#E5E5E5",
            padding: 15,
            marginTop: 10,
            width: window.innerWidth,
          }}
          column
        >
          <Div inline="start" style={{ width: "100%" }}>
            <I
              icon={Type.ICON.LIST_UL}
              style={{
                fontSize: 15,
                color: Color.ECOMMERCE.CINZA_ESCURO,
                marginBottom: 15,
              }}
            />
            <Label
              style={{
                color: Color.ECOMMERCE.CINZA_ESCURO,
                fontSize: 15,
                marginLeft: 10,
              }}
              family="Bold"
              value="LISTAS SUGERIDAS"
            />
          </Div>
          <Div column>
            <Image
              src={ListasCombos}
              styleImage={{
                width: window.innerWidth - 30,
                height: 200,
                marginTop: 10,
              }}
              alt="Lista de Compras Combos"
            />
            {/**
              *  <Label
              value="Monte suas listas e receba em casa"
              family="Light"
              style={{
                color: "white",
                width: 250,
                fontSize: 20,
                position: "absolute",
                transform: `translateY(50px)`,
                marginLeft: 10,
              }}
            />
            <Label
              value="Nas listas sugeridas da loja você pode optar por assinar exatamente como são oferecidas"
              family="Light"
              style={{
                color: "white",
                width: 300,
                fontSize: 12,
                position: "absolute",
                transform: `translateY(150px)`,
                marginLeft: 20,
                textAlign: "jutify",
              }}
            />
              */}
          </Div>

          <Div inline="center" style={{ width: "100%" }}>
            {this.renderAgrupamentosListas(usuarioLogado)}
          </Div>

          {sugeridasTag && (
            <Div
              style={{ width: window.innerWidth - 30, marginTop: 10 }}
              inline="center"
            >
              <If and value1={listasDeCompra.length > 2}>
                <CarouselProdutoGenericoNovo
                  timeSlide={7000}
                  isMobile
                  arrows
                  itens={listasDeCompra}
                  slidesPerPage={SpaceUtils.getQuantidadeItensView()}
                  offset={40}
                />
              </If>
              <If and value1={listasDeCompra.length === 1}>
                <Div style={{ width: "80%" }} inline="start">
                  {listasDeCompra[0]}
                </Div>
              </If>
              <If and value1={listasDeCompra.length === 2}>
                <Div style={{ width: "80%" }} inline="between">
                  {listasDeCompra[0]}
                  {listasDeCompra[1]}
                </Div>
              </If>
            </Div>
          )}

          <Div
            style={{ width: window.innerWidth - 30, marginTop: 10 }}
            inline="center"
          >
            {minhasListas && listasDeCompra}
          </Div>
        </Div>
      )
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
    ...store.empresaState,
    ...store.listasDeCompraState,
  };
};

export default connect(mapStateToProps)(ListasComprasSugeridasMobile);
