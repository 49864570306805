import React, { Component } from "react";
import { toast } from "react-toastify";

import {
  Div,
  Label,
  If,
  InputDefault,
  Image,
  I,
  ModalNotification,
  NavbarTab,
  SelectValue,
  Select,
} from "../../../../components";
import { Color, Http, Mask, Type, Utils } from "../../../../utilities";
import EmpresaUtils from "../../../utils/EmpresaUtils";
import UsuarioUtils from "../../../utils/UsuarioUtils";
import ListaCompraUtils from "../web/ListaCompraUtils";

const styles = {
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
  },
  lista: {
    height: 50,
    border: `1px solid ${Color.ECOMMERCE.CINZA}`,
    margin: 5,
    color: Color.ECOMMERCE.CINZA,
    width: "100%",
  },
};

const TAB_MINHAS_LISTAS = "MINHAS_LISTAS";
const TAB_NOVA_LISTA = "NOVA_LISTA";

class AdicionarProdutoListaCompraMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: {
        target: TAB_MINHAS_LISTAS,
        value: "Minhas listas",
        active: true,
      },
      nomeNovaLista: "",
      listaSelecionada: {},
      mensagem: "",
      produto: JSON.parse(JSON.stringify(props.produto)),
    };
  }

  getQuantidadeLabel(produto) {
    let preco = produto.precoVenda;
    let quantidade = produto.quantidade || 0;
    return produto.possuiQuantidadeFracionada
      ? `${Number(produto.quantidade)}g`
      : `${quantidade}un x ${Mask.formatValueBr(preco)}`;
  }

  componentDidMount() {
    let produto = this.state.produto;
    produto = ListaCompraUtils.addQuantidadeItem(produto);
    this.setState({ produto });
  }

  UNSAFE_componentWillReceiveProps(props) {
    let produto = JSON.parse(JSON.stringify(props.produto));
    produto = ListaCompraUtils.addQuantidadeItem(produto);
    this.setState({ produto, listaSelecionada: {} });
  }

  cadastrarNovaListaCompra() {
    let produto = this.state.produto;
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    let idListaCompra = Utils.uuidv4();

    let listaParam = {
      idListaCompra: idListaCompra,
      tipo: "CLIENTE",
      descricao: this.state.nomeNovaLista,
      idCliente: cliente.idCliente,
      inicioVigencia: new Date().getTime(),
      fimVigencia: new Date().getTime(),
      ativo: true,
      ordem: 1,
      preco: 0,
      precoOferta: 0,
      itens: [
        {
          idListaCompraProduto: Utils.uuidv4(),
          idListaCompra: idListaCompra,
          idProdutoWeb: produto.idProdutoWeb,
          preco: produto.precoVenda,
          quantidade: produto.quantidade,
          ordem: 1,
        },
      ],
    };

    Http.post("/listacompra", listaParam)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.setState({ mensagem: "" }, () =>
              ModalNotification.renderMessage({
                title: "Mensagem",
                body: "Lista de compras cadastrada com sucesso!",
                handlerConfirm: () => this.props.handlerNovaListaCadastrada(),
                labelButtonConfirm: "Fechar",
              })
            );
          } else {
            this.setState({ mensagem: data.mensagem });
          }
        } else {
          console.log("Falha ao cadastrar lista de compra");
        }
      })
      .catch((erro) => console.log(erro));
  }

  getListaCompraAtualizacao() {
    let { listaSelecionada, produto } = this.state;
    let itensLista = Array.from(listaSelecionada.itens);

    let itemNaLista = itensLista.filter(
      (i) => i.codigoBarras === produto.codigoBarras
    )[0];

    if (Utils.isValueValid(itemNaLista)) {
      itemNaLista.quantidade = produto.quantidade;
    } else {
      itensLista.push({
        idListaCompraProduto: Utils.uuidv4(),
        idListaCompra: listaSelecionada.idListaCompra,
        idProdutoWeb: produto.idProdutoWeb,
        preco: produto.precoVenda,
        quantidade: produto.quantidade,
        ordem: 1,
      });
    }

    listaSelecionada.itens = itensLista;

    return listaSelecionada;
  }

  atualizarListaCompra() {
    Http.put("/listacompra", this.getListaCompraAtualizacao())
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            toast.info("Produto adicionado na lista com sucesso!", {
              position: "top-right",
            });
            this.props.handlerNovaListaCadastrada();
          } else {
            toast.error("Falha ao adicionar produto na lista de compras", {
              position: "top-right",
            });
            this.props.handlerNovaListaCadastrada();
          }
        }
      })
      .catch((error) => console.log(error));
  }

  getItensTab = () => {
    let tab = this.state.tab;
    return [
      {
        target: TAB_MINHAS_LISTAS,
        value: "Minhas listas",
        active: tab.target === "MINHAS_LISTAS",
      },
      {
        target: TAB_NOVA_LISTA,
        value: "Adicionar nova lista",
        active: tab.target === "NOVA_LISTA",
      },
    ];
  };

  renderNavBarTab() {
    return (
      <Div style={{ width: "100%" }}>
        <NavbarTab
          itens={this.getItensTab()}
          handlerClick={(tab) => this.setState({ tab })}
        />
      </Div>
    );
  }

  getListasSelect = () => {
    let listas = Array.from(this.props.listas);
    let itens = [new SelectValue(null, "Selecione")];

    listas.forEach((lista) => {
      itens.push(new SelectValue(lista.idListaCompra, lista.descricao));
    });

    return itens;
  };

  render() {
    let { nomeNovaLista, produto, mensagem, listaSelecionada, tab } =
      this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    return (
      <Div style={{ width: "100%" }}>
        <If and value1={Utils.isValueValid(mensagem)}>
          <Label
            value={mensagem}
            style={{ fontSize: 20, color: corPrimaria, width: "100%" }}
            family="Light"
          />
        </If>
        <Label
          value="Produto à ser adicionado na lista"
          family="Light"
          style={{ fontSize: 20 }}
        />
        <Div
          inline="start"
          rounded
          shadow
          className="border"
          margin="2"
          padding="2"
        >
          <Div inline="start">
            <Image
              src={produto.urlImagemPrincipalThumbnail}
              styleImage={{ width: 50, height: 50 }}
            />
            <Label
              value={produto.descricao}
              style={{ width: 150 }}
              className="text-ellipsis"
              family="Light"
              marginLeft="2"
            />
          </Div>
          <Div inline="center" col="12" style={styles.containerAddItem}>
            <I
              icon={Type.ICON.MINUS}
              pointer
              style={{
                color: corPrimaria,
                fontSize: 20,
              }}
              handlerClick={() => {
                if (produto.quantidade > 0) {
                  produto = ListaCompraUtils.removeQuantidadeItem(produto);
                  this.setState({ produto });
                }
              }}
            />
            <Label
              value={this.getQuantidadeLabel(produto)}
              family="Light"
              margin="3"
              className="mx-auto"
            />
            <I
              icon={Type.ICON.PLUS}
              pointer
              style={{
                color: corPrimaria,
                fontSize: 20,
              }}
              handlerClick={() => {
                produto = ListaCompraUtils.addQuantidadeItem(produto);
                this.setState({ produto });
              }}
            />
          </Div>
        </Div>
        {this.renderNavBarTab()}
        <If
          and
          value1={
            tab.target === TAB_MINHAS_LISTAS &&
            !Utils.isValueValid(this.props.listas)
          }
        >
          <Label
            col="12"
            value="Você não possui listas de compra cadastrada, clique na aba adicionar nova lista"
            family="Light"
            style={{ fontSize: 20 }}
          />
        </If>
        <If
          and
          value1={
            tab.target === TAB_MINHAS_LISTAS &&
            Utils.isValueValid(this.props.listas)
          }
        >
          <Div className="d-flex flex-column">
            <Label
              value="Selecione uma lista para adicionar o produto"
              style={{ fontSize: 20 }}
              family="Light"
            />
            <Select
              responsive="12"
              values={this.getListasSelect()}
              valueSelected={listaSelecionada.idListaCompra}
              handlerChange={(e) => {
                let idListaCompra = e.target.value;
                let listaSelecionada = Array.from(this.props.listas).filter(
                  (lista) => lista.idListaCompra === idListaCompra
                )[0];
                this.setState({ listaSelecionada });
              }}
            />
          </Div>
          <Div inline="between" marginTop="5">
            <Label
              handlerClick={(e) => this.props.handlerCancel()}
              family="SemiBold"
              style={{
                backgroundColor: Color.ECOMMERCE.CINZA,
                color: "white",
                width: "50%",
                padding: 10,
                textAlign: "center",
              }}
              className="d-flex justify-content-center align-items-center"
              value="Cancelar"
              pointer
            />
            <Label
              handlerClick={() => {
                if (Utils.isValueValid(listaSelecionada)) {
                  if (produto.quantidade > 0) {
                    this.atualizarListaCompra();
                  } else {
                    toast.error(
                      "A quantidade do produto deve ser maior que zero",
                      {
                        position: "top-right",
                      }
                    );
                  }
                } else {
                  toast.error("Nenhuma lista foi selecionada!", {
                    position: "top-right",
                  });
                  //this.setState({ mensagem: "Nenhuma lista foi selecionada" });
                }
              }}
              family="SemiBold"
              style={{
                backgroundColor: corPrimaria,
                color: "white",
                padding: 10,
                width: "50%",
              }}
              className="d-flex justify-content-center align-items-center"
              value="Confirmar"
              pointer
            />
          </Div>
        </If>
        <If and value1={tab.target === TAB_NOVA_LISTA}>
          <Div shadow className="border" rounded padding="2" margin="2">
            <InputDefault
              responsive="8"
              handlerChange={(e) => {
                this.setState({
                  nomeNovaLista: e.target.value,
                  mensagem: "",
                });
              }}
              label="Nome da lista"
              value={nomeNovaLista}
            />
          </Div>
          <Div inline="between">
            <Label
              handlerClick={(e) => this.props.handlerCancel()}
              family="SemiBold"
              style={{
                backgroundColor: Color.ECOMMERCE.CINZA,
                color: "white",
                width: "50%",
                padding: 10,
                textAlign: "center",
              }}
              className="d-flex justify-content-center align-items-center"
              value="Cancelar"
              pointer
            />
            <Label
              handlerClick={() => {
                if (Utils.isValueValid(nomeNovaLista)) {
                  if (produto.quantidade > 0) {
                    this.cadastrarNovaListaCompra();
                  } else {
                    toast.error(
                      "A quantidade do produto deve ser maior que zero",
                      {
                        position: "top-right",
                      }
                    );
                  }
                } else {
                  toast.error("O nome da lista é obrigatório", {
                    position: "top-right",
                  });
                }
              }}
              family="SemiBold"
              style={{
                backgroundColor: corPrimaria,
                color: "white",
                padding: 10,
                width: "50%",
              }}
              className="d-flex justify-content-center align-items-center"
              value="Confirmar"
              pointer
            />
          </Div>
        </If>
      </Div>
    );
  }
}

export default AdicionarProdutoListaCompraMobile;
