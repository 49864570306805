import React, { useEffect, useState } from "react";
import { Div, Label, I } from "..";
import { Color, Type } from "../../utilities";

import "./checkbutton.css";

const CheckbuttonLight = ({ title, Icon, checked, onClick, col, style }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <Div
      pointer
      style={{
        display: "flex",
        padding: "10px 15px",
        backgroundColor: "#FFF",
        color: Color.ECOMMERCE.CINZA_ESCURO,
        justifyContent: "space-between",
        minWidth: "200px",
        textAlign: "center",
        alignItems: "center",
        border: isChecked ? `1px solid ${Color.EMPRESA.PRIMARIA}` :  "1px solid #dee2e6",
        cursor: "pointer",
        ...style
      }}
      handlerClick={onClick}
      responsive={col}
    >
      <Div>
        {Icon ? Icon : null}
        <Label value={title} />
      </Div>

      <I
        icon={ isChecked ? Type.ICON.SUCCESS_CIRCLE : Type.ICON.CIRCLE}
        style={{
          color: isChecked ? Color.EMPRESA.PRIMARIA : "#dee2e6",
          fontSize: 20,
        }}
      />
    </Div>
  );
};

export default CheckbuttonLight;
