import React from "react";
import { Div, Label } from "../../../components";
import {col, padding, responsive} from "../../css";

const getClassDefault = (props) => {
  let classe = "";
  classe = classe.concat(col(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(padding(props));
  return classe;
};

const TextArea = (props) => {
  return (
    <Div className={getClassDefault(props)} col={props.col}>
      {props.label && (
        <Label
          value={props.label}
          style={{ fontSize: Number(props.fontSizeLabel || 0) }}
        ></Label>
      )}
      <textarea
        disabled={props.disabled}
        placeholder={props.placeHolder}
        maxLength={props.maxLength}
        name={props.name}
        className="form-control"
        rows={props.rows}
        onChange={props.handlerChange}
        onBlur={props.handlerBlur}
        style={props.style}
        value={props.value}
        defaultValue={props.defaultValue}
      ></textarea>
    </Div>
  );
};

export default TextArea;
