import { SET_LISTAS_DE_COMPRA } from "../actions/ActionTypes";

const initialState = {
  listasDeCompra: [],
};

export const listasDeCompraReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LISTAS_DE_COMPRA:
      return {
        ...state,
        listasDeCompra: action.listasDeCompra,
      };
    default:
      return state;
  }
};
