export default class Zindex {

    static DEFAULT = 1;
    static MIL_E_DOIS = 1002;

    //HEADER MENU CLIENTE
    static BACKGROUND_MENU_CLIENTE = 1;
    static BOTOES_CADASTRAR_REGISTRAR = 1;
    static BOTOES_ME_AJUDA = 1;
    static DADOS_USUARIO_LOGADO = 1005;
    static MENU_LOGIN = 1002;

    //MENU ESQUERDO PRINCIPAL
    static MENU = 1011;
    static MENU_CLIENTE = 1010;
    static MAPA_LOJA = 1011;
    static TEMPLATE_NOSSAS_LOJAS = 1011;
    static TEMPLATE_POLITICA = 1011;

    //MENU DIREITO PRINCIPAL

}
