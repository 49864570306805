import React, { Component } from "react";
import { Div, I, If, Label } from "../../../components";

import ItemProdutoAbaixouPreco from "../ItemProdutoAbaixouPreco";
import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";

import "../produtos.css";
import ProdutoUtils from "../ProdutoUtils";
import UsuarioUtils from "../../utils/UsuarioUtils";
import CarouselProdutoGenericoNovo from "../../../components/carousel/CarouselProdutoGenericoNovo";

class ProdutosVisualizados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
    };
  }

  componentDidMount() {
    this.listarUltimosProdutosVisualizados();
  }

  listarUltimosProdutosVisualizados() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();

    if (Utils.isValueValid(cliente)) {
      Http.get(
        `${EndPoints.PRODUTOS_ULTIMOS_VISUALIZADOS}/${cliente.idCliente}`
      )
        .then(({ status, data }) => {
          if (status) {
            this.setState({ produtos: data });
          }
        })
        .catch((erro) => console.log(erro));
    }
  }

  getUltimosProdutosVisualizados = () => {
    let itens = [];

    let produtosSeparados = Utils.isValueValid(this.state.produtos)
      ? Utils.quebrarArray(this.state.produtos, 4)
      : [];

    Array.from(produtosSeparados).forEach((produtos, index) => {
      itens.push({
        value: (
          <Div inline="center" key={index}>
            {Array.from(produtos).map((prod, index) => {
              return (
                <ItemProdutoAbaixouPreco
                  history={this.props.history}
                  key={index}
                  image={prod.urlImagemPrincipalThumbnail}
                  descricao={prod.descricao}
                  precoUnitario={prod.precoUnitario}
                  precoVenda={prod.precoVenda}
                  precoAtacado={prod.precoAtacado}
                  background="white"
                  codigoBarras={prod.codigoBarras}
                  handlerClick={(updateItem) =>
                    this.props.handlerClick(prod, updateItem)
                  }
                  quantidade={prod.quantidade}
                  empresa={this.props.empresa}
                  disponivel={prod.disponivel}
                  estoqueDisponivel={prod.estoqueDisponivel}
                  fatorEmGrama={prod.fatorEmGrama}
                  possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
                  percentualEconomia={prod.percentualEconomia}
                  handlerAddItemCarrinho={(quantidade) => {
                    prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
                    this.props.handlerAddItemCarrinho(prod);
                  }}
                />
              );
            })}
          </Div>
        ),
        active: index === 0,
      });
    });

    return itens;
  };

  getProdutos() {
    return Array.from(this.state.produtos).map((prod, index) => {
      return (
        <Div className="d-flex justify-content-start align-items-start align-self-start">
          <ItemProdutoAbaixouPreco
            history={this.props.history}
            key={index}
            image={prod.urlImagemPrincipalThumbnail}
            descricao={prod.descricao}
            precoUnitario={prod.precoUnitario}
            precoVenda={prod.precoVenda}
            precoAtacado={prod.precoAtacado}
            codigoBarras={prod.codigoBarras}
            background="white"
            handlerClick={(updateItem) =>
              this.props.handlerClick(prod, updateItem)
            }
            quantidade={prod.quantidade}
            disponivel={prod.disponivel}
            empresa={this.props.empresa}
            percentualEconomia={prod.percentualEconomia}
            estoqueDisponivel={prod.estoqueDisponivel}
            quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
            fatorEmGrama={prod.fatorEmGrama}
            possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
            precosConcorrentes={prod.precosConcorrentes || []}
            handlerAddItemCarrinho={(quantidade) => {
              prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
              this.props.handlerAddItemCarrinho(prod);
            }}
          />
        </Div>
      );
    });
  }

  renderProdutosVisualizados() {
    return (
      <Div
        style={{ width: "100%", backgroundColor: "#F2F2F2", paddingTop: 20 }}
        inline="center"
      >
        <Div style={{ width: 1150 }}>
          <Div inline="start" col="12">
            <I
              icon={Type.ICON.STACKOVERFLOW}
              style={{
                fontSize: 25,
                color: Color.ECOMMERCE.CINZA_ESCURO,
                marginBottom: 20,
              }}
            />
            <Label
              style={{
                color: "#878787",
                fontSize: "25px",
                marginLeft: 10,
              }}
              family="Bold"
              value="ÚLTIMOS VISUALIZADOS"
            />
          </Div>

          <CarouselProdutoGenericoNovo
            timeSlide={7000}
            itens={this.getProdutos()}
            slidesPerPage={4}
            arrows
          />
        </Div>
      </Div>
    );
  }

  render() {
    return (
      <If and value1={Utils.isValueValid(this.state.produtos)}>
        {this.renderProdutosVisualizados()}
      </If>
    );
  }
}

export default ProdutosVisualizados;
