export default class BreadParam {

    target
    value
    active

    constructor(target, value, active) {
        this.target = target
        this.value = value
        this.active = active
    }

}