import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Div, InputDefault, Notification, Title } from "../../components";
import DepartamentoService from "../../service/DepartamentoService";


class EditarSecao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departamentos: [],
      secao: [],
      departamentoCustom: "",
      secoesSelectedVincular: [],
    };

  }

  handleChange = (e) => {
    e.stopPropagation();
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };


  confirmEdicao() {
    const { objEdicao } = this.props;

    let newObjEdicao = {
      idEmpresa: this.props.idEmpresa,
      idFilial: this.props.idFilial,
      idClassificacaoWeb: objEdicao.idClassificacaoWeb,
      descricao: this.state.departamentoCustom,
      ativo: true,
      idFuncionarioAtualizacao: this.props.idFuncionario
    }

    DepartamentoService.editarDepartamento(newObjEdicao).then(({ status, message }) => {
      if (status) {
        Notification.success(message)
        this.props.closeComponente()
      } else {
        Notification.danger(message)
      }
    })
  }



  bodyNovoDepartamento() {
    const { departamentoCustom } = this.state;
    const { objEdicao } = this.props;

    return (
      <>
        <InputDefault
          maxLength={30}
          responsive="12"
          label={`Descrição do ${objEdicao.descricao}`}
          name="departamentoCustom"
          placeholder="Escreva uma Descrição"
          required
          handlerChange={(e) => this.handleChange(e)}
          value={departamentoCustom}
        />

        <Div style={{
          margin: "25px 15px",
          display: "flex",
          justifyContent: "end"
        }}>
          <Button
            handlerClick={(e) => this.confirmEdicao()}
            value="Confirmar"
            btn="primary"
            marginRight="3"
          />

          <Button
            handlerClick={
              (e) => this.setState({
                departamentoCustom: "",
                secaoCustom: "",
              },
                this.props.closeComponente()
              )
            }
            value="Cancelar"
            btn="secondary"
          />
        </Div>
      </>
    )
  }

  render() {
    const { objEdicao } = this.props

    return (
      <Div style={{ width: '100%', margin: "25px 10px 0px 0px" }} shadow className="border">
        <Title
          value={`Editar ${objEdicao.descricao}`}
          type="h4"
          paddingLeft="3"
          paddingRight="4"
          paddingTop="3"
        />

        <Div
          col="12"
          padding="0"
          style={{
            margin: "35px 0px",
          }}
        >
          {this.bodyNovoDepartamento()}
        </Div>

      </Div>
    )
  }

}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
  idFuncionario: store.loginState.user.idFuncionario
});

export default connect(mapStateToProps)(EditarSecao);
