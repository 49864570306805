import React, { Component } from "react";
import { CarouselProdutosGenerico, Div, Label } from "../../components";

import ItemProdutoAbaixouPreco from "./ItemProdutoAbaixouPreco";
import { Utils } from "../../utilities";

import "./produtos.css";

const styles = {
  title: {
    color: "#46160E",
    fontSize: window.innerWidth > 600 ? "25px" : "20px",
    marginLeft: 10,
  },
};

class ProdutosListaGenerico extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: props.produtos || [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ produtos: props.produtos });
  }

  getProdutos = () => {
    let itens = [];

    let produtosSeparados = Utils.isValueValid(this.state.produtos)
      ? Utils.quebrarArray(this.state.produtos, window.innerWidth > 600 ? 6 : 2)
      : [];

    Array.from(produtosSeparados).forEach((produtos, index) => {
      itens.push({
        value: (
          <Div inline="center" key={index}>
            {Array.from(produtos).map((prod, index) => {
              return (
                <ItemProdutoAbaixouPreco
                  history={this.props.history}
                  key={index}
                  image={prod.urlImagemPrincipalThumbnail}
                  descricao={prod.descricao}
                  precoUnitario={prod.precoUnitario}
                  precoVenda={prod.precoVenda}
                  precoAtacado={prod.precoAtacado}
                  codigoBarras={prod.codigoBarras}
                  handlerClick={() => this.props.handlerClick(prod)}
                  quantidade={prod.quantidade}
                  empresa={this.props.empresa}
                  percentualEconomia={prod.percentualEconomia}
                  handlerAddItemCarrinho={(quantidade) => {
                    prod.quantidade = quantidade;
                    prod.valorSubTotalItem = (
                      Number(quantidade) * Number(prod.precoVenda)
                    ).toFixed(2);
                    prod.selecionado = true;
                    this.props.handlerAddItemCarrinho(prod);
                  }}
                />
              );
            })}
          </Div>
        ),
        active: index === 0,
      });
    });

    return itens;
  };

  render() {
    return (
      <Div style={{ width: 1100 }}>
        <Label value={this.props.titulo} style={styles.title} family="Bold" />
        <CarouselProdutosGenerico itens={this.getProdutos()} />
      </Div>
    );
  }
}

export default ProdutosListaGenerico;
