import React from 'react';
import NavbarMenu from './NavbarMenu'
import Id from '../../../utilities/enuns/Id';
import Logo from '../../image/Logo'

const getClassContainer = (props) => {
    let classe = 'nav-side-menu '
    classe = classe.concat(props.className ? `${props.className} ` : '')
    classe = classe.concat(props.active ? 'active ' : '')
    return classe
}

const Navbar = (props) => {
    return (
        <div id={props.id} className={getClassContainer(props)}>
            <div className="brand">
                <Logo></Logo>
            </div>
            <i className="fa fa-bars fa-2x toggle-btn " data-toggle="collapse" data-target={`#${Id.HTML.MENU}`}></i>
            <div className="menu-list">
                <NavbarMenu id={Id.HTML.MENU}>
                    {props.children}
                </NavbarMenu>
            </div>
        </div>
    )
}

export default Navbar