import React from "react";
import { Color, Mask, Type, Utils } from "../../../utilities";
import { Div, I, If, Label, Row } from "../../../components";
import Zindex from "../../utils/Zindex";

export default function RenderNossasLojas({ empresa, setState, state }) {
  let filiais = Utils.isValueValid(empresa) ? empresa.filiais : [];

  return (
    <Div
      className="scrollhost"
      style={{
        background: "#f2f2f2",
        width: "100%",
        height: "100%",
        position: "fixed",
        overflowX: "hidden",
        zIndex: Zindex.TEMPLATE_NOSSAS_LOJAS,
        top: 0,
        bottom: 0,
        right: 0,
        transition: "1s",
        padding: 15,
      }}
    >
      <I
        icon={Type.ICON.CLOSEX}
        sizeIcon="2"
        colorText={Color.NODE.DANGER}
        handlerClick={() => setState({ abrirNossasLojas: false })}
        style={{ position: "absolute", top: 5, right: 5 }}
        pointer
      />
      <div className="shadow">
        <Row
          col="12"
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            fontSize: "18px",
            marginTop: 40,
            padding: "10px",
            border: "1px solid #dee2e6",
            borderBottom: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          family="Bold"
        >
          <Div responsive="4" padding="0">
            <Label
              value="Nossas Lojas"
              style={{
                color: "rgb(87, 87, 87)",
                textTransform: "uppercase",
                width: "100%",
                marginLeft: "5px",
              }}
              family="SemiBold"
              marginBottom="0"
            />
          </Div>

          {/* <I
            pointer
            icon={Type.ICON.MAP_MARKER}
            handlerClick={() => {}}
            style={{
              color: Color.EMPRESA.PRIMARIA,
              fontSize: 20,
              marginRight: "10px",
            //  position: "absolute",
            //  right: "0",
            //  bottom: 5,
            }}
          /> */}
        </Row>

        <Row col="12" className="border" style={{ padding: "10px" }}>
          <Div col="12" marginTop="1">
            <Label
              value="Para ver a localização click no endereço."
              family="SemiBold"
              style={{
                color: Color.EMPRESA.PRIMARIA,
                fontSize: 15,
                marginTop: 16,
              }}
            />
          </Div>
          <If and value1={Utils.isValueValid(filiais)}>
            {Array.from(filiais).map((filial, index) => {
              return (
                <Div
                  col="12"
                  style={{
                    borderRadius: "8px",
                    padding: "25px 10px 15px",
                    margin: "0px 0px 15px",
                    border: "1px solid #dee2e6",
                  }}
                  className="shadow"
                  handlerClick={() =>
                    setState({
                      abrirMapa: !state.abrirMapa,
                      filialMapa: filial,
                    })
                  }
                >
                  <Label
                    value={filial.razaoSocial}
                    style={{
                      lineHeight: "15px",
                      fontSize: 14,
                      width: "100%",
                      color: Color.ECOMMERCE.CINZA_ESCURO,
                      textAlign: "justify",
                    }}
                    family="Bold"
                    //  className="text-ellipsis"
                    pointer
                  />
                  <Label
                    value={Mask.getValueMaskPhone(filial.telefone)}
                    style={{
                      lineHeight: "13px",
                      fontSize: 14,
                      width: "100%",
                      marginBottom: 15,
                      color: Color.ECOMMERCE.CINZA,
                      textAlign: "justify",
                    }}
                    pointer
                    family="Light"
                    // className="text-ellipsis"
                  />
                  <Label
                    value={`${filial.enderecoCompleto} - CEP: ${filial.cep}`}
                    style={{
                      lineHeight: "13px",
                      fontSize: 14,
                      width: "100%",
                      color: Color.ECOMMERCE.CINZA,
                      textAlign: "justify",
                    }}
                    pointer
                    family="Light"
                    //  className="text-ellipsis"
                  />

                  {/* <I
                  pointer
                  icon={Type.ICON.MAP_MARKER}
                  handlerClick={() => {}}
                  style={{
                    color: Color.EMPRESA.PRIMARIA,
                    fontSize: 25,
                    marginRight: "10px",
                    position: "absolute",
                    right: "0",
                    bottom: 5,
                  }}
                /> */}
                </Div>
              );
            })}
          </If>
        </Row>
      </div>
    </Div>
  );
}
