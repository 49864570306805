import React from "react";
import TableColumn from "../TableColumn";
import Button from "../../buttons/Button";
import I from "../../i/I";
import Color from "../../../utilities/enuns/Color";
import PropTypes from "prop-types";
import Span from "../../texts/span/Span";
import { Utils } from "../../../utilities";
import If from "../../if/If";

const TableColumnButton = (props) => {
  return (
    <TableColumn>
      <Button
        btnOutLine={Color.NODE.DEFAULT}
        handlerClick={props.handlerClick}
        inline="center"
        className="btn-sm col"
      >
        <I
          icon={props.type}
          sizeIcon={props.sizeIcon}
          colorText={props.colorText}
          title={props.title}
        ></I>
        <If
          and
          value1={props.edit !== true}
          value2={Utils.isValueValid(props.edit)}
        >
          <Span padding="2" value={props.edit} />
        </If>
        <If
          and
          value1={props.delete !== true}
          value2={Utils.isValueValid(props.delete)}
        >
          <Span padding="2" value={props.delete} />
        </If>
      </Button>
    </TableColumn>
  );
};

TableColumnButton.propTypes = {
  icon: PropTypes.string,
  sizeIcon: PropTypes.string,
  title: PropTypes.string,
  colorText: PropTypes.string,
  handlerClick: PropTypes.func.isRequired,
};

export default TableColumnButton;
