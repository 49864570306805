import React, { Component } from "react";
import { Div } from "../../components";


class CenterTop extends Component {
  render() {
    return <Div>{this.props.children}</Div>;
  }
}

export default CenterTop;
