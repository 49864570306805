import React, { useEffect, useState } from "react";
import {
  Checkbutton,
  Div,
  I,
  If,
  Image,
  InputInteger,
  Label,
  Row,
  Select,
  SelectValue,
} from "../../components";
import { Color, Http, Mask, Type, Utils } from "../../utilities";
import CheckbuttonLight from "../../components/checkbutton/CheckbuttonLight";
import TemplateCartaoNovo from "./TemplateCartaoNovo";
import SocketClient from "../../components/websocket/SocketClient";
import {
  HEADER_SOCKET,
  URL_TOKEN_SOCKET_NOVO,
} from "../../utilities/axios/api";

import ImagemQRCODE from "../../assets/images/bandeiras/icon-qrcode.png";
import ImagemVisa from "../../assets/images/bandeiras/icon-visa.png";
import ImagemMasterCard from "../../assets/images/bandeiras/icon-mastercard.png";
import ImagemAmericanExpress from "../../assets/images/bandeiras/icon-amex.png";
import ImagemHiperCard from "../../assets/images/bandeiras/icon-hipercard.png";
import ImagemElo from "../../assets/images/bandeiras/icon-elo.png";
import ImagemDiners from "../../assets/images/bandeiras/icon-diners.png";

import TagManagerUtils from "../../utilities/utils/TagManagerUtils";

const styles = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    textTransform: "uppercase",
    width: "100%",
    marginLeft: "5px",
  },
  mobilePagamento: {},
};

const PagamentosNovos = ({
  formasPagamentos,
  quantidadeMaximaParcelas,
  empresa,
  parametros,
  pedido,
  recebimentoPedido,
  handlerRecebimento,
  carrinho,
  continuarFinalizacaoPedido,
  handlerSucessoPagamento,
  handlerErrorPagamento,
  windowPagamentoDebito,
  mobile,
  handlerInitPagamento,
  handlerTipoPagamento,
}) => {
  const [tipoEntrega, setTipoEntrega] = useState(true);
  const [obsTroco, setObsTroco] = useState("");
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState({
    pagamentoOnline: true,
    pagamentoOffline: false,
    pagamentoTipoCreditoOnline: true,
    pagamentoTipoDebitoOnline: false,
    pagamentoTipoCreditoOffline: false,
    pagamentoTipoDebitoOffline: false,
    pagamentoTipoQrCodePix: false,
    pagamentoTipoDinheiro: true,
    pagamentoTipoVoucher: false,

    formasDePagamentoOnline: [
      {
        descricao: "DINHEIRO",
        active: true,
        idEmpresaFormaPagamento: null
      },
      {
        descricao: "CREDITO",
        active: true,
      },
      {
        descricao: "DEBITO",
        active: true,
      },
    ],
    formasDePagamentoOffline: [
      {
        descricao: "DINHEIRO",
        active: true,
        idEmpresaFormaPagamento: null
      },
      {
        descricao: "CREDITO",
        active: false,
      },
      {
        descricao: "DEBITO",
        active: true,
      },
    ],
    quantidadeMaximaParcelas: quantidadeMaximaParcelas,
  });

  const [tipoPagamentoselecionado, setTipoPagamentoSelecionado] = useState(
    Type.PAGAMENTO.DEBITO
  );

  useEffect(() => {
    // let { pedido, recebimentoPedido } = state;
    let { fantasia } = empresa;

    if (Utils.isNotUndefined(recebimentoPedido.tipo)) {
      TagManagerUtils.InformacoesDePagamento(
        pedido,
        recebimentoPedido.tipo,
        fantasia
      );
    }

    setState((state) => ({
      ...state,
      formasDePagamentoOnline: getFormasPagamentoOnline(),
      formasDePagamentoOffline: getFormasPagamentoOffline(),
    }));
    if (Utils.isNotObjectEmpty(parametros)) {
      setTipoEntrega(parametros.aceitaPagamentoOnline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading && state.formasDePagamentoOnline) {
      handlerInitPagamento();
      handlerRecebimento(getRecebimentoInicial());
      handlerTipoPagamento(parametros.aceitaPagamentoOnline);

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.formasDePagamentoOnline]);

  const getImagemBandeira = (bandeira) => {
    let descricao = String(bandeira.descricao);
    switch (descricao) {
      case "VISA":
        return ImagemVisa;
      case "MASTER CARD":
        return ImagemMasterCard;
      case "HIPERCARD":
        return ImagemHiperCard;
      case "AMERICAN EXPRESS":
        return ImagemAmericanExpress;
      case "QRCODE":
        return ImagemQRCODE;
      case "ELO":
        return ImagemElo;
      case "DINERS CLUB":
        return ImagemDiners;
      default:
        return ImagemQRCODE;
    }
  };

  const getValorTotalPedido = () => {
    let { valorTaxaEntrega, valorDesconto } = carrinho.cabecalho;

    let valorTotalPedido = Number(
      pedido.valorTotal +
        valorTaxaEntrega +
        parametros.valorTaxaServico -
        valorDesconto
    );

    return valorTotalPedido;
  };

  const getQuantidadeParcelamentoCredito = () => {
    let valorTotalPedido = Number(getValorTotalPedido());

    let quantidades = [];

    let i = 0;

    for (i = 1; i <= state.quantidadeMaximaParcelas; i++) {
      quantidades.push(
        new SelectValue(
          i,
          `Em ${i}x de ${Mask.formatValueBr(valorTotalPedido / i)}`
        )
      );
    }
    return quantidades;
  };

  const getTipoPagamento = () => {
    return tipoPagamentoselecionado;
  };

  const getFormasPagamentoOnline = () => {
    let creditoValido = false;
    let debitoValido = false;

    formasPagamentos.forEach((item) => {
      if (item.onLine && item.debito) {
        debitoValido = true;
      }
      if (item.onLine && item.credito) {
        creditoValido = true;
      }
    });

    let formasPagamentosOnline = [
      { descricao: "CREDITO", active: creditoValido },
      { descricao: "DEBITO", active: debitoValido },
    ];
    if (Utils.isValueValid(empresa.parametros.chavePix)) {
      formasPagamentosOnline.push({ descricao: "PIX QRCODE", active: false });
    }
    return formasPagamentosOnline;
  };

  const getFormasPagamentoOffline = () => {
    let creditoValido = false;
    let debitoValido = false;
    let dinheiroValido = false;

    let pagamentoDinheiro = Array.from(parametros.formasPagamentos).filter(
      (pagamento) => pagamento.dinheiro
    )[0];

    console.log("f", formasPagamentos)

    formasPagamentos.forEach((item) => {
      if (item.offLine && item.debito) {
        debitoValido = true;
      }
      if (item.offLine && item.credito) {
        creditoValido = true;
      }
      if (item.offLine && item.dinheiro) {
        dinheiroValido = true;
      }
    });

    return [
      {
        descricao: "DINHEIRO",
        active: dinheiroValido,
        idEmpresaFormaPagamento: pagamentoDinheiro.idEmpresaFormaPagamento,
      },
      { descricao: "CREDITO", active: creditoValido },
      { descricao: "DEBITO", active: debitoValido },
      /*  { descricao: "VOUCHER", active: false },*/
    ];
  };

  const getBandeirasCartao = () => {
    let formasDePagamento = formasPagamentos || [];

    const {
      pagamentoOnline,
      pagamentoOffline,
      pagamentoTipoCreditoOnline,
      pagamentoTipoDebitoOnline,
      pagamentoTipoCreditoOffline,
      pagamentoTipoDebitoOffline,
      pagamentoTipoDinheiro,
    } = state;

    let pagamentos = [];

    if (pagamentoOnline) {
      if (pagamentoTipoCreditoOnline) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.onLine === pagamentoOnline &&
            pg.credito === pagamentoTipoCreditoOnline &&
            !pg.qrCode
        );
      } else if (pagamentoTipoDebitoOnline) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.onLine === pagamentoOnline &&
            pg.debito === pagamentoTipoDebitoOnline &&
            !pg.qrCode
        );
      }
    } else {
      if (pagamentoTipoCreditoOffline) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.offLine === pagamentoOffline &&
            pg.credito === pagamentoTipoCreditoOffline
        );
      } else if (pagamentoTipoDebitoOffline) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.offLine === pagamentoOffline &&
            pg.debito === pagamentoTipoDebitoOffline
        );
      } else if (pagamentoTipoDinheiro) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.offLine === pagamentoOffline &&
            pg.dinheiro === pagamentoTipoDinheiro
        );
      }
    }

    let pagamentosSelectValue = [];

    pagamentos.forEach((pagamento) => {
      pagamentosSelectValue.push({
        idEmpresaFormaPagamento: pagamento.idEmpresaFormaPagamento,
        descricao: pagamento.descricao,
        imagem: getImagemBandeira(pagamento),
        tipoBandeira: pagamento.tipoBandeira,
      });
    });

    return pagamentosSelectValue;
  };

  const getBandeirasSelect = () => {
    let formasDePagamento = parametros.formasPagamentos || [];

    const {
      pagamentoOnline,
      pagamentoOffline,
      pagamentoTipoCreditoOnline,
      pagamentoTipoDebitoOnline,
      pagamentoTipoCreditoOffline,
      pagamentoTipoDebitoOffline,
      pagamentoTipoDinheiro,
    } = state;

    let pagamentos = [];

    if (pagamentoOnline) {
      if (pagamentoTipoCreditoOnline) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.onLine === pagamentoOnline &&
            pg.credito === pagamentoTipoCreditoOnline &&
            !pg.qrCode
        );
      } else if (pagamentoTipoDebitoOnline) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.onLine === pagamentoOnline &&
            pg.debito === pagamentoTipoDebitoOnline &&
            !pg.qrCode
        );
      }
    } else {
      if (pagamentoTipoCreditoOffline) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.offLine === pagamentoOffline &&
            pg.credito === pagamentoTipoCreditoOffline
        );
      } else if (pagamentoTipoDebitoOffline) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.offLine === pagamentoOffline &&
            pg.debito === pagamentoTipoDebitoOffline
        );
      } else if (pagamentoTipoDinheiro) {
        pagamentos = Array.from(formasDePagamento).filter(
          (pg) =>
            pg.offLine === pagamentoOffline &&
            pg.dinheiro === pagamentoTipoDinheiro
        );
      }
    }

    let pagamentosSelectValue = [];

    pagamentos.forEach((pagamento) => {
      pagamentosSelectValue.push(
        new SelectValue(pagamento.idEmpresaFormaPagamento, pagamento.descricao)
      );
    });

    return pagamentosSelectValue;
  };

  function consultarSituacaoDoPedidoEFinalizar() {
    Http.get(`/pedido/${carrinho.cabecalho.idLancamentoPedidoApp}/situacao`)
      .then(({ status, data }) => {
        if (status) {
          if (data.statusPagamento) {
            handlerSucessoPagamento(data);
          } else {
            handlerErrorPagamento(data);
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  function handlerSocket() {
    return (
      <SocketClient
        url={URL_TOKEN_SOCKET_NOVO()}
        headers={HEADER_SOCKET()}
        topics={[
          `/user/recebimento/${carrinho.cabecalho.idLancamentoPedidoApp}`,
        ]}
        onMessage={(notify) => {
          if (Utils.isValueValid(windowPagamentoDebito)) {
            windowPagamentoDebito.close();
          }
          consultarSituacaoDoPedidoEFinalizar();
        }}
      />
    );
  }

  function getRecebimentoInicial() {
    let bandeiras = getBandeirasCartao();

    let formasDePagamento = state.pagamentoOnline
      ? state.formasDePagamentoOnline
      : state.formasDePagamentoOffline;

    return Utils.isArrayNotEmpty(bandeiras)
      ? {
          tipo: formasDePagamento[0].descricao,
          bandeiraCartao: state.pagamentoTipoCreditoOnline
            ? //||
              //this.state.pagamentoTipoDebitoOnline
              bandeiras[0].tipoBandeira || bandeiras[0].descricao
            : null,
          idEmpresaFormaPagamento: bandeiras[0].idEmpresaFormaPagamento,
          quantidadeParcelas: state.pagamentoOnline ? 1 : null,
        }
      : null;
  }

  function OpcoesPagamento() {
    let debitoValido = false;
    let creditoValido = false;
    let dinheiroValido = false;

    (tipoEntrega
      ? state.formasDePagamentoOnline
      : state.formasDePagamentoOffline
    ).forEach((item) => {
      if (item.descricao === Type.PAGAMENTO.DEBITO) {
        debitoValido = item.active;
      }

      if (item.descricao === Type.PAGAMENTO.CREDITO) {
        creditoValido = item.active;
      }

      if (item.descricao === Type.PAGAMENTO.DINHEIRO) {
        dinheiroValido = item.active;
      }
    });

    return (
      <Row col="12" marginTop="3" inline="start">
        <If and value1={debitoValido}>
          <CheckbuttonLight
            title="Debito"
            col="3"
            style={{
              marginBottom: "15px",
            }}
            Icon={
              <I
                pointer
                icon={Type.ICON.CREDIT_CARD_ALT}
                style={{
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  fontSize: 20,
                  margin: "2px 17px 0px 0px",
                }}
              />
            }
            checked={
              tipoPagamentoselecionado === Type.PAGAMENTO.DEBITO ? true : false
            }
            onClick={() => {
              setTipoPagamentoSelecionado(Type.PAGAMENTO.DEBITO);
              handlerRecebimento({
                ...recebimentoPedido,
                tipo: Type.PAGAMENTO.DEBITO,
              });
            }}
          />
        </If>
        <If and value1={creditoValido}>
          <CheckbuttonLight
            title="Crédito"
            col={"3"}
            style={{ marginBottom: "15px", marginLeft: !mobile && "20px" }}
            Icon={
              <I
                pointer
                icon={Type.ICON.CREDIT_CARD_ALT}
                style={{
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  fontSize: 20,
                  margin: "2px 17px 0px 0px",
                }}
              />
            }
            checked={
              tipoPagamentoselecionado === Type.PAGAMENTO.CREDITO ? true : false
            }
            onClick={(e) => {
              setTipoPagamentoSelecionado(Type.PAGAMENTO.CREDITO);
              handlerRecebimento({
                ...recebimentoPedido,
                tipo: Type.PAGAMENTO.CREDITO,
              });
            }}
          />
        </If>

        <If and value1={!tipoEntrega && dinheiroValido}>
          <CheckbuttonLight
            title="Dinheiro"
            col={"3"}
            style={{
              marginBottom: "15px",
              marginLeft: !mobile && "20px",
            }}
            Icon={
              <I
                pointer
                icon={Type.ICON.MONEY}
                style={{
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  fontSize: 20,
                  margin: "2px 17px 0px 0px",
                }}
              />
            }
            checked={
              tipoPagamentoselecionado === Type.PAGAMENTO.DINHEIRO
                ? true
                : false
            }
            onClick={() => {
              setTipoPagamentoSelecionado(Type.PAGAMENTO.DINHEIRO);
              handlerRecebimento({
                idEmpresaFormaPagamento: state.formasDePagamentoOffline[0].idEmpresaFormaPagamento,
                tipo: Type.PAGAMENTO.DINHEIRO,

              });
            }}
          />
        </If>
      </Row>
    );
  }

  return (
    <>
      {handlerSocket()}
      <Row
        col="12"
        style={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          fontSize: "18px",
          padding: "15px 20px",
          border: "1px solid #dee2e6",
          borderBottom: "none",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        family="Bold"
      >
        <Div responsive="4" padding="0">
          <Label
            value="Formas de Pagamento"
            style={styles.labelResumoCompra}
            family="SemiBold"
            marginBottom="0"
          />
        </Div>
        <Row
          responsive="8"
          style={{
            display: "flex",
            justifyContent: mobile ? "space-between" : "end",
            padding: mobile && "0",
            marginTop: mobile && "10px",
            marginLeft: mobile && "-10px",
          }}
        >
          {formasPagamentos &&
            formasPagamentos
              .filter(
                (pg) =>
                  pg.onLine === state.pagamentoOnline &&
                  pg.credito === state.pagamentoTipoCreditoOnline &&
                  !pg.qrCode
              )
              .map((item) => {
                let imagem = getImagemBandeira(item);
                return (
                  <Image
                    src={imagem}
                    width={mobile ? "25px" : "35"}
                    style={{ marginLeft: "15px" }}
                  />
                );
              })}
        </Row>
      </Row>
      <Div col="12" className="border" style={{ padding: "10px 20px" }}>
        <Row col="12" style={{ alignItems: "center", justifyContent: "start" }}>
          {parametros.aceitaPagamentoOnline && (
            <Checkbutton
              corPrimaria={Color.EMPRESA.PRIMARIA}
              checked={tipoEntrega}
              title="Pagamento Online"
              onClick={() => {
                setTipoEntrega(true);
                handlerInitPagamento();
                handlerRecebimento(getRecebimentoInicial());
                handlerTipoPagamento(true);
              }}
              style={{
                margin: "9px 15px 9px 0",
                width: "215px",
                height: "55px",
                fontSize: "15px",
                flexDirection: "row",
                justifyContent: "start",
                border: "1px solid #dee2e6",
              }}
              Icon={
                <I
                  pointer
                  icon={Type.ICON.SHOPPING_CART}
                  style={{
                    color: tipoEntrega
                      ? Color.ECOMMERCE.WHITE
                      : Color.EMPRESA.PRIMARIA,
                    fontSize: 23,
                    marginRight: "10px",
                  }}
                />
              }
            />
          )}

          {parametros.aceitaRetirada && (
            <Checkbutton
              corPrimaria={Color.EMPRESA.PRIMARIA}
              checked={!tipoEntrega}
              title="Pagamento Entrega/Retirada"
              onClick={() => {
                setTipoEntrega(false);
                handlerInitPagamento();
                handlerRecebimento(getRecebimentoInicial());
                handlerTipoPagamento(false);
              }}
              style={{
                margin: "10px 15px 10px 0",
                width: "215px",
                height: "55px",
                fontSize: "15px",
                flexDirection: "row",
                justifyContent: "start",
                border: "1px solid #dee2e6",
              }}
              Icon={
                <I
                  pointer
                  icon={Type.ICON.SHOOPPING_BAG}
                  style={{
                    color: !tipoEntrega
                      ? Color.ECOMMERCE.WHITE
                      : Color.EMPRESA.PRIMARIA,
                    fontSize: 23,
                    marginRight: "10px",
                  }}
                />
              }
            />
          )}
        </Row>

        <OpcoesPagamento />

        <If
          and
          value1={
            tipoPagamentoselecionado === Type.PAGAMENTO.DINHEIRO && !tipoEntrega
          }
        >
          <Div inline="center" marginTop="4" className="w-100">
            <Div responsive="12" padding="0">
              <InputInteger
                responsive="12"
                label="Precisa de troco?"
                placeholder="Levar troco para"
                value={obsTroco}
                handlerChange={(e) => {
                  setObsTroco(e.target.value);
                }}
              />
            </Div>
          </Div>
        </If>

        <If
          and
          value1={
            (tipoPagamentoselecionado === Type.PAGAMENTO.CREDITO ||
              tipoPagamentoselecionado === Type.PAGAMENTO.DEBITO) &&
            tipoEntrega
          }
        >
          <TemplateCartaoNovo
            tipoPagamento={getTipoPagamento}
            bandeiras={getBandeirasCartao}
            bandeirasSelect={getBandeirasSelect}
            pedido={pedido}
            recebimentoPedido={recebimentoPedido}
            handlerRecebimento={handlerRecebimento}
          />
        </If>
        <If and value1={tipoPagamentoselecionado === Type.PAGAMENTO.CREDITO}>
          <Div
            style={{
              backgroundColor: "rgb(231, 232, 234)",
              borderRadius: "8px",
              margin: "0px 15px",
              padding: "7px 16px",
              marginTop: "-11px",
            }}
          >
            <Select
              padding="0"
              responsive="12"
              colorLabel={Color.ECOMMERCE.CINZA_ESCURO}
              label="Número de parcelas"
              values={getQuantidadeParcelamentoCredito()}
              handlerChange={(e) => {
                handlerRecebimento({
                  ...recebimentoPedido,
                  quantidadeParcelas: e.target.value,
                });
              }}
            />
          </Div>
        </If>

        <Div
          pointer
          inline="center"
          handlerClick={() => continuarFinalizacaoPedido()}
          style={{
            color: Color.ECOMMERCE.WHITE,
            padding: 10,
            margin: "30px 15px 10px",
            borderRadius: 4,
            backgroundColor: Color.EMPRESA.PRIMARIA,
          }}
        >
          <Label
            value={"Finalizar Pedido"}
            marginTop="2"
            style={{ fontSize: 18 }}
            family="SemiBold"
            pointer
          />
        </Div>
      </Div>
    </>
  );
};

export default PagamentosNovos;
