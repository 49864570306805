import React, { Component } from "react";
import {
  Div,
  Table,
  Title,
} from "../../components";
import { connect } from "react-redux";
import { Color, Http, Type, Utils } from "../../utilities";
import { withRouter } from "react-router-dom";

class MenuListar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogRede: false,
      empresas: [],
      menus: [],
      concorrente: {},
      selectedConcorrente: {},
      globalFilter: null,
      //...this.props.location.state,
    };
  }

  UNSAFE_componentWillMount() {
    this.getMenus();
  }

  getMenus() {
    Http.get("menu").then(({ status, data }) => {
      if (status) {
        this.setState({
          menus: Utils.isArrayNotEmpty(data) ? data : [],
        });
      }
    });
  }

  editarMenu(menu) {
    this.props.history.push({
      pathname: "menus/" + menu.descricao,
      state: { menu: menu, editar: true },
    });
  }


  render() {

    return (
      <Div
        margin="3"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
      >
        <Title
          value="Menus"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        <Div padding="2">
          <Table
            columns={[
              { value: "Icone", align: "center" },
              { value: "Chave Pesquisa", align: "center" },
              { value: "Descrição", align: "center" },
              { value: "Tipo", align: "center" },
             
            ]}
            fields={[
              {
                value: "urlFoto",
                type: Type.DADO.IMG,
                align: "left",
              },
              { value: "chavePesquisa", type: Type.DADO.STRING },
              { value: "descricao", type: Type.DADO.STRING },
              { value: "tipo", type: Type.DADO.DATE },
            ]}
            bordered
            search
            orientation="center"
            pagination
         //   rota={PathRoute.PATH.PESQUISA_CONCORRENTE_CADASTRAR}
            edit
            handlerEdit={(menu) => this.editarMenu(menu)}       
            data={this.state.menus}
            error="Nenhum menu encontrado"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idUsuario: store.loginState.user.idUsuario,
});

export default withRouter(connect(mapStateToProps)(MenuListar));
