import { Http } from '../utilities';

class HorariosService {
  static async getHorariosEntrega() {

    let res = null;
    await Http.get(`/empresa/horarios/entregas/entrega`).then(
      response => {
        res = response;
      },
    );
    return res;
  }

  static async getHorariosRetirada() {

    let res = null;
    await Http.get(`/empresa/horarios/entregas/retirada`).then(
      response => {
        res = response;
      },
    );
    return res;
  }

  static async putHorarios(obj) {
    let res = null;

    await Http.put('/empresa/horarios/entregas/sincronizar', obj).then(response => {
      res = response;
    });

    return res;

  }

  static async createHorarios(obj) {
    let res = null;

    await Http.post('/empresa/horarios/entregas/sincronizar', obj).then(response => {
      res = response;
    });

    return res;
  }

  static async removerHorario(obj) {
    let res = null;

    await Http.delete('/empresa/horarios/entregas/', obj).then(response => {
      res = response;
    });

    return res;
  }

  static async createHorariosTaxas(obj) {
    let res = null;

    await Http.post('/empresa/taxas/entregas/sincronizar', obj).then(response => {
      res = response;
    });

    return res;
  }

  static async getHorariosTaxas(obj) {
    let res = null;

    await Http.get('/empresa/taxas/entregas', obj).then(response => {
      res = response;
    });

    return res;
  }
}

export default HorariosService;

