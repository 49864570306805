import { carrinhoReducer } from "./CarrinhoReducer";
import { produtosReducer } from "./ProdutosReducer";
import { empresaReducer } from "./EmpresaReducer";
import { listasDeCompraReducer } from "./ListasDeCompraReducer";
import { menusReducer } from "./MenuReducer";
import { anunciosReducer } from "./AnunciosReducer";

import { combineReducers } from "redux";
import { parametrosPedidoReducer } from "./ParametrosPedidoReducer";
import { loginReducer } from "./LoginReducer";

export const Reducers = combineReducers({
  carrinhoState: carrinhoReducer,
  produtosState: produtosReducer,
  empresaState: empresaReducer,
  listasDeCompraState: listasDeCompraReducer,
  menusState: menusReducer,
  anunciosState: anunciosReducer,
  parametrosPedidoState: parametrosPedidoReducer,
  loginState: loginReducer
});
