import React, { Component } from "react";
import { connect } from "react-redux";
import { Div, I, If, Label } from "../../components";
import { Color, Keys, Type, Utils } from "../../utilities";
import ProdutosSugestaoPesquisaMobile from "../produtos/mobile/ProdutosSugestaoPesquisaMobile";

const ID_ITEM_FECHA_MENU = Utils.uuidString();

const styles = {
  search: {
    position: "absolute",
    top: 110,
    left: 0,
    width: "100%",
    zIndex: 1002,
    overflow: "auto",
  },
  searchActive: {
    position: "absolute",
    top: 110,
    left: 0,
    width: "100%",
    zIndex: 1002,
    overflow: "auto",
  },
  labelBusca: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 13,
  },
};

class PesquisaProdutoMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resultadoPesquisa: props.resultadoPesquisa || [],
      activePesquisa: props.activePesquisa,
      empresa: props.empresa,
    };
  }

  componentDidMount() {
    if (this.state.activePesquisa) {
      Utils.toggleScrollBody("hidden");
    } else {
      Utils.toggleScrollBody("auto");
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      {
        resultadoPesquisa: props.resultadoPesquisa,
        activePesquisa: props.activePesquisa,
        empresa: props.empresa,
        textoPesquisa: props.textoPesquisa,
      },
      () => {
        if (this.state.activePesquisa) {
          Utils.toggleScrollBody("hidden");
        } else {
          Utils.toggleScrollBody("auto");
        }
      }
    );
  }

  getBuscasAnteriores = () => {
    let buscas = JSON.parse(localStorage.getItem(Keys.KEY_BUSCAS_DIGITADAS));

    return Utils.isValueValid(buscas)
      ? Array.from(buscas).reverse().slice(0, 3)
      : [];
  };

  fecharMenuBusca() {
    let div = document.getElementById(this.props.id);
    if (Utils.isValueValid(div)) {
      div.classList.toggle("collapsed");
      document.getElementById(ID_ITEM_FECHA_MENU).click();
    }
  }

  render() {
    const buscasAnteriores = this.getBuscasAnteriores();

    let produtos = this.state.resultadoPesquisa.sucessoPesquisa
      ? []
      : this.state.resultadoPesquisa.listaProdutosSugeridos;

    return (
      <Div
        bg={Color.NODE.LIGHT}
        id={this.props.id}
        className="collapse"
        style={this.state.activePesquisa ? styles.searchActive : styles.search}
      >
        <I
          icon={Type.ICON.CLOSEX}
          colorText={Color.NODE.DANGER}
          style={{ position: "absolute", right: 10, top: 10 }}
          sizeIcon="2"
          handlerClick={() => {
            this.fecharMenuBusca();
            this.props.handlerClosePesquisa();
          }}
        />
        <Div inline="center" padding="3">
          <Div
            className="d-flex flex-column align-items-center align-self-start justify-content-start"
            padding="2"
          >
            <Label
              value="BUSCAS ANTERIORES"
              family="SemiBold"
              style={styles.labelBusca}
            />
            <If and value1={Utils.isValueValid(buscasAnteriores)}>
              <Div padding="3">
                {buscasAnteriores.map((busca, index) => {
                  return (
                    <Div
                      inline="start"
                      key={index}
                      style={{ color: "#c5c5c5" }}
                      pointer
                      handlerClick={() => {
                        this.setState({ textoPesquisa: busca }, () =>
                          this.props.handlerPesquisa(busca, ID_ITEM_FECHA_MENU)
                        );
                      }}
                    >
                      <li>{busca}</li>
                    </Div>
                  );
                })}
              </Div>
              <Div
                inline="start"
                marginTop="2"
                handlerClick={() => {
                  localStorage.removeItem(Keys.KEY_BUSCAS_DIGITADAS);
                  this.props.handlerRemoveBuscas();
                }}
              >
                <I
                  icon={Type.ICON.CLOSEX}
                  colorText={Color.NODE.DANGER}
                  sizeIcon="1"
                  style={{ marginBottom: 10 }}
                  pointer
                />
                <Label
                  value="LIMPAR BUSCAS"
                  family="Light"
                  marginLeft="1"
                  pointer
                />
              </Div>
            </If>
            <If and value1={!Utils.isValueValid(buscasAnteriores)}>
              <Label value="Nenhuma busca anterior encontrada" />
            </If>
          </Div>

          <Div className="h-100align-self-start" responsive="8" padding="4">
            <Div className="d-flex flex-column justify-content-start">
              <If and value1={Utils.isValueValid(produtos)}>
                <Div inline="start">
                  <Label
                    value={`Nenhum produto encontrado para o termo: "${this.state.textoPesquisa}"`}
                    family="Light"
                    style={{
                      fontSize: 15,
                      color: Color.ECOMMERCE.MARRON_ESCURO,
                    }}
                  />
                </Div>
              </If>
              <Label
                value="SUGESTÕES"
                family="SemiBold"
                style={styles.labelBusca}
              />
            </Div>
            <Div>
              <If and value1={Utils.isValueValid(produtos)}>
                <ProdutosSugestaoPesquisaMobile produtos={produtos} />
              </If>
              <If and value1={!Utils.isValueValid(produtos)}>
                <Label
                  value="Nenhum produto sugerido para a pesquisa!"
                  family="Light"
                  style={{ fontSize: 15 }}
                />
              </If>
            </Div>
          </Div>
        </Div>
        <Div
          toggle="collapse"
          target={`#${this.props.id}`}
          id={ID_ITEM_FECHA_MENU}
        />
       
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
  };
};

export default connect(mapStateToProps)(PesquisaProdutoMobile);
