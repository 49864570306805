import { Utils } from "../../../../utilities";
import ProdutoUtils from "../../ProdutoUtils";

export default class ListaCompraUtils {
  
  static removeQuantidadeItem(item) {
    let quantidade = Number(item.quantidade);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      if (this.state.quantidadePeso) {
        quantidade = quantidade - Number(item.fatorEmGrama);
      } else {
        quantidade = quantidade - 1;
      }
    } else {
      quantidade = quantidade - 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    return item;
  }

  static addQuantidadeItem(item) {
    let quantidade = Number(item.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      if (this.state.quantidadePeso) {
        quantidade = quantidade + Number(item.fatorEmGrama);
      } else {
        quantidade = quantidade + 1;
      }
    } else {
      quantidade = quantidade + 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    return item;
  }
}
