import React, { Component } from "react";

import {
  ButtonIconOutLine,
  Div,
  Label,
  ModalNotification,
  Notification,
  Select,
  SelectValue,

} from "../../components";
import ProdutoCataloService from "../../service/ProdutoCatalogoService";

import { Color, Utils } from "../../utilities";


class DepartamentoProdutoDetalhe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departamentos: [new SelectValue(0, "Selecione um Departamento", true)],
      secoes: [new SelectValue(0, "Selecione um Secões", true)],
      filtrosDepartamentos: {
        departamentos: [new SelectValue(0, "Selecione um Departamento", true)],
        secoes: [

        ],
      },
      custom: [],
      objProdutoDepartamentoSecao: {},
      selectDep: 0,
      selectSec: 0,
      idProdutoWebDepartametoSecao: "",
      editar: false,
      loading: false
    }
  }

  UNSAFE_componentWillMount() {
    this.getDepartamentoSecao()

    let produto = this.props.produto

    console.log("p", produto)

    if(produto) {
      this.setState({
        selectDep: produto.idDepartamentoWeb.toString(),
        selectSec: produto.idSecaoWeb.toString(),
        loading: true,
        editar: false,
        idProdutoWebDepartametoSecao: produto.idProdutoWebDepartametoSecao
      })
    }
  }

  componentDidUpdate() {
    if(this.state.loading) {
      this.getSecoes(this.state.selectDep)
      this.setState({
        loading: false,
        editar: false
      })
    }
  }

  getDepartamentoSecao() {

    ProdutoCataloService.getDepartamentoSecaoFiltro().then((data) => {
      let newData = []

      if (Utils.isNotNull(data)) {
        newData = data.sort((a, b) => Number.parseInt(a.idDepartamentoSecaoExterno) - Number.parseInt(b.idDepartamentoSecaoExterno))
        let departamentos = [new SelectValue(0, "Selecione um Departamento", true)]

        newData.forEach((depto) => {
          departamentos.push(
            new SelectValue(depto.idDepartamentoSecao, depto.idDepartamentoSecaoExterno + " - " + depto.descricao, false, depto.secoes)
          );
        });

        this.setState({
          filtrosDepartamentos: {
            departamentos
          },
          editar: false
        });
      }

    })
      .catch((error) => console.log(error));
  }

  confirmarDepSecaoProduto() {
    const { selectDep, selectSec, idProdutoWebDepartametoSecao, editar } = this.state

    let obj = {
      idProdutoWebDepartamentoSecao: idProdutoWebDepartametoSecao,
      idDepartamentoWeb: selectDep,
      idSecaoWeb: selectSec
    }

    if(editar) {
      ProdutoCataloService.alterarDepartamentoSec(obj).then((data) =>{
        if (Utils.isNotNull(data)) {
          Notification.success(data.mensagem)
          this.setState({
            loading: true,
            editar: false
          })
        } else {
          Notification.danger(Utils.isNotNull(data) ? data.mensagem : "Não Foi Possivel Atualizar Departamento e Seção")
        }
      })
    }


  }


  mudarDepSecProduto() {

    ModalNotification.renderMessage({
      title: "Alterar Departamento e Seção ",
      body: (
        `Deseja mudar o departamento e a seção desse produto?`
      ),
      handlerConfirm: () => this.confirmarDepSecaoProduto(),
      handlerCancel: () => { },
      labelButtonCancel: "Cancelar",
    });
  }

  getSecoes(dep) {
    const options = [new SelectValue(0, "Selecione um Secões", true)]
    let secao = [new SelectValue(0, "Selecione um Secões", true)]


    this.state.filtrosDepartamentos.departamentos.forEach(item => {
      if (item.id === Number.parseInt(dep)) {
        secao = item.obj
      }
    })

    let newData = secao.sort((a, b) => Number.parseInt(a.idDepartamentoSecaoExterno) - Number.parseInt(b.idDepartamentoSecaoExterno))

    newData.forEach(item => {
      options.push(
        new SelectValue(item.idDepartamentoSecao, item.idDepartamentoSecaoExterno + " - " + item.descricao, false)
      )
    })

    let obj = {
      numeroPagina: 1,
      departamentosFiltro: [
        {
          idFiltro: dep.toString()
        }
      ],
    }

    if (dep === 0) {
      this.setState({
        depSelecionado: dep,
        secoes: [],
        multiSelectSec: [],
        selectSec: "",
        editar: true,
      })
    } else {

      this.setState({
        secoes: options,
        objSecao: obj,
        selectDep: dep,
        editar: true,
      })

    }

  }


  render() {
    const { secoes, filtrosDepartamentos, selectDep, objProdutoDepartamentoSecao, selectSec } = this.state;

    return (
      <Div marginBottom="2">

        <Div inline="between" marginTop="5">
          <Div
            shadow
            responsive="6"
            className="border d-flex flex-column align-items-start"
          >
            <Label value="Departamento" col="12" />
            <Select
              placeholder="Departamentos"
              responsive="12"
              marginTop="2"
              handlerChange={(e) => this.getSecoes(e.target.value)}
              values={filtrosDepartamentos.departamentos}
              valueSelected={selectDep}
            />
          </Div>
          <Div
            shadow
            responsive="6"
            className="border d-flex flex-column align-items-start"
          >
            <Label value="Seção" col="12" />
            <Select
              responsive="12"
              marginTop="2"
              handlerChange={(e) =>
                this.setState({
                  objProdutoDepartamentoSecao: {
                    ...objProdutoDepartamentoSecao,
                    idSecaoWeb: e.target.value
                  },
                  selectSec: e.target.value,
                  editar: true
                })
              }
              values={secoes}
              valueSelected={selectSec}
            />
          </Div>

          <Div col="12" inline="end">
            {/* <ButtonIconOutLine
              value="Cancelar"
              style={{ maxWidth: "170px", height: "40px", marginTop: "27px" }}
              btn={Color.NODE.SECONDARY}
              handlerClick={() => this.setState({ editCustomDep: false })}
              col="2"
              className="custom-btn"
            /> */}

            <ButtonIconOutLine
              value="Confirmar"
              style={{ maxWidth: "170px", height: "40px", marginTop: "27px" }}
              btn={Color.NODE.PRIMARY}
              handlerClick={() => this.mudarDepSecProduto()}
              col="2"
              className="custom-btn"
            />

          </Div>

        </Div>

      </Div>
    );
  }
}

export default DepartamentoProdutoDetalhe;