import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Div, Label, I } from "..";
import {
  meses,
  diasDaSemana,
  getMesAtualDatePicker,
  getDiaDaSemanaPrimeiroDiaDoMes,
  getMesDatePicker
} from "./datas";
import { Type, Utils } from "../../utilities";
import moment from "moment";

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dias: [],
      mes: "",
      ano: "",
      diaAtual: "",
      invisible: false
    };

    this.idMesesDatePicker = Utils.uuidString();
  }

  UNSAFE_componentWillMount() {
    let mesAtual = getMesAtualDatePicker();
    this.setState({
      invisible: false,
      dias: mesAtual.dias,
      mes: mesAtual.mes,
      ano: mesAtual.ano
    });
  }

  changeDay(diasDoMes, diaMes) {
    this.props.handlerChangeDay(
      moment(new Date(diaMes.ano, diaMes.mes, diaMes.dia)).format(
        this.props.pattern
      )
    );
  }

  renderDiasDatePicker(diasDoMes, key) {
    return diasDoMes
      .filter(diaFilter => diaFilter.diaSemana === key)
      .map((diaMes, keyDia) => {
        return (
          <Label
            padding="1"
            pointer
            className="dia-change"
            key={keyDia}
            value={diaMes.dia}
            handlerClick={e => this.changeDay(diasDoMes, diaMes)}
          ></Label>
        );
      });
  }

  renderDiasEmBrancoAntes(key) {
    let primeiroDiaDoMesNaSemana = getDiaDaSemanaPrimeiroDiaDoMes(
      this.state.ano,
      this.state.mes.value
    );

    return key < primeiroDiaDoMesNaSemana ? (
      <Label value="#" invisible></Label>
    ) : null;
  }

  renderDiasEmBrancoDepois(dias, key) {
    if (key === 6) {
      let dia = dias.slice(-1)[0];
      let diaSemanaDoUltimoDia = dia.diaSemana;
      let index = 6 - diaSemanaDoUltimoDia;

      if (index > 0) {
        for (index; index < 7; index++) {
          return <Label value="#" invisible></Label>;
        }
      }
    }
  }

  carregarProximoMes(ano, mes) {
    let proximoMes = mes;
    if (proximoMes === 11) {
      proximoMes = 0;
    } else {
      proximoMes = mes + 1;
    }

    this.carregarMes(ano, proximoMes, false);
  }

  carregarMesAnterior(ano, mes) {
    let proximoMes = mes;
    if (proximoMes === 0) {
      proximoMes = 11;
    } else {
      proximoMes = mes - 1;
    }

    this.carregarMes(ano, proximoMes, false);
  }

  carregarMes(ano, mes, renderDatePicker) {
    let mesAtual = getMesDatePicker(ano, mes);

    this.setState(
      {
        invisible: false,
        dias: mesAtual.dias,
        mes: mesAtual.mes,
        ano: mesAtual.ano
      },
      () => {
        if (renderDatePicker) {
          this.setState({ ...this.state, invisible: false });
        }
      }
    );
  }

  getTemplateMeses() {
    return (
      <Div>
        <Div inline="between">
          {meses()
            .slice(0, 4)
            .map((mes, key) => {
              return (
                <Label
                  pointer
                  col="3"
                  className="ano"
                  key={key}
                  handlerClick={e =>
                    this.carregarMes(this.state.ano, mes.value, true)
                  }
                >
                  {mes.label}
                </Label>
              );
            })}
        </Div>
        <Div inline="between">
          {meses()
            .slice(4, 8)
            .map((mes, key) => {
              return (
                <Label
                  className="ano"
                  col="3"
                  key={key}
                  pointer
                  handlerClick={e =>
                    this.carregarMes(this.state.ano, mes.value, true)
                  }
                >
                  {mes.label}
                </Label>
              );
            })}
        </Div>
        <Div inline="between">
          {meses()
            .slice(8, 12)
            .map((mes, key) => {
              return (
                <Label
                  className="ano"
                  col="3"
                  key={key}
                  pointer
                  handlerClick={e =>
                    this.carregarMes(this.state.ano, mes.value, true)
                  }
                >
                  {mes.label}
                </Label>
              );
            })}
        </Div>
      </Div>
    );
  }

  handlerChangeAno(ano) {
    let mesAtual = getMesDatePicker(ano, this.state.mes.value);

    this.setState({
      invisible: false,
      dias: mesAtual.dias,
      mes: mesAtual.mes,
      ano: mesAtual.ano
    });
  }

  getTemplateAnos(ano) {
    let anosAnteriores = [];
    let anosPosteriores = [];
    [1, 2, 3, 4].forEach(i => {
      anosAnteriores.push(ano - i);
      anosPosteriores.push(ano + i);
    });

    let anos = [];
    anos = anos.concat(anosAnteriores.sort());
    anos.push(ano);
    anos = anos.concat(anosPosteriores);

    return (
      <Div inline="center">
        <I
          icon={Type.ICON.ARROW_CHEV_LEFT}
          pointer
          handlerClick={e => this.renderTemplateAnos(ano - 9)}
        ></I>
        <Div column>
          <Div inline="between" col="12">
            {anos.slice(0, 3).map((ano, key) => {
              return (
                <Label
                  pointer
                  col="4"
                  className="ano"
                  key={key}
                  handlerClick={e => this.handlerChangeAno(ano)}
                >
                  {ano}
                </Label>
              );
            })}
          </Div>
          <Div inline="between">
            {anos.slice(3, 6).map((ano, key) => {
              return (
                <Label
                  pointer
                  col="4"
                  className="ano"
                  key={key}
                  handlerClick={e => this.handlerChangeAno(ano)}
                >
                  {ano}
                </Label>
              );
            })}
          </Div>
          <Div inline="between">
            {anos.slice(6, 9).map((ano, key) => {
              return (
                <Label
                  pointer
                  col="4"
                  className="ano"
                  key={key}
                  handlerClick={e => this.handlerChangeAno(ano)}
                >
                  {ano}
                </Label>
              );
            })}
          </Div>
        </Div>
        <I
          icon={Type.ICON.ARROW_CHEV_RIGHT}
          pointer
          handlerClick={e => this.renderTemplateAnos(ano + 9)}
        ></I>
      </Div>
    );
  }

  renderTemplateMeses() {
    ReactDOM.render(
      this.getTemplateMeses(),
      document.getElementById(this.idMesesDatePicker)
    );
  }

  renderTemplateAnos(ano = this.state.ano) {
    ReactDOM.render(
      this.getTemplateAnos(ano),
      document.getElementById(this.idMesesDatePicker)
    );
  }

  getTemplateDatePicker() {
    const { dias, mes, ano } = this.state;
    return (
      <Div display={this.state.invisible ? "none" : "block"}>
        <Div inline="between">
          <Div>
            <Label
              value={`${mes.label} de `}
              pointer
              handlerClick={e => {
                this.setState(
                  {
                    ...this.state,
                    invisible: true
                  },
                  () => this.renderTemplateMeses()
                );
              }}
            ></Label>
            <Label
              value={ano}
              marginLeft="1"
              pointer
              handlerClick={e => {
                this.setState(
                  {
                    ...this.state,
                    invisible: true
                  },
                  () => this.renderTemplateAnos()
                );
              }}
            ></Label>
          </Div>
          <Div>
            <I
              size={Type.ICON.X}
              icon={Type.ICON.ARROW_CHEV_LEFT}
              padding="1"
              pointer
              handlerClick={e => this.carregarMesAnterior(ano, mes.value)}
            ></I>
            <I
              size={Type.ICON.X}
              icon={Type.ICON.ARROW_CHEV_RIGHT}
              padding="1"
              pointer
              handlerClick={e => this.carregarProximoMes(ano, mes.value)}
            ></I>
          </Div>
        </Div>
        <Div className="d-flex align-items-start flex-wrap justify-content-between">
          {diasDaSemana.map((diaSemana, key) => {
            return (
              <Div column key={key}>
                <Label value={diaSemana} underline></Label>

                {this.renderDiasDatePicker(dias, key)}
                {this.renderDiasEmBrancoDepois(dias, key)}
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }

  render() {
    return (
      <Div
        borderLeft={this.props.borderLeft}
        colorText={this.props.colorText}
        responsive={this.props.responsive}
        bg={this.props.bg}
        padding="2"
      >
        {this.getTemplateDatePicker()}
        <Div
          id={this.idMesesDatePicker}
          display={this.state.invisible ? "block" : "none"}
        ></Div>
      </Div>
    );
  }
}

export default DatePicker;
