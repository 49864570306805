import React, { Component } from "react";

import { Div, Input, Label } from "../../../components";

import './radio.css'

class Radio extends Component {
  getClassRadio() {
    let classe = "custom-control custom-radio ";
    classe = classe.concat(this.props.inline ? "custom-control-inline " : "");
    classe = classe.concat(this.props.className ? `${this.props.className} ` : "");
    return classe;
  }

  render() {
    return (
      <Div className={this.getClassRadio()} col={this.props.col}>
        <Input
          type="radio"
          className="custom-control-input cursor-pointer"
          name={this.props.name}
          value={this.props.value}
          id={this.props.id}
          handlerChange={this.props.handlerChange}
          checked={this.props.checked}
        ></Input>
        <Label
          className="custom-control-label cursor-pointer"
          for={this.props.id}
          ml={this.props.ml}
        >
          {this.props.label}
        </Label>
      </Div>
    );
  }
}

export default Radio;
