import React from "react";
import { Component } from "react";
import { Div, I, If, Label, Title } from "../../../components";
import { Color, EndPoints, Http, Mask, Type, Utils } from "../../../utilities";

import {ReactComponent as IconValor} from "../../../assets/images/icon-valor.svg";
import {ReactComponent as IconEntrega} from "../../../assets/images/icon-entrega.svg";
import {ReactComponent as IconProduto} from "../../../assets/images/icon-produto.svg";

import BotaoPaginaInicial from "../../utils/BotaoPaginaInicial";
import TemplateItensPedidoDetalheMobile from "./TemplateItensPedidoDetalheMobile";

const styles = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
  },
  labelValorAlterado: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: "13px",
  },
  labelBorda: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    borderBottom: "1px dotted #E5E5E5",
  },
  labelAdicionarLista: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    borderRadius: 10,
    fontSize: 12,
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
  },
};

class PedidoDetalheClienteMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pedidoResumo: props.location.state.pedido,
      pedido: {},
    };
  }

  componentDidMount() {
    Http.get(
      EndPoints.PEDIDO.replace(
        "%s",
        this.state.pedidoResumo.idLancamentoPedidoApp
      )
    )
      .then(({ status, data }) => {
        if (status) {
          this.setState({ pedido: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getQuantidadeProdutos(pedido) {
    return Utils.isValueValid(pedido) ? Array.from(pedido.itens).length : 0;
  }

  renderResumoDaCompra() {
    const { pedido } = this.state;
    let quantidadeItens = this.getQuantidadeProdutos(pedido);

    let alteradoNaSeparacao =
      Number(pedido.valorTotalSeparacao) > 0 &&
      Number(pedido.valorTotal).toFixed(2) !==
        Number(pedido.valorTotalSeparacao).toFixed(2);

    return (
      <Div inline="center" className="align-self-start w-100" marginTop="3">
        <Label
          value="Resumo da compra"
          style={styles.labelResumoCompra}
          family="SemiBold"
        />
        <Label style={styles.labelBorda} col="12" />

        <Div className="w-100">
          <Div inline="start">
            <IconProduto style={{ margin: 2 }} />
            <Label
              value={`${quantidadeItens} produtos`}
              style={styles.labelCinza}
              family="Light"
              margin="2"
            />
          </Div>
          <Div inline="start">
            <IconValor style={{ width: "22px", margin: 2 }} />
            <Div
              className="d-flex flex-column justify-content-start"
              marginLeft="2"
            >
              <Title
                value="Subtotal do carrinho"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
              <Title
                value={Mask.formatValueBr(pedido.valorTotal || 0)}
                type="h6"
                style={styles.labelBlue}
                family="Bold"
              />
            </Div>
          </Div>
          <Div inline="start">
            <IconEntrega style={{ width: "30px", margin: 2 }} />
            <Div className="d-flex flex-column justify-content-start">
              <Title
                value="Taxa de entrega"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
              <Title
                value={Mask.formatValueBr(pedido.valorTaxaEntrega || 0)}
                type="h6"
                style={styles.labelBlue}
                family="Bold"
              />
            </Div>
          </Div>
          <Div inline="start">
            <IconValor style={{ width: "22px", margin: 2 }} />
            <Div
              className="d-flex flex-column justify-content-start"
              marginLeft="2"
            >
              <Title
                value="Taxa de serviço"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
              <Title
                value={Mask.formatValueBr(pedido.valorTaxaServico || 0)}
                type="h6"
                style={styles.labelBlue}
                family="Bold"
              />
            </Div>
          </Div>
          <Div inline="start">
            <IconValor style={{ width: "22px", margin: 2 }} />
            <Div
              className="d-flex flex-column justify-content-start"
              marginLeft="2"
            >
              <Title
                value="Desconto"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
              <Title
                value={Mask.formatValueBr(pedido.valorDescontoCupom || 0)}
                type="h6"
                style={styles.labelRed}
                family="Bold"
              />
            </Div>
            <If and value1={Utils.isValueValid(pedido.codigoCupomDesconto)}>
              <Label
                value={`${pedido.codigoCupomDesconto}`}
                family="Bold"
                marginLeft="4"
                style={{
                  fontSize: 12,
                  color: Color.ECOMMERCE.WHITE,
                  backgroundColor: Color.ECOMMERCE.LARANJA,
                  padding: 5,
                  borderRadius: 10,
                }}
              />
            </If>
          </Div>
          <Div inline="start" className="border-top " shadow>
            <IconValor style={{ width: "22px", margin: 2 }} />
            <Div
              className="d-flex flex-column justify-content-start"
              marginLeft="2"
            >
              <Title
                value="Total do pedido"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
              <Title
                value={Mask.formatValueBr(pedido.valorTotal || 0)}
                type="h6"
                style={
                  alteradoNaSeparacao
                    ? styles.labelValorAlterado
                    : styles.labelBlue
                }
                family="Bold"
              />
            </Div>
          </Div>
          <If and value1={alteradoNaSeparacao}>
            <Div inline="start" className="border-top " shadow>
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Total (Alterado na separação)"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(pedido.valorTotalSeparacao || 0)}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
          </If>
          <Label style={styles.labelBorda} col="12" />

          <Div
            className="d-flex justify-content-center align-items-center align-self-center"
            style={{
              padding: 3,
              border: `3px solid ${Color.ECOMMERCE.LARANJA}`,
              borderRadius: 10,
              color: Color.ECOMMERCE.LARANJA,
              opacity: 0.8,
              lineHeight: "10px",
            }}
            pointer
            handlerClick={() => this.props.history.replace("/cliente/pedidos")}
          >
            <Label
              value="Voltar para meus pedidos"
              pointer
              family="SemiBold"
              margin="2"
            />
            <I icon={Type.ICON.ARROW_CIRCLE_LEFT} sizeIcon="2" />
          </Div>
          <BotaoPaginaInicial
            history={this.props.history}
            style={{ marginTop: 10 }}
          />
        </Div>
      </Div>
    );
  }

  render() {
    return (
      <Div inline="start" margin="2">
        {this.renderResumoDaCompra()}
        <TemplateItensPedidoDetalheMobile
          pedido={this.state.pedido}
          parametros={this.props.parametros}
        />
      </Div>
    );
  }
}

export default PedidoDetalheClienteMobile;
