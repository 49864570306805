import React from "react";
import { Label, Span, Input, Div } from "../..";
import { Type, Color, Utils } from "../../../utilities";
import {
  className,
  slider,
  backGround,
  margin,
  padding,
  inline,
} from "../../css";

const getClassToggle = (props) => {
  let classe = "slider ";
  classe = classe.concat(props.rounded ? "round " : "");
  classe = classe.concat(slider(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(className(props));
  return classe;
};

const changeChecked = (e, props) => {
  if (props.handlerChange) {
    const { value, label, name } = props;
    props.handlerChange({
      checked: e.target.checked,
      value,
      label,
      name,
    });
  }
};

const CheckboxToggle = (props) => {
  let idInput = Utils.uuidString();

  return (
    <Div
      inline={props.inline || "center"}
      display={props.display}
      marginTop={props.marginTop}
      marginRight={props.marginRight}
    >
      {props.labelLeft && (
        <Label
          value={props.labelLeft}
          textColor={Color.NODE.WHITE}
          family="SemiBold"
        />
      )}
      <Label className="switch" marginLeft="1" marginRight="1">
        <Input
          id={idInput}
          type={Type.INPUT.CHECKBOX}
          checked={props.checked}
          handlerChange={(e) => changeChecked(e, props)}
          handlerClick={props.handlerClick}
        />
        <Span className={getClassToggle(props)} inline="end"></Span>
      </Label>
      {props.labelRight && (
        <Label
          pointer
          value={props.labelRight}
          textColor={Color.NODE.WHITE}
          for={idInput}
          family="SemiBold"
        />
      )}
    </Div>
  );
};

CheckboxToggle.defaultProps = {
  rounded: true,
};

export default CheckboxToggle;
