import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Div, Image, Label, ModalNotification } from "../../../components";
import {
  atualizarCarrinho,
  setProdutos,
  setEmpresa,
  setParametrosPedido,
} from "../../../redux/actions";
import { Color, EndPoints, Http, Utils } from "../../../utilities";

import ProdutoDetalheMobile from "../../produtos/mobile/ProdutoDetalheMobile";

import UsuarioUtils from "../../utils/UsuarioUtils";
import CarouselBannerGenericoMobile from "../../../components/carousel/CarouselBannerGenericoMobile";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import { EmpresaUtils, ProdutosDestaqueMobile } from "../..";

const styles = {
  titleCarouselOferta: {
    fontSize: Utils.isViewMobileLarguraPequeno() ? 12 : 15,
    color: "white",
    whiteSpace: "wrap",
    width: Utils.isViewMobileLarguraPequeno() ? 100 : 200,
    position: "absolute",
    transform: Utils.isViewMobileLarguraPequeno()
      ? `translateX(12px)`
      : `translateX(30px)`,
  },
  titleCarouselOfertaLight: {
    fontSize: Utils.isViewMobileLarguraPequeno() ? 12 : 15,
    color: "white",
    whiteSpace: "wrap",
    width: Utils.isViewMobileLarguraPequeno() ? 100 : 200,
    position: "absolute",
    transform: Utils.isViewMobileLarguraPequeno()
      ? `translateX(12px)`
      : `translateX(30px)`,
  },
};

class PrincipalColorauMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: [],
      produtos: [],
      menus: [],
      produtosVisualizados: [],
      produtosSimilares: [],
      carrinho: props.carrinho,
      empresa: {},
      zIndexHeader: 1001,
      caracteristicaSelecionada: "",
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
    this.visualizarProduto = this.visualizarProduto.bind(this);
    this.listarProdutosSimilares = this.listarProdutosSimilares.bind(this);
    this.visualizarProdutosAnuncio = this.visualizarProdutosAnuncio.bind(this);

    document.title = "Tintas Colorau";
  }

  UNSAFE_componentWillMount() {
    this.consultarParametrosPedido();
    this.consultarUltimosProdutosVisualizados();
    this.consultarOfertas();
  }

  consultarUltimosProdutosVisualizados() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();

    if (Utils.isValueValid(cliente)) {
      Http.get(`/produto/visualizados/cliente/${cliente.idCliente}`)
        .then(({ status, data }) => {
          if (status) {
            this.setState({ produtosVisualizados: data });
          }
        })
        .catch((erro) => console.log(erro));
    }
  }

  listarMenus() {
    Http.get(EndPoints.MENU)
      .then(({ status, data }) => {
        if (status) {
          this.setState({ menus: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  componentDidMount() {
    this.consultarEmpresa();
    this.listarMenus();
  }

  consultarEmpresa() {
    Http.get("empresa")
      .then(({ status, data }) => {
        if (status) {
          this.setState({ empresa: data }, () => this.props.setEmpresa(data));
        }
      })
      .catch((erro) => console.log(erro));
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.props.setParametrosPedido(data);
            CarrinhoUtils.calcularValorTaxaEntrega(
              this.props.parametros,
              this.props.carrinho,
              this.props.atualizarCarrinho
            );
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  consultarOfertas() {
    Http.get("oferta")
      .then(({ status, data }) => {
        if (status) {
          this.setState({ anuncios: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  listarProdutosSimilares(produto, updateItem) {
    Http.get(EndPoints.PRODUTOS_SIMILARES.replace("%s", produto.idProdutoWeb))
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtosSimilares: data }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        } else {
          this.setState({ produtosSimilares: [] }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        }
      })
      .catch((error) => console.log(error));
  }

  visualizarProduto = (produto, updateItem) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalheMobile
        produto={produto}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          if (Utils.isFunction(updateItem)) {
            updateItem(produto.quantidade);
          }
        }}
        history={this.props.history}
        empresa={this.state.empresa}
      />
    );
  };

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.props.parametros
    );
  }

  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
  }) {
    if (
      Utils.isValueValid(marcaFiltro) ||
      Utils.isValueValid(idDepartamentoSecaoFiltro)
    ) {
      this.props.history.push("/anuncio/produtos", {
        titulo,
        idAnuncioOferta,
        idDepartamentoSecaoFiltro,
        tipoDepartamentoSecao,
        marcaFiltro,
        empresa: this.state.empresa,
        possuiProdutos,
      });
    }
  }

  getItensBannerMobile() {
    let { anuncios } = this.state || {};
    let especiais = Array.from(anuncios.especial || []);

    let especiaisPagina = Utils.quebrarArray(especiais, 3);

    let itens = [];

    let WIDTH = window.innerWidth / 2.5;
    let HEIGHT_DESTAQUE = window.innerHeight / 4;
    let HEIGHT_MICRO = Number(window.innerHeight / 8) - Number(2);

    especiaisPagina.forEach((itemPagina) => {
      itens.push(
        <Div inline="center">
          <Div
            inline="center"
            margin="1"
            handlerClick={() => this.visualizarProdutosAnuncio(itemPagina[0])}
          >
            <Image
              src={
                Utils.isValueValid(itemPagina[0]) ? itemPagina[0].urlFoto : ""
              }
              width={WIDTH}
              styleImage={{
                height: HEIGHT_DESTAQUE,
              }}
            />
          </Div>
          <Div column>
            {itemPagina[1] && (
              <Div
                marginBottom="1"
                marginTop="1"
                inline="center"
                handlerClick={() =>
                  this.visualizarProdutosAnuncio(itemPagina[1])
                }
              >
                <Image
                  src={
                    Utils.isValueValid(itemPagina[1])
                      ? itemPagina[1].urlFoto
                      : ""
                  }
                  width={WIDTH}
                  styleImage={{
                    height:
                      itemPagina[1].tipo === "DESTAQUE"
                        ? HEIGHT_DESTAQUE
                        : HEIGHT_MICRO,
                  }}
                />
              </Div>
            )}
            {itemPagina[2] && (
              <Div
                marginBottom="1"
                inline="center"
                handlerClick={() =>
                  this.visualizarProdutosAnuncio(itemPagina[2])
                }
              >
                <Image
                  src={
                    Utils.isValueValid(itemPagina[2])
                      ? itemPagina[2].urlFoto
                      : ""
                  }
                  width={WIDTH}
                  styleImage={{
                    height: HEIGHT_MICRO,
                  }}
                />
              </Div>
            )}
          </Div>
        </Div>
      );
    });

    return [...itens];
  }

  getItensBanner() {
    let { anuncios } = this.state || {};
    let especiais = Array.from(anuncios.especial || []);

    let especiaisPagina = Utils.quebrarArray(especiais, 4);

    let itens = [];

    especiaisPagina.forEach((itemPagina) => {
      itens.push(
        <Div inline="end">
          <Div
            margin="2"
            inline="center"
            handlerClick={() => this.visualizarProdutosAnuncio(itemPagina[0])}
          >
            <Label
              value={
                Utils.isValueValid(itemPagina[0]) ? itemPagina[0].titulo : ""
              }
              style={styles.titleCarouselOferta}
              family="SemiBold"
              className="text-border-black"
            />
            <Image
              src={
                Utils.isValueValid(itemPagina[0]) ? itemPagina[0].urlFoto : ""
              }
              width="320px"
              height="350x"
            />
          </Div>
          <Div column margin="2">
            <Div
              marginTop="1"
              marginBottom="1"
              inline="center"
              handlerClick={() => this.visualizarProdutosAnuncio(itemPagina[1])}
            >
              <Label
                value={
                  Utils.isValueValid(itemPagina[1]) ? itemPagina[1].titulo : ""
                }
                width="320px"
                family="SemiBold"
                style={styles.titleCarouselOfertaLight}
                className="text-border-black"
              />
              <Image
                src={
                  Utils.isValueValid(itemPagina[1]) ? itemPagina[1].urlFoto : ""
                }
                width="320px"
              />
            </Div>
            <Div
              marginTop="1"
              marginBottom="1"
              inline="center"
              handlerClick={() => this.visualizarProdutosAnuncio(itemPagina[2])}
            >
              <Label
                value={
                  Utils.isValueValid(itemPagina[2]) ? itemPagina[2].titulo : ""
                }
                family="SemiBold"
                style={styles.titleCarouselOfertaLight}
                className="text-border-black"
              />
              <Image
                src={
                  Utils.isValueValid(itemPagina[2]) ? itemPagina[2].urlFoto : ""
                }
                width="320px"
              />
            </Div>
          </Div>
          <Div
            margin="2"
            inline="center"
            handlerClick={() => this.visualizarProdutosAnuncio(itemPagina[3])}
          >
            <Label
              value={
                Utils.isValueValid(itemPagina[3]) ? itemPagina[3].titulo : ""
              }
              style={styles.titleCarouselOferta}
              family="SemiBold"
              className="text-border-black"
            />
            <Image
              src={
                Utils.isValueValid(itemPagina[3]) ? itemPagina[3].urlFoto : ""
              }
              width="270px"
              height="270px"
            />
          </Div>
        </Div>
      );
    });

    return [...itens];
  }

  getItensBannerTopo() {
    const { anuncios } = this.state;
    let itens = [];

    let itensTopo = Array.from(anuncios.banner || []);

    if (Utils.isValueValid(itensTopo)) {
      itensTopo.forEach((item) => {
        itens.push(
          <img
            src={item.urlFotoMobile}
            width="100%"
            className="cursor-pointer"
            onClick={() => this.visualizarProdutosAnuncio(item)}
            alt="Banner Topo"
          />
        );
      });
    }

    return itens;
  }

  getCaracteristicasDepartamento = () => {
    let map = new Map();
    map.set("CIMENTO QUEIMADO", [
      "Efeito perolizado",
      "Fácil aplicação",
      "Ótimo rendimento",
      "Pronto apra uso",
      "Sem cheiro",
      "Excelente cobertura",
      "Efeito brilhante e aveludado",
      "Secagem rápida",
    ]);

    map.set("TINTAS LISAS", [
      "Acabamento fosco ou brilhante",
      "Uso interno e externo",
      "Fácil aplicação",
      "Resistente as intempéries",
      "Alto rendimento",
      "Secagem rápida",
      "Ótima cobertura",
    ]);

    map.set("REVESTIMENTOS", [
      "Acabamento texturizado",
      "Uso interno e externo",
      "Preenche superfícies porosas",
      "Corrige imperfeições",
      "Aplicável sobre reboco, blocos de concreto, fibrocimento, massa corrida",
      "Pode ser utilizado em repinturas sobre PVA ou acrílico",
    ]);

    map.set("COMPLEMENTOS", [
      "Fácil aplicação",
      "Proporciona uniformidade",
      "Excelente secagem",
      "Resistente a alcalinidade",
      "Ótimo poder de cobertura",
      "Grande poder de penetração",
      "Melhora a durabilidade de tintas e massas",
    ]);

    map.set("MASSAS", [
      "Secagem rápida",
      "Excelente poder de preenchimento",
      "Fácil aplicação",
      "Ótima aderência",
      "Acabamento sofisticado",
    ]);

    return map;
  };

  renderPrincipaisCaracteristicas = (produto, caracteristicas) => {
    ModalNotification.render(
      produto,
      <Div column>
        <Image
          src="https://www.colorau.mobioh.com.br/imgs/cimentocolorauu.webp"
          width="300px"
        />
        <Div className="d-flex flex-column">
          {Array.from(caracteristicas).map((caract, index) => {
            return (
              <Label
                value={`- ${caract}`}
                family="SemiBold"
                style={{
                  fontSize: 20,
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                }}
                key={index}
              />
            );
          })}
        </Div>
      </Div>
    );
  };

  renderTecnologiaProdutos = () => {
    let corSecundaria = EmpresaUtils.getCorSecundaria(this.state.empresa);
    let mapDepartamentos = this.getCaracteristicasDepartamento();
    let keys = Array.from(mapDepartamentos.keys());

    return (
      <Div>
        <Div inline="center">
          <Div
            //style={{ position: "absolute", transform: `translateX(-335px)` }}
            className="d-flex flex-column"
          >
            <Label
              value="Principais características"
              inline="center"
              family="Bold"
              style={{ fontSize: 25, color: "#674B4F" }}
            />
            <Div
              style={{
                backgroundColor: corSecundaria,
                width: window.innerWidth - 20,
                padding: 10,
                borderRadius: 20,
                color: "white",
                fontSize: 20,
              }}
              className="d-flex flex-column"
            >
              {keys.map((depto, index) => {
                return (
                  <Label
                    pointer
                    value={depto}
                    handlerClick={() =>
                      this.setState(
                        { caracteristicaSelecionada: depto },
                        () => {
                          this.renderPrincipaisCaracteristicas(
                            depto,
                            mapDepartamentos.get(depto)
                          );
                        }
                      )
                    }
                    key={index}
                    family="SemiBold"
                    style={
                      depto === this.state.caracteristicaSelecionada
                        ? {
                            backgroundColor: "#A0525E",
                            padding: 5,
                            borderRadius: 10,
                          }
                        : null
                    }
                  />
                );
              })}
            </Div>
          </Div>
          {/**
             * <span
            style={{
              border: `1px dashed ${Color.ECOMMERCE.CINZA_ESCURO}`,
              width: 120,
              position: "absolute",
              transform: `rotate(135deg) translate(-230px, 0px)`,
            }}
          />
          <span
            style={{
              border: `1px dashed ${Color.ECOMMERCE.CINZA_ESCURO}`,
              width: 350,
              position: "absolute",
              transform: `translate(383px, -205px)`,
            }}
          />
          <Image
            src="https://www.colorau.mobioh.com.br/imgs/cimentocolorauu.webp"
            width="400px"
          />
          <span
            style={{
              border: `1px dashed ${Color.ECOMMERCE.CINZA_ESCURO}`,
              width: 120,
              position: "absolute",
              transform: `rotate(45deg) translate(225px, 0px)`,
            }}
          />
          <span
            style={{
              border: `1px dashed ${Color.ECOMMERCE.CINZA_ESCURO}`,
              width: 350,
              position: "absolute",
              transform: `translate(380px, 203px)`,
            }}
          />
           <Div
            //style={{ position: "absolute", transform: `translateX(335px)` }}
            className="d-flex flex-column ml-3 mt-5 align-self-start"
          >
            <Label
              value="Principais características"
              family="Bold"
              style={{ fontSize: 25, color: "#674B4F" }}
            />
            <Div
              className="d-flex flex-column align-items-start "
              style={{ width: 400 }}
            >
              {Array.from(
                mapDepartamentos.get(this.state.caracteristicaSelecionada)
              ).map((caract, index) => {
                return (
                  <Label
                    value={`- ${caract}`}
                    family="SemiBold"
                    style={{
                      fontSize: 20,
                      color: Color.ECOMMERCE.CINZA,
                      listStyleType: "circle",
                    }}
                    key={index}
                  />
                );
              })}
            </Div>
          </Div>
             */}
        </Div>
      </Div>
    );
  };

  render() {
    return (
      <>
        <Div className="w-100 overflow-hidden" style={{ marginTop: 20 }}>
          <CarouselBannerGenericoMobile
            timeSlide={7000}
            itens={this.getItensBannerTopo()}
            slidesPerPage={1}
            dots
          />
        </Div>
        <ProdutosDestaqueMobile
          history={this.props.history}
          handlerClick={this.listarProdutosSimilares}
          empresa={this.state.empresa}
          handlerAddItemCarrinho={(produto) =>
            this.adicionarItemNoCarrinho(produto)
          }
        />
        {Utils.isValueValid(this.state.empresa) &&
          this.renderTecnologiaProdutos()}
        <Div
          id="divDepartamentosViewPrincipal"
          handlerClick={(e) => e.preventDefault()}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.parametrosPedidoState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { atualizarCarrinho, setProdutos, setEmpresa, setParametrosPedido },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrincipalColorauMobile);
