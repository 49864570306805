import React, { Component } from "react";
import { Div, ModalCustom, Notification, Table } from "../../components";
import ConcorrentesService from "../../service/ConcorrentesService";
import { Type } from "../../utilities";

class EditarProdutoPreco extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produto: [],
      produtoEditado: {},
      dataPadrao: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      produto: [
        this.props.produtoPreco
      ]
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.produtoPreco !== this.props.produtoPreco) {
      this.setState({
        produto: [
          this.props.produtoPreco
        ]
      })
    }
  }



  atualizarProduto = () => {
    const { produtoEditado } = this.state;

    if (Object.keys(produtoEditado).length !== 0) {
      let obj = {
        idConcorrente: produtoEditado.idConcorrente,
        idConcorrentePreco: produtoEditado.idConcorrentePreco,
        idProdutoWeb: produtoEditado.idProdutoWeb,
        precoVenda: produtoEditado.precovendaconcorrente,
        dataExpiracao: produtoEditado.dataexpiracao ? produtoEditado.dataexpiracao + " 00:00:00.0" : produtoEditado.dataExpiracao
      }

      console.log(obj)

      ConcorrentesService.createConcorrentesPrecos([obj]).then(({ data, status }) => {
        if (status) {
          Notification.success(data.mensagem);
          this.props.setLoadingProdutosPreco(true);
          this.setState({
            produtosSelecionados: [],
          })
        } else {
          Notification.danger("error ao sincronizar produto!");
        }
      })
    } else {
      Notification.danger("Informe o preço de venda")
    }

  }

  render() {
    const { produto, dataPadrao } = this.state;
    return (
      <ModalCustom
        title="Editar Pesquisa de Preço"
        showModal={this.props.closeModal}
        setShowModal={this.props.setCloseModal}
        confirm={() => this.atualizarProduto()}
      >
        <Div col="12">
          <Table
            columns={[
              { value: "Código de Barras", align: "center" },
              { value: "Descricao", align: "left" },
              { value: "Concorrente", align: "center" },
              { value: "Preço de Venda", align: "center" },
              { value: "Data de Expiração", align: "center" },
            ]}
            fields={[
              {
                value: "codigoBarrasProduto",
                align: "center",
                type: Type.DADO.STRING,
              },
              {
                value: "descricaoProduto",
                align: "left",
                type: Type.DADO.STRING,
              },
              {
                value: "descricaoConcorrente",
                align: "left",
                type: Type.DADO.STRING,
              },
              {
                input: true,
                typeInput: "number",
                value: "precovendaconcorrente",
                width: "150px",
              },
              {
                input: true,
                typeInput: "date",
                value: "dataexpiracao",
                valueInput: dataPadrao
              },
            ]}
            bordered
            orientation="center"
            pagination
            data={produto}
            error="Não foi possivel carregar as informações do Produto"
            handlerChangeInput={(data) => this.setState({ produtoEditado: data })}

          ></Table>
        </Div>
      </ModalCustom>
    )
  }
}
export default EditarProdutoPreco;