import { Keys, Utils } from "../../utilities";
import CryptoJS from "crypto-js";

export default class UsuarioUtils {
  static setUsuarioLojaWeb(usuario) {
    if (Utils.isValueValid(usuario)) {
      let usuarioEncrypt = CryptoJS.AES.encrypt(
        JSON.stringify({ ...usuario }),
        Keys.KEY_USUARIO_SECRET_LOJA_WEB
      );

      localStorage.setItem(Keys.KEY_USUARIO_LOJA_WEB, usuarioEncrypt);
    }
  }

  static getUsuarioLojaWeb() {
    let usuario = localStorage.getItem(Keys.KEY_USUARIO_LOJA_WEB);
    let usuarioDecrypt = null;
    if (Utils.isValueValid(usuario)) {
      try {
        usuarioDecrypt = CryptoJS.AES.decrypt(
          usuario,
          Keys.KEY_USUARIO_SECRET_LOJA_WEB
        ).toString(CryptoJS.enc.Utf8);
      } catch (error) {
        if (String(error).includes("Malformed UTF-8 data")) {
          localStorage.setItem(
            "error",
            "Erro ao realizar parse dos dados de usuário, provavelmente houve alteração na estrutura do usuário"
          );
          localStorage.removeItem(Keys.KEY_USUARIO_LOJA_WEB);
          Utils.refreshPage();
        }
      }
    }

    return JSON.parse(usuarioDecrypt);
  }

  static removerUsuarioLojaWeb() {
    localStorage.removeItem(Keys.KEY_USUARIO_LOJA_WEB);
  }

  static setDadosAcessoUsuario(usuario) {
    if (Utils.isValueValid(usuario)) {
      let usuarioEncrypt = CryptoJS.AES.encrypt(
        JSON.stringify({
          usuario,
        }),
        Keys.KEY_SALVAR_ACESSO_LOGIN_SECRET
      );

      localStorage.setItem(Keys.KEY_SALVAR_ACESSO_LOGIN, usuarioEncrypt);
    }
  }

  static removerDadosAcessoUsuario() {
    localStorage.removeItem(Keys.KEY_SALVAR_ACESSO_LOGIN);
  }

  static getDadosAcessoUsuario() {
    let usuario = localStorage.getItem(Keys.KEY_SALVAR_ACESSO_LOGIN);
    let carrinhoDecrypt = null;
    if (Utils.isValueValid(usuario)) {
      carrinhoDecrypt = CryptoJS.AES.decrypt(
        localStorage.getItem(Keys.KEY_SALVAR_ACESSO_LOGIN),
        Keys.KEY_SALVAR_ACESSO_LOGIN_SECRET
      ).toString(CryptoJS.enc.Utf8);
    }

    return JSON.parse(carrinhoDecrypt);
  }
}
