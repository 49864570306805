import React from "react";
import { Div } from "../../components";
import "./anuncio.css";

const WIDTH_IMAGE_OFERTA = 260;
const HEIGHT_IMAGE_OFERTA_1 = 410;
const HEIGHT_IMAGE_OFERTA_2 = 200;

const ItemOfertaPrioridadeMobile = ({
  image,
  isOfertaUm,
  handlerClick,
  widthImage,
  heightImage,
  labelsBanners,
  pagina,
  title,
  subTitle,
  exibirTitulo,
}) => {

  const newAnuncio = {
    titulo: title,
    preco: subTitle,
    exibirTitulo
  }
  
  return (
    <Div style={{ margin: 5 }} handlerClick={() => handlerClick()}>
      { labelsBanners && labelsBanners(newAnuncio) }
      <img
        src={image}
        alt="Imagem da oferta"
        style={{
          width: widthImage || WIDTH_IMAGE_OFERTA,
          height: isOfertaUm
            ? heightImage || HEIGHT_IMAGE_OFERTA_1
            : heightImage || HEIGHT_IMAGE_OFERTA_2,
          borderRadius: 10,
        }}
      />
    </Div>
  );
};

export default ItemOfertaPrioridadeMobile;
