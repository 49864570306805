import React from "react";
import { Component } from "react";

import { Div, If } from "../../components";
import CarouselBannerTop from "../../components/carousel/CarouselBannerTop";
import CarouselBannerTopMobile from "../../components/carousel/CarouselBannerTopMobile";
import { Utils } from "../../utilities";

import "./anuncio.css";

class AnunciosBannerTopo extends Component {
  render() {
    return (
      <Div className="w-100">
        <If and value1={Utils.isViewMobile()}>
          <CarouselBannerTopMobile
            itens={this.props.itens}
            history={this.props.history}
          />
        </If>
        <If and value1={!Utils.isViewMobile()}>
          <CarouselBannerTop
            itens={this.props.itens}
            history={this.props.history}
          />
        </If>
      </Div>
    );
  }
}

export default AnunciosBannerTopo;
