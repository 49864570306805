import { Http } from '../utilities';

class AssinaturaService {
  static async getListaDeCompraCliente(idCliente) {

    let res = null;
    await Http.get(`/listacompra/cliente/${idCliente}`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async getListaDeCompra(idListaCompra) {

    let res = null;
    await Http.get(`/assinatura/listacompra/${idListaCompra}`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async getListaDeCompraSugeridasCliente(idCliente) {

    let res = null;
    await Http.get(`/assinatura/listacompra/sugeridas/cliente/${idCliente}`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async consultarAssinaturaListaDeCompraClienteId(idListaCompra, idCliente) {

    let res = null;
    await Http.get(`/assinatura/listacompra/${idListaCompra}/cliente/${idCliente}`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }


  static async getParamentros(idCliente) {

    let res = null;
    await Http.get(`/assinatura/parametros/cliente/${idCliente}`).then(
      response => {
        res = response;
      },
    );
    return res;
  }

  static async putAssinatura(obj) {
    let res = null;

    await Http.put('/assinatura', obj).then(response => {
      res = response;
    });

    return res;

  }

  static async pausarAssinatura(obj) {
    let res = null;

    await Http.put('/assinatura/pausar', obj).then(response => {
      res = response;
    });

    return res;

  }

  static async cancelarAssinatura(obj) {
    let res = null;

    await Http.delete('/assinatura', obj).then(response => {
      res = response;
    });

    return res;

  }

  static async createAssinatura(obj) {
    let res = null;

    await Http.post('/assinatura', obj).then(response => {
      res = response;
    });

    return res;
  }

  static async adminListarAssinaturas(obj) {
    let res = null;

    await Http.post('/assinatura/filtro', obj).then(response => {
      res = response;
    });

    return res;
  }

  static async adminDashboardAssinatura(obj) {
    let res = null;

    await Http.post('/assinatura/dashboard', obj).then(response => {
      res = response;
    });

    return res;
  }

  static async adminGetProdutosLista(idListaCompra) {

    let res = null;
    await Http.get(`/listacompra/${idListaCompra}/produto`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }
}

export default AssinaturaService;

