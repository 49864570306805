import { Utils } from "../../utilities";

export default class SpaceUtils {
  static MOBILE_CONTAINER_CELULAR = window.innerWidth;
  static MOBILE_CONTAINER_TABLET = window.innerWidth;

  static MOBILE_CONTAINER_ATUAL = Utils.isViewTablet()
    ? this.MOBILE_CONTAINER_TABLET
    : this.MOBILE_CONTAINER_CELULAR;

  static getHeightImagemBannerVertical = () => {
    let isViewTablet = Utils.isViewTabletAll();
    let isViewTabletGrande = Utils.isViewTabletGrande();
    let isViewMobileGrande = Utils.isViewMobileGrande();

    let height = 240;

    if (isViewMobileGrande) height = 240;
    if (isViewTablet) height = 265;
    if (isViewTabletGrande) height = 325;

    return height;
  };

  static getWidthImagemBannerVertical = () => {
    let WIDTH_IMAGE = window.innerWidth - 20;
    let WIDTH_IMAGE_TABLET = window.innerWidth / 2.5;
    let isViewTablet = Utils.isViewTablet();

    return isViewTablet ? WIDTH_IMAGE_TABLET : WIDTH_IMAGE;
  };

  static getQuantidadeItensView = () => {
    let isViewMobileGrande = Utils.isViewMobileGrande();
    let isViewTablet = Utils.isViewTablet();
    let isViewTabletGrande = Utils.isViewTabletGrande();

    let quantidade = isViewMobileGrande || isViewTablet  ? 6 : 3;

    if (isViewTabletGrande) quantidade = 8;

    return quantidade;
  };
}
