import React, { useState } from "react";

import { Div, Input, I } from "../../../components";
import { Type } from "../../../utilities";
import { responsive } from "../../css";

import "./input.css";
import Label from "../../texts/label/Label";
import InputDefaultValue from "./InputDefaultValue";

const getClassIcon = props => {
  let classe = `d-flex input-group-text ${props.prepend ? "input-group-prepend" : "input-group-append"
    } `;
  classe = classe.concat(props.bgIcon ? `bg-${props.bgIcon} ` : "");
  return classe;
};

const getClassInput = props => {
  let classe = "";
  classe = classe.concat(props.formcontrol ? "form-control " : "");
  classe = classe.concat(responsive(props));
  classe = classe.concat(props.className ? `${props.className} ` : "");

  return classe;
};

const getInput = (props, typeDinamic) => {
  let input;
  input = props.defaultValue ? (
    <InputDefaultValue {...props} type={typeDinamic} className={getClassInput(props)} />
  ) : (
    <Input {...props} type={typeDinamic} className={getClassInput(props)} />
  );
  return input;
};

const InputGroup = props => {
  const [isOpen, setIsOpen] = useState(true);
  const [typeDinamic, setTypeDinamic] = useState(props.type);

  return (
    <Div>
      {props.label && <Label value={props.label}></Label>}
      <Div className="input-group" marginBottom="3">
        {props.prepend && (
          <Div className="input-group-prepend">
            <I
              className={`${getClassIcon(props)} `}
              icon={props.icon}
              size={Type.ICON.X}
              pointer
              colorText={props.colorIcon}
              bg={props.bgIcon}
              handlerClick={props.handlerIcon}
            >
              {props.valueGroupLeft ? props.valueGroupLeft : ""}
            </I>
          </Div>
        )}
        {getInput(props, typeDinamic)}
        {props.append && (
          <Div className="input-group-append">
            <I
              className={`${getClassIcon(props)} `}
              icon={props.icon}
              pointer
              size={Type.ICON.X}
              colorText={props.colorIcon}
              handlerClick={props.handlerIcon}
            >
              {props.valueGroupRight ? props.valueGroupRight : ""}
            </I>
          </Div>
        )}
        {
          props.name === "senha" &&
          <I
            className={`${getClassIcon(props)} `}
            icon={ isOpen ? Type.ICON.EYE : Type.ICON.EYE_SLASH}
            pointer
            size={Type.ICON.X}
            style={{ borderRadius: "0px"  }}
            colorText={props.colorIcon}
            handlerClick={
              () => { 
                if(isOpen){
                  setTypeDinamic("text")
                } else {
                  setTypeDinamic("password")
                }
                setIsOpen(!isOpen) 
              }
            }
          />
        }
      </Div>
    </Div>
  );
};

InputGroup.defaultProps = {
  prepend: true,
  formcontrol: true
};

export default InputGroup;
