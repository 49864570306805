import React from "react";
import { CheckboxToggle, TableColumn } from "../..";

const TableColumnActive = props => {
  return (
    <TableColumn className="pt-2" >
      <CheckboxToggle {...props.active} checked={props.checked} handlerChange={props.handlerActive}></CheckboxToggle>
    </TableColumn>
  );
};

export default TableColumnActive;
