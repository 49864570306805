import React from "react";
import { className } from "../../css";

const NavbarSubMenu = props => {
  const getClassDefault = () => {
    let classe = `sub-menu collapse`;
    classe = classe.concat(className(props))
    return classe;
  };

  return (
    <ul id={props.id} className={getClassDefault()} data-parent={`#${props.parent}`} style={{ marginLeft: "12px" }}>
      {props.value || props.children}
    </ul>
  );
};

export default NavbarSubMenu;
